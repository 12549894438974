import {
	DefaultButton,
	PrimaryButton,
	Separator,
	Stack,
	TextField,
} from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";
// import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import { LimePanel as Panel } from "../../../components/LimePanel";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { getAssetIdFromSystem, getParameterByName, guid } from "../../../util";
import AdvancedGraphForm from "../components/forms/AdvancedGraphForm";
import AlarmTextBoxForm from "../components/forms/AlarmTextBoxForm";
import BarGraphForm from "../components/forms/BarGraphForm";
import CustomTableForm from "../components/forms/CustomTableForm";
//import GltfLoaderForm from "../components/forms/GltfLoaderForm";
import CylinderGaugeForm from "../components/forms/CylinderGaugeForm2";
import GaugeForm from "../components/forms/GaugeForm";
import GeneralForm from "../components/forms/GeneralForm";
import HourlyHeatMapForm from "../components/forms/HourlyHeatMapForm";
import HtmlForm from "../components/forms/HtmlForm";
import IframeTileForm from "../components/forms/IframeTileForm";
import IndicatorForm from "../components/forms/IndicatorForm";
import MapForm from "../components/forms/MapForm";
import MeanBoxChartForm from "../components/forms/MeanBoxChartForm";
import PieChartForm from "../components/forms/PieChartForm";
//import SolidGaugeForm from "../components/forms/SolidGaugeForm";
import TableForm from "../components/forms/TableForm";
import TextBoxForm from "../components/forms/TextBoxForm";
import XYChartForm from "../components/forms/XYChartForm";
//import EditChartForm from "../components/forms/EditChartForm";
import StateReportForm from "../components/visuals/StateReport/StateReportForm";
import DataItemTableForm from "../components/forms/DataItemTableForm";
import DownloadButtonForm from "../components/visuals/DownloadButton/DownloadButtonForm";
import ActionDispatcherForm from "../components/visuals/ActionDispatcher/ActionDispatcherForm";
const forms = {
	Gauge: GaugeForm,
	CylinderGauge: CylinderGaugeForm,
	//GltfLoaderComponent: GltfLoaderForm,
	//SolidGauge: SolidGaugeForm,
	//BasicGraph: BasicGraphForm,
	TextBox: TextBoxForm,
	DataItemTable: DataItemTableForm,
	AlarmTextBox: AlarmTextBoxForm,
	Map: MapForm,
	AdvancedGraph: AdvancedGraphForm,
	FastGraph: AdvancedGraphForm,
	Table: TableForm,
	CustomTable: CustomTableForm,
	IframeTile: IframeTileForm,
	Indicator: IndicatorForm,
	BarGraph: BarGraphForm,
	MeanBoxChart: MeanBoxChartForm,
	PieChart: PieChartForm,
	HourlyHeatMap: HourlyHeatMapForm,
	//	EditChart: EditChartForm,
	XYChart: XYChartForm,
	StateReport: StateReportForm,
	CustomHTML: HtmlForm,
	DownloadButton: DownloadButtonForm,
	ActionDispatcher: ActionDispatcherForm,
};

const defaultState = {
	properties: {
		type: null,
		decimals: "2",
	},
	showGen: false,
};

class Modal extends Component {
	state = {
		properties: {
			type: null,
			decimals: "2",
		},
		showGen: false,
		showTile: true,
	};
	componentDidMount() {}
	handleData = (formData) => {
		const { tile } = this.props.ui.modal;
		var properties = { ...this.state.properties };
		properties.type = tile.properties.type;
		//Object.assign(properties, tile.properties);
		Object.assign(properties, formData);
		this.setState({ properties });
	};

	renderForm = () => {
		const { tile } = this.props.ui.modal;

		if (tile && tile.properties.type && forms[tile.properties.type.value]) {
			const { properties } = tile;
			const Form = forms[properties.type.value];
			return (
				<Form
					addData={this.handleData}
					properties={this.state.properties}
				/>
			);
		} else {
			return <div>No Type Selected</div>;
		}
	};

	componentDidUpdate() {}

	closeModal = () => {
		const { resetModal } = this.props;
		this.setState({ properties: { type: null } });
		resetModal();
	};

	submitModal = (e) => {
		e.preventDefault();
		const { resetModal, ui, editTile, activeTab } = this.props;
		const { properties } = this.state;
		const { type } = properties;
		const newTile = {
			...ui.modal.tile,
			properties: {
				...properties,
				settingsGuid: guid(),
				type: { ...type, default: false },
			},
		};
		if (properties.type) {
			// this.setState({properties: ...properties, type})
			editTile(newTile, activeTab.index);
		}
		this.setState(defaultState);
		resetModal();
	};

	handleChange = (e) => {
		e.preventDefault();
		const { properties } = this.state;
		this.setState({
			properties: {
				...properties,
				[e.target.name]: e.target.value,
			},
		});
	};

	render() {
		const { ui } = this.props;
		const { showGen, showTile } = this.state;
		//const dragHandlers = {onStart: this.onStart, onStop: this.onStop};
		const showHideClassName = `${
			ui.modal.visible ? "modal-show" : "modal-hide"
		}`;

		return (
			<Panel
				isBlocking={false}
				isLightDismiss={true}
				isFooterAtBottom={true}
				headerText="Edit tile"
				//type={PanelType.medium}
				isOpen={ui.modal.visible}
				onOpen={() => {
					this.setState({
						properties: this.props.ui.modal.tile.properties,
					});
				}}
				onDismiss={() => {
					this.props.resetModal();
				}}
				onRenderFooterContent={() => {
					return (
						<Stack
							tokens={{ childrenGap: 10 }}
							horizontal
							horizontalAlign="end"
						>
							<PrimaryButton
								text="Submit"
								onClick={this.submitModal}
							/>
							<DefaultButton
								text="Close"
								onClick={this.closeModal}
							/>
						</Stack>
					);
				}}
			>
				<TextField
					defaultValue={
						window.location.origin +
						"/assets/" +
						getParameterByName("assetId") +
						"/dashboard/" +
						getParameterByName("dashboardId") +
						"/tile/" +
						this.props?.ui?.modal?.tile?.id
					}
				></TextField>
				<form>
					<Separator>
						<Stack
							tokens={{ childrenGap: 5 }}
							horizontal
							onClick={() => {
								this.setState({ showGen: !showGen });
							}}
						>
							<p>General Settings</p>
							<Icon
								iconName={
									showGen
										? "CollapseContentSingle"
										: "ExploreContentSingle"
								}
							/>
						</Stack>
					</Separator>
					<div hidden={!showGen}>
						<GeneralForm addData={this.handleData} />
						<Separator></Separator>
					</div>
					<Separator>
						<Stack
							tokens={{ childrenGap: 5 }}
							horizontal
							onClick={() => {
								this.setState({ showTile: !showTile });
							}}
						>
							<p>Tile Settings</p>
							<Icon
								iconName={
									showTile
										? "CollapseContentSingle"
										: "ExploreContentSingle"
								}
							/>
						</Stack>
					</Separator>
					<div hidden={!showTile}>{this.renderForm()}</div>
				</form>
			</Panel>
		);
	}
}

function mapStateToProps({ ui, activeTab }) {
	return { ui, activeTab };
}

export default connect(mapStateToProps, actions)(Modal);
