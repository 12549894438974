import * as actions from "../../actions/index";
import {
	ActionButton,
	Breadcrumb,
	DefaultButton,
	Dropdown,
	PrimaryButton,
	Separator,
	Stack,
	Text,
	TextField,
} from "@fluentui/react";
import * as Papa from "papaparse";
import * as React from "react";
import { connect } from "react-redux";
import { Link, NavLink, Route, Switch } from "react-router-dom";
import JsonForm from "../../components/JsonForm/JsonForm";
import { Counties } from "./Counties";
import UPlotComponent from "../dashboard/components/visuals/uPlot/uPlot";
import { projection } from "./Projections";
class JobProvisioner extends React.Component {
	componentDidMount() {
		if (this.props.assets.length == 0) {
			this.props.loadAssets(true);
		}
		this.setState({ assets: this.props.assets }, () =>
			this.setSelectedAsset()
		);
		fetch("/config/customers.json")
			.then((r) => r.json())
			.then((customers) => this.setState({ customers }));
	}
	componentWillReceiveProps(nextProps) {
		this.setState({ assets: nextProps.assets }, () =>
			this.setSelectedAsset()
		);
	}
	state = {
		step: 2,
	};

	renderStep(step) {
		switch (step) {
			case 1:
				return <></>;
			case 2:
				return (
					<Stack>
						<JsonForm
							onFormUpdated={(val) =>
								this.setState({ jobDetails: val })
							}
							values={this.state.jobDetails}
							schema={this.customerForm()}
						></JsonForm>
						<Stack horizontal>
							{/* <DefaultButton onClick={()=>{this.setState({step:1})}}>Back</DefaultButton> */}
							<PrimaryButton
								onClick={() => {
									this.setState({ step: 3 });
								}}
							>
								Continue
							</PrimaryButton>
						</Stack>
					</Stack>
				);
			case 3:
				return (
					<Stack>
						<JsonForm
							onFormUpdated={(val) =>
								this.setState({ jobDetails: val })
							}
							values={this.state.jobDetails}
							schema={jobInfo}
						></JsonForm>
						<Stack horizontal>
							<DefaultButton
								onClick={() => {
									this.setState({ step: 2 });
								}}
							>
								Back
							</DefaultButton>
							<PrimaryButton
								onClick={() => {
									this.setState({ step: 4 });
								}}
							>
								Continue
							</PrimaryButton>
						</Stack>
					</Stack>
				);
			case 4:
				return (
					<Stack>
						<JsonForm
							onFormUpdated={(val) =>
								this.setState({ jobDetails: val })
							}
							values={this.state.jobDetails}
							schema={technicalForm}
						></JsonForm>
						<TextField
							label="Projection CSV"
							type="file"
							onChange={(e, val) => {
								const reader = new FileReader();
								reader.onload = (evt) => {
									var csv = Papa.parse(evt.target.result, {
										skipEmptyLines: true,
									});
									console.log(csv);
									var labels = csv.data[0].slice(1);
									var projections = csv.data[0]
										.slice(1)
										.map((label) => {
											var labelIndex =
												csv.data[0].indexOf(label);
											return {
												label,
												data: [
													csv.data
														.slice(1)
														.map((row, rowi) =>
															parseFloat(
																csv.data[
																	rowi + 1
																][0]
															)
														),
													csv.data
														.slice(1)
														.map((row, rowi) =>
															parseFloat(
																csv.data[
																	rowi + 1
																][labelIndex]
															)
														),
												],
											};
										});
									this.setState({ projections });
									console.log(projections);
								};
								reader.onerror = function (evt) {
									console.log("error reading file");
								};
								reader.readAsText(e.target.files[0]);
							}}
						></TextField>
						<ActionButton
							text="Download projection template"
							iconProps={{ iconName: "download" }}
							onClick={() => {
								const element = document.createElement("a");
								const file = new Blob([projection], {
									type: "text/csv",
								});
								element.href = URL.createObjectURL(file);
								element.download = "projection.csv";
								document.body.appendChild(element); // Required for this to work in FireFox
								element.click();
							}}
						></ActionButton>
						{this.state.projections && (
							<div
								style={{
									position: "relative",
									width: "100%",
									height: 400,
								}}
							>
								<Stack horizontal>
									{this.state.projections?.map((el) => {
										return (
											<div
												style={{
													position: "relative",
													width:
														100 /
															this.state
																.projections
																.length +
														"%",
													height: 300,
												}}
											>
												<UPlotComponent
													data={el.data}
													opts={{
														title: el.label,
														class: "spark",
														pxAlign: false,
														cursor: {
															show: false,
														},
														select: {
															show: false,
														},
														legend: {
															show: false,
														},
														scales: {
															x: {
																time: false,
																dir: -1,
																ori: 1,
															},
															y: {
																dir: 1,
																ori: 0,
															},
														},
														cursor: {
															lock: true,
															drag: {
																x: true,
																y: true,
															},
															sync: {
																key: 1,
																scales: [
																	"x",
																	"y",
																],
															},
														},
														legend: { show: true },
														axes: [
															{
																side: 3,
																stroke: "white",
																label: "Depth",
																//	show: false,
															},
															{
																stroke: "white",
																side: 2,
																label: el.label,
																//	show: false,
															},
														],
														series: [
															{
																label: "Depth (ft)",
															},
															{
																label: el.label,
																stroke: "#03a9f4",
															},
														],
													}}
												></UPlotComponent>
											</div>
										);
									})}
								</Stack>
							</div>
						)}

						<Stack horizontal>
							<DefaultButton
								onClick={() => {
									this.setState({ step: 3 });
								}}
							>
								Back
							</DefaultButton>
							<PrimaryButton
								onClick={() => {
									this.setState({ step: 5 });
								}}
							>
								Continue
							</PrimaryButton>
						</Stack>
					</Stack>
				);
			case 5:
				return (
					<Stack>
						<JsonForm
							onFormUpdated={(val) =>
								this.setState({ jobDetails: val })
							}
							values={this.state.jobDetails}
							schema={equipmentForm}
						></JsonForm>
						<Stack horizontal>
							<DefaultButton
								onClick={() => {
									this.setState({ step: 4 });
								}}
							>
								Back
							</DefaultButton>
							<PrimaryButton
								onClick={() => {
									this.setState({ step: 6 });
								}}
							>
								Continue
							</PrimaryButton>
						</Stack>
					</Stack>
				);
			case 6:
				var newJobId = `${this.state.jobDetails["Oil Company"]} - ${this.state.jobDetails["Pad"]} - ${this.state.jobDetails["Well"]}`;
				var oldJobId = `${this.state.initJobDetails["Oil Company"]} - ${this.state.initJobDetails["Pad"]} - ${this.state.initJobDetails["Well"]}`;
				var oldJobAsset = this.props.assets.find(
					(ass) =>
						ass.serialNumber.toLowerCase() == oldJobId.toLowerCase()
				);
				var newJobAsset = this.props.assets.find(
					(ass) =>
						ass.serialNumber.toLowerCase() == newJobId.toLowerCase()
				);
				console.log(newJobAsset);
				return (
					<Stack>
						<table>
							<thead>
								<tr>
									<th></th>
									<th>
										<h3>Previous job</h3>
									</th>
									<th>
										<h3>New job</h3>
									</th>
								</tr>
							</thead>
							<tbody>
								{this.formSchema().map((el) => {
									var newv = this.state.jobDetails[el.key];
									var prev =
										this.state.initJobDetails[el.key];
									return el.key ? (
										<tr
											style={{
												backgroundColor:
													prev == newv
														? "transparent"
														: "#ffff0055",
											}}
										>
											<td>{el.key}</td>
											<td>{prev}</td>
											<td>{newv}</td>
										</tr>
									) : (
										<></>
									);
								})}
							</tbody>
						</table>
						<Stack horizontal>
							<PrimaryButton
								onClick={() => {
									var attributes = Object.keys(
										this.state.jobDetails
									).map((key) => {
										return {
											attributeName: key,
											attributeValue:
												this.state.jobDetails[key],
										};
									});
									attributes.push({
										attributeName: "JobSerialNumber",
										attributeValue: newJobId,
									});
									attributes.push({
										attributeName: "JobAssetDefinition",
										attributeValue: "HCU-LIME",
									});
									this.state.projections?.forEach((proj) => {
										attributes.push({
											attributeName:
												"Projection-" + proj.label,
											attributeValue: JSON.stringify(
												proj.data[0].map((el, i) => {
													return [
														proj.data[0][i],
														proj.data[1][i],
													];
												})
											),
										});
									});
									Promise.all([
										fetch(
											`/api/serialNumber/HCU-LIME!${newJobId}/attributes`,
											{
												method: "POST",
												body: JSON.stringify(
													attributes
												),
											}
										),
										fetch(
											`/api/serialNumber/${this.state.asset.serialNumber}/attributes`,
											{
												method: "POST",
												body: JSON.stringify(
													attributes
												),
											}
										),
									]).then((res) =>
										this.props.loadAssets(true)
									);
								}}
							>
								Create job '{newJobId}'
							</PrimaryButton>

							{newJobAsset && (
								<Link
									to={`/assets/${newJobAsset.assetId}/dashboard`}
								></Link>
							)}
						</Stack>
					</Stack>
				);
		}
	}
	customerForm() {
		return [
			{
				key: "State",
				type: "enum",
				label: "State",
				options: statesApi,
			},
			{
				key: "County",
				type: "enum",
				label: "County",
				options: Counties.filter(
					(c) => c.st == this?.state?.jobDetails?.["State"]
				),
			},
			{
				key: "Oil Company",
				required: true,
				type: "enum",
				label: "Oil Company",
				options: this.state.customers?.map((el) => {
					return { key: el, text: el };
				}),
			},
			{
				required: true,
				key: "Pad",
				type: "text",
				label: "Pad",
				placeholder: "Pad",
			},
			{
				required: true,
				key: "Well",
				type: "text",
				label: "Well",
				placeholder: "Well",
			},
			{
				required: true,
				key: "API Number",
				type: "number",
				label: "API Number",
				placeholder: "XX-",
			},
		];
	}
	formSchema() {
		return [
			...this.customerForm(),
			...jobInfo,
			...technicalForm,
			...equipmentForm,
		];
	}
	setSelectedAsset(assetId) {
		if (!assetId) assetId = this.state.asset?.assetId;
		var values = {};
		var asset = this.props.assets.find((ass) => ass.assetId == assetId);
		if (asset) {
			asset.attributes?.forEach((att) => {
				if (
					this.formSchema()
						.map((el) => el.key)
						.indexOf(att.attributeName) >= 0
				) {
					values[att.attributeName] = att.attributeValue;
				}
			});
			this.setState({
				asset: asset,
				jobDetails: values,
				initJobDetails: values,
			});
		}
	}
	render() {
		return (
			<Stack style={{ margin: 10 }}>
				<Stack>
					<Dropdown
						label="Snubbing Unit"
						options={this.state?.assets
							?.filter(
								(el) =>
									el.assetDefinitionId == 1 ||
									el.assetDefinitionId == 76192
							)
							.map((el) => {
								return { key: el.assetId, text: el.assetAlias };
							})}
						onChange={(e, val) => {
							this.setSelectedAsset(val.key);
						}}
					></Dropdown>
					{/* <Stack horizontal><PrimaryButton onClick={()=>{this.setState({step:2})}}>Continue</PrimaryButton></Stack> */}
					<Stack horizontal verticalAlign="center">
						{[
							//{text:this.state.asset?`Select unit (${this.state.asset.assetAlias ?? ''})`:'Select Unit',isCurrentItem:true,onClick:()=>{this.setState({step:1})}},
							{
								text: "Customer info",
								onClick: () => {
									this.setState({ step: 2 });
								},
							},
							{
								text: "Job info",
								onClick: () => {
									this.setState({ step: 3 });
								},
							},
							{
								text: "Technical info",
								onClick: () => {
									this.setState({ step: 4 });
								},
							},
							{
								text: "Equipment",
								onClick: () => {
									this.setState({ step: 5 });
								},
							},
							{
								text: "Confirm",
								onClick: () => {
									this.setState({ step: 6 });
								},
							},
						].map((el, i, arr) => {
							return (
								<>
									<ActionButton
										onClick={() => {
											el.onClick();
										}}
									>
										<h2>{el.text}</h2>
									</ActionButton>
									{i != arr.length - 1 ? (
										<h2>{">"}</h2>
									) : (
										<></>
									)}
								</>
							);
						})}
					</Stack>
				</Stack>
				{this.renderStep(this.state.step)}
			</Stack>
		);
	}
}

function mstp({ assets }) {
	return { assets };
}
export default connect(mstp, actions)(JobProvisioner);

const jobDescriptions = [
	"Cement Drillout",
	"Cleanout",
	"Drill Out",
	"Drill Out - Tubing Install",
	"Fishing",
	"Hybrid Drill Out",
	"Other",
	"Tubing Install",
	"Tubing Pull",
];
const states = [
	"Alabama",
	"Alaska",
	"American Samoa",
	"Arizona",
	"Arkansas",
	"California",
	"Colorado",
	"Connecticut",
	"Delaware",
	"District of Columbia",
	"Florida",
	"Georgia",
	"Guam",
	"Hawaii",
	"Idaho",
	"Illinois",
	"Indiana",
	"Iowa",
	"Kansas",
	"Kentucky",
	"Louisiana",
	"Maine",
	"Maryland",
	"Massachusetts",
	"Michigan",
	"Minnesota",
	"Minor Outlying Islands",
	"Mississippi",
	"Missouri",
	"Montana",
	"Nebraska",
	"Nevada",
	"New Hampshire",
	"New Jersey",
	"New Mexico",
	"New York",
	"North Carolina",
	"North Dakota",
	"Northern Mariana Islands",
	"Ohio",
	"Oklahoma",
	"Oregon",
	"Pennsylvania",
	"Puerto Rico",
	"Rhode Island",
	"South Carolina",
	"South Dakota",
	"Tennessee",
	"Texas",
	"U.S. Virgin Islands",
	"Utah",
	"Vermont",
	"Virginia",
	"Washington",
	"West Virginia",
	"Wisconsin",
	"Wyoming",
];

const statesApi = [
	{ key: "1", text: "ALABAMA" },
	{ key: "2", text: "ARIZONA" },
	{ key: "3", text: "ARKANSAS" },
	{ key: "4", text: "CALIFORNIA" },
	{ key: "5", text: "COLORADO" },
	{ key: "6", text: "CONNECTICUT" },
	{ key: "7", text: "DELAWARE" },
	{ key: "8", text: "DISTRICT OF COLUMBIA" },
	{ key: "9", text: "FLORIDA" },
	{ key: "10", text: "GEORGIA" },
	{ key: "11", text: "IDAHO" },
	{ key: "12", text: "ILLINOIS" },
	{ key: "13", text: "INDIANA" },
	{ key: "14", text: "IOWA" },
	{ key: "15", text: "KANSAS" },
	{ key: "16", text: "KENTUCKY" },
	{ key: "17", text: "LOUISIANA" },
	{ key: "18", text: "MAINE" },
	{ key: "19", text: "MARYLAND" },
	{ key: "20", text: "MASSACHUSETTS" },
	{ key: "21", text: "MICHIGAN" },
	{ key: "22", text: "MINNESOTA" },
	{ key: "23", text: "MISSISSIPPI" },
	{ key: "24", text: "MISSOURI" },
	{ key: "25", text: "MONTANA" },
	{ key: "26", text: "NEBRASKA" },
	{ key: "27", text: "NEVADA" },
	{ key: "28", text: "NEW HAMPSHIRE" },
	{ key: "29", text: "NEW JERSEY" },
	{ key: "30", text: "NEW MEXICO" },
	{ key: "31", text: "NEW YORK" },
	{ key: "32", text: "NORTH CAROLINA" },
	{ key: "33", text: "NORTH DAKOTA" },
	{ key: "34", text: "OHIO" },
	{ key: "35", text: "OKLAHOMA" },
	{ key: "36", text: "OREGON" },
	{ key: "37", text: "PENNSYLVANIA" },
	{ key: "38", text: "RHODE ISLAND" },
	{ key: "39", text: "SOUTH CAROLINA" },
	{ key: "40", text: "SOUTH DAKOTA" },
	{ key: "41", text: "TENNESSEE" },
	{ key: "42", text: "TEXAS" },
	{ key: "43", text: "UTAH" },
	{ key: "44", text: "VERMONT" },
	{ key: "45", text: "VIRGINIA" },
	{ key: "46", text: "WASHINGTON" },
	{ key: "47", text: "WEST VIRGINIA" },
	{ key: "48", text: "WISCONSIN" },
	{ key: "49", text: "WYOMING" },
	{ key: "50", text: "ALASKA" },
	{ key: "51", text: "HAWAII" },
	{ key: "55", text: "ALASKA OFFSHORE" },
	{ key: "56", text: "PACIFIC COAST OFFSHORE" },
	{ key: "60", text: "NORTHERN GULF OF MEXICO" },
	{ key: "61", text: "ATLANTIC COAST OFFSHORE" },
];
const plugs = [
	"Magnum",
	"Magnum Ace",
	"FraXion",
	"Fas Drill",
	"Obsidian",
	"Other",
	"EZ Drill",
	"Diamondback",
	"Copperhead",
	"Boss Hog",
	"RzrFRAC",
	"Scorpion",
	"Snub Nose",
	"Team",
];
const motors = ["BICO", "DRILEX", "NOV", "Other"];
const bits = [
	"Varel Slipstream Tri Cone",
	"JZ - Tri Cone",
	"PDC - Mill",
	"PDC - One Trip",
	"PlugBuster - Mill",
	"Reed",
	"Smith - Tri Cone",
	"Valco",
	"Other",
];
const formations = [
	"Big Lime",
	"Berea",
	"Gordon Stray",
	"DJ",
	"Clinton",
	"Marcellus",
	"Oriskany",
	"Permian",
	"Utica",
	"Other",
];
const jobInfo = [
	{
		required: true,
		key: "Job start time",
		type: "datetime",
		label: "Job start time",
		horizontal: true,
		datetime: new Date(),
	},
	{
		key: "Rig Up Time",
		type: "number",
		suffix: "hours",
		label: "Rig Up Time",
	},
	{
		key: "Rig Over Time",
		type: "number",
		suffix: "hours",
		label: "Rig Over Time",
	},
	{
		key: "BOP Count",
		type: "number",
		label: "BOP Count",
	},

	{
		key: "High Pressure",
		type: "boolean",
		label: "High Pressure?",
	},
	{
		key: "Circulation Time",
		type: "number",
		suffix: "hours",
		label: "Circuclation Time",
	},

	{
		key: "Rig Down Time",
		type: "number",
		suffix: "hours",
		label: "Rig Down Time",
	},
	{
		required: true,
		key: "Job end time",
		type: "datetime",
		label: "Job end time",
		horizontal: true,
		datetime: new Date(),
	},
];
const technicalForm = [
	{
		key: "Formation Type",
		type: "enum",
		label: "Formation Type",
		options: formations.map((el) => {
			return { key: el, text: el };
		}),
	},
	{
		key: "Job Description",
		type: "enum",
		label: "Job Description",
		options: jobDescriptions.map((el) => {
			return { key: el, text: el };
		}),
	},
	{
		key: "Kill Depth (ft)",
		type: "number",
		label: "Kill Depth (ft)",
	},
	{
		key: "Prod. Tubing Depth (ft)",
		type: "number",
		label: "Prod. Tubing Depth (ft)",
	},
	{
		key: "Total Depth (ft)",
		type: "number",
		label: "Total Depth (ft)",
	},
];
const equipmentForm = [
	{
		key: "Rig Rating",
		type: "enum",
		label: "Rig Rating",
		options: [
			{ key: "285,000 lbs", text: "285,000 lbs" },
			{ key: "300,000 lbs", text: "300,000 lbs" },
		],
	},

	{
		key: "Frac Plug Type",
		type: "enum",
		label: "Frac Plug Type",
		options: plugs.map((el) => {
			return { key: el, text: el };
		}),
	},
	{
		key: "Motor",
		type: "enum",
		label: "Motor",
		options: motors.map((el) => {
			return { key: el, text: el };
		}),
	},
	{
		key: "Bit",
		type: "enum",
		label: "Bit",
		options: bits.map((el) => {
			return { key: el, text: el };
		}),
	},
];
