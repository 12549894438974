import {
	ActionButton,
	Checkbox,
	DefaultButton,
	Dropdown,
	Panel,
	PanelType,
	PrimaryButton,
	Spinner,
	SpinnerSize,
	Stack,
	TextField,
} from "@fluentui/react";
import { Button } from "primereact/button";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import SystemMultiDataItemSelector from "../../../components/SystemMultiDataItemSelector";
import { getAssetIdFromSystem } from "../../../util";
import DateTimePicker from "../components/forms/DateTimePicker";
class ExportDialog extends Component {
	state = {
		startDate: new Date(new Date() - 1000 * 3600 * 24),
		endDate: new Date(),
		format: "csv",
		dataItems: [],
		compress: true,
		filename: `${this.props.system?.assetAlias}_${new Date(
			new Date() - 1000 * 3600 * 24
		)
			.toJSON()
			.replace(/[^-a-z0-9]/gi, "_")}`,
	};

	onHide() {}
	getFilename() {}
	export() {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			DataItems: this.state.dataItems.map((el) => {
				return {
					assetId: getAssetIdFromSystem(null, el.slotPath),
					dataItemId: el.dataItem.dataItemId,
				};
			}),
			StartTime: this.state.startDate,
			EndTime: this.state.endDate,
			Format: { Type: this.state.format },
			OutputSettings: {
				Method: "File",
				Filename: this.state.filename + "." + this.state.format,
				Compress: this.state.compress,
				NotifyEmails: this.state.notifyMe
					? [this.props.auth.auth.user.username]
					: [],
			},
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};
		this.setState({ loading: true, timeout: false });
		fetch("/api/HistoricalDataItems", requestOptions)
			.then(function (t) {
				if (t.ok) {
					return t.json();
				} else {
					this.setState({ loading: false, timeout: true });
				}
			})
			.then((json) => {
				this.setState({ timeout: false, loading: false, link: json });
			})
			// .then(response => response.text())
			// .then(result => download(result,"export.csv","text/csv"))
			.catch((error) => {
				this.setState({ timeout: true, loading: false });
			});
	}

	render() {
		var footer = (
			<div>
				<Button
					label="Apply"
					icon="pi pi-check"
					onClick={this.export.bind(this)}
				/>
				<Button
					label="Cancel"
					icon="pi pi-times"
					onClick={() => {
						this.props.toggleExport();
					}}
				/>
			</div>
		);
		const cars = [
			{ label: "Audi", value: "Audi" },
			{ label: "BMW", value: "BMW" },
			{ label: "Fiat", value: "Fiat" },
			{ label: "Honda", value: "Honda" },
			{ label: "Jaguar", value: "Jaguar" },
			{ label: "Mercedes", value: "Mercedes" },
			{ label: "Renault", value: "Renault" },
			{ label: "VW", value: "VW" },
			{ label: "Volvo", value: "Volvo" },
		];
		return (
			<Panel
				header="Export to CSV"
				isLightDismiss
				onDismiss={() => {
					this.props.toggleExport();
				}}
				type={PanelType.medium}
				isOpen={this.props.ui.exportModal}
				footer={footer}
			>
				<Stack tokens={{ childrenGap: 15 }}>
					<DateTimePicker
						label="Start time"
						horizontal
						onDateChanged={(e) => {
							this.setState({ startDate: e });
						}}
						datetime={this.state.startDate}
					/>

					<DateTimePicker
						label="End time"
						horizontal
						onDateChanged={(e) => {
							this.setState({ endDate: e });
						}}
						datetime={this.state.endDate}
					/>
					<SystemMultiDataItemSelector
						label="Select data items"
						onChange={(val) => {
							console.log(val);
							this.setState({ dataItems: val });
						}}
					></SystemMultiDataItemSelector>
					<Stack
						horizontal
						verticalAlign="end"
						tokens={{ childrenGap: 5 }}
					>
						<TextField
							style={{ width: "200px" }}
							label="File name"
							value={this.state.filename}
							onChange={(e, val) => {
								this.setState({ filename: val });
							}}
							suffix={
								"." +
								this.state.format +
								(this.state.compress ? ".zip" : "")
							}
						></TextField>
					</Stack>
					<Dropdown
						selectedKey={this.state.format}
						options={[
							{ key: "csv", text: "CSV" },
							{ key: "sqlite", text: "SQLite" },
						]}
						onChange={(e, val) => {
							this.setState({ format: val.key });
						}}
					></Dropdown>
					<Checkbox
						label="Compress"
						checked={this.state.compress}
						onChange={(e, val) => {
							this.setState({ compress: val });
						}}
					/>
					<Checkbox
						label="Email me the results"
						checked={this.state.notifyMe}
						onChange={(e, val) => {
							this.setState({ notifyMe: val });
						}}
					/>
					{this.state.loading ? (
						<Stack horizontal>
							<DefaultButton
								onClick={() => {
									this.setState({
										loading: false,
										timeout: false,
									});
								}}
							>
								Cancel
							</DefaultButton>
							<Spinner size={SpinnerSize.medium} />
						</Stack>
					) : (
						<Stack horizontal>
							<PrimaryButton onClick={this.export.bind(this)}>
								Export
							</PrimaryButton>
						</Stack>
					)}
					{this.state.link && (
						<ActionButton
							onClick={() => {
								fetch(this.state.link.download).then((t) => {
									return t.blob().then((b) => {
										var a = document.createElement("a");
										a.href = URL.createObjectURL(b);
										a.setAttribute(
											"download",
											this.state.link.file.filename
										);
										a.click();
									});
								});
							}}
							iconProps={{ iconName: "Download" }}
						>
							{this.state.link.file.filename}
						</ActionButton>
					)}
				</Stack>
			</Panel>
		);
	}
}

function mapStateToProps({ system, ui, auth }) {
	return {
		auth,
		system,
		ui: ui,
	};
}

export default connect(mapStateToProps, actions)(ExportDialog);
