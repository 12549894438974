import SystemForm from "./SystemForm";
import React from "react";
import {
	DefaultEffects,
	TextField,
	Panel,
	PanelType,
	ActionButton,
	SearchBox,
	mergeStyles,
	mergeStyleSets,
	Stack,
	Spinner,
	SpinnerSize,
	CommandBar,
	DetailsList,
	SelectionMode,
	PrimaryButton,
	Nav,
	Text,
	Separator,
	DefaultButton,
} from "@fluentui/react/";
import SearchableDropdown from "../../../components/SearchableDropdown";
export default class SystemConfiguration extends React.Component {
	state = {};
	deleteSystem(id) {
		fetch(`/api/ef/systems/${id}`, { method: "delete" })
			.then((data) => data.json())
			.then((data) => {
				console.log(data);
				this.props.onSystemsUpdated();
			})
			.catch((err) => console.log(err));
	}
	render() {
		return (
			<>
				<DetailsList
					compact
					selectionMode={SelectionMode.none}
					items={this.props.filteredSystems}
					columns={[
						{
							fieldName: "name",
							name: "Name",
							key: "name",
						},
						{
							name: "System Definition",
							key: "sysdef",
							onRender: (item) => (
								<Text>
									{
										this.props.systemDefinitions.find(
											(sd) =>
												sd.systemDefinitionId ==
												item.systemDefinitionId
										)?.name
									}
								</Text>
							),
						},
						{
							minWidth: 200,
							key: "edit",
							onRender: (item) => {
								return (
									<ActionButton
										iconProps={{
											iconName: "Edit",
										}}
										onClick={() => {
											this.setState({
												systemForm: true,
												showNewSys: false,
												selectedSystem: item,
											});
										}}
									>
										Edit slot assignments
									</ActionButton>
								);
							},
						},
						{
							minWidth: 200,
							key: "delete",
							onRender: (item) => {
								return (
									<ActionButton
										iconProps={{
											iconName: "Delete",
										}}
										onClick={() => {
											this.deleteSystem(item.systemId);
										}}
									>
										Delete
									</ActionButton>
								);
							},
						},
					]}
				></DetailsList>
				{this.state.showNewSys ? (
					<Stack
						verticalAlign="end"
						tokens={{ childrenGap: 10 }}
						horizontal
					>
						<TextField
							label="System name"
							value={this.state.newSystemName}
							onChange={(e, val) => {
								this.setState({
									newSystemName: val,
								});
							}}
						></TextField>
						<SearchableDropdown
							label="Select definition"
							minWidth={200}
							options={
								this.props.systemDefinitions?.map((el) => {
									return {
										key: el.systemDefinitionId,
										text: el.name,
									};
								}) ?? []
							}
							onChange={(e, val) => {
								this.setState({
									newSystemSystemDefinitionId: val.key,
								});
							}}
						/>
						<PrimaryButton
							iconProps={{
								iconName: "Plus",
							}}
							onClick={() => {
								fetch("/api/ef/systems", {
									method: "post",
									headers: {
										"Content-Type": "application/json",
									},
									body: JSON.stringify({
										name: this.state.newSystemName,
										systemDefinitionId: this.state
											.newSystemSystemDefinitionId,
									}),
								})
									.then((res) => res.json())
									.then((sys) =>
										this.props.onSystemsUpdated()
									);
							}}
						>
							Add
						</PrimaryButton>
						<DefaultButton
							text="Cancel"
							onClick={() => {
								this.setState({
									showNewSys: false,
								});
							}}
						></DefaultButton>
					</Stack>
				) : (
					<Stack horizontalAlign="end" horizontal>
						<ActionButton
							iconProps={{
								iconName: "Add",
							}}
							onClick={() => {
								this.setState({
									showNewSys: true,
									systemForm: false,
								});
							}}
						>
							Add new system
						</ActionButton>
					</Stack>
				)}
				{this.state.systemForm && (
					<div style={{ padding: 20 }}>
						<SystemForm
							key={this.state.selectedSystem?.systemId}
							systemId={this.state.selectedSystem?.systemId}
							systems={this.props.systems}
							assets={this.props.assets}
							onSaveSystem={() => {
								this.setState({ systemForm: false });
							}}
						></SystemForm>
					</div>
				)}
			</>
		);
	}
}
