import { Checkbox } from "office-ui-fabric-react";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../../actions/index";
import DateTimePicker from "../../forms/DateTimePicker";

class StateReportForm extends Component {
	state = {
		dataItem: undefined,
		labelColor: "",
		valueColor: "",
		backgroundColor: "",
		timestamp: false,
	};

	static defaultProps = {};

	componentDidMount() {
		const { tile } = this.props.ui.modal;
		if (tile) {
			const { showPie, showGraph, showLog, showKPI } = tile.properties;
			//const newDi = this.dataItems.filter((di) => di.label === dataItem.label);
			this.setState({
				showPie,
				showGraph,
				showLog,
				showKPI,
			});
		}
	}

	handleChange2 = (key, val) => {
		const { addData } = this.props;
		this.setState({ [key]: val }, () => {
			addData(this.state);
		});
	};
	_onSelectDate = (date) => {
		this.saveAttribute({
			assetId: this.props.system.assetId,
			attributeName: "jobStartTime",
			attributeValue: date.toJSON(),
		});
	};
	_onSelectTime = (event, option, index) => {
		if (option) {
			let hour = option.key;
			this.setState(
				{
					endHour: hour,
				},
				this.filterSchedule
			);
		}
	};
	saveAttribute(att) {
		fetch(`/api/assets/${att.assetId}/attributes`, {
			method: "put",
			body: JSON.stringify([att]),
			headers: { "content-type": "application/json" },
		}).then((res) => this.props.loadAsset());
		this.setState({}, () => this.props.addData(this.state));
	}
	getTimespanString(value) {
		if (value % 24 == 0) {
			return `${value / 24} Day${value == 24 ? "" : "s"}`;
		} else if (value < 24) {
			return `${value} Hours`;
		} else {
			return `${Math.floor(value / 24)} Day${
				Math.floor(value / 24) == 1 ? "" : "s"
			} ${value % 24} Hours`;
		}
	}
	getStartTimeAttribute() {
		var attributeValue = this.props?.system?.attributes?.find(
			(el) => el.attributeName == "jobStartTime"
		)?.attributeValue;

		return attributeValue ? new Date(attributeValue) : new Date();
	}
	getNumericAttribute(attributeName) {
		var attributeValue = parseFloat(
			this.props?.system?.attributes?.find(
				(el) => el.attributeName == attributeName
			)?.attributeValue ?? "0"
		);
		return attributeValue ? attributeValue : 0;
	}
	render() {
		return (
			<section className="component-form">
				<Checkbox
					checked={this.state.showPie}
					label="Show Pie Chart"
					onChange={(e, val) => {
						this.handleChange2("showPie", val);
					}}
				></Checkbox>
				<Checkbox
					checked={this.state.showGraph}
					onChange={(e, val) => {
						this.handleChange2("showGraph", val);
					}}
					label="Show Graph"
				></Checkbox>
				<Checkbox
					checked={this.state.showLog}
					onChange={(e, val) => {
						this.handleChange2("showLog", val);
					}}
					label="Show Activity Log"
				></Checkbox>
				<Checkbox
					checked={this.state.showKPI}
					onChange={(e, val) => {
						this.handleChange2("showKPI", val);
					}}
					label="Show KPIs"
				></Checkbox>
				<DateTimePicker
					label="Job start time"
					onDateChanged={(datetime) => {
						this._onSelectDate(datetime);
					}}
					datetime={this.getStartTimeAttribute()}
				></DateTimePicker>
			</section>
		);
	}
}

function mapStateToProps({ ui, system }) {
	return { ui, system };
}

export default connect(mapStateToProps, actions)(StateReportForm);
