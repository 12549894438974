import { PrimaryButton, Stack, Spinner } from "office-ui-fabric-react";
import { useState } from "react";
import { download } from "../../../../../util";
import { executeFetch } from "../../FetchForm";

export default function DownloadButton(props) {
	let [inProgress, setInProgress] = useState(false);
	let getData = () => {
		setInProgress(true);
		executeFetch(props.properties.fetch).then((data) => {
			download(data, props.properties.filename);
			setInProgress(false);
		});
	};

	return (
		<div
			style={{
				height: "100%",
				width: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<PrimaryButton
				{...props.properties}
				onClick={getData}
			></PrimaryButton>
			{inProgress && <Spinner></Spinner>}
		</div>
	);
}
