import React, { Component } from "react";
import { connect } from "react-redux";
import { TextField, MaskedTextField } from "@fluentui/react/lib/TextField";
import {
	Dropdown,
	DropdownMenuItemType,
	IDropdownOption,
	IDropdownStyles,
} from "@fluentui/react/lib/Dropdown";
import {
	DefaultButton,
	DefaultPalette,
	Stack,
	IStackStyles,
	IStackTokens,
} from "@fluentui/react";
import MultiAxesConfig from "./MultiAxesConfig";
import { Separator } from "@fluentui/react/lib/Separator";
import { Checkbox } from "@fluentui/react/lib/Checkbox";
import { Slider } from "@fluentui/react/lib/Slider";
import { TooltipHost, ITooltipHostStyles } from "@fluentui/react/lib/Tooltip";

const times = [
	{ label: "5 minutes ago", value: "five" },
	{ label: "10 minutes ago", value: "ten" },
	{ label: "half hour ago", value: "halfhour" },
	{ label: "1 hour ago", value: "hour" },
];

const selectOptions = [
	{ label: "Live", value: "live" },
	{ label: "Fixed", value: "fixed" },
];

class MultiAxesLiveForm extends Component {
	state = {
		//form fields
		series: [],
		title: "",
		axes: [],
		liveStart: "",
		fixedStart: "",
		timeType: "live",
		legend: true,
		visible: false,
		invert: false,
		refreshData: false,
		refreshInterval: 15,
		decimation: 50,
	};

	componentDidMount() {
		// populates fields when editing
		const { tile } = this.props.ui.modal;
		if (tile && !tile.properties.type.default) {
			const {
				title,
				axes,
				series,
				liveStart,
				fixedStart,
				timeType,
				legend,
				invert,
				refreshData,
				refreshInterval,
				decimation,
				library,
			} = tile.properties;
			// map series and replace data item with one from this.dataitems

			this.setState({
				title,
				axes,
				series,
				liveStart,
				fixedStart,
				timeType,
				legend,
				invert,
				refreshInterval,
				refreshData,
				decimation,
				library,
			});
		}
	}

	handleChange = (e) => {
		e.preventDefault();
		const { addData } = this.props;
		const value = e.target.checked ? e.target.checked : e.target.value;
		this.setState({ [e.target.name]: value }, () => {
			addData(this.state);
		});
	};

	openModal = (e) => {
		e.stopPropagation();
		e.preventDefault();
		this.setState({ visible: true });
	};

	closeModal = (e) => {
		e.stopPropagation();
		e.preventDefault();
		this.setState({ visible: false });
	};

	onAxesChange = (newState) => {
		const { addData } = this.props;
		const { axes, series } = newState;
		this.setState({ axes, series, visible: false }, () => {
			addData(this.state);
		});
	};

	render() {
		const { addData } = this.props;
		const {
			title,
			timeType,
			legend,
			series,
			axes,
			invert,
			fixedStart,
			refreshData,
			refreshInterval,
			decimation,
		} = this.state;
		return (
			<Stack vertical tokens={{ childrenGap: 5 }}>
				<Dropdown
					label={"Charting Library"}
					options={[
						{ key: "amcharts", text: "AmCharts (More features)" },
						{ key: "uplot", text: "uPlot (Better Performance)" },
					]}
					selectedKey={this.state.library}
					onChange={(e, val) => {
						this.setState({ library: val.key }, () => {
							addData(this.state);
						});
					}}
				></Dropdown>
				<TooltipHost content="Time range on the graph">
					<TextField
						label="Graph Duration (hours)"
						type="number"
						min="0"
						max="168"
						value={fixedStart}
						onChange={(e, val) => {
							this.setState({ fixedStart: val }, () => {
								addData(this.state);
							});
						}}
					/>
				</TooltipHost>
				<TextField
					value={title}
					type="text"
					onChange={(e, val) => {
						this.setState({ title: val }, () => {
							addData(this.state);
						});
					}}
					label="Title"
				/>
				<TooltipHost content="Lower resolutions will run better by decimating your data. 100% is no decimation.">
					<Slider
						label="Resolution"
						min={0}
						max={100}
						valueFormat={(num) => {
							return num + "%";
						}}
						value={decimation}
						// eslint-disable-next-line react/jsx-no-bind
						onChange={(val) => {
							this.setState(
								{ decimation: parseFloat(val) },
								() => {
									addData(this.state);
								}
							);
						}}
					/>
				</TooltipHost>
				<Checkbox
					label="Display Legend"
					checked={legend}
					onChange={(e, val) => {
						this.setState({ legend: val }, () => {
							addData(this.state);
						});
					}}
				/>

				<Checkbox
					label="Invert Axes"
					checked={invert}
					onChange={(e, val) => {
						this.setState({ invert: val }, () => {
							addData(this.state);
						});
					}}
				/>

				<Checkbox
					label="Refresh Data"
					checked={refreshData}
					onChange={(e, val) => {
						this.setState({ refreshData: val }, () => {
							addData(this.state);
						});
					}}
				/>
				<div hidden={!refreshData}>
					<TextField
						label="Refresh Every (seconds)"
						type="number"
						inputProps={{ min: "5" }}
						value={refreshInterval}
						onChange={(e, val) => {
							this.setState(
								{ refreshInterval: parseFloat(val) },
								() => {
									addData(this.state);
								}
							);
						}}
					/>
				</div>
				<MultiAxesConfig
					onChange={this.onAxesChange}
					series={series}
					axes={axes}
				/>
			</Stack>
		);
	}
}

function mapStateToProps({ data, ui }) {
	return { data, ui };
}

export default connect(mapStateToProps)(MultiAxesLiveForm);
