import React from "react";
import { ColorPicker } from "primereact/colorpicker";
import { InputText } from "primereact/inputtext";
import PopoutColorPicker from "../../../../components/PopoutColorPicker";
const MyColorPicker = ({ handleChange, color, label, name }) => {
	return (
		<div className="color-picker">
			<span className="color-picker-label">{label}</span>
			<ColorPicker
				value={color}
				name={name}
				onChange={handleChange}
				defaultColor="000000"
			/>
			<InputText
				style={{
					border: "none",
					borderBottom: "1px solid #585858",
					width: "30%",
					backgroundColor: "inherit",
				}}
				onChange={handleChange}
				value={color}
				type="text"
				name={name}
				className="color-picker-color"
			/>
		</div>
	);
};

MyColorPicker.defaultProps = {
	color: "000000",
	label: "",
	name: "",
	handleChange: () => {},
};

export default MyColorPicker;
