import { AutoComplete } from "primereact/autocomplete";
import { Fieldset } from "primereact/fieldset";
//import 'primeflex/primeflex.css';
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../actions";
import { getParameterByName } from "../../../../util";
class PatientChart extends Component {
	static defaultProps = {
		data: undefined, //{}
		decimals: "2",
		fontSize: "14",
	};
	state = {
		settings: this.props.settings,
		readOnly: true,
	};
	foodAllergens = [
		"Almond",
		"Tree Nuts",
		"Fruit",
		"Avocado",
		"Banana",
		"Mango",
		"Pineapple",
		"Barley",
		"Brazil",
		"Cashew",
		"Celery",
		"Crustaceans",
		"Egg",
		"Fish",
		"Gluten",
		"Hazelnut",
		"Lupin",
		"Macadamia",
		"Milk",
		"Molluscs",
		"Mustard",
		"Nuts",
		"Oats",
		"Peanuts",
		"Pecan",
		"Pistachio",
		"Rye",
		"Sesame",
		"Soy",
		"Sulphites",
		"Walnut",
		"Wheat",
	];
	environmentalAllergens = [
		"Cat Dander",
		"Dog Dander",
		"Dust Mites",
		"Latex",
		"Mold",
		"Pollen",
		"Cigarette Smoke",
		"Cedar",
		"Animal Dander",
	];
	drugAllergens = [
		"Carbamazepine",
		"Tegretol",
		"Lamotrigine",
		"Lamictal",
		"Phenytoin",
		"Amoxicillin",
		"Ampicillin",
		"Penicillin",
		"Tetracycline",
		"Aspirin",
		"Sulfa",
		"Rituximab",
		"Abacavir",
		"Carbamazepine",
		"Atracurium",
		"Succinylcholine",
		"Vecuronium",
	];
	async savePatientSettings() {
		console.log("click:");
		var raw = JSON.stringify({
			attributeName: "PatientInfo",
			attributeValue: JSON.stringify(this.state.settings),
		});
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
		};

		fetch(
			`/api/Assets/${getParameterByName("assetId")}/Attributes`,
			requestOptions
		)
			.then((response) => response.text())
			.then((result) => this.props.attributes())
			.catch((error) => console.log("error", error));
	}
	render() {
		const { properties, fontSize } = this.props;
		const { text } = properties;
		const style = { "font-size": fontSize + "px", padding: "10px" };
		console.log(style);
		return (
			<div style={style}>
				<div>
					<h3>Patient Information</h3>
					<div>
						<p>Edit Mode</p>
						<InputSwitch
							label="Edit"
							checked={!this.state.readOnly}
							onChange={(e) => {
								if (!e.value) {
									this.savePatientSettings();
								}
								this.setState({ readOnly: !e.value });
							}}
						></InputSwitch>
					</div>
				</div>
				<Fieldset
					style={style}
					legend="Contact"
					toggleable={true}
					collapsed={this.state.ContactCollapsed}
					onToggle={(e) =>
						this.setState({ ContactCollapsed: e.value })
					}
				>
					<div className="p-fluid p-formgrid p-grid">
						<div className="p-field p-col-12 p-md-6">
							<label htmlFor="firstname">Firstname</label>
							<InputText
								id="firstname"
								readOnly={this.state.readOnly}
								value={this.state.settings.firstname}
								onChange={(e) => {
									var settings = this.state.settings;
									settings.firstname = e.target.value;
									this.setState({ settings });
								}}
								type="text"
							/>
						</div>
						<div className="p-field p-col-12 p-md-6">
							<label htmlFor="lastname">Lastname</label>
							<InputText
								id="lastname"
								readOnly={this.state.readOnly}
								value={this.state.settings.lastname}
								type="text"
								onChange={(e) => {
									var settings = this.state.settings;
									settings.lastname = e.target.value;
									this.setState({ settings });
								}}
							/>
						</div>
						<div className="p-field p-col-12">
							<label htmlFor="address1">Address Line 1</label>
							<InputText
								id="address1"
								type="text"
								readOnly={this.state.readOnly}
								value={this.state.settings.address1}
								onChange={(e) => {
									var settings = this.state.settings;
									settings.address1 = e.target.value;
									this.setState({ settings });
								}}
							/>
						</div>
						<div className="p-field p-col-12">
							<label htmlFor="address2">Address Line 2</label>
							<InputText
								id="address2"
								type="text"
								readOnly={this.state.readOnly}
								value={this.state.settings.address2}
								onChange={(e) => {
									var settings = this.state.settings;
									settings.address2 = e.target.value;
									this.setState({ settings });
								}}
							/>
						</div>
						<div className="p-field p-col-12 p-md-6">
							<label htmlFor="city">City</label>
							<InputText
								id="city"
								readOnly={this.state.readOnly}
								value={this.state.settings.city}
								type="text"
								onChange={(e) => {
									var settings = this.state.settings;
									settings.city = e.target.value;
									this.setState({ settings });
								}}
							/>
						</div>
						<div className="p-field p-col-12 p-md-3">
							<label htmlFor="state">State</label>
							<InputText
								id="state"
								readOnly={this.state.readOnly}
								value={this.state.settings.state}
								type="text"
								onChange={(e) => {
									var settings = this.state.settings;
									settings.state = e.target.value;
									this.setState({ settings });
								}}
							/>
						</div>
						<div className="p-field p-col-12 p-md-3">
							<label htmlFor="zip">Zip</label>
							<InputText
								id="zip"
								readOnly={this.state.readOnly}
								value={this.state.settings.zip}
								onChange={(e) => {
									var settings = this.state.settings;
									settings.zip = e.target.value;
									this.setState({ settings });
								}}
								type="text"
							/>
						</div>
					</div>
				</Fieldset>
				<Fieldset
					legend="Current Conditions"
					style={style}
					toggleable={true}
					collapsed={this.state.ConditionsCollapsed}
					onToggle={(e) =>
						this.setState({ ConditionsCollapsed: e.value })
					}
				>
					<label>Medical Conditions</label>
					<AutoComplete
						id="conditions"
						readOnly={this.state.readOnly}
						style={{ width: "100%" }}
						multiple={true}
						value={this.state.settings.conditions}
						onChange={(e) => {
							var settings = this.state.settings;
							settings.conditions = e.value;
							this.setState({ settings });
						}}
						suggestions={this.state.conditionSuggestions}
						completeMethod={this.suggestCondition.bind(this)}
					/>
					<label>Food Allergies</label>
					<AutoComplete
						style={{ width: "100%" }}
						readOnly={this.state.readOnly}
						multiple={true}
						value={this.state.settings.foodAllergies}
						onChange={(e) => {
							var settings = this.state.settings;
							settings.foodAllergies = e.value;
							this.setState({ settings });
						}}
						suggestions={this.state.foodAllergensSuggestions}
						completeMethod={this.suggestFoods.bind(this)}
					/>
					<br></br>
					<label>Enviromental Allergies</label>
					<AutoComplete
						readOnly={this.state.readOnly}
						style={{ width: "100%" }}
						multiple={true}
						value={this.state.settings.environmentalAllergies}
						onChange={(e) => {
							var settings = this.state.settings;
							settings.environmentalAllergies = e.value;
							this.setState({ settings });
						}}
						suggestions={
							this.state.environmentalAllergensSuggestions
						}
						completeMethod={this.suggestEnviron.bind(this)}
					/>
					<br></br>
					<label>Drug Allergies</label>
					<AutoComplete
						readOnly={this.state.readOnly}
						style={{ width: "100%" }}
						multiple={true}
						value={this.state.settings.drugAllergies}
						onChange={(e) => {
							var settings = this.state.settings;
							settings.drugAllergies = e.value;
							this.setState({ settings });
						}}
						suggestions={this.state.drugAllergensSuggestions}
						completeMethod={this.suggestDrugAllergy.bind(this)}
					/>
				</Fieldset>
				<Fieldset
					legend="Medications"
					toggleable={true}
					style={style}
					collapsed={this.state.MedicationsCollapsed}
					onToggle={(e) =>
						this.setState({ MedicationsCollapsed: e.value })
					}
				>
					<AutoComplete
						readOnly={this.state.readOnly}
						style={{ width: "100%" }}
						multiple={true}
						value={this.state.settings.medications}
						onChange={(e) => {
							var settings = this.state.settings;
							settings.medications = e.value;
							this.setState({ settings });
						}}
						suggestions={this.state.medicationSuggestions}
						completeMethod={this.suggestMedication.bind(this)}
					/>
				</Fieldset>
			</div>
		);
	}
	suggestFoods(event) {
		let results = this.foodAllergens.filter((brand) => {
			return brand.toLowerCase().indexOf(event.query.toLowerCase()) >= 0;
		});
		this.setState({ foodAllergensSuggestions: results });
	}
	suggestEnviron(event) {
		let results = this.environmentalAllergens.filter((brand) => {
			return brand.toLowerCase().indexOf(event.query.toLowerCase()) >= 0;
		});
		this.setState({ environmentalAllergensSuggestions: results });
	}
	async suggestCondition(event) {
		try {
			let results = await fetch(
				"https://clinicaltables.nlm.nih.gov/api/conditions/v3/search?authenticity_token=&terms=" +
					event.query,
				{
					method: "GET",
				}
			).then((res) => res.json());
			this.setState({
				conditionSuggestions: results[3].map((el) => el[0]),
			});
		} catch {}
	}
	async suggestDrugAllergy(event) {
		try {
			let results = await fetch(
				"https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search?authenticity_token=&terms=" +
					event.query,
				{
					method: "GET",
				}
			).then((res) => res.json());
			this.setState({ drugAllergensSuggestions: results[1] });
		} catch {}
	}
	async suggestMedication(event) {
		try {
			let results = await fetch(
				"https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search?authenticity_token=&terms=" +
					event.query,
				{
					method: "GET",
				}
			).then((res) => res.json());
			this.setState({ medicationSuggestions: results[1] });
		} catch {}
	}
}
function mapStateToProps({ data }) {
	try {
		var settings = JSON.parse(
			data.attributes.object["PatientInfo"].attributeValue
		);
		return {
			settings: settings,
		};
	} catch {
		return {
			settings: {},
		};
	}
}

export default connect(mapStateToProps, actions)(PatientChart);
