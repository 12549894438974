export function tooltipsPlugin(opts) {
	function init(u, opts, data) {
		let plot = u.root.querySelector(".u-over");
		// let ttc = u.cursortt = document.createElement("div");
		// ttc.className = "tooltip";
		// ttc.textContent = "(x,y)";
		// ttc.style.pointerEvents = "none";
		// ttc.style.position = "absolute";
		// ttc.style.background = "rgba(0,0,255,0.1)";
		// plot.appendChild(ttc);

		u.seriestt = opts.series.map((s, i) => {
			if (i == 0) return;

			let tt = document.createElement("div");
			tt.invert = opts.invert;
			tt.className = "tooltip";
			tt.textContent = "Tooltip!";
			tt.style.pointerEvents = "none";
			tt.style.position = "absolute";
			tt.style.padding = "2px";
			tt.style["font-size"] = "11px";
			tt.style.border = "1px solid white";
			tt.style.color = "white";
			tt.style["font-weight"] = "bolder";
			tt.style[
				"text-shadow"
			] = `.1em 0 black, 0 .1em black, -.1em 0 black, 0 -.1em black, -.1em -.1em black, -.1em .1em black, .1em -.1em black, .1em .1em black`;
			// tt.style[
			// 	"text-shadow"
			// ] = `.1em 0 black, 0 .1em black, -.1em 0 black, 0 -.1em black`;
			// tt.style.background = "rgba(0,0,0,0.1)";
			tt.style.color = s.color;
			tt.style.display = s.show ? null : "none";

			plot.appendChild(tt);
			return tt;
		});

		function hideTips() {
			//ttc.style.display = "none";
			u.seriestt.forEach((tt, i) => {
				if (i == 0) return;

				tt.style.display = "none";
			});
		}

		function showTips() {
			//ttc.style.display = null;
			u.seriestt.forEach((tt, i) => {
				if (i == 0) return;

				let s = u.series[i];
				tt.style.display = s.show ? null : "none";
			});
		}

		plot.addEventListener("mouseleave", () => {
			if (!u.cursor._lock) {
				//	u.setCursor({left: -10, top: -10});
				hideTips();
			}
		});

		plot.addEventListener("mouseenter", () => {
			showTips();
		});

		hideTips();
	}

	function setCursor(u) {
		const { left, top, idx } = u.cursor;

		// this is here to handle if initial cursor position is set
		// not great (can be optimized by doing more enter/leave state transition tracking)
		//	if (left > 0)
		//		u.cursortt.style.display = null;

		// u.cursortt.style.left = left + "px";
		// u.cursortt.style.top = top + "px";
		// u.cursortt.textContent = "(" + u.posToVal(left, "x").toFixed(2) + ", " + u.posToVal(top, "y");

		// can optimize further by not applying styles if idx did not change
		u.seriestt.forEach((tt, i, seriestt) => {
			if (i == 0) return;

			let s = u.series[i];
			if (s.show) {
				// this is here to handle if initial cursor position is set
				// not great (can be optimized by doing more enter/leave state transition tracking)
				//	if (left > 0)
				//		tt.style.display = null;

				let xVal = u.data[0][idx];
				let yVal = u.data[i][idx];

				tt.textContent = s.label + ": " + yVal?.toFixed(2);
				tt.style.display = yVal ? null : "none";
				tt.style.backgroundColor = s._stroke;

				var yCor = tt.invert
					? Math.round(u.valToPos(xVal, "x"))
					: Math.round(u.valToPos(yVal, s.scale));
				var xCor = tt.invert
					? Math.round(u.valToPos(yVal, s.scale))
					: Math.round(u.valToPos(xVal, "x"));
				var badPosition = true;
				while (badPosition) {
					badPosition = seriestt.reduce((valid, series, j, els) => {
						if (
							series &&
							series.style.display !== "none" &&
							i != j
						) {
							return (
								valid ||
								Math.abs(yCor - parseInt(series.style.top)) < 20
							);
						} else {
							return valid;
						}
						//console.log(series)
					}, false);
					if (badPosition) {
						yCor += 3;
					}
				}
				tt.style.left = xCor + "px";
				tt.style.top = yCor + "px";
			}
		});
	}

	return {
		hooks: {
			init,
			setCursor,
			// setScale: [
			//     (u, key) => {
			//    //     console.log('setScale', key);
			//     }
			// ],
			// setSeries: [
			//     (u, idx) => {
			//     //    console.log('setSeries', idx);
			//     }
			// ],
		},
	};
}
