import React, { Component } from "react";
import { connect } from "react-redux";
import MyColorPicker from "./MyColorPicker";
import {
	TextField,
	Stack,
	Dropdown,
	IconButton,
	PrimaryButton,
} from "@fluentui/react";
import SystemDataItemSelector from "../../../../components/SystemDataItemSelector";
import PopoutColorPicker from "../../../../components/PopoutColorPicker";

class BarGraphForm extends Component {
	state = {
		plots: null,
		title: "",
		color: "",
		max: "",
		min: "",
	};

	componentDidMount() {
		const { tile } = this.props.ui.modal;
		if (tile && !tile.properties.type.default) {
			const { title, color, max, min } = tile.properties;
			var plots = tile.properties.plots;
			if (!tile.properties.plots && tile.properties.dataItems) {
				plots = tile.properties.dataItems.map((el) => {
					return {
						dataItem: el,
						slotPath: [],
						color: tile.properties.color ?? "#ff0000",
					};
				});
			}
			this.setState({
				title,
				color,
				max,
				min,
				plots,
			});
		}
	}

	handleChange = (e) => {
		e.preventDefault();
		const { addData } = this.props;
		const value = e.target.checked ? e.target.checked : e.target.value;
		this.setState({ [e.target.name]: value }, () => {
			addData(this.state);
		});
	};

	addPlot() {
		var plots = [...(this.state.plots ?? [])];
		plots.push({ slotPath: [], dataItem: null, color: "#00ff00" });
		this.setState({ plots }, () => {
			this.props.addData(this.state);
		});
	}
	updatePlot(i, val) {
		var plots = [...(this.state.plots ?? [])];
		if (!plots[i]) plots[i] = {};
		Object.assign(plots[i], val);
		this.setState({ plots }, () => {
			this.props.addData(this.state);
		});
	}
	updatePlotDataItem(i, val) {
		var plots = [...(this.state.plots ?? [])];
		if (!plots[i]) plots[i] = {};
		plots[i].dataItem = { ...val.dataItem };
		plots[i].slotPath = [...val.slotPath];
		this.setState({ plots }, () => {
			this.props.addData(this.state);
		});
	}
	removePlot(i) {
		var plots = [...(this.state.plots ?? [])];
		plots.splice(i, 1);
		this.setState({ plots }, () => {
			this.props.addData(this.state);
		});
	}

	render() {
		const { addData } = this.props;
		const { plots, title, color, max, min } = this.state;
		const { tile } = this.props.ui.modal;
		var definition = this.props.definition;
		for (var i in tile.properties.slotPath ?? []) {
			var slotMatch = definition.slots.find(
				(el) => el.slotId == tile.properties.slotPath[i]
			);
			definition =
				slotMatch.assetDefinition || slotMatch.systemDefinition;
		}
		return (
			<Stack vertical>
				{this.state.plots?.map((el, i) => {
					return (
						<Stack horizontal>
							<SystemDataItemSelector
								slotPath={el.slotPath}
								dataItem={el.dataItem}
								style={{ width: "400px" }}
								onDataItemSelected={(val) => {
									this.updatePlot(i, val);
								}}
							></SystemDataItemSelector>
							<PopoutColorPicker
								color={el.color ?? "green"}
								onChange={(ev, col) => {
									this.updatePlot(i, { color: col.str });
								}}
							></PopoutColorPicker>
							<IconButton
								iconProps={{ iconName: "Close" }}
							></IconButton>
						</Stack>
					);
				})}
				<PrimaryButton
					onClick={() => {
						this.addPlot();
					}}
					text="Add value"
				></PrimaryButton>
				<TextField
					label="Title"
					value={title}
					type="text"
					onChange={(e, val) => {
						this.setState({ title: val }, addData(this.state));
					}}
					name="title"
				/>

				<TextField
					label="Maximum"
					value={max}
					type="number"
					onChange={(e, val) => {
						this.setState(
							{ max: parseFloat(val) },
							addData(this.state)
						);
					}}
					name="max"
				/>

				<TextField
					label="Mininmum"
					value={min}
					type="number"
					onChange={(e, val) => {
						this.setState(
							{ min: parseFloat(val) },
							addData(this.state)
						);
					}}
					name="min"
				/>
				<MyColorPicker
					color={color}
					handleChange={this.handleChange}
					label="Column color:"
					name="color"
				/>
				<span>
					If min and max are not specificed the axis will autoscale
				</span>
			</Stack>
		);
	}
}

function mapStateToProps({ data, ui, definition }) {
	return { data, ui, definition };
}

export default connect(mapStateToProps)(BarGraphForm);
