import { ColorSet } from "@amcharts/amcharts4/core";
import {
	Checkbox,
	DetailsList,
	Dropdown,
	IconButton,
	PrimaryButton,
	Separator,
	Stack,
	Text,
	TextField,
	Toggle,
} from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import PaginatedTable from "../../../../components/PaginatedTable/PaginatedTable";
import PopoutColorPicker from "../../../../components/PopoutColorPicker";
import SearchableDropdown from "../../../../components/SearchableDropdown";
import { executeFetch, FetchForm } from "../FetchForm";

const colorSet = new ColorSet();
const $ = window.$;
class XYChartForm extends Component {
	state = {
		flot: {
			dataItems: [],
			xaxes: [],
			yaxes: [],
			series: [],
			hours: 0.25,
		},
	};

	componentDidMount() {
		// populates fields when editing

		const { tile } = this.props.ui.modal;
		if (tile.properties?.flot) {
			this.setState(
				{
					flot: { ...tile.properties.flot },
					jsonFlot: JSON.stringify(tile.properties.flot, null, 4),
				},
				() => {
					if (!this.state.flot.fields) {
						this.updateFieldsFromFetch();
					}
				}
			);
		}
	}
	updateFieldsFromFetch() {
		executeFetch(this.state.flot.fetch)
			.then((d) => {
				return d.split("\r\n")[0].split(",");
			})
			.then((fields) => this.updateFlot("fields", fields));
	}
	focusSearchBar() {
		var x = $(".dataitemsearchfield input");
		if (x[0]) {
			x[0].focus();
		}
	}
	checkValid() {
		const { addData } = this.props;
		try {
			var x = JSON.parse(this.state.optionsString);
			addData({ flot: x });
		} catch (e) {}
	}
	renderSeries() {}
	updateFlot(key, value) {
		var flot = { ...this.state.flot };

		flot[key] = value;

		this.setState({ flot }, () => {
			this.addData();
		});
	}
	getFetchFromDataItems() {
		var flot = { ...this.state.flot };
		var body = `{"DataItems":[${flot.dataItems
			.map((di) => `{"assetId":{{system.assetId}},"dataItemId":${di}}`)
			.join(",")}],
			"Hours": ${flot.hours},
				"Format":{"Type":"csv"},"OutputSettings":{"Method":"Respond"}}`;
		return {
			url: "/api/HistoricalDataItems",
			method: "POST",
			body,
			contentType: "text",
			parsing: "(csv)=>csv",
		};
	}
	updateFlotState(state) {
		var flot = { ...this.state.flot };
		Object.assign(flot, state);
		this.setState({ flot }, () => {
			this.addData();
		});
	}
	updateSeriesLabel(seriesIndex, label) {
		var flot = { ...this.state.flot };
		flot.series[seriesIndex].label = label;
		this.setState({ flot }, () => {
			this.addData();
		});
	}
	updateSeriesX(seriesIndex, dataIndex) {
		var flot = { ...this.state.flot };
		flot.series[seriesIndex].xvalue = dataIndex;
		this.setState({ flot }, () => {
			this.addData();
		});
	}
	updateSeriesY(seriesIndex, dataIndex) {
		var flot = { ...this.state.flot };
		var series = flot.series;
		series[seriesIndex].yvalue = dataIndex;
		this.setState({ flot }, () => {
			this.addData();
		});
	}
	updateSeriesXAxis(seriesIndex, axis) {
		var flot = { ...this.state.flot };
		var series = flot.series;
		series[seriesIndex].xaxis = axis;
		this.setState({ flot }, () => {
			this.addData();
		});
	}
	updateSeriesFill(seriesIndex, color) {
		var flot = { ...this.state.flot };
		var series = flot.series;
		series[seriesIndex].fillColor = color.str;
		series[seriesIndex].fill = color.a / 100;
		if (series[seriesIndex].lines) {
			series[seriesIndex].lines.fillColor = color.str;
			series[seriesIndex].lines.fill = color.a / 100;
		}
		if (series[seriesIndex].points) {
			series[seriesIndex].points.fillColor = color.str;
			series[seriesIndex].points.fill = color.a / 100;
		}
		if (series[seriesIndex].bars) {
			series[seriesIndex].bars.fillColor = color.str;
			series[seriesIndex].bars.fill = color.a / 100;
		}
		this.setState({ flot }, () => {
			this.addData();
		});
	}
	updateSeriesYAxis(seriesIndex, axis) {
		var flot = { ...this.state.flot };
		var series = flot.series;
		series[seriesIndex].yaxis = axis;
		this.setState({ flot }, () => {
			this.addData();
		});
	}
	updateSeries(seriesIndex, field, value) {
		var flot = { ...this.state.flot };
		var series = flot.series;
		series[seriesIndex][field] = value;
		this.setState({ flot }, () => {
			this.addData();
		});
	}
	updateAxesLabel(dim, i, label) {
		var flot = { ...this.state.flot };
		var axes = flot[dim];
		axes[i].axisLabel = label;
		this.setState({ flot }, () => {
			this.addData();
		});
	}
	updateAxesPosition(dim, i, position) {
		var flot = { ...this.state.flot };
		var axes = flot[dim];
		axes[i].position = position;
		this.setState({ flot }, () => {
			this.addData();
		});
	}
	updateAxesMin(dim, i, val) {
		var flot = { ...this.state.flot };
		var axes = flot[dim];
		axes[i].min = val;
		this.setState({ flot }, () => {
			this.addData();
		});
	}
	updateAxesMax(dim, i, val) {
		var flot = { ...this.state.flot };
		var axes = flot[dim];
		axes[i].max = val;
		this.setState({ flot }, () => {
			this.addData();
		});
	}
	updateAxes(dim, i, field, val) {
		var flot = { ...this.state.flot };
		var axes = flot[dim];
		axes[i][field] = val;
		this.setState({ flot }, () => {
			this.addData();
		});
	}
	deleteXAxis(i) {
		var flot = { ...this.state.flot };
		var axes = flot.xaxes;
		axes.splice(i, 1);
		this.setState({ flot }, () => {
			this.addData();
		});
	}
	deleteYAxis(i) {
		var flot = { ...this.state.flot };
		var axes = flot.yaxes;
		axes.splice(i, 1);
		this.setState({ flot }, () => {
			this.addData();
		});
	}
	deleteSeries(i) {
		var flot = { ...this.state.flot };
		var axes = flot.series;
		axes.splice(i, 1);
		this.setState({ flot }, () => {
			this.addData();
		});
	}
	addNewSeries() {
		var flot = { ...this.state.flot };
		var series = flot.series;
		var color = colorSet.getIndex(series.length).rgba;
		var fill = 0.2;
		var fillColor = colorSet.getIndex(series.length);
		fillColor.alpha = fill;
		fillColor = fillColor.rgba;
		series.push({
			label: "newSeries",
			xaxis: 1,
			yaxis: 1,
			color: color,
			fillColor: fillColor,
			lines: {
				show: true,
				fillColor: fillColor,
				fill: fill,
				color: color,
			},
		});
		series.forEach((el, i) => {
			el.key = i;
		});
		this.setState({ flot, selectedSeries: series.length - 1 }, () => {
			this.addData();
		});
	}

	addNewXAxis() {
		var flot = { ...this.state.flot };
		var axes = flot.xaxes;
		axes.push({
			label: "New Axis",
			min: null,
			max: null,
			position: "bottom",
			autoScale: "exact",
		});
		this.setState({ flot }, () => {
			this.addData();
		});
	}

	addNewYAxis() {
		var flot = { ...this.state.flot };
		var axes = flot.yaxes;
		axes.push({
			label: "New Axis",
			min: null,
			max: null,
			position: "left",
			autoScale: "exact",
		});
		this.setState({ flot }, () => {
			this.addData();
		});
	}
	trySetAdvanced(json) {
		this.setState({ jsonFlot: json });
		try {
			var x = JSON.parse(json);
			this.setState({ flot: x, jsonInvalid: false }, () => {
				this.props.addData({ flot: { ...this.state.flot } });
			});
		} catch (e) {
			this.setState({ jsonInvalid: true });
		}
	}

	addData() {
		var flot = { ...this.state.flot };
		if (flot.dataSource == "dataItems") {
			flot.fetch = this.getFetchFromDataItems();
		}
		console.log("Logging new fetch", flot.fetch);
		this.props.addData({ flot });
		this.setState({
			flot,
			jsonFlot: JSON.stringify(this.state.flot, null, 4),
		});
	}
	setDataset(val) {
		if (val) {
			var flot = { ...this.state.flot };
			if (val.selected) {
				flot.dataItems = [...this.state.flot.dataItems, val.key];
			} else {
				flot.dataItems = [
					...this.state.flot.dataItems.filter((di) => di !== val.key),
				];
			}

			this.setState({ flot }, () => {
				this.addData();
			});
		}
	}

	setDataSource(dataSource) {
		if (dataSource) {
			var flot = { ...this.state.flot };
			flot.dataSource = dataSource;
			this.setState({ flot }, () => {
				this.addData();
			});
		}
	}
	setFetch(fetch) {
		if (fetch) {
			var flot = { ...this.state.flot };
			flot.fetch = fetch;
			this.setState({ flot }, () => {
				this.addData();
			});
		}
	}
	render() {
		let i = this.state.selectedSeries;

		var stuff = (
			<Stack>
				<Toggle
					label="Show raw editor"
					onChange={() => {
						this.setState({ advanced: !this.state.advanced });
					}}
				></Toggle>
				{this.state.advanced ? (
					<Stack>
						<a
							target="_blank"
							href="https://github.com/flot/flot/blob/master/API.md"
						>
							View advanced options
						</a>
						<TextField
							style={{ height: "500px", fontSize: "14px" }}
							multiline
							value={this.state.jsonFlot}
							onChange={(e, val) => {
								this.trySetAdvanced(val);
							}}
						></TextField>
						{this.state.jsonInvalid && (
							<p style={{ color: "red" }}>JSON invalid</p>
						)}
					</Stack>
				) : (
					<>
						<Separator>Data source</Separator>
						<Dropdown
							label="Data source type"
							options={[
								{ key: "dataItems", text: "Data items" },
								{ key: "fetch", text: "Web Request" },
							]}
							selectedKey={this.state.flot.dataSource}
							onChange={(e, val) => {
								this.setDataSource(val.key);
							}}
						></Dropdown>

						{(console.log(
							"At render fetch",
							this.state.flot.fetch
						) || this.state.flot.dataSource) == "fetch" && (
							<Stack>
								<FetchForm
									{...this.state.flot.fetch}
									onChange={(e, fetch) =>
										this.setFetch(fetch)
									}
								></FetchForm>
							</Stack>
						)}
						{this.state.flot.dataSource == "dataItems" && (
							<Stack>
								<SearchableDropdown
									label="Data items"
									style={{ width: "200px" }}
									multiSelect
									dropdownWidth="200px"
									onChange={(e, val) => {
										this.setDataset(val);
									}}
									selectedKeys={this.state.flot.dataItems}
									options={this.props.definition?.dataItemDefinitions?.map(
										(el) => {
											return {
												key: el.dataItemId,
												text: el.label,
											};
										}
									)}
								></SearchableDropdown>
								<TextField
									type="number"
									icon
									label={"Plot length (hours):"}
									value={this.state.flot.hours}
									step={0.25}
									onChange={(e, val) => {
										this.updateFlot("hours", val);
									}}
								/>
								<Stack
									horizontal
									verticalAlign="center"
									tokens={{ childrenGap: 10 }}
								>
									<Text style={{ verticalAlign: "middle" }}>
										Refresh
									</Text>
									<Checkbox
										checked={this.state.flot.refresh}
										onChange={(e, val) => {
											this.updateFlot("refresh", val);
										}}
									></Checkbox>
									<Stack
										style={{
											display: this.state.flot.refresh
												? ""
												: "none",
										}}
										horizontal
										verticalAlign="center"
										tokens={{ childrenGap: 10 }}
									>
										<Text
											style={{ verticalAlign: "middle" }}
										>
											every{" "}
										</Text>
										<TextField
											type="number"
											defaultValue="5"
											value={
												this.state.flot.refreshInterval
											}
											onChange={(e, val) => {
												this.updateFlot(
													"refreshInterval",
													parseFloat(val)
												);
											}}
										/>
										<Text>seconds </Text>
									</Stack>
								</Stack>
							</Stack>
						)}
						<Stack horizontalAlign="end" horizontal>
							<PrimaryButton
								text="Detect fields"
								onClick={() => {
									this.updateFieldsFromFetch();
								}}
							/>
						</Stack>
						<Stack tokens={{ childrenGap: 10 }}>
							<Separator>Graph Settings</Separator>
							<TextField
								label={"Title:"}
								value={this.state.flot.title}
								onChange={(e, val) => {
									this.updateFlot("title", val);
								}}
							></TextField>
							<PopoutColorPicker
								label="Font color"
								color={this.state.flot.fontColor}
								onChange={(ev, color) => {
									this.updateFlot("fontColor", color.str);
								}}
							></PopoutColorPicker>
							<Checkbox
								label="Label axes"
								checked={this.state.flot.axisLabels?.show}
								onChange={(e, val) => {
									this.updateFlot("axisLabels", {
										show: val,
									});
								}}
							></Checkbox>
							<Checkbox
								label="Show crosshair"
								checked={this.state.flot.crosshair ?? false}
								onChange={(e, val) => {
									this.updateFlotState({
										crosshair: val
											? { mode: "xy" }
											: undefined,
										grid: val
											? { hoverable: "true" }
											: undefined,
									});
								}}
							></Checkbox>
							<Checkbox
								label="Zoom"
								checked={
									this.state.flot.zoom?.interactive ?? false
								}
								onChange={(e, val) => {
									this.updateFlot("zoom", {
										interactive: val,
									});
								}}
							></Checkbox>
							<Checkbox
								label="Pan"
								checked={this.state.flot.pan ?? false}
								onChange={(e, val) => {
									this.updateFlot(
										"pan",
										val ? { interactive: true } : undefined
									);
								}}
							></Checkbox>
							<Checkbox
								label="Legend"
								checked={this.state.flot.legend ?? false}
								onChange={(e, val) => {
									this.updateFlot(
										"legend",
										val ? { show: true } : undefined
									);
								}}
							></Checkbox>
							<Separator>X Axes</Separator>
							<PaginatedTable
								selectionMode={0}
								showExport={false}
								showPageSize={false}
								label="X-Axes"
								items={this.state?.flot?.xaxes ?? []}
								columns={[
									{
										key: "label",
										name: "Label",
										cellStyle: { width: 200 },
										onRender: (item, i, col) => {
											return (
												<TextField
													value={
														this.state.flot.xaxes[i]
															.axisLabel
													}
													onChange={(e, val) => {
														this.updateAxesLabel(
															"xaxes",
															i,
															val
														);
													}}
												></TextField>
											);
										},
									},
									{
										key: "position",
										name: "Position",
										cellStyle: { width: 200 },
										onRender: (item, i, col) => {
											return (
												<Dropdown
													onChange={(e, val) => {
														this.updateAxesPosition(
															"xaxes",
															i,
															val.key
														);
													}}
													selectedKey={
														this.state.flot.xaxes[i]
															.position
													}
													options={[
														{
															key: "bottom",
															text: "Bottom",
														},
														{
															key: "top",
															text: "Top",
														},
													]}
												></Dropdown>
											);
										},
									},
									{
										key: "min",
										name: "Min",
										cellStyle: { width: 200 },
										onRender: (item, i, col) => {
											return (
												<TextField
													type="number"
													placeholder="Auto"
													value={
														this.state.flot.xaxes[i]
															.min
													}
													onChange={(e, val) => {
														this.updateAxesMin(
															"xaxes",
															i,
															val
														);
													}}
												></TextField>
											);
										},
									},
									{
										key: "max",
										name: "Max",
										cellStyle: { width: 200 },
										onRender: (item, i, col) => {
											return (
												<TextField
													type="number"
													placeholder="Auto"
													value={
														this.state.flot.xaxes[i]
															.max
													}
													onChange={(e, val) => {
														this.updateAxesMax(
															"xaxes",
															i,
															val
														);
													}}
												></TextField>
											);
										},
									},
									{
										key: "time",
										name: "Time",
										cellStyle: {
											width: 20,
											textAlign: "center",
											margin: "auto",
										},
										onRender: (item, i, col) => {
											return (
												<Checkbox
													checked={
														this.state.flot.xaxes[i]
															.mode == "time"
													}
													onChange={(e, val) => {
														this.updateAxes(
															"xaxes",
															i,
															"mode",
															val ? "time" : null
														);
														this.updateAxes(
															"xaxes",
															i,
															"timezone",
															"browser"
														);
														this.updateAxes(
															"xaxes",
															i,
															"timeBase",
															"milliseconds"
														);
													}}
												></Checkbox>
											);
										},
									},
									{
										key: "invert",
										name: "Inv",
										cellStyle: {
											width: 20,
											textAlign: "center",
											margin: "auto",
										},
										onRender: (item, i, col) => {
											return (
												<Checkbox
													checked={
														this.state.flot.xaxes[i]
															.invert
													}
													onChange={(e, val) => {
														this.updateAxes(
															"xaxes",
															i,
															"invert",
															val
														);
													}}
												></Checkbox>
											);
										},
									},
									{
										key: "delete",
										cellStyle: {
											width: 20,
											textAlign: "center",
											margin: "auto",
										},
										onRender: (item, i, col) => {
											return (
												<div>
													<IconButton
														iconProps={{
															iconName: "Delete",
														}}
														iconName={"Delete"}
														onClick={() => {
															this.deleteXAxis(i);
														}}
													></IconButton>
												</div>
											);
										},
									},
								]}
							></PaginatedTable>
							<Stack horizontal horizontalAlign="end">
								<PrimaryButton
									iconProps={{ iconName: "Add" }}
									text="Add X-axis"
									onClick={() => {
										this.addNewXAxis();
									}}
								></PrimaryButton>
							</Stack>
							<Separator>Y Axes</Separator>
							<PaginatedTable
								selectionMode={0}
								label="Y-Axes"
								showExport={false}
								showPageSize={false}
								items={this.state.flot?.yaxes ?? []}
								columns={[
									{
										key: "label",
										name: "Label",
										cellStyle: { width: 200 },
										onRender: (item, i, col) => {
											return (
												<TextField
													value={
														this.state.flot.yaxes[i]
															.axisLabel
													}
													onChange={(e, val) => {
														this.updateAxesLabel(
															"yaxes",
															i,
															val
														);
													}}
												></TextField>
											);
										},
									},
									{
										key: "position",
										name: "Position",
										cellStyle: { width: 200 },
										onRender: (item, i, col) => {
											return (
												<Dropdown
													onChange={(e, val) => {
														this.updateAxesPosition(
															"yaxes",
															i,
															val.key
														);
													}}
													selectedKey={
														this.state.flot.yaxes[i]
															.position
													}
													options={[
														{
															key: "left",
															text: "Left",
														},
														{
															key: "right",
															text: "Right",
														},
													]}
												></Dropdown>
											);
										},
									},
									{
										key: "min",
										name: "Min",
										cellStyle: { width: 200 },
										onRender: (item, i, col) => {
											return (
												<TextField
													type="number"
													value={
														this.state.flot.yaxes[i]
															.min
													}
													placeholder="Auto"
													onChange={(e, val) => {
														this.updateAxesMin(
															"yaxes",
															i,
															val
														);
													}}
												></TextField>
											);
										},
									},
									{
										key: "max",
										cellStyle: { width: 200 },
										name: "Max",
										onRender: (item, i, col) => {
											return (
												<TextField
													type="number"
													value={
														this.state.flot.yaxes[i]
															.max
													}
													placeholder="Auto"
													onChange={(e, val) => {
														this.updateAxesMax(
															"yaxes",
															i,
															val
														);
													}}
												></TextField>
											);
										},
									},
									{
										key: "time",
										name: "Time",
										cellStyle: {
											width: 20,
											textAlign: "center",
											margin: "auto",
										},
										onRender: (item, i, col) => {
											return (
												<Checkbox
													checked={
														this.state.flot.yaxes[i]
															.mode == "time"
													}
													onChange={(e, val) => {
														this.updateAxes(
															"yaxes",
															i,
															"mode",
															val ? "time" : null
														);
														this.updateAxes(
															"yaxes",
															i,
															"timezone",
															"browser"
														);

														this.updateAxes(
															"yaxes",
															i,
															"timeBase",
															"milliseconds"
														);
													}}
												></Checkbox>
											);
										},
									},
									{
										key: "invert",
										name: "Inv",
										cellStyle: {
											width: 20,
											textAlign: "center",
											margin: "auto",
										},
										onRender: (item, i, col) => {
											return (
												<Checkbox
													checked={
														this.state.flot.yaxes[i]
															.invert
													}
													onChange={(e, val) => {
														this.updateAxes(
															"yaxes",
															i,
															"invert",
															val
														);
													}}
												></Checkbox>
											);
										},
									},
									{
										key: "delete",

										cellStyle: {
											width: 20,
											textAlign: "center",
											margin: "auto",
										},
										onRender: (item, i, col) => {
											return (
												<div>
													<IconButton
														iconProps={{
															iconName: "Delete",
														}}
														onClick={() => {
															this.deleteYAxis(i);
														}}
													></IconButton>
												</div>
											);
										},
									},
								]}
							></PaginatedTable>

							<Stack horizontal horizontalAlign="end">
								<PrimaryButton
									iconProps={{ iconName: "Add" }}
									text="Add Y-axis"
									onClick={() => {
										this.addNewYAxis();
									}}
								></PrimaryButton>
							</Stack>
							<Separator>Series</Separator>
							<PaginatedTable
								showPageSize={false}
								showSearch={false}
								showExport={false}
								pageSize={100}
								selectedKeys={[this.state.selectedSeries]}
								selectionMode={1}
								onActiveItemChanged={(item, i, ev) => {
									this.setState({ selectedSeries: i });
								}}
								items={this.state.flot?.series ?? []}
								columns={[
									{
										key: "label",
										name: "Label",
										fieldName: "label",
										onRender: (item, i, col) => {
											return (
												<TextField
													value={
														this.state.flot
															?.series?.[i]?.label
													}
													onChange={(e, val) => {
														this.updateSeriesLabel(
															i,
															val
														);
													}}
												></TextField>
											);
										},
									},
									{
										key: "xvalue",
										name: "X Value",
										onRender: (item, i, col) => {
											return (
												<Dropdown
													onChange={(e, val) => {
														this.updateSeriesX(
															i,
															val.key
														);
													}}
													selectedKey={
														this.state.flot
															.series?.[i]?.xvalue
													}
													options={this.state.flot.fields?.map(
														(c, i) => {
															return {
																key: i,
																text: c,
															};
														}
													)}
												></Dropdown>
											);
										},
									},
									{
										key: "yvalue",
										name: "Y Value",
										onRender: (item, i, col) => {
											return (
												<Dropdown
													onChange={(e, val) => {
														this.updateSeriesY(
															i,
															val.key
														);
													}}
													selectedKey={
														this.state.flot.series[
															i
														].yvalue
													}
													options={this.state.flot.fields?.map(
														(c, i) => {
															return {
																key: i,
																text: c,
															};
														}
													)}
												></Dropdown>
											);
										},
									},

									{
										key: "delete",
										name: "Delete",
										onRender: (item, i, col) => {
											return (
												<div>
													<IconButton
														iconProps={{
															iconName: "Delete",
														}}
														onClick={() => {
															this.deleteSeries(
																i
															);
														}}
													></IconButton>
												</div>
											);
										},
									},
								]}
							></PaginatedTable>
							<Stack horizontal horizontalAlign="end">
								<PrimaryButton
									iconProps={{ iconName: "Add" }}
									text="Add series"
									onClick={() => {
										this.addNewSeries();
									}}
								></PrimaryButton>
							</Stack>
							{i >= 0 && i < this.state.flot.series.length && (
								<Stack
									style={{ marginLeft: 30 }}
									tokens={{ childrenGap: 5 }}
									horizontalAlign="start"
								>
									<TextField
										label="Series label"
										value={this.state.flot.series[i].label}
										onChange={(e, val) => {
											this.updateSeriesLabel(i, val);
										}}
									></TextField>
									<Stack
										tokens={{ childrenGap: 5 }}
										horizontal
									>
										<Dropdown
											label="X value"
											style={{ width: 200 }}
											onChange={(e, val) => {
												this.updateSeriesX(i, val.key);
											}}
											selectedKey={
												this.state.flot.series?.[i]
													?.xvalue
											}
											options={this.state.flot?.fields?.map(
												(c, i) => {
													return { key: i, text: c };
												}
											)}
											// options={[
											// 	{ key: 0, text: "timestamp" },
											// ].concat(
											// 	this.props.definition?.dataItemDefinitions
											// 		?.filter((el) => {
											// 			return this.state.flot.dataItems.includes(
											// 				el.dataItemId
											// 			);
											// 		})
											// 		.map((el, i) => {
											// 			return {
											// 				key: i + 1,
											// 				text: el.label,
											// 			};
											// 		})
											// )}
										></Dropdown>
										<Dropdown
											label="X axis"
											style={{ width: 200 }}
											onChange={(e, val) => {
												this.updateSeriesXAxis(
													i,
													val.key
												);
											}}
											selectedKey={
												this.state.flot.series[i].xaxis
											}
											options={this.state.flot.xaxes?.map(
												(el, j) => {
													return {
														key: j + 1,
														text: el.axisLabel,
													};
												}
											)}
										></Dropdown>
									</Stack>
									<Stack
										tokens={{ childrenGap: 5 }}
										horizontal
									>
										<Dropdown
											label="Y value"
											style={{ width: 200 }}
											onChange={(e, val) => {
												this.updateSeriesY(i, val.key);
											}}
											selectedKey={
												this.state.flot.series[i].yvalue
											}
											options={this.state.flot.fields?.map(
												(c, i) => {
													return { key: i, text: c };
												}
											)}
											// options={[
											// 	{ key: 0, text: "timestamp" },
											// ].concat(
											// 	this.props.definition?.dataItemDefinitions
											// 		?.filter((el) => {
											// 			return this.state.flot.dataItems.includes(
											// 				el.dataItemId
											// 			);
											// 		})
											// 		.map((el, i) => {
											// 			return {
											// 				key: i + 1,
											// 				text: el.label,
											// 			};
											// 		})
											// )}
										></Dropdown>
										<Dropdown
											style={{ width: 200 }}
											label="Y axis"
											onChange={(e, val) => {
												this.updateSeriesYAxis(
													i,
													val.key
												);
											}}
											selectedKey={
												this.state.flot.series[i].yaxis
											}
											options={this.state.flot.yaxes?.map(
												(el, j) => {
													return {
														key: j + 1,
														text: el.axisLabel,
													};
												}
											)}
										></Dropdown>
									</Stack>
									<Stack
										tokens={{ childrenGap: 5 }}
										horizontal
										verticalAlign="start"
									>
										<PopoutColorPicker
											label="Stroke color"
											color={
												this.state.flot.series[i].color
											}
											onChange={(ev, color) => {
												this.updateSeries(
													i,
													"color",
													color.str
												);
											}}
										></PopoutColorPicker>
										<PopoutColorPicker
											label="Fill color"
											color={
												this.state.flot.series[i]
													.fillColor
											}
											onChange={(ev, color) => {
												this.updateSeriesFill(i, color);
											}}
										></PopoutColorPicker>
										<TextField
											type="number"
											label="Line width"
											value={
												this.state.flot.series[i].lines
													?.lineWidth
											}
										></TextField>
									</Stack>
									<Stack tokens={{ childrenGap: 5 }}>
										<Checkbox
											label="Line"
											checked={
												this.state.flot.series[i].lines
													?.show
											}
											onChange={(e, val) => {
												var lines = {
													...this.state.flot.series[i]
														.lines,
												};
												lines.show = val;
												lines.fill =
													this.state.flot.series[
														i
													].fill;
												lines.fillColor =
													this.state.flot.series[
														i
													].fillColor;
												this.updateSeries(
													i,
													"lines",
													lines
												);
											}}
										/>
										<Checkbox
											label="Bars"
											checked={
												this.state.flot.series[i].bars
													?.show
											}
											onChange={(e, val) => {
												var bars = {
													...this.state.flot.series[i]
														.bars,
												};
												bars.show = val;
												bars.fill =
													this.state.flot.series[
														i
													].fill;
												bars.fillColor =
													this.state.flot.series[
														i
													].fillColor;
												this.updateSeries(
													i,
													"bars",
													bars
												);
											}}
										/>
										<Checkbox
											label="points"
											checked={
												this.state.flot.series[i].points
													?.show
											}
											onChange={(e, val) => {
												var points = {
													...this.state.flot.series[i]
														.points,
												};
												points.show = val;
												points.fill =
													this.state.flot.series[
														i
													].fill;
												points.fillColor =
													this.state.flot.series[
														i
													].fillColor;
												this.updateSeries(
													i,
													"points",
													points
												);
											}}
										/>
									</Stack>
								</Stack>
							)}
						</Stack>
					</>
				)}
			</Stack>
		);
		if (this.nameInput) {
			this.nameInput.focus();
		}
		return stuff;
	}
}

function mapStateToProps({ ui, definition }) {
	return { ui, definition };
}

export default connect(mapStateToProps)(XYChartForm);

// <span className='color-picker-color'>{band3}</span>
