import React from "react";
import PaginatedDetailsList from "../PaginatedTable/PaginatedDetailsList";
export default function QueryTableViewer(props) {
	if (!props.df) return <></>;
	let items = props.df.data.map((row, i) => {
		return Object.fromEntries(
			row.map((col, i) => [props.df.columns[i], col])
		);
	});

	return (
		<PaginatedDetailsList
			showPageLength={items.length > 10}
			{...props}
			items={items}
		></PaginatedDetailsList>
	);
}
