import { PrimaryButton, Stack, Spinner } from "office-ui-fabric-react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { download } from "../../../../../util";
import { executeFetch } from "../../FetchForm";

export default function ActionDispatcher(props) {
	let dispatch = useDispatch();
	let [active, setActive] = useState(false);
	let opt = props.properties;
	return (
		<div
			style={{
				height: "100%",
				width: "100%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<PrimaryButton
				text={active ? opt.reverseText : opt.actionText}
				onClick={() => {
					if (!active && opt.action) {
						dispatch({
							type: opt.action,
						});
					} else if (active && opt.reverseAction) {
						dispatch({
							type: opt.reverseAction,
						});
					}
					setActive(!active);
				}}
			></PrimaryButton>
		</div>
	);
}
