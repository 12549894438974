import { Stack } from "@fluentui/react";
import UPlotComponent from "../../pages/dashboard/components/visuals/uPlot/uPlot";
import PopoutColorPicker from "../PopoutColorPicker";

export default function DataframUPlotLineChart({ df, options }) {
	if (!df) return <></>;
	const opts = {
		padding: [0, 10, 0, 10],
		axes: [
			{
				font: "12px Proxima Nova",
				labelFont: "12px Proxima Nova",
				stroke: "white",
				side: 2,
				label: df.columns[0],
			},
			{
				stroke: "white",
				scale: "y",
				font: "12px Proxima Nova",
				labelFont: "12px Proxima Nova",
				label: "Y values",
				side: 3,
				grid: { show: false },
			},
		],
		legend: { font: "12px Proxima Nova" },
		cursor: { fill: "#ffffff", drag: { x: true, y: true, uni: 50 } },
		time: false,
		rotated: true,
		title: "SQLite Viewer",
		scales: {
			x: { dir: 1, ori: 0, auto: true, time: false },
			y: { dir: 1, ori: 1, auto: true },
		},
		// "series":[{},{"stroke":"#67b7dc","scale":"Usage","label":"System CPU1 Load","fillTo":-999999}],
		series: df.columns.map((c, i) => {
			return i == 0
				? { label: c }
				: {
						stroke:
							options?.[c] ??
							"#" +
								Math.floor(Math.random() * 16777215).toString(
									16
								),
						scale: "y",
						label: c,
				  };
		}),
	};
	return (
		<div style={{ height: "100%" }}>
			<UPlotComponent opts={opts} data={df.col_data}></UPlotComponent>
			{/* <Stack horizontal>
				{df.columns?.map((columnName) => (
					<PopoutColorPicker
						label={columnName}
						color={options?.[columnName] ?? "red"}
					></PopoutColorPicker>
				))}
			</Stack> */}
		</div>
	);
}
