import React, { Component } from "react";
import SortableTree, {
	getTreeFromFlatData,
	getFlatDataFromTree,
} from "react-sortable-tree";
import "./style.scss";
import "react-sortable-tree/style.css"; // This only needs to be imported once in your app
import { PrimaryButton } from "@fluentui/react";
import AssetGroupViewer from "../AssetGroupViewer/AssetGroupViewer";
export default class AssetGroupOrganizer extends Component {
	componentDidMount() {
		this.loadGroups();
	}
	loadGroups() {
		fetch("/api/assetgroups")
			.then((res) => res.json())
			.then((ags) => {
				ags.forEach((element) => {
					element.title = element.assetGroupName;
				});
				var x = getTreeFromFlatData({
					flatData: ags,
					getKey: (item) => {
						return item.assetGroupId;
					},
					getParentKey: (item) => {
						return item.parentGroupId;
					},
					rootKey: 0,
				});
				this.setState({ treeData: x });
			});
	}
	saveChanges() {
		var options = {
			treeData: this.state.treeData,
			getNodeKey: (el) => {
				return el.node.assetGroupId;
			},
			ignoreCollapsed: false,
		};
		var flat = [...getFlatDataFromTree(options)];
		flat.forEach((el) => {
			el.node.parentGroupId = el.parentNode?.assetGroupId ?? null;
		});
		var newAgs = flat.map((el) => el.node);
		fetch("/api/assetgroups", {
			method: "put",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(newAgs),
		})
			.then((res) => res.json())
			.then(() => {
				this.loadGroups();
			});
	}
	render() {
		return (
			<div style={{ height: "90vh" }}>
				<SortableTree
					treeData={this.state?.treeData}
					onChange={(treeData) => {
						this.setState({ treeData });
					}}
				/>
				<AssetGroupViewer></AssetGroupViewer>
				<PrimaryButton
					text="Save Changes"
					onClick={() => this.saveChanges()}
				></PrimaryButton>
			</div>
		);
	}
}
