import {
	Stack,
	TextField,
	Slider,
	DefaultButton,
	getTheme,
	DefaultEffects,
	IconButton,
} from "@fluentui/react";
import React, { useRef } from "react";
import { useEffect } from "react";
import { FontIcon } from "@fluentui/react/lib/Icon";
import "./PaginatedTable.scss";
import { filter } from "@amcharts/amcharts4/.internal/core/utils/Iterator";
import xychart from "../../pages/dashboard/components/visuals/xychart/xychart";
import { download } from "../../util";
export default function PaginatedTable(props) {
	var itemsIsArray =
		Object.prototype.toString.call(props.items) == "[object Array]";
	var itemsElementIsObject =
		Object.prototype.toString.call(props.items?.[0]) == "[object Object]";
	if (!(itemsIsArray && itemsElementIsObject)) {
		return <Stack>No data...</Stack>;
	}
	let ref = useRef();
	let theme = getTheme();
	let [search, setSearch] = React.useState(props.search ?? "");

	let [pageNum, setPageNum] = React.useState(0);
	let [pageSize, setPageSize] = React.useState(props.pageSize ?? 10);

	let [columns, setColumns] = React.useState(props.columns ?? []);
	let [items, setItems] = React.useState(props.items ?? []);
	if (ref.current) {
		console.log(
			ref.current.clientHeight,
			ref.current.parentElement.clientHeight,
			ref.current.querySelector("tr").clientHeight
		);
	}
	useEffect(() => {
		if (props.columns) {
			setColumns(props.columns);
		} else if (items[0] && !props.columns) {
			var autoColumns = Object.keys(items[0]).map((c) => {
				return { key: c, name: c, fieldName: c };
			});
			setColumns(autoColumns);
		} else {
			setColumns([]);
		}
	}, [props.columns, items[0]]);

	useEffect(() => setItems(props.items), [props.items]);
	useEffect(() => setPageSize(props.pageSize ?? 10), [props.pageSize]);
	let filteredItems = props.items.filter((el) => {
		if (typeof props.filter == "function") {
			return props.filter(el, search);
		} else {
			return true;
		}
	});
	let visibleItems = filteredItems.slice(
		Math.floor(pageNum, maxPageNum) * pageSize,
		(pageNum + 1) * pageSize
	);
	let maxPageNum = Math.floor((filteredItems.length + 1) / pageSize);
	if (pageNum > maxPageNum) {
		setPageNum(maxPageNum);
	}
	let pageNavStyle = { fontSize: "1.8rem", margin: 10 };
	let pageLinks = [];
	for (var i = 0; i <= maxPageNum; i++) {
		pageLinks.push(i);
	}
	let _sortColumn = (ev, column) => {
		if (
			typeof column.sort == "function" ||
			typeof column.sort == "boolean"
		) {
			const newColumns = columns.slice();
			const currColumn = newColumns.filter(
				(currCol) => column.key === currCol.key
			)[0];
			let isSortedDescending = false;
			newColumns.forEach((newCol) => {
				if (newCol === currColumn) {
					isSortedDescending = !currColumn.isSortedDescending;
					currColumn.isSortedDescending =
						!currColumn.isSortedDescending;
					currColumn.isSorted = true;
				} else {
					newCol.isSorted = false;
					newCol.isSortedDescending = true;
				}
			});
			let newItems = items;
			if (typeof column.sort == "function") {
				newItems = items.sort(column.sort);
			} else if (typeof column.sort == "boolean" && column.sort) {
				newItems = items.sort((i1, i2) => {
					return i1[column.fieldName]
						?.toString()
						.localeCompare(i2[column.fieldName]?.toString());
				});
			}

			if (isSortedDescending) {
				newItems.reverse();
			}
			setColumns(newColumns);
			setItems(newItems);
		}
	};

	return (
		<div style={props.style}>
			<Stack
				style={{ overflowX: "scroll", height: "100%" }}
				verticalAlign="space-between"
			>
				<Stack horizontal horizontalAlign="space-between">
					<Stack horizontal horizontalAlign="start">
						{(props.showSearch ?? true) &&
							typeof props.filter == "function" && (
								<TextField
									iconProps={{ iconName: "search" }}
									placeholder="Search..."
									value={search}
									onChange={(e, val) => setSearch(val)}
								></TextField>
							)}
					</Stack>

					{props.title && <h2>{props.title}</h2>}
					<Stack horizontal horizontalAlign="end">
						{(props.showPageSize ?? true) && (
							<TextField
								label="Page length: "
								underlined
								mask
								i
								width={50}
								type="number"
								value={pageSize}
								onChange={(e, val) =>
									setPageSize(parseInt(val))
								}
							></TextField>
						)}
						{props.showExport != false && (
							<IconButton
								iconProps={{
									iconName: "Download",
								}}
								menuProps={{
									shouldFocusOnMount: true,
									items: [
										{
											key: "csv",
											text: "CSV",
											onClick: () => {
												var exportableColumns =
													columns.filter(
														(c) =>
															typeof c.export ==
																"function" ||
															c.fieldName
													);
												let csvContent =
													exportableColumns
														.map(
															(c, i) =>
																c.name ??
																c.fieldName ??
																c.key ??
																"Col" + i
														)
														.join(",");
												for (
													var i = 0;
													i < filteredItems.length;
													i++
												) {
													csvContent += "\n";
													csvContent +=
														exportableColumns.map(
															(c) => {
																if (
																	typeof c.export ==
																	"function"
																) {
																	return c.export(
																		filteredItems[
																			i
																		],
																		c
																	);
																} else {
																	return (
																		filteredItems[
																			i
																		][
																			c
																				.fieldName
																		]?.toString() ??
																		"--"
																	);
																}
															}
														);
												}
												download(
													csvContent,
													(props.title ??
														"table_export") + ".csv"
												);
											},
										},
										{
											key: "json",
											text: "JSON",
											onClick: () => {
												var exportableColumns =
													columns.filter(
														(c) =>
															typeof c.export ==
																"function" ||
															c.fieldName
													);

												var exportObj =
													filteredItems.map((f) => {
														var row = {};
														exportableColumns.forEach(
															(c, i) => {
																var key =
																	c.name ??
																	c.fieldName ??
																	c.key ??
																	"Col" + i;
																if (
																	typeof c.export ==
																	"function"
																) {
																	row[key] =
																		c.export(
																			f,
																			c
																		) ??
																		null;
																} else {
																	row[key] =
																		f[
																			c
																				.fieldName
																		]?.toString() ??
																		null;
																}
															}
														);
														return row;
													});
												download(
													JSON.stringify(
														exportObj,
														undefined,
														4
													),
													(props.title ??
														"table_export") +
														".json"
												);
											},
										},
									],
								}}
							></IconButton>
						)}
					</Stack>
				</Stack>
				<Stack.Item style={{ overflow: "auto" }} grow={1}>
					<table
						className="lm-pg-table"
						style={
							props.tableStyle ?? {
								height: "100%",
								overflow: "scroll",
							}
						}
					>
						<thead>
							<tr>
								{props.selectionMode > 0 && <th></th>}
								{columns.map((c) => {
									if (c.visible === false) {
										return <></>;
									}
									return (
										<th
											key={c.key ?? c.id ?? c.fieldName}
											onClick={(e) => {
												_sortColumn(e, c);
											}}
										>
											<Stack
												verticalAlign="center"
												horizontal
												horizontalAlign="start"
											>
												<div>{c.name}</div>
												{c.sort &&
													(c.isSorted ? (
														c.isSortedDescending ? (
															<IconButton
																iconProps={{
																	iconName:
																		"SortUp",
																}}
																iconName="SortUp"
															></IconButton>
														) : (
															<IconButton
																iconProps={{
																	iconName:
																		"SortDown",
																}}
																iconName="SortDown"
															></IconButton>
														)
													) : (
														<IconButton
															style={{
																color: "#aaa",
															}}
															iconProps={{
																iconName:
																	"Sort",
															}}
															iconName="Sort"
														></IconButton>
													))}
											</Stack>
										</th>
									);
								})}
							</tr>
						</thead>
						<tbody>
							{visibleItems ? (
								visibleItems.map((i, visIndex) => {
									let index = filteredItems.indexOf(i);
									var bgColor;
									if (
										typeof props.rowBackgroundColor ==
										"function"
									) {
										bgColor = props.rowBackgroundColor(i);
									}
									return (
										<tr
											key={i.id ?? i.key ?? index}
											onClick={(e) => {
												if (
													typeof props.onActiveItemChanged ==
													"function"
												) {
													props.onActiveItemChanged(
														i,
														index,
														e
													);
												}
												if (
													typeof props.onRowClick ==
													"function"
												) {
													props.onRowClick(
														e,
														i,
														index
													);
												}
											}}
											style={{
												background: bgColor,
												boxShadow:
													DefaultEffects.elevation8,
												borderTop: `1px solid ${theme.palette.neutralLight}`,
											}}
										>
											{props.selectionMode > 0 && (
												<td style={{ width: 25 }}>
													{props.selectedKeys?.indexOf(
														index
													) >= 0 ? (
														<span>
															{typeof props.onRenderSelected ==
															"function" ? (
																props.onRenderSelected()
															) : (
																<IconButton
																	iconProps={{
																		iconName:
																			"Accept",
																	}}
																></IconButton>
															)}
														</span>
													) : (
														<IconButton
															iconProps={{
																iconName:
																	"LocationCircle",
															}}
														></IconButton>
													)}
												</td>
											)}

											{columns.map((c, indexx) => {
												if (c.visible === false) {
													return <></>;
												}
												if (
													typeof c.onRenderComponent ==
													"function"
												) {
													return (
														<td
															key={indexx}
															style={c.cellStyle}
														>
															{c.onRenderComponent(
																i,
																c
															)}
														</td>
													);
												} else if (
													typeof c.onRender ==
													"function"
												) {
													return (
														<td
															key={indexx}
															style={c.cellStyle}
														>
															{c.onRender(
																i,
																index,
																c
															)}
														</td>
													);
												} else if (
													typeof c.onRenderContent ==
													"function"
												) {
													return (
														<td
															key={indexx}
															style={c.cellStyle}
															dangerouslySetInnerHTML={{
																__html: c.onRenderContent(
																	i,
																	c
																),
															}}
														></td>
													);
												} else {
													return (
														<td
															key={indexx}
															style={c.cellStyle}
														>
															{i?.[
																c.fieldName
															]?.toString()}
														</td>
													);
												}
											})}
										</tr>
									);
								})
							) : (
								<p>Oops</p>
							)}
						</tbody>
					</table>
				</Stack.Item>
				{maxPageNum > 0 && (
					<Stack>
						{(props.showSlider ?? true) && (
							<Slider
								max={maxPageNum}
								minimum={0}
								value={pageNum}
								valueFormat={(v) => {
									return v + 1;
								}}
								showValue
								// eslint-disable-next-line react/jsx-no-bind
								onChange={(v) => setPageNum(v)}
							/>
						)}

						<Stack
							horizontal
							verticalAlign={"center"}
							horizontalAlign={"space-between"}
						>
							<DefaultButton
								style={{
									margin: 2,
									color: theme.palette.themePrimary,
								}}
								onClick={() => {
									setPageNum(Math.max(pageNum - 1, 0));
								}}
							>
								PREV
							</DefaultButton>
							{pageLinks.map((el, i, arr) => {
								if (
									arr.length < 8 ||
									i == 0 ||
									i == arr.length - 1 ||
									(i > pageNum - 4 && i < pageNum + 4)
								) {
									return (
										<a
											key={el}
											style={{
												margin: 2,
												fontSize:
													el == pageNum
														? "2rem"
														: "1rem",
												color:
													el == pageNum
														? theme.palette.black
														: theme.palette
																.themePrimary,
												fontWeight:
													el == pageNum
														? "Bold"
														: "Regular",
											}}
											onClick={() => {
												setPageNum(el);
											}}
										>
											{el + 1}
										</a>
									);
								} else if (
									i == pageNum - 4 ||
									i == pageNum + 4
								) {
									return (
										<a
											key={i}
											style={{
												margin: 2,
												color: theme.palette
													.themePrimary,
											}}
										>
											...
										</a>
									);
								}
							})}
							<DefaultButton
								style={{
									margin: 5,
									color: theme.palette.themePrimary,
								}}
								onClick={() => {
									setPageNum(
										Math.min(pageNum + 1, maxPageNum)
									);
								}}
							>
								NEXT
							</DefaultButton>
						</Stack>
					</Stack>
				)}
			</Stack>
		</div>
	);
}
