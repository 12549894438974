import React, { Component } from "react";
import { connect } from "react-redux";
import { Checkbox } from "primereact/checkbox";
import SystemDataItemSelector from "../../../../components/SystemDataItemSelector";
import PopoutColorPicker from "../../../../components/PopoutColorPicker";

class IndicatorForm extends Component {
	state = {
		dataItem: undefined,
		labelColor: "",
		valueColor: "",
		backgroundColor: "",
		timestamp: false,
	};

	static defaultProps = {};

	componentDidMount() {
		const { tile } = this.props.ui.modal;
		if (tile) {
			const {
				dataItem,
				labelColor,
				valueColor,
				backgroundColor,
				timestamp,
				slotPath,
			} = tile.properties;
			//const newDi = this.dataItems.filter((di) => di.label === dataItem.label);
			this.setState({
				dataItem,
				labelColor,
				valueColor,
				backgroundColor,
				timestamp,
				slotPath,
			});
		}
	}

	handleChange = (e) => {
		//e.preventDefault();
		const { addData } = this.props;
		const value = e.target.checked ? e.target.checked : e.target.value;
		this.setState({ [e.target.name]: value }, () => {
			addData(this.state);
		});
	};

	handleChange2 = (key, val) => {
		const { addData } = this.props;
		this.setState({ [key]: val }, () => {
			addData(this.state);
		});
	};
	render() {
		const { dataItem, labelColor, valueColor, backgroundColor, timestamp } =
			this.state;
		const { tile } = this.props.ui.modal;
		var definition = this.props.definition;
		for (var i in tile?.properties?.slotPath ?? []) {
			var slotMatch = definition?.slots?.find(
				(el) => el.slotId == tile.properties.slotPath[i]
			);
			definition =
				slotMatch?.assetDefinition || slotMatch?.systemDefinition;
		}
		var dataItemList = definition?.dataItemDefinitions ?? [];
		return (
			<section className="component-form">
				{/* <SearchableDropdown
          style={{width:'100%'}}
          optionLabel={'label'}
          name='dataItem'
          value={dataItem}
          onChange={this.handleChange}
          options={dataItemList.map((di)=>{return {text:di.label,key:di.dataItemId}})}
          placeholder='Select a data item'
          filter={true}
          filterBy='label'
        /> */}

				<SystemDataItemSelector
					label="Data item"
					key={this.props.ui?.modal?.tile?.id}
					onDataItemSelected={(val) => {
						console.log(val);
						this.handleChange2("dataItem", val.dataItem);
						this.handleChange2("slotPath", val.slotPath);
					}}
					dataItem={this.state.dataItem}
					slotPath={this.state.slotPath}
				></SystemDataItemSelector>
				<PopoutColorPicker
					label={"Label color"}
					color={labelColor}
					onChange={(ev, color) => {
						this.handleChange2("labelColor", color.str);
					}}
				></PopoutColorPicker>
				<PopoutColorPicker
					label={"Background color"}
					color={backgroundColor}
					onChange={(ev, color) => {
						this.handleChange2("backgroundColor", color.str);
					}}
				></PopoutColorPicker>
				<PopoutColorPicker
					label={"Value color"}
					color={valueColor}
					onChange={(ev, color) => {
						this.handleChange2("valueColor", color.str);
					}}
				></PopoutColorPicker>

				<Checkbox
					checked={timestamp}
					onChange={this.handleChange}
					name="timestamp"
				/>
				<label htmlFor="timestamp" className="p-checkbox-label">
					Show Timestamp
				</label>
			</section>
		);
	}
}

function mapStateToProps({ ui, definition }) {
	return { ui, definition };
}

export default connect(mapStateToProps)(IndicatorForm);
