import { Checkbox, TextField } from "office-ui-fabric-react";
import { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { FetchForm } from "../../FetchForm";

export default function DownloadButtonForm(props) {
	let tileProps = props.properties;
	return (
		<div>
			<FetchForm
				{...tileProps.fetch}
				onChange={(e, fetch) => props.addData({ fetch })}
			></FetchForm>
			<TextField
				label="Downloaded file name"
				value={tileProps?.filename}
				onChange={(e, val) => props.addData({ filename: val })}
			></TextField>
			<TextField
				label="Button text"
				value={tileProps?.text}
				onChange={(e, val) => props.addData({ text: val })}
			></TextField>
			<TextField
				label="Button icon"
				value={tileProps?.iconProps?.iconName}
				onChange={(e, val) =>
					props.addData({ iconProps: { iconName: val } })
				}
				autoComplete
			></TextField>
		</div>
	);
}
