import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export function defaultConfig() {
  return (
    <DataTable
      value={generateData()}
      rows={10}
      paginator={true}
    >
      <Column field="label" header="Label" sortable={true} />
      <Column field="value" header="Value" sortable={true} />
    </DataTable>
  )
}

function generateData() {
  const data = [];
  for (let i = 0; i < 100; i++) {
    data.push({label: `datapoint ${i}`, value: Math.floor(Math.random() * 100)})
  }
  return data
}
