import * as React from "react";
import { GroupedList } from "@fluentui/react/lib/GroupedList";
import { Toggle } from "@fluentui/react/lib/Toggle";

import {
	IColumn,
	IObjectWithKey,
	DetailsRow,
} from "@fluentui/react/lib/DetailsList";
import {
	GroupHeader,
	IGroupHeaderCheckboxProps,
	IGroupHeaderProps,
	IGroupRenderProps,
} from "@fluentui/react/lib/GroupedList";
const groupProps = {
	onRenderHeader: (props) => <GroupHeader {...props} />,
};

export default class AssetGroupViewer extends React.Component {
	state = { groups: [], items: [] };
	componentDidMount() {
		this.loadData();
	}
	loadData() {
		fetch("/api/ef/assetgroups/1")
			.then((data) => data.json())
			.then((json) => this.cleanData(json));
	}
	cleanData(rootAg) {
		var items = [];
		var group = {};
		this.addItemsAndGroups(rootAg, group, items);

		this.setState({ items, groups: [group] });
	}
	addItemsAndGroups(assetGroup, group, items) {
		group.key = assetGroup.assetGroupId;
		group.name = assetGroup.assetGroupName;
		group.startIndex = items.length;
		group.count = 0;
		group.level = group.level ?? 0;
		if (assetGroup.assets) {
			group.count = group.count + assetGroup.assets.length;
			assetGroup.assets.forEach((ass) => {
				items.push(ass);
			});
		}
		if (assetGroup.childAssetGroups) {
			group.children = [];

			for (var i = 0; i < assetGroup.childAssetGroups.length; i++) {
				var ag = assetGroup.childAssetGroups[i];
				var newGroup = { level: group.level + 1 };
				group.children.push(newGroup);
				this.addItemsAndGroups(ag, newGroup, items);
			}
		}
	}
	render() {
		return (
			<div>
				<GroupedList
					items={this.state.items}
					// eslint-disable-next-line react/jsx-no-bind
					onRenderCell={(depth, item, i) => {
						if (item) {
							var columns = Object.keys(item)
								.slice(0, 3)
								.map((key) => ({
									key: key,
									name: key,
									fieldName: key,
									minWidth: 300,
								}));
							return (
								item && (
									<DetailsRow
										columns={columns}
										groupNestingDepth={depth}
										item={item}
										itemIndex={i}
									/>
								)
							);
						}
					}}
					groupProps={groupProps}
					groups={this.state.groups}
					compact={true}
				/>
			</div>
		);
	}
}
