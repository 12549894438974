import { getTheme, IconButton, Separator, Stack } from "office-ui-fabric-react";
import { useEffect, useRef } from "react";

import ReactDOM from "react-dom";

export function LimePanel(props) {
	var panelContent = useRef();
	var overlay = useRef();
	var theme = getTheme();

	useEffect(() => {
		overlay.current = document.createElement("div");
		overlay.current.style =
			"position:fixed;display:flex;width:100%;height:100vh;overflow:auto;flex-direction:row;top:0px;z-index:1000000;background-color:#aaaaaaaa;";
		document.querySelector("body").append(overlay.current);
		panelContent.current = document.createElement("div");
		const lightDismiss = document.createElement("div");

		lightDismiss.style = "flex-grow:1";
		panelContent.current.style =
			"flex-grow:1;overflow:auto;max-width:1000px";
		lightDismiss.onclick = () => {
			console.log("Lightdismiss");
			if (props.onDismiss) {
				props.onDismiss();
			}
		};
		overlay.current.append(lightDismiss);
		overlay.current.append(panelContent.current);

		return () => {
			document.querySelector("body").removeChild(overlay.current);
		};
	}, []);
	useEffect(() => {
		if (overlay.current) {
			overlay.current.style.visibility = props.isOpen
				? "visible"
				: "hidden";
		}
	}, [props.isOpen]);
	if (!props.isOpen) {
		return <div></div>;
	}
	let content = (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				minHeight: "100vh",
				width: "100%",
				gap: 10,
				padding: 20,
				float: "right",
				background: theme.palette.white,
			}}
		>
			<div style={{ alignSelf: "end" }}>
				<IconButton
					onClick={() => {
						if (props.onDismiss) {
							props.onDismiss();
						}
					}}
					style={{ color: "white" }}
					iconProps={{ iconName: "ChromeClose" }}
				></IconButton>
			</div>

			{props.children}
			{props.onRenderFooterContent && (
				<div style={{ marginTop: "auto" }}>
					<Separator horizontal></Separator>
					{props.onRenderFooterContent()}
				</div>
			)}
		</div>
	);

	if (panelContent.current) {
		return ReactDOM.createPortal(content, panelContent.current);
	}
	return <div></div>;
}
