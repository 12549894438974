import React, { Component } from "react";
import { connect } from "react-redux";
import { Editor } from "primereact/editor";
import { PanelMenu } from "primereact/panelmenu";
import { Dropdown, Stack, Separator } from "@fluentui/react";
class AlarmTextBoxForm extends Component {
	state = {
		defaultText: this.props.ui.modal.tile
			? this.props.ui.modal.tile.properties.defaultText
			: "",
		openText: this.props.ui.modal.tile
			? this.props.ui.modal.tile.properties.openText
			: "",
		acknowledgedText: this.props.ui.modal.tile
			? this.props.ui.modal.tile.properties.acknowledgedText
			: "",
		escalatedText: this.props.ui.modal.tile
			? this.props.ui.modal.tile.properties.escalatedText
			: "",
		alarm: this.props.ui.modal.tile
			? this.props.ui.modal.tile.properties.alarm
			: 0,
		alarmDefinitions: [],
	};
	componentDidMount() {
		const { currentData } = this.props;
		const { attributes } = this.props.data;
	}

	render() {
		const {
			defaultText,
			openText,
			acknowledgedText,
			escalatedText,
		} = this.state;
		return (
			<Stack>
				<Dropdown
					label="Alarm"
					onChange={(e, val) => {
						this.setState({ alarm: val.key }, () => {
							this.props.addData(this.state);
						});
					}}
					selectedKey={this.state.alarm}
					options={this.state.alarmDefinitions.map((ad) => {
						return { key: ad.alarmDefinitionId, text: ad.name };
					})}
				></Dropdown>
				<Separator>Default/Closed</Separator>
				<Editor
					style={{
						display: "inline-block",
						height: "300px",
						width: "100%",
					}}
					name="defaultText"
					value={defaultText}
					onTextChange={(e) => {
						this.setState({ defaultText: e.htmlValue }, () => {
							this.props.addData(this.state);
						});
					}}
					placeholder="Default text here"
				/>
				<Separator>Open Alarm</Separator>
				<Editor
					style={{
						display: "inline-block",
						height: "300px",
						width: "100%",
					}}
					name="openText"
					value={openText}
					onTextChange={(e) => {
						this.setState({ openText: e.htmlValue }, () => {
							this.props.addData(this.state);
						});
					}}
					placeholder="Alarm open text here"
				/>
				<Separator>Acknowldged Alarm</Separator>
				<Editor
					style={{
						display: "inline-block",
						height: "300px",
						width: "100%",
					}}
					name="acknowledgedText"
					value={acknowledgedText}
					onTextChange={(e) => {
						this.setState({ acknowledgedText: e.htmlValue }, () => {
							this.props.addData(this.state);
						});
					}}
					placeholder="Alarm acknowldged text here"
				/>
				<Separator>Escalated Alarm</Separator>
				<Editor
					style={{
						display: "inline-block",
						height: "300px",
						width: "100%",
					}}
					name="escalatedText"
					value={escalatedText}
					onTextChange={(e) => {
						this.setState({ escalatedText: e.htmlValue }, () => {
							this.props.addData(this.state);
						});
					}}
					placeholder="Alarm escalated text here"
				/>
			</Stack>
		);
	}
}

function mapStateToProps({ ui, currentData }) {
	return { ui, currentData };
}
export default connect(mapStateToProps)(AlarmTextBoxForm);

//const dataItems =
// const dis = this.props.data.dataItems.map((di)=>{
//   return (
//   <AccordionTab header={di.label}>
//   <ul>
//    <li
//       key={di.dataItemId}
//       onClick={()=>{this.addText(`\${data.dataItemsObject['${di.dataItemId}'].label}`)}}>
//       Label
//     </li>
//     <li
//       key={di.dataItemId}
//       onClick={()=>{this.addText(`\${data.dataItemsObject['${di.dataItemId}'].value}`)}}>
//       Value
//     </li>
//     <li
//       key={di.dataItemId}
//       onClick={()=>{this.addText(`\${data.dataItemsObject['${di.dataItemId}'].unit}`)}}>
//       Unit
//     </li>
//     <li
//       key={di.dataItemId}
//       onClick={()=>{this.addText(`\${data.dataItemsObject['${di.dataItemId}'].timestamp}`)}}>
//       Timestamp
//     </li>
//   </ul>
//   </AccordionTab>
//   )
// })
// const attributes = []
// const att = this.props.data.attributes.map((att)=>{
//   return (
//     <AccordionTab header={att.attributeName}>
//     <ul>
//      <li
//         onClick={()=>{this.addText(`\${data.attributesObject['${att.attributeName}'].attributeName}`)}}>
//         Name
//       </li>
//       <li
//         onClick={()=>{this.addText(`\${data.attributesObject['${att.attributeName}'].attributeValue}`)}}>
//         Value
//       </li>
//     </ul>
//     </AccordionTab>
//     )
//   })
// const substitutions = [
//   <AccordionTab header="Time">
//   <li onClick={()=>{this.addText("${this.state.time}")}}>Current Time</li>,
//   <li>Current Date</li>
//   </AccordionTab>,
//   <AccordionTab header="Data Items">
//   <Accordion>{dis}</Accordion>
// </AccordionTab>,
// <AccordionTab header="Attributes">
//   <Accordion>{att}</Accordion>
// </AccordionTab>
//   //<li><ul>{attributes}</ul></li>,
// ]
