import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { Stack, ActionButton } from "@fluentui/react";
import MarkerClusterer from "@googlemaps/markerclustererplus";

class AssetMap extends Component {
	map;
	infoWindows = {};
	markers = {};
	state = {};
	static defaultProps = {
		center: {
			lat: 29.786891,
			lng: -95.5674241,
		},
		zoom: 11,
		assets: [],
	};

	componentDidMount() {
		fetch("/config/alarms.json")
			.then((data) => data.json())
			.then((alarms) => this.setState({ alarms }));
		this.initMap();
	}
	componentDidUpdate() {
		for (var i in this.props.assets) {
			this.placeMarkers(this.props.assets[i]);
		}
	}
	render() {
		return (
			<div
				style={{ width: "100%", height: "100%" }}
				id={`${this.props.id}assetMap`}
			></div>
		);
	}
	initMap() {
		this.bounds = new window.google.maps.LatLngBounds();
		var home = { lat: 29.7604, lng: -95.3698 };
		this.map = new window.google.maps.Map(
			document.querySelector(`#${this.props.id}assetMap`),
			{
				zoom: 2,
				center: home,
				mapTypeId: "satellite",
			}
		);

		for (var i in this.props.assets) {
			this.placeMarkers(this.props.assets[i]);
		}
		//this.getSeismicEvents();
		if (
			window.location.host.toLowerCase() == "seismicms.com" ||
			window.location.host.toLowerCase() == "localhost:3000"
		) {
			this.getShakeSeismicEvents2();
		}
		this.map.addListener("maptypeid_changed", () => {
			var typeToColor, type, color, k, label;

			typeToColor = {
				terrain: "black",
				roadmap: "black",
				hybrid: "white",
				satellite: "white",
			};

			type = this.map.getMapTypeId();
			color = typeToColor[type];

			for (k in this.markers) {
				if (this.markers.hasOwnProperty(k)) {
					label = this.markers[k].getLabel();
					label.color = color;
					this.markers[k].setLabel(label);
				}
			}
		});
	}
	getAttributeValue(el, name) {
		return el?.attributes?.filter((att) => {
			return att.attributeName
				?.toLowerCase()
				?.includes(name.toLowerCase());
		})[0]?.attributeValue;
	}
	getSeismicInfoWindow2(feature, marker) {
		var div = document.createElement("div");
		var id = `marker${feature.id}`;
		div.id = id;
		console.log(marker.position);
		var nearBy = [
			...this.props.assets.filter(
				(ass) =>
					ass.currentCoordinates?.latitude &&
					ass.currentCoordinates?.latitude
			),
		];
		nearBy.sort((ass1, ass2) => {
			return (
				this.calcCrow(
					ass1.currentCoordinates.latitude,
					ass1.currentCoordinates.longitude,
					marker.position.lat(),
					marker.position.lng()
				) -
				this.calcCrow(
					ass2.currentCoordinates.latitude,
					ass2.currentCoordinates.longitude,
					marker.position.lat(),
					marker.position.lng()
				)
			);
		});
		console.log(nearBy);
		var content = (
			<Stack>
				<h2 style={{ color: "black" }}>Magnitude {feature.mag}</h2>
				<p style={{ color: "black" }}>{feature.region}</p>
				<p style={{ color: "black" }}>
					{new Date(feature.otime).toLocaleString()} Local
				</p>
				<p style={{ color: "black" }}>
					{new Date(feature.otime).toUTCString()} UTC
				</p>
				<a
					target="_blank"
					href={`https://seismicms.com/widgets/seismic-timechart/events.html?shakeEvent=${feature.eventID}`}
				>
					View event
				</a>
				{nearBy.slice(0, 6).map((asset) => {
					return (
						<a
							target="_blank"
							href={`https://seismicms.com/widgets/seismic-timechart/index.html?alias=${
								asset.assetAlias
							}&stationCode=${
								asset.attributes.find(
									(att) => att.attributeName == "Station"
								)?.attributeValue
							}&time=${new Date(feature.otime).toJSON()}`}
						>
							{asset.assetAlias}{" "}
							{this.calcCrow(
								asset.currentCoordinates.latitude,
								asset.currentCoordinates.longitude,
								marker.position.lat(),
								marker.position.lng()
							).toFixed(1)}{" "}
							miles
						</a>
					);
				})}
			</Stack>
		);
		if (this.infoWindows[feature.eventID]) {
			this.infoWindows[feature.eventID].close();
		}
		var info = new window.google.maps.InfoWindow({
			content: div,
		});
		this.infoWindows[feature.eventID] = info;
		info.open(this.map, marker);
		ReactDOM.render(content, div);
		return info;
	}
	getSeismicInfoWindow(feature, marker) {
		var div = document.createElement("div");
		var id = `marker${feature.id}`;
		div.id = id;
		console.log(marker.position);
		var nearBy = [
			...this.props.assets.filter(
				(ass) =>
					ass.currentCoordinates?.latitude &&
					ass.currentCoordinates?.latitude
			),
		];
		nearBy.sort((ass1, ass2) => {
			return (
				this.calcCrow(
					ass1.currentCoordinates.latitude,
					ass1.currentCoordinates.longitude,
					marker.position.lat(),
					marker.position.lng()
				) -
				this.calcCrow(
					ass2.currentCoordinates.latitude,
					ass2.currentCoordinates.longitude,
					marker.position.lat(),
					marker.position.lng()
				)
			);
		});
		console.log(nearBy);
		var content = (
			<Stack>
				<h2 style={{ color: "black" }}>
					Magnitude {feature.properties.mag}
				</h2>
				<p style={{ color: "black" }}>{feature.properties.place}</p>
				<p style={{ color: "black" }}>
					{new Date(feature.properties.time).toLocaleString()} Local
				</p>
				<p style={{ color: "black" }}>
					{new Date(feature.properties.time).toUTCString()} UTC
				</p>
				<a
					target="_blank"
					href={`https://seismicms.com/widgets/seismic-timechart/events.html?shakeEvent=${feature.id}`}
				>
					{asset.assetAlias}{" "}
					{this.calcCrow(
						asset.currentCoordinates.latitude,
						asset.currentCoordinates.longitude,
						marker.position.lat(),
						marker.position.lng()
					).toFixed(1)}{" "}
					miles
				</a>
				{nearBy.slice(0, 6).map((asset) => {
					return (
						<a
							target="_blank"
							href={`https://seismicms.com/widgets/seismic-timechart/index.html?alias=${
								asset.assetAlias
							}stationCode=${
								asset.attributes.find(
									(att) => att.attributeName == "Station"
								).attributeValue
							}&time=${new Date(
								feature.properties.time
							).toJSON()}`}
						>
							{asset.assetAlias}{" "}
							{this.calcCrow(
								asset.currentCoordinates.latitude,
								asset.currentCoordinates.longitude,
								marker.position.lat(),
								marker.position.lng()
							).toFixed(1)}{" "}
							miles
						</a>
					);
				})}
				<a target="_blank" href={feature.properties.url}>
					More information
				</a>
			</Stack>
		);
		if (this.infoWindows[feature.id]) {
			this.infoWindows[feature.id].close();
		}
		var info = new window.google.maps.InfoWindow({
			content: div,
		});
		this.infoWindows[feature.id] = info;
		info.open(this.map, marker);
		ReactDOM.render(content, div);
		return info;
	}
	getInfoWindow(asset, marker) {
		var div = document.createElement("div");
		var id = `marker${asset.assetId}`;
		div.id = id;

		var content = (
			<Stack>
				<img src={this.getAttributeValue(asset, "thumbnail")}></img>
				<Stack vertical>
					<p
						style={{
							color: "black",
						}}
					>
						{asset.assetAlias.toUpperCase()}
					</p>

					<Stack horizontal>
						<p
							style={{
								color: "black",
							}}
						>
							{asset.assetDefinition.assetDefinitionName.toUpperCase()}
						</p>
						<pre style={{ margin: "0px" }}> </pre>
						<p
							style={{
								color: "black",
							}}
						>
							{asset.serialNumber.toUpperCase() !=
							asset.assetAlias.toUpperCase()
								? asset.serialNumber.toUpperCase()
								: ""}
						</p>
					</Stack>
					<p
						style={{
							fontSize: ".75em",
							color: "#222",
						}}
					>
						({asset?.currentCoordinates?.latitude.toFixed(5)},
						{asset?.currentCoordinates?.longitude.toFixed(5)})
					</p>
				</Stack>
				<Stack horizontal horizontalAlign="space-between">
					<ActionButton
						style={{
							color: "black",
						}}
						onClick={() => {
							this.props.onEditAsset(asset);
						}}
						iconProps={{ iconName: "Edit" }}
					>
						Edit
					</ActionButton>
					{marker.getDraggable() ? (
						<ActionButton
							style={{
								color: "black",
							}}
							onClick={() => {
								marker.setDraggable(false);
								console.log(
									marker.position.lat(),
									marker.position.lng()
								);
								fetch(
									`/api/assets/${asset.assetId}/coordinates`,
									{
										method: "post",
										headers: {
											"Content-Type": "application/json",
										},
										body: JSON.stringify({
											assetId: asset.assetId,
											latitude: marker.position.lat(),
											longitude: marker.position.lng(),
										}),
									}
								);
								window.google.maps.event.trigger(
									marker,
									"click"
								);
							}}
							iconProps={{ iconName: "CheckMark" }}
						>
							Save location
						</ActionButton>
					) : (
						<ActionButton
							style={{
								color: "black",
							}}
							onClick={() => {
								marker.setDraggable(true);
								window.google.maps.event.trigger(
									marker,
									"click"
								);
							}}
							iconProps={{ iconName: "MapPin" }}
						>
							Move marker
						</ActionButton>
					)}
				</Stack>
				<ActionButton
					style={{
						color: "black",
					}}
					onClick={() => {
						const { history } = this.props;
						if (history) {
							var url = this.getAttributeValue(
								asset,
								"interfaceUrl"
							);
							if (url != null) {
								history.push(`/ifr/${url}`);
								//window.location.href=url
							} else {
								history.push(
									`/assets/${asset.assetId}/dashboard`
								);
							}
						}
					}}
					iconProps={{ iconName: "ViewDashboard" }}
				>
					Open Dashboard
				</ActionButton>
			</Stack>
		);
		if (this.infoWindows[asset.assetId]) {
			this.infoWindows[asset.assetId].close();
		}
		var info = new window.google.maps.InfoWindow({
			content: div,
		});
		this.infoWindows[asset.assetId] = info;
		info.open(this.map, marker);
		ReactDOM.render(content, div);
		return info;
	}
	getSeismicEvents() {
		fetch(
			"https://quakelink.raspberryshake.org/events/query?limit=200&minmag=2&sort=-time&region=Western+Texas",
			{}
		)
			.then((res) => res.text())
			.then((text) => {
				return text
					.split("\r\n")
					.map((line) => line.split(";"))
					.filter((el) => el.length > 5)
					.map((el) => {
						var element = {
							ID: el[0],
							Timestamp: new Date(el[2] + "Z"),
							latitude: el[5],
							longitude: el[6],
							Location: "(" + el[5] + "," + el[6] + ")",
							Magnitude: parseFloat(el[3]),
							Depth: parseFloat(el[7]),
						};
						return element;
					});
			})
			.then((events) => {});

		fetch(
			"https://earthquake.usgs.gov/earthquakes/feed/v1.0/summary/2.5_week.geojson"
		)
			.then((res) => res.json())
			.then((events) => {
				var seisMarkers = events.features.map((element) => {
					return this.placeSeismicMarkers(element);
				});
				new MarkerClusterer(this.map, seisMarkers, {
					imagePath:
						"https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
				});
			});
	}
	getShakeSeismicEvents() {
		fetch("https://quakelink.raspberryshake.org/events/query?archived", {
			headers: {},
			body: "otime>=2021,8,12,15,8,39,468",
			method: "POST",
		})
			.then((res) => res.json())
			.then((events) => {
				var seisMarkers = events.seiscomp.events.map((element) => {
					return this.placeSeismicMarkers2(element);
				});
				new MarkerClusterer(this.map, seisMarkers, {
					imagePath:
						"https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
				});
			});
	}
	getShakeSeismicEvents2() {
		fetch(
			"https://quakelink.raspberryshake.org/events/query?limit=200&minmag=2&sort=-time&region=Western+Texas"
		)
			.then((res) => res.text())
			.then((text) => {
				var seisMarkers = text
					.split("\r\n")
					.map((line) => line.split(";"))
					.map((el) => {
						var element = {
							eventID: el[0],
							lat: parseFloat(el[5]),
							lon: parseFloat(el[6]),
							otime: new Date(el[1]),
							mag: parseFloat(el[3]),
							depth: parseFloat(el[7]),
						};
						return element;
					})
					.filter((element) => element.lat && element.lon)
					.map((element) => this.placeSeismicMarkers2(element));
				new MarkerClusterer(this.map, seisMarkers, {
					imagePath:
						"https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
				});
			});
	}

	placeSeismicMarkers(el) {
		if (this.markers[el.id]) {
			this.markers[el.id].setMap(null);
		}
		var location = {
			lat: el.geometry.coordinates[1],
			lng: el.geometry.coordinates[0],
		};
		var marker = new window.google.maps.Marker({
			position: location,
			icon: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
			label: {
				text: el.properties.title,
				color: "white",
				background: "blue",
			},
			fillColor: "blue",
			//map: this.map,
		});
		marker.addListener("click", () => {
			this.getSeismicInfoWindow(el, marker);
		});
		this.markers[el.id] = marker;
		return marker;
	}
	placeSeismicMarkers2(el) {
		var location = {
			lat: el.lat,
			lng: el.lon,
		};
		var marker = new window.google.maps.Marker({
			position: location,
			icon: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
			label: {
				text: el.eventID,
				color: "white",
				background: "blue",
			},
			fillColor: "blue",
			//map: this.map,
		});
		marker.addListener("click", () => {
			this.getSeismicInfoWindow2(el, marker);
		});
		this.markers[el.eventID] = marker;
		return marker;
	}
	calcCrow(lat1, lon1, lat2, lon2) {
		var R = 6371; // km
		var dLat = this.toRad(lat2 - lat1);
		var dLon = this.toRad(lon2 - lon1);
		var lat1 = this.toRad(lat1);
		var lat2 = this.toRad(lat2);

		var a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.sin(dLon / 2) *
				Math.sin(dLon / 2) *
				Math.cos(lat1) *
				Math.cos(lat2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		return d;
	}

	// Converts numeric degrees to radians
	toRad(Value) {
		return (Value * Math.PI) / 180;
	}
	placeMarkers(asset) {
		if (this.markers[asset.assetId]) {
			this.markers[asset.assetId].setMap(null);
		}
		if (asset.currentCoordinates) {
			var latitude = asset.currentCoordinates.latitude;
			var longitude = asset.currentCoordinates.longitude;
			if (
				(latitude != 0 && longitude != 0) ||
				asset.currentCoordinates.set
			) {
				if (asset.currentCoordinates.set) {
				}
				var location = asset.currentCoordinates.set
					? { lat: this.map.center.lat(), lng: this.map.center.lng() }
					: { lat: latitude, lng: longitude };
				var pinColor =
					this.state.alarms?.find(
						(al) =>
							al.severity ==
							asset.attributes?.find(
								(att) => att.attributeName == "severity"
							)?.attributeValue
					)?.color ?? "#333333";
				// Pick your pin (hole or no hole)
				var pinSVGHole =
					"M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z";
				var labelOriginHole = new window.google.maps.Point(12, 15);
				const svgMarker = {
					path: pinSVGHole,
					anchor: new window.google.maps.Point(12, 17),
					fillOpacity: 1,
					fillColor: pinColor,
					strokeWeight: 2,
					strokeColor: "white",
					scale: 2,
					labelOrigin: labelOriginHole,
				};
				var marker = new window.google.maps.Marker({
					position: location,
					label: {
						text: asset.assetAlias,
						color: "white",
						background: "blue",
					},
					fillColor: "blue",
					draggable: asset.currentCoordinates.set,
					map: this.map,
					icon: this.getAttributeValue(asset, "marker") ?? svgMarker,
				});
				marker.addListener("click", () => {
					this.getInfoWindow(asset, marker);
				});

				this.bounds.extend(marker.position);
				this.map.fitBounds(this.bounds);
				this.markers[asset.assetId.toString()] = marker;
				if (asset.currentCoordinates.set) {
					this.getInfoWindow(asset, marker);
				}
			}
		}
	}
}
export default withRouter(AssetMap);
