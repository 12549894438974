import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import FilePanel from "../../../components/Panels/FilePanel";
import { defaultPlaybackRange, getParameterByName } from "../../../util";
import ExportDialog from "../containers/ExportDialog";
import Main from "../containers/Main";
import MaintenancePanel from "../containers/Maintenance";
import Modal from "../containers/Modal";
import PlaybackBar from "../containers/Playback";
import Tabs from "../containers/Tabs";
import { Switch, withRouter } from "react-router-dom";
import { Route, NavLink } from "react-router-dom";
import { compose } from "redux";
// css entry point
// import variables from '../styles/variables.scss';
import "../styles/main.scss";
import Tile from "../containers/Tile";

class Dashboard extends Component {
	state = {
		loading: true,
	};

	componentDidMount() {
		this.setState({ loading: true });
		try {
			window.document.title =
				this.props.system.assetAlias ??
				this.props.system.serialNumber ??
				this.props.system.name;
			if (this.props.system.assetId) {
				Promise.all([
					this.props.loadAssetDashboard(this.props.system),
				]).then(() => {
					defaultPlaybackRange();
					this.props.loadDefinition();
					this.setState({ loading: false });
				});
			} else if (this.props.system.systemId) {
				Promise.all([
					this.props.loadSystemDashboard(this.props.system.systemId),
				]).then(() => {
					this.props.loadDefinition();
					this.setState({ loading: false });
				});
			} else {
				console.log("where is my shit");
			}

			if (getParameterByName("mode") === "builder") {
				this.props.unsetViewOnly();
			} else {
				this.props.setViewOnly();
			}
		} catch (e) {
			console.log(e);
			this.setState({ loading: "failed" });
		}
	}
	componentWillUnmount() {
		this.setState({ loading: true });
		this.props.applyDashboard({
			config: JSON.stringify({ tiles: [], tabs: [], layouts: [] }),
		});
		console.log("Dashboard unmounting");
	}
	render() {
		const { loading } = this.state;
		if (loading === "failed") {
			return (
				<>
					<section style={{ height: "94vh" }}>
						<div
							style={{
								position: "absolute",
								top: "50%",
								left: "50%",
								transform: "translateX(-50%)",
							}}
						>
							<h2>Failed to load Dashboard. Please refresh.</h2>
						</div>
					</section>
				</>
			);
		} else if (loading) {
			return (
				<>
					<section style={{ height: "94vh" }}>
						<div
							style={{
								position: "absolute",
								top: "50%",
								left: "50%",
								transform: "translateX(-50%)",
							}}
						>
							<Spinner size={SpinnerSize.large} />
						</div>
					</section>
				</>
			);
		}
		return (
			<Switch>
				<Route
					path="/assets/:assetId/dashboard/:dashboardId/tile/:tileId"
					render={(props) => {
						var tileTab = this.props?.tiles?.find((tr) =>
							tr.find((t) => t.id == props.match.params.tileId)
						);
						var tile = tileTab?.find(
							(t) => t.id == props.match.params.tileId
						);
						//this.props.hideNav();
						if (tile?.properties) {
							return <Tile tile={tile}></Tile>;
						} else {
							return <Spinner></Spinner>;
						}
					}}
				></Route>
				<Route
					path="/assets/:assetId/dashboard/:dashboardId/tabs/:tabIndex"
					render={(props) => {
						return (
							<Main
								className="main"
								tabIndex={parseInt(props.match.params.tabIndex)}
							/>
						);
					}}
				></Route>
				<Route path="*">
					<Tabs />
					<Modal />
					<ExportDialog />
					<MaintenancePanel />
					<FilePanel></FilePanel>
					<PlaybackBar></PlaybackBar>
					<Main className="main" />
				</Route>
			</Switch>
		);
	}
}
function mstp({ tiles, definition }) {
	return { tiles, definition };
}
export default compose(withRouter, connect(mstp, actions))(Dashboard);
