import React, { Component } from "react";
import { connect } from "react-redux";

import {
	Separator,
	DefaultButton,
	Stack,
	TextField,
	IconButton,
	Dropdown,
	Check,
	Checkbox,
	PrimaryButton,
	Slider,
} from "@fluentui/react";
import * as am4core from "@amcharts/amcharts4/core";
import PopoutColorPicker from "../../../../components/PopoutColorPicker";
import SearchableDropdown from "../../../../components/SearchableDropdown";
import SystemDataItemSelector from "../../../../components/SystemDataItemSelector";
import UPlotComponent from "../visuals/uPlot/uPlot";
import SteppedLine from "../visuals/uPlot/StepLinePlugin";
import { cleanColor } from "../../../../util";
import { useEffect } from "react";
import { useState } from "react";

const colorSet = new am4core.ColorSet();

class MultiAxesConfig extends Component {
	tile = this.props.ui.modal;
	state = {
		axes: this.props.axes,
		series: this.props.series,
	};
	dataItems = [];
	componentDidUpdate() {}
	componentDidMount() {
		const { tile } = this.props.ui.modal;
		if (tile && !tile.properties.type.default) {
			const { axes, series } = tile.properties;
			const newSeries = series.map((plot) => {
				return {
					...plot,
					axis:
						axes.find((el) => el.label == plot.axis?.label) ??
						axes[0],
				};
			});
			if (series.length !== 0) {
				this.setState({ series: newSeries, axes }, () => {
					this.props.onChange(this.state);
				});
			}
		}
	}

	handleChange = (e, i, type) => {
		console.log(e);
		console.log(i);
		console.log(type);
		if (type === "axis") {
			const axes = [...this.state.axes];
			axes[i][e.target.name] = e.target.value;
			this.setState({ axes });
		} else if (type === "series") {
			const series = [...this.state.series];
			series[i][e.target.name] = e.target.value;
			this.setState({ series }, () => {
				this.props.onChange(this.state);
			});
		}
	};
	handleAxisChange(i, key, value) {
		const axes = [...this.state.axes];
		axes[i][key] = value;
		this.setState({ axes });
	}

	handleSeriesAxisChange(seriesIndex, newAxis) {
		const series = [...this.state.series];
		const axes = [...this.state.axes];
		console.log(axes);
		const ax = axes.find((el) => el.label == newAxis.key);
		console.log(ax);
		series[seriesIndex].axis = ax;
		this.setState({ series }, () => {
			this.props.onChange(this.state);
		});
	}
	handleSeriesDataItemChange(seriesIndex, newDataItem) {
		const series = [...this.state.series];
		const di = this.dataItems.find(
			(el) => el.dataItemId == newDataItem.key
		);
		series[seriesIndex].dataItem = di;
		this.setState({ series }, () => {
			this.props.onChange(this.state);
		});
	}
	handleSeriesDataItemChange2(seriesIndex, val) {
		const series = [...this.state.series];
		series[seriesIndex].dataItem = val.dataItem;
		series[seriesIndex].slotPath = val.slotPath;
		this.setState({ series }, () => {
			this.props.onChange(this.state);
		});
	}
	handleSeriesLineChange(seriesIndex, line) {
		const series = [...this.state.series];
		series[seriesIndex].line = line;
		this.setState({ series }, () => {
			this.props.onChange(this.state);
		});
	}
	handleSeriesSmoothChange(seriesIndex, smooth) {
		const series = [...this.state.series];
		series[seriesIndex].smooth = smooth;
		this.setState({ series }, () => {
			this.props.onChange(this.state);
		});
	}
	handleSeriesColorChange(seriesIndex, color) {
		const series = [...this.state.series];
		series[seriesIndex].color = color.str;
		this.setState({ series }, () => {
			this.props.onChange(this.state);
		});
	}
	handleSeriesFillChange(seriesIndex, color) {
		const series = [...this.state.series];
		series[seriesIndex].fill = color.str;
		this.setState({ series }, () => {
			this.props.onChange(this.state);
		});
	}
	addAxis = (e) => {
		e.preventDefault();
		const length = this.state.axes.length;
		this.setState(
			(prevState) => ({
				axes: [
					...prevState.axes,
					{
						label: `yaxis${length}`,
						min: "",
						max: "",
					},
				],
			}),
			() => {
				this.props.onChange(this.state);
			}
		);
	};

	addSeries = (e) => {
		e.preventDefault();
		this.setState(
			(prevState) => ({
				series: [
					...prevState.series,
					{
						dataItem: "",
						axis: "",
						color: colorSet
							.getIndex(prevState.series.length)
							.hex.substring(1),
					},
				],
			}),
			() => {
				this.props.onChange(this.state);
			}
		);
	};

	seriesOptions = () => {
		var i = this.state.selectedSeries;
		var { series, axes } = this.state;
		var ser = series[i];
		return (
			i >= 0 && (
				<Stack>
					<Dropdown
						label="Axis"
						name="axis"
						style={{ width: "200px" }}
						options={axes.map((el, i) => {
							return { key: el.label, text: el.label };
						})}
						selectedKey={series[i].axis?.label}
						onChange={(e, val) =>
							this.handleSeriesAxisChange(i, val)
						}
						filter
						placeholder="Choose Y-Axis"
						required={true}
					/>
					<div style={{ height: 100, width: "100%" }}>
						<SeriesLinePreview
							count={500}
							series={ser}
						></SeriesLinePreview>
					</div>

					<Stack
						horizontal
						tokens={{ childrenGap: 15 }}
						verticalAlign="end"
					>
						<Dropdown
							label="Line style"
							name="line"
							style={{ width: "90px" }}
							options={[
								{ key: "line", text: "Line" },
								{ key: "step", text: "Step" },
								{ key: "dash", text: "Dashed" },
							]}
							selectedKey={series[i].line ?? "line"}
							onChange={(e, val) =>
								this.handleSeriesLineChange(i, val.key)
							}
							placeholder="Line type"
							required={true}
						/>
						<PopoutColorPicker
							label="Line color"
							color={cleanColor(series[i].color)}
							onChange={(ev, color) => {
								this.handleSeriesColorChange(i, color);
							}}
						></PopoutColorPicker>
						<PopoutColorPicker
							label="Fill color"
							color={cleanColor(series[i].fill)}
							onChange={(ev, color) => {
								this.handleSeriesFillChange(i, color);
							}}
						></PopoutColorPicker>
					</Stack>
					<Stack horizontal>
						<Stack.Item grow={1}>
							<Slider
								label="Smoothing Samples"
								type="number"
								max={100}
								value={series[i].smooth}
								onChange={(val) =>
									this.handleSeriesSmoothChange(i, val)
								}
							></Slider>
						</Stack.Item>

						{/* <TextField
							label=" "
							type="number"
							value={series[i].smooth}
							placeholder="Smooth"
							onChange={(e, val) =>
								this.handleSeriesSmoothChange(i, val)
							}
						></TextField> */}
					</Stack>

					<Stack horizontal>
						<div style={{ marginTop: 10 }}>
							<PrimaryButton
								text="Done"
								onClick={() =>
									this.setState({ selectedSeries: undefined })
								}
							></PrimaryButton>
						</div>
					</Stack>
				</Stack>
			)
		);
	};
	renderSeries = (plot, i) => {
		var { series, axes } = this.state;
		var ser = series[i];
		return (
			<Stack>
				<Stack
					onClick={() =>
						this.setState({
							selectedSeries:
								this.state.selectedSeries == i ? undefined : i,
						})
					}
					horizontal
					tokens={{ childrenGap: 5 }}
					verticalAlign="center"
				>
					<Stack.Item grow={1}>
						<SystemDataItemSelector
							slotPath={plot.slotPath ?? []}
							dataItem={plot.dataItem}
							onDataItemSelected={(val) => {
								this.handleSeriesDataItemChange2(i, val);
							}}
						></SystemDataItemSelector>
					</Stack.Item>

					<div style={{ height: 35, width: 200 }}>
						<SeriesLinePreview series={ser}></SeriesLinePreview>
					</div>
					<IconButton
						onClick={(e) =>
							this.setState({
								selectedSeries:
									this.state.selectedSeries == i
										? undefined
										: i,
							})
						}
						iconProps={{ iconName: "Edit" }}
					></IconButton>
					<IconButton
						style={{ color: "red" }}
						onClick={(e) => this.handleDelete(e, i, "series")}
						iconProps={{ iconName: "Cancel" }}
					></IconButton>
				</Stack>
				{this.state.selectedSeries == i && (
					<div
						style={{
							border: "1px solid #888",
							padding: 10,
							marginTop: 10,
							marginBottom: 10,
						}}
					>
						{this.seriesOptions()}
					</div>
				)}
			</Stack>
		);
	};

	renderAxes = (axis, i) => {
		const { axes } = this.state;
		return (
			<Stack
				verticalAlign="center"
				horizontal
				key={i}
				tokens={{ childrenGap: 5 }}
			>
				<TextField
					style={{ width: "150px" }}
					type="text"
					value={axes[i].label}
					onChange={(e, val) =>
						this.handleAxisChange(i, "label", val)
					}
				/>
				<TextField
					style={{ width: "150px" }}
					value={axes[i].min}
					onChange={(e, val) => this.handleAxisChange(i, "min", val)}
					placeholder="min"
					type="number"
				/>
				<TextField
					style={{ width: "150px" }}
					value={axes[i].max}
					onChange={(e, val) => this.handleAxisChange(i, "max", val)}
					placeholder="max"
					type="number"
				/>
				<Checkbox
					style={{ width: "50px" }}
					checked={axes[i].grid ?? i == 0}
					onChange={(e, val) => this.handleAxisChange(i, "grid", val)}
				></Checkbox>
				<IconButton
					style={{ color: "red" }}
					onClick={(e) => this.handleDelete(e, i, "axis")}
					iconProps={{ iconName: "Cancel" }}
				></IconButton>
			</Stack>
		);
	};

	handleDelete = (e, index, type) => {
		const { axes, series } = this.state;
		if (type === "axis") {
			this.setState(
				{ axes: axes.filter((axis, i) => i !== index) },
				() => {
					this.props.onChange(this.state);
				}
			);
		} else if (type === "series") {
			this.setState(
				{ series: series.filter((series, i) => i !== index) },
				() => {
					this.props.onChange(this.state);
				}
			);
		}
	};

	render() {
		const { axes, series } = this.state;
		const { submitModal, closeModal } = this.props;

		return (
			<Stack tokens={{ childrenGap: 5 }}>
				<Separator>Axes</Separator>
				<Stack tokens={{ childrenGap: 5 }}>
					<Stack
						horizontal
						key={"axesHeader"}
						tokens={{ childrenGap: 5 }}
					>
						<span style={{ width: "150px" }}>Label</span>
						<span style={{ width: "150px" }}>Minimum</span>
						<span style={{ width: "150px" }}>Maximum</span>
						<span style={{ width: "50px" }}>Grid</span>
					</Stack>
					{axes.map(this.renderAxes)}
					<DefaultButton
						iconProps={{ iconName: "Add" }}
						text="Add Axis"
						onClick={this.addAxis}
					/>
				</Stack>
				<Separator>Series</Separator>

				<Stack tokens={{ childrenGap: 5 }}>
					<Stack
						horizontal
						key={"seriesHeader"}
						tokens={{ childrenGap: 5 }}
					>
						<span style={{ width: "200px" }}>Data Item</span>
						<span style={{ width: "200px" }}>Y Axis</span>
					</Stack>
					{series.map(this.renderSeries)}
					<DefaultButton
						iconProps={{ iconName: "Add" }}
						text="Add Series"
						onClick={this.addSeries}
					/>
				</Stack>
			</Stack>
		);
	}
}

function SeriesLinePreview(props) {
	let ser = props.series;
	let [data, setData] = useState([
		Array.from(Array(props.count ?? 80).keys()),
		Array.from(Array(props.count ?? 80).keys()).map(
			(el, i) => Math.random() * 100
		),
	]);

	return (
		<UPlotComponent
			key={JSON.stringify(ser)}
			data={data}
			opts={{
				pxAlign: false,
				cursor: {
					show: false,
				},
				select: {
					show: false,
				},
				legend: {
					show: false,
				},
				scales: {
					x: {
						time: false,
					},
				},
				axes: [
					{
						show: false,
					},
					{
						show: false,
					},
				],
				series: [
					{},
					{
						stroke: cleanColor(ser.color),
						fill: cleanColor(ser.fill),
						dash: ser.line == "dash" ? [5, 5] : undefined,
						spanGaps: true,
						smooth: ser.smooth,
						paths: ser.line == "step" ? SteppedLine : undefined,
					},
				],
			}}
		></UPlotComponent>
	);
}

function mapStateToProps({ data, ui, system, definition }) {
	return { data, ui, system, definition };
}

export default connect(mapStateToProps)(MultiAxesConfig);
