import { useEffect } from "react";
import { useState } from "react";

export function useSignaledState(defaultVal, handler) {
	let [val, setVal] = useState(defaultVal);
	useEffect(() => {
		if (handler) {
			handler(val);
		}
	}, [val]);
	return [val, setVal];
}
