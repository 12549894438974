import React, { Component } from 'react';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import MyColorPicker from './MyColorPicker';
import * as am4core from "@amcharts/amcharts4/core";

const colorSet = new am4core.ColorSet();

class HourlyHeatMapForm extends Component {
  state = {
    dataItem: null,
    start:7,
    stop:1,
    title:"",
    color: colorSet.getIndex(0).hex.substring(1),
  }
  timeoptions = [{"key":0,"text":"12:00 AM"},{"key":1,"text":"1:00 AM"},{"key":2,"text":"2:00 AM"},{"key":3,"text":"3:00 AM"},{"key":4,"text":"4:00 AM"},{"key":5,"text":"5:00 AM"},{"key":6,"text":"6:00 AM"},{"key":7,"text":"7:00 AM"},{"key":8,"text":"8:00 AM"},{"key":9,"text":"9:00 AM"},{"key":10,"text":"10:00 AM"},{"key":11,"text":"11:00 AM"},{"key":12,"text":"12:00 PM"},{"key":13,"text":"1:00 PM"},{"key":14,"text":"2:00 PM"},{"key":15,"text":"3:00 PM"},{"key":16,"text":"4:00 PM"},{"key":17,"text":"5:00 PM"},{"key":18,"text":"6:00 PM"},{"key":19,"text":"7:00 PM"},{"key":20,"text":"8:00 PM"},{"key":21,"text":"9:00 PM"},{"key":22,"text":"10:00 PM"},{"key":23,"text":"11:00 PM"}]
  dataItems = this.props.data.dataItemDefinitions.array;

  componentDidMount() {
      console.log("Mean Box form")
    // populates fields when editing
    const { tile } = this.props.ui.modal;
    if(tile && !tile.properties.type.default) {
      const { dataItem, title, color,start,stop } = tile.properties;
      this.setState({
        dataItem,
        title,
        color,
        start,
        stop
      });
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    const {addData} = this.props;
    this.setState({[e.target.name]: e.target.value}, () =>{
      addData(this.state);
    });
  }

  render() {
    const { title, dataItem, color,start,stop } = this.state;

    return (
      <div className='component-form'>
        <Dropdown
          style={{width:'100%'}}
          optionLabel={'label'}
          name='dataItem'
          value={dataItem}
          onChange={this.handleChange}
          options={this.dataItems}
          placeholder='Select a data item'
          filter={true}
          filterBy='label'
          required={true}
        />
        {/* <Dropdown
         style={{width:'100%'}}
         optionLabel={'label'}
         name='start'
         value={start}
         default={1}
         onChange={this.handleChange}
         options={this.timeoptions}
         placeholder='Select a start time'
         required={true}
        /> */}
        <span className='p-float-label'>
          <InputText
            value={title}
            type='text'
            onChange={this.handleChange}
            name='title'
          />
          <label htmlFor='title'>Title</label>
        </span>
        <MyColorPicker color={color} handleChange={this.handleChange} label='Color:' name='color'/>
      </div>
    );
  }
}

function mapStateToProps({data, ui}) {
  return {data, ui}
}

export default connect(mapStateToProps)(HourlyHeatMapForm);

// <span className='color-picker-color'>{band3}</span>
