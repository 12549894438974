const system = (state = {}, action) => {
	switch (action?.type) {
		case "SYSTEM_UPDATED": {
			state = action.payload ?? {};
			return state;
		}
		case "UPDATE_ASSET": {
			state = action.payload ?? {};
			return state;
		}
		case "DELETED_ATTRIBUTES":
			var newState = { ...state };
			const delAttr = action.payload;
			newState.attributes = newState.attributes.filter((att) => {
				var matchedDeletedAttributes = delAttr.find(
					(delAtt) =>
						delAtt.assetId == att.assetId &&
						delAtt.attributeName.toLowerCase() ==
							att.attributeName.toLowerCase()
				);
				return matchedDeletedAttributes == null;
			});

			return newState;
		case "ATTRIBUTES_DATA":
			var newAttributes = [...state.attributes];
			let updatedAtt = action.payload;
			for (var i = 0; i < updatedAtt.length; i++) {
				var att = updatedAtt[i];
				var matchAtt = newAttributes.find((el) => {
					el.attributeName.toLowerCase() ==
						att.attributeName.toLowerCase() &&
						el.assetId == att.assetId;
				});
				if (matchAtt) {
					newAttributes[i] = att;
				} else {
					newAttributes.push(att);
				}
			}
			return { ...state, attributes: newAttributes };
		default:
			return state;
	}
};
export default system;
