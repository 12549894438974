import React, { Component } from "react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { DatePicker } from "@fluentui/react";
class DateTimePicker extends Component {
	initDate = this.props.datetime ?? this.props.defaultDatetime ?? new Date();
	state = {
		date: this.initDate,
		minutes: parseInt(this.initDate.getMinutes() / 15) * 15,
		hours:
			this.initDate.getHours() >= 12
				? this.initDate.getHours() - 12
				: this.initDate.getHours(),
		ampm: this.initDate.getHours() >= 12 ? 1 : 0,
	};
	componentDidMount() {
		if (this.state.date) {
			this.updateDateTime();
		}
	}
	componentDidUpdate(prevProps) {
		if (prevProps.datetime != this.props.datetime && this.props.datetime) {
			let initState = {
				date: this.props.datetime || new Date(),
				minutes:
					parseInt(
						(this.props.datetime || new Date()).getMinutes() / 15
					) * 15,
				hours:
					(this.props.datetime || new Date()).getHours() >= 12
						? (this.props.datetime || new Date()).getHours() - 12
						: (this.props.datetime || new Date()).getHours(),
				ampm:
					(this.props.datetime || new Date()).getHours() >= 12
						? 1
						: 0,
			};
			this.setState(initState);
		}
	}
	updateDateTime() {
		var x = new Date(this.state.date.getTime());
		x.setHours(0, 0, 0, 0);
		x.setHours(
			this.state.ampm == 0 ? this.state.hours : this.state.hours + 12
		);
		x.setMinutes(this.state.minutes);
		if (this.state.datetime) {
			this.props.onDateChanged(x);
		}
		this.setState({ datetime: x });
	}

	render() {
		let options = [];
		for (var i = 0; i < 12; i++) {
			options.push({ key: i, text: `${i == 0 ? 12 : i}` });
		}
		var timepicker = [
			<td key="time">
				<Dropdown
					options={options}
					defaultSelectedKey={this.state.hours}
					onChange={(e, option) => {
						console.log(option);
						this.setState(
							{ hours: option.key },
							this.updateDateTime
						);
					}}
				/>
			</td>,
			<td>
				<Dropdown
					defaultSelectedKey={this.state.minutes}
					onChange={(e, option) => {
						console.log(option);
						this.setState(
							{ minutes: option.key },
							this.updateDateTime
						);
					}}
					options={[
						{ key: 0, text: "00" },
						{ key: 15, text: "15" },
						{ key: 30, text: "30" },
						{ key: 45, text: "45" },
					]}
				/>
			</td>,
			<td>
				<Dropdown
					defaultSelectedKey={this.state.ampm}
					onChange={(e, option) => {
						console.log(option);
						this.setState(
							{ ampm: option.key },
							this.updateDateTime
						);
					}}
					options={[
						{ key: 0, text: "AM" },
						{ key: 1, text: "PM" },
					]}
				/>
			</td>,
		];
		var datepicker = (
			<td key="date" colSpan="3">
				<DatePicker
					label={this.props.label}
					value={this.state.date}
					onSelectDate={(e) => {
						console.log(e);
						e.setHours(0, 0, 0, 0);
						this.setState({ date: e }, this.updateDateTime);
					}}
				/>
			</td>
		);
		return (
			<div>
				<table style={{ margin: "0px" }}>
					<tbody>
						{this.props.horizontal ? (
							<tr style={{ verticalAlign: "bottom" }}>
								{datepicker}
								{timepicker}
							</tr>
						) : (
							<>
								<tr>{datepicker}</tr>
								<tr>{timepicker}</tr>
							</>
						)}
					</tbody>
				</table>
			</div>
		);
	}
}
export default DateTimePicker;
