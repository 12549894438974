import {
	DetailsList,
	Dropdown,
	IconButton,
	SelectionMode,
	Stack,
} from "@fluentui/react";
import React from "react";
import DateTimePicker from "../dashboard/components/forms/DateTimePicker";

class TDMSViewer extends React.Component {
	state = {
		fileStartTime: new Date(new Date().getTime() - 3600 * 24 * 1000),
		fileEndTime: new Date(),
		fileNameFilter: "%",
		flotConfig: {
			xaxes: [
				{
					label: "New Axis",
					min: null,
					max: null,
					position: "bottom",
					autoScale: "none",
					axisLabel: "x axis label",
					mode: "time",
					timezone: "browser",
					timeBase: "milliseconds",
				},
			],
			yaxes: [
				{
					label: "New Axis",
					min: null,
					max: null,
					position: "left",
					autoScale: "none",
					axisLabel: "y axis label",
				},
			],
			series: [
				{
					label: "newSeries",
					xaxis: 1,
					yaxis: 1,
					color: "rgb(103,183,220)",
					fillColor: "rgba(103,183,220,0.2)",
					lines: {
						show: true,
						fillColor: "rgba(103,183,220,0.2)",
						fill: 0.2,
						color: "rgb(103,183,220)",
					},
					key: 0,
					xvalue: 0,
					yvalue: 2,
				},
			],
		},
	};
	componentDidMount() {
		this.loadFiles();
	}
	loadFiles() {
		fetch(
			`/api/files?startDate=${this.state.fileStartTime.toJSON()}&endDate=${this.state.fileEndTime.toJSON()}&filename=${
				this.state.fileNameFilter
			}`
		)
			.then((res) => res.json())
			.then((files) => {
				this.setState({ files });
			});
	}
	loadGroups() {
		fetch(`/api/files/${this.state.fileId}/tdms/groups`)
			.then((res) => res.json())
			.then((groups) => {
				var selectedGroup = groups[0];
				this.setState({ groups, selectedGroup }, this.loadChannels);
			});
	}
	loadChannels() {
		fetch(
			`/api/files/${this.state.fileId}/tdms/groups/${this.state.selectedGroup}/channels`
		)
			.then((res) => res.json())
			.then((channels) => this.setState({ channels }));
	}
	render() {
		return (
			<Stack>
				<DateTimePicker
					horizontal
					datetime={this.state.fileStartTime}
					onDateChanged={(date) => {
						this.setState({ fileStartTime: date });
					}}
					label="Begin Date"
				/>
				<DateTimePicker
					horizontal
					datetime={this.state.fileEndTime}
					onDateChanged={(date) => {
						this.setState({ fileEndTime: date });
					}}
					label="End Date"
				/>
				<Stack horizontal>
					<IconButton
						iconProps={{ iconName: "refresh" }}
						onClick={() => this.loadFiles()}
					></IconButton>
					<Dropdown
						onChange={(e, val) => {
							this.setState({ fileId: val.key }, this.loadGroups);
						}}
						label="Choose a file"
						options={this.state.files?.map((file) => {
							return { key: file.fileId, text: file.filename };
						})}
					></Dropdown>

					<div
						style={{
							textAlign: "center",
							objectFit: "contain",
							border: "1px dashed white",
							width: "100%",
							lineHeight: "100px",
							height: "100px",
						}}
						onDragOver={(e) => {
							e.preventDefault();
						}}
						onDrop={(e) => {
							e.preventDefault();
							var items = e.dataTransfer.items;
							let formData = new FormData();
							for (var i = 0; i < items.length; i++) {
								console.log(items[i]);
								formData.append("file", items[i].getAsFile());
							}
							this.setState({ loading: true });
							fetch(`/api/files`, {
								method: "post",
								body: formData,
							}).then(() => {
								this.setState({ loading: false });
								this.loadFiles();
							});
						}}
					>
						Drop files here
					</div>
				</Stack>
				<Dropdown
					onChange={(e, val) => {
						this.setState(
							{ selectedGroup: val.key },
							this.loadChannels
						);
					}}
					label="Choose a file"
					options={this.state.groups?.map((group) => {
						return { key: group, text: group };
					})}
				></Dropdown>
				<Stack horizontal>
					<DetailsList
						selectionMode={SelectionMode.multiple}
						items={
							this.state.plots?.map((chan) => {
								return { Channel: chan };
							}) ?? []
						}
						compact
					></DetailsList>
				</Stack>
			</Stack>
		);
	}
}

export default TDMSViewer;
