import React, { Component } from "react";
import { connect } from "react-redux";

import { Stack } from "@fluentui/react";
import { TextField } from "@fluentui/react/lib/TextField";
import { Dropdown } from "@fluentui/react/lib/Dropdown";

class GeneralForm extends Component {
	state = {
		decimals: "2",
		fontSize: "14",
	};

	componentDidMount() {
		const { tile } = this.props.ui.modal;
		if (tile && !tile.properties.type.default) {
			const { decimals, fontSize, overflow } = tile.properties;
			this.setState({ decimals, fontSize, overflow });
		}
	}

	handleChange = (key, val) => {
		this.props.addData({ [key]: val });
		this.setState({ [key]: val });
	};

	render() {
		const { decimals, fontSize, overflow } = this.state;

		return (
			<Stack vertical tokens={{ childrenGap: 5 }}>
				<TextField
					label="Decimals"
					value={decimals || ""}
					type="number"
					onChange={(e, val) => {
						this.handleChange("decimals", parseInt(val));
					}}
					id="decimals"
					name="decimals"
				/>
				<TextField
					label="Font Size"
					value={fontSize || ""}
					type="number"
					onChange={(e, val) => {
						this.handleChange("fontSize", parseInt(val));
					}}
					id="fontSize"
					name="fontSize"
				/>
				<Dropdown
					label="Overflow"
					value={overflow || "hide"}
					options={[
						{ key: "hidden", text: "Cut Off Height" },
						{ key: "scroll", text: "Vertical Scroll" },
					]}
					onChange={(e, val) => {
						this.handleChange("overflow", val.key);
					}}
					id="overflow"
					name="overflow"
				/>
			</Stack>
		);
	}
}

function mapStateToProps({ ui }) {
	return { ui };
}

export default connect(mapStateToProps)(GeneralForm);
