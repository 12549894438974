import {
	ChoiceGroup,
	DetailsList,
	Dropdown,
	IconButton,
	Panel,
	PanelType,
	Pivot,
	PivotItem,
	PrimaryButton,
	Separator,
	Stack,
	Text,
} from "@fluentui/react";
import React, { useState } from "react";

import initSqlJs from "sql.js";
import UPlotComponent from "../../pages/dashboard/components/visuals/uPlot/uPlot";
import { getParameterByName, guid } from "../../util";
import PopoutColorPicker from "../PopoutColorPicker";

import { useTable, usePagination } from "react-table";
import QueryTableViewer from "./QueryTableViewer";
import { createRef } from "react";
import MultiSelect from "../MultiSelect";
import DanfoPlot from "./DanfoPlot";

import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import { useMemo } from "react";
import DataframeVizualizer from "./DataframeVisualizer";
import { useEffect } from "react";
import { useSignaledState } from "../Hooks/LimeHooks";

require("codemirror/mode/sql/sql");
require("codemirror/mode/javascript/javascript");
const dfd = window.dfd;
export default class SQLiteReport extends React.Component {
	textEditorRef = createRef();
	constructor() {
		super();
		this.state = {
			db: null,
			err: null,
			results: null,
			queries: localStorage["queryConfig"]
				? JSON.parse(localStorage["queryConfig"])
				: [{ title: "Query 1", query: garbquery, parsing: garbParse }],
		};
	}
	execret(sql) {
		let results = null,
			err = null;
		try {
			// The sql is executed synchronously on the UI thread.
			// You may want to use a web worker
			results = this.state.db.exec(sql); // an array of objects is returned
			return results;
		} catch (e) {
			// exec throws an error when the SQL statement is invalid
			err = e;
		}
	}
	componentDidMount() {
		// sql.js needs to fetch its wasm file, so we cannot immediately instantiate the database
		// without any configuration, initSqlJs will fetch the wasm files directly from the same path as the js
		// see ../config-overrides.js
		this.setState({ message: "loading SQLite" });
		this.loadFiles();
		if (getParameterByName("fileId")) {
			this.loadSQL(getParameterByName("fileId"));
		}
	}

	loadSQL(fileId) {
		this.setState({ fileId });
		initSqlJs({ locateFile: (filename) => `/wasm/${filename}` })
			.then((SQL) => {
				this.setState({ message: "fetching database" });
				var xhr = new XMLHttpRequest();
				xhr.open("GET", `/api/files/${fileId}/download`, true);
				var type = "sql";
				if (type == "sql") {
					xhr.responseType = "arraybuffer";
					xhr.onload = (e) => {
						console.log(xhr);
						if (xhr.status == 200 && xhr.readyState == 4) {
							var uInt8Array = new Uint8Array(xhr.response);
							var db = new SQL.Database(uInt8Array);
							this.setState({
								message: "opening database",
								db: db,
							});
						}
					};
				}
				// else if ((type = "zip")) {
				// 	zip.configure({
				// 		useWebWorkers: false,
				// 	});
				// 	xhr.responseType = "blob";
				// 	xhr.onload = (e) => {
				// 		console.log(xhr);
				// 		if (xhr.status == 200 && xhr.readyState == 4) {
				// 			xhr.response;

				// 			const reader = new zip.ZipReader(
				// 				new zip.BlobReader(xhr.response)
				// 			);
				// 			// get all entries from the zip
				// 			reader.getEntries().then((entries) => {
				// 				if (entries.length) {
				// 					// get first entry content as text by using a TextWriter
				// 					entries[0]
				// 						.getData(new zip.Uint8ArrayWriter())
				// 						.then((uInt8Array) => {
				// 							console.log(uInt8Array);
				// 							var db = new SQL.Database(
				// 								uInt8Array
				// 							);
				// 							reader.close();
				// 							this.setState({
				// 								message: "opening database",
				// 								db: db,
				// 							});
				// 						});
				// 				}
				// 			});
				// 		}
				// 	};
				// }
				xhr.send();
			})
			.catch((err) => this.setState({ err }));
	}

	loadTableDetails(tablename) {
		var deets = this.execret(`select * from ${tablename} limit 100;`);
		if (deets) {
			var filters = [];
			deets[0].columns?.forEach((col) => {
				filters[col] = this.execret(
					`select distinct ${col} from ${tablename} limit 15`
				)[0].values.map((v) => v[0]);
			});
			this.setState({ filters, results: deets });
		}
	}
	loadFiles() {
		fetch("/api/files?filename=%.sqlite")
			.then((res) => res.json())
			.then((files) => this.setState({ files }));
	}
	/**
	 * Renders a single value of the array returned by db.exec(...) as a table
	 */

	render() {
		return (
			<div className="App">
				<Dropdown
					label={"Choose a file"}
					selectedKey={this.state.fileId}
					options={this.state.files?.map((f) => {
						return { key: f.fileId, text: f.filename };
					})}
					onChange={(e, val) => {
						this.loadSQL(val.key);
					}}
				></Dropdown>
				<Separator></Separator>
				{this.state.queries.map((options, i) => (
					<div>
						<QueryViewer
							{...options}
							db={this.state.db}
						></QueryViewer>
						<Separator></Separator>
					</div>
				))}
				<PrimaryButton
					text="Add query"
					onClick={() => {
						this.setState({
							queries: [
								...this.state.queries,
								{
									title: `Query ${
										this.state.queries.length + 1
									}`,
								},
							],
						});
					}}
				></PrimaryButton>
			</div>
		);
	}
}

function QueryViewer(props) {
	//Query viewer needs a database, query, and graphing options
	let [query, setQuery] = useSignaledState(props.query, props.onQueryChange);
	let [parsing, setParsing] = useState(props.parsing);
	let [queryTab, setQueryTab] = useState(props.queryTab ?? "dummy"); //text, dummy
	let [sqlError, setSqlError] = useState(null);
	let [dfError, setDfError] = useState(null);
	let [useDummy, setUseDummy] = useState(true);
	let [dataTab, setDataTab] = useState("line");
	let [queryKey, setQueryKey] = useState(guid());
	let [selectedTable, setSelectedTable] = useState(null);
	let [selectedFields, setSelectedFields] = useState([]);

	let [showDataPanel, setShowDataPanel] = useState(false);
	let [showVizPanel, setShowVizPanel] = useState(false);
	let [lineChartOptions, setLineChartOptions] = useState({});

	React.useEffect(() => {
		if (props.onChange) {
			props.onChange({
				query,
				parsing,
				title,
				queryTab,
				dataTab,
			});
		}
	});
	React.useEffect(() => {
		setQuery(
			`select \n\t${selectedFields
				?.map((f) => `[${f.key}]`)
				.join(",\n\t")} \nfrom [${selectedTable}]`
		);
	}, [selectedTable, selectedFields]);

	let exec = (sql) => {
		if (props.db) {
			try {
				return props.db?.exec(sql);
			} catch (err) {
				setSqlError(err);
				return null;
			}
		}
		return null;
	};

	const tables = useMemo(() => {
		if (props.db) {
			let q = exec(
				"select name from sqlite_master WHERE  name NOT LIKE 'sqlite_%';"
			);
			return q?.[0]?.values[0] ?? [];
		} else return [];
	}, [props.db]);

	const fields = useMemo(() => {
		if (props.db && selectedTable) {
			var q = exec(`pragma table_info('${selectedTable}')`);
			return (
				q?.[0]?.values?.map((f) => {
					return { key: f[1], text: f[1] };
				}) ?? []
			);
		} else {
			return [];
		}
	}, [props.db, selectedTable]);

	const df = useMemo(() => {
		var q = exec(query);
		if (props.db && q) {
			let { columns, values } = q[0];
			let df = new window.dfd.DataFrame(values, { columns });
			var parsed = (df) => df;
			try {
				eval("parsed=" + parsing);
				df = parsed(df);
			} catch (e) {
				setDfError(e);
				var x = e;
			}
			return df;
		}
	}, [queryKey]);

	const sqlFromOptions = () => {};
	return (
		<Stack>
			{props.db && (
				<Stack>
					<Panel
						isOpen={showDataPanel}
						headerText={props.title ?? "Query 1"}
						type={PanelType.large}
						onDismiss={() => {
							setShowDataPanel(false);
						}}
					>
						<Pivot
							onLinkClick={(item, e) => {
								console.log(item, e);
							}}
						>
							<PivotItem
								headerText="Choose fields"
								itemIcon="SearchData"
							>
								<div>
									<Dropdown
										selectedKey={selectedTable}
										onChange={(e, val) => {
											if (!useDummy) {
												if (
													window.confirm(
														"Unfortunately, you can't use the dummy editor and text query editor at the same time. Using this will clear your text query. Do you want to continue?"
													)
												) {
													setUseDummy(true);
													setSelectedTable(val.key);
													sqlFromOptions();
												}
											} else {
												setSelectedTable(val.key);
												sqlFromOptions();
											}
										}}
										label="Table"
										options={tables.map((t) => {
											return { key: t, text: t };
										})}
									></Dropdown>
									<MultiSelect
										label="Choose columns"
										options={fields}
										selectedOptions={selectedFields}
										onChange={(selection) => {
											if (!useDummy) {
												if (
													window.confirm(
														"Unfortunately, you can't use the dummy editor and text query editor at the same time. Using this will clear your text query. Do you want to continue?"
													)
												) {
													setSelectedFields(
														selection
													);
													setUseDummy(true);
												}
											} else {
												setSelectedFields(selection);
												sqlFromOptions();
											}
										}}
									/>
								</div>
							</PivotItem>
							<PivotItem
								headerText="Raw query"
								itemIcon="CodeEdit"
							>
								<CodeMirror
									value={query}
									options={{
										mode: "text/x-sql",
										theme: "material",
										lineNumbers: true,
									}}
									onBeforeChange={(editor, data, query) => {
										setUseDummy(false);
										setQuery(query);
									}}
									onChange={(editor, data, value) => {}}
								/>
							</PivotItem>
							<PivotItem
								headerText="Code transform"
								itemIcon="Brush"
							>
								<CodeMirror
									value={parsing}
									options={{
										mode: "text/javascript",
										theme: "material",
										lineNumbers: true,
									}}
									onBeforeChange={(
										editor,
										data,
										parsingValue
									) => {
										setParsing(parsingValue);
									}}
									onChange={(editor, data, value) => {}}
								/>
								<p>{dfError?.toString()}</p>
							</PivotItem>
						</Pivot>
						<PrimaryButton
							text="Execute"
							onClick={() => {
								setQueryKey(guid());
							}}
						>
							Execute
						</PrimaryButton>
						<Pivot
							onLinkClick={(item, e) => {
								setDataTab(item.props.viz);
								console.log(item, e);
							}}
						>
							<PivotItem
								viz="line"
								headerText="Line chart"
								itemIcon="SearchData"
							></PivotItem>
							<PivotItem
								viz="pie"
								headerText="Pie chart"
								itemIcon="PieDouble"
							></PivotItem>
							<PivotItem
								viz="bar"
								headerText="Bar chart"
								itemIcon="BarChartVertical"
							></PivotItem>
							<PivotItem
								viz="table"
								headerText="Table"
								itemIcon="Table"
							></PivotItem>
						</Pivot>
						<DataframeVizualizer
							colors={{}}
							dataTab={dataTab}
							setDataTab={setDataTab}
							df={df}
						></DataframeVizualizer>
					</Panel>

					<Stack horizontal horizontalAlign="end">
						<IconButton
							title="Customize query"
							onClick={() => setShowDataPanel(true)}
							iconProps={{ iconName: "SearchData" }}
						></IconButton>
						<IconButton
							title="Change vizualization"
							onClick={() => setShowVizPanel(true)}
							iconProps={{ iconName: "StackedLineChart" }}
						></IconButton>
					</Stack>
					<div
						key={queryKey}
						style={{
							overflowY: "scroll",
							minHeight: "500px",
							width: "100%",
						}}
					>
						<DataframeVizualizer
							colors={{}}
							dataTab={dataTab}
							setDataTab={setDataTab}
							df={df}
						></DataframeVizualizer>
					</div>
				</Stack>
			)}
		</Stack>
	);
}

// const garbquery='SELECT CAST((julianday(timestamp) - 2440587.5)*86400000 AS INTEGER) as time, value from measurement where attrib_Id = 48 limit 50000;'
const garbquery = `		  select 1600000000 as zero, ABS(random() % 500) as one, ABS(random() % 500) + 500 as two, ABS(random() % 500) + 1000 as three
union all select 1600001000 as zero, ABS(random() % 500) as one, ABS(random() % 500) + 500 as two, ABS(random() % 500) + 1000 as three
union all select 1600002000 as zero, ABS(random() % 500) as one, ABS(random() % 500) + 500 as two, ABS(random() % 500) + 1000 as three
union all select 1600003000 as zero, ABS(random() % 500) as one, ABS(random() % 500) + 500 as two, ABS(random() % 500) + 1000 as three
union all select 1600004000 as zero, ABS(random() % 500) as one, ABS(random() % 500) + 500 as two, ABS(random() % 500) + 1000 as three
union all select 1600005000 as zero, ABS(random() % 500) as one, ABS(random() % 500) + 500 as two, ABS(random() % 500) + 1000 as three
union all select 1600006000 as zero, ABS(random() % 500) as one, ABS(random() % 500) + 500 as two, ABS(random() % 500) + 1000 as three
union all select 1600007000 as zero, ABS(random() % 500) as one, ABS(random() % 500) + 500 as two, ABS(random() % 500) + 1000 as three
union all select 1600008000 as zero, ABS(random() % 500) as one, ABS(random() % 500) + 500 as two, ABS(random() % 500) + 1000 as three
union all select 1600009000 as zero, ABS(random() % 500) as one, ABS(random() % 500) + 500 as two, ABS(random() % 500) + 1000 as three
union all select 1600010000 as zero, ABS(random() % 500) as one, ABS(random() % 500) + 500 as two, ABS(random() % 500) + 1000 as three
union all select 1600011000 as zero, ABS(random() % 500) as one, ABS(random() % 500) + 500 as two, ABS(random() % 500) + 1000 as three
union all select 1600012000 as zero, ABS(random() % 500) as one, ABS(random() % 500) + 500 as two, ABS(random() % 500) + 1000 as three
union all select 1600013000 as zero, ABS(random() % 500) as one, ABS(random() % 500) + 500 as two, ABS(random() % 500) + 1000 as three`;
const garbParse = `//Extra parsing can be done here with JS. Query data is provided as a dataframe. See https://danfo.jsdata.org/api-reference/dataframe
(df)=>{
	return df;
}`;
