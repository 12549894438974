import { Dialog } from "@fluentui/react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Button } from "primereact/button";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { getParameterByName } from "../../../util";
import EventForm from "../components/forms/EventForm";

String.prototype.escapeJSONKey = function () {
	return this.replace(/\\n/g, "\\n")
		.replace(/\\"/g, '\\"')
		.replace(/\\r/g, "\\r")
		.replace(/\\t/g, "\\t")
		.replace(/\\b/g, "\\b")
		.replace(/\\f/g, "\\f");
};

class MaintenancePanel extends Component {
	state = { events: [], visible: this.props.ui.maintenanceModal };
	componentDidMount() {
		this.loadEvents();
	}

	loadEvents() {
		// fetch("/api/events?assetId="+getParameterByName("assetId")).then(response => response.json())
		// .then(result => this.setState({"events":result}))
		// .catch(error => this.setState({"events":[]}));
	}
	onHide() {
		this.props.toggleMaintenance();
	}
	formatDateToString(date) {
		// 01, 02, 03, ... 29, 30, 31
		var dd = (date.getDate() < 10 ? "0" : "") + date.getDate();
		// 01, 02, 03, ... 10, 11, 12
		var MM = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
		// 1970, 1971, ... 2015, 2016, ...
		var yyyy = date.getFullYear();

		// create the format you want
		return yyyy + "-" + MM + "-" + dd;
	}
	convertToCalendarEvents(limeevents) {
		var x = limeevents.map((el) => {
			var date = new Date(el.timestamp);
			var datestring = this.formatDateToString(date);
			var data = el;
			var title = el.eventDefinition.name;
			var description = el.eventDefinition.name;
			return {
				date: datestring,
				end: datestring,
				title: title,
				description: description,
				data: data,
			};
		});
		return x;
	}
	guid() {
		return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
			/[xy]/g,
			function (c) {
				var r = (Math.random() * 16) | 0,
					v = c == "x" ? r : (r & 0x3) | 0x8;
				return v.toString(16);
			}
		);
	}
	handleDateClick = (arg) => {
		// bind with an arrow function
		alert(arg.dateStr);
	};
	render() {
		var footer = (
			<div>
				<Button
					label="Cancel"
					icon="pi pi-times"
					onClick={() => {
						this.props.toggleMaintenance();
					}}
				/>
			</div>
		);
		return (
			<Dialog
				footer={footer}
				backgroundColor="#333"
				header="Maintenance Calendar"
				closable={false}
				header={<div></div>}
				onHide={() => {}}
				maximizable={true}
				modal={true}
				visible={this.props.ui.maintenanceModal}
			>
				<div>
					<table>
						<tbody>
							<tr>
								<td valign="top">
									<div style={{ width: "600px" }}>
										{" "}
										<FullCalendar
											contentHeight="auto"
											events={this.convertToCalendarEvents(
												this.state.events
											)}
											dayMaxEventRows={4}
											plugins={[dayGridPlugin]}
											navLinkDayClick={(a, b, c) => {
												console.log(a);
											}}
											eventClick={(info) => {
												this.setState({
													selectedEvent: {
														...info.event._def
															.extendedProps.data,
													},
												});
											}}
											initialView="dayGridMonth"
										/>
										<Button
											onClick={() => {
												this.setState({
													selectedEvent: {
														eventId: this.guid(),
														assetId:
															getParameterByName(
																"assetId"
															),
														timestamp:
															new Date().toJSON(),
														meta: "",
														cause: "",
														eventDefinition: {},
													},
												});
											}}
											label="New Maintenance"
										></Button>
									</div>
								</td>
								<td valign="top">
									<div style={{ float: "left" }}>
										<EventForm
											key={
												this.state.selectedEvent
													?.eventId
											}
											dateClick={this.handleDateClick}
											// onEventChanged={(e) =>{this.setState({selectedEvent:{...e}})}}
											event={this.state.selectedEvent}
											onEventUpdated={() => {
												this.loadEvents();
											}}
										></EventForm>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</Dialog>
		);
	}
}

function mapStateToProps({ data, ui }) {
	return { data, ui };
}

export default connect(mapStateToProps, actions)(MaintenancePanel);
