import React, { Component } from 'react';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';

class PieChartForm extends Component {
  state = {
    url: '',
    rows: '10',
    autoFormat: true,
    columns: '',
  }

  componentDidMount() {
    const { tile } = this.props.ui.modal;
    if(tile) {
      const { url, columns, rows, autoFormat } = tile.properties;
      this.setState({
        url,
        columns,
        rows,
        autoFormat
      });
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    const {addData} = this.props;
    const value = e.target.value ? e.target.value : e.target.checked;
    this.setState({[e.target.name]: value}, () =>{
      addData(this.state);
    });
  }

  render () {
    const { url, columns, autoFormat, rows } = this.state;
    return (
      <section className='component-form'>
        <InputText
          value={url}
          onChange={this.handleChange}
          placeholder='Data Source eg. /api/...'
          name='url'
        />
      {/*<InputText
          value={rows}
          onChange={this.handleChange}
          placeholder='Rows to display'
          name='rows'
          type='number'
        />*/}
        <div style={{marginBottom: '1rem'}}>
          <Checkbox checked={autoFormat} onChange={this.handleChange} name='autoFormat' />
          <label htmlFor='autoFormat' className='p-checkbox-label'>Auto Format</label>
        </div>
        <InputTextarea
          rows={15}
          columns={50}
          value={columns}
          onChange={this.handleChange}
          placeholder='Optional for advance tables/users. Columns: must be in the following format exactly, [{"header": "", "field":""}, {...}, etc].
            To make custom cells, add a "body" field to the column object. The field value must be a function that takes a data arguemnt and returns a string of valid html. Opening tags must also have a "^" after the tag name. Example, "body": () => {return "<a^ href="mylink.com" >data.field</a> (data)'
          name='columns'
        />
      </section>
    )
  }
}

function mapStateToProps({ ui }) {
  return { ui };
}

export default connect(mapStateToProps)(PieChartForm);
