import React, { useRef, useEffect, useState, useMemo } from "react";
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "mapbox-gl-controls/lib/controls.css";
import { Stack, Dropdown, ActionButton, values } from "office-ui-fabric-react";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import { useDispatch } from "react-redux";
import { showAssetEditPanel } from "../actions";
mapboxgl.accessToken =
	"pk.eyJ1Ijoia2JpZWJlciIsImEiOiJjazlrZ3c4OHkwNDY2M2RtdWIzZjczeW5zIn0.Js07hZZ-_djZb4RBF0zqnA";

export default function MapboxSources(props) {
	const mapContainer = useRef(null);
	const map = useRef(null);
	const mapCenterMarker = useRef(null);
	const dispatch = useDispatch();
	const { layers, selectedLayers, sources, mapStyle } = props;
	useEffect(() => {
		if (map.current?.loaded()) {
			for (var i = 0; i < layers.length; i++) {
				map.current.setLayoutProperty(
					layers[i].id,
					"visibility",
					selectedLayers?.indexOf(layers[i].id) >= 0
						? "visible"
						: "none"
				);
			}
		}
	});

	useEffect(() => {
		if (map.current) {
			map.current.setStyle(mapStyle);
		}
	}, [mapStyle]);

	useEffect(() => {
		if (props.posFinder) {
			let { lat, lng } = map.current.getCenter();
			mapCenterMarker.current = new mapboxgl.Marker({
				anchor: "top",
			})
				.setLngLat([lng, lat])
				.addTo(map.current);
		}
		return () => {
			if (mapCenterMarker.current) {
				mapCenterMarker.current.remove();
			}
		};
	}, [props.posFinder]);

	useEffect(() => {
		console.log("Re initializing map");
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style:
				mapStyle ?? "mapbox://styles/kbieber/ckuijpi1d8y5r17n3jwhplrle",
			zoom: 5,
			center: [-95, 30],
		});
		if (
			props.defaultBounds[0][0] &&
			props.defaultBounds[0][1] &&
			props.defaultBounds[1][0] &&
			props.defaultBounds[1][1]
		) {
			map.current.fitBounds(props.defaultBounds);
		}
		map.current.addControl(
			new mapboxgl.NavigationControl(),
			"bottom-right"
		);

		map.current.on("styleimagemissing", (e) => {
			const id = e.id;
			map.current.loadImage(id, (error, image) => {
				if (!error) {
					if (map.current.hasImage(id)) {
						map.current.updateImage(id, image);
					} else {
						map.current.addImage(id, image);
					}
				}
			});
		});

		map.current.on("move", function () {
			if (mapCenterMarker.current) {
				mapCenterMarker.current.setLngLat(map.current.getCenter());
			}
		});
		map.current.on("moveend", () => {
			if (props.onMapMove) {
				props.onMapMove(map.current.getCenter());
			}
		});

		return () => map.current.remove();
	}, []);
	useEffect(() => {
		try {
			if (map.current) {
				for (var s in sources) {
					if (map.current.getSource(s)) {
						console.log(map.current.getSource(s));
						map.current.getSource(s).setData(sources[s].data);
					} else {
						map.current.addSource(s, sources[s]);
					}
				}
			}
		} catch (e) {}
	}, [JSON.stringify(sources)]);
	useEffect(() => {
		const applyLayers = () => {
			if (map.current) {
				for (var s in sources) {
					if (map.current.getSource(s)) {
						map.current.getSource(s).setData(sources[s].data);
					} else {
						map.current.addSource(s, sources[s]);
					}
				}
				for (var l in layers) {
					if (map.current.getLayer(layers[l].id)) {
					} else {
						var myLayer = map.current.addLayer(layers[l]);
						map.current.setLayoutProperty(
							layers[l].id,
							"visibility",
							layers[l].visibility ?? "visible"
						);
						map.current.on("click", layers[l].id, (e) => {
							// Copy coordinates array.
							const coordinates =
								e.features[0].geometry.coordinates.slice();
							const description =
								e.features[0].properties.description;

							const asset = e.features[0].properties.asset;

							// Ensure that if the map is zoomed out such that multiple
							// copies of the feature are visible, the popup appears
							// over the copy being pointed to.

							while (
								Math.abs(e.lngLat.lng - coordinates[0]) > 180
							) {
								coordinates[0] +=
									e.lngLat.lng > coordinates[0] ? 360 : -360;
							}

							var popup = new mapboxgl.Popup();
							popup.setLngLat(coordinates);

							if (asset) {
								popup.setDOMContent(
									assetPopup({
										...JSON.parse(asset),
										...props,
										dispatch,
									})
								);
							} else {
								popup.setHTML(description);
							}
							popup.addTo(map.current);
						});
					}
				}
			}
		};
		map.current.off("styledata", applyLayers);
		map.current.on("styledata", applyLayers);
	}, [layers, sources]);
	return <div style={props.style} ref={mapContainer}></div>;
}

let assetPopup = (asset) => {
	var div = document.createElement("div");
	var content = (
		<Stack>
			<Stack vertical>
				<p
					style={{
						color: "black",
					}}
				>
					{asset.assetAlias.toUpperCase()}
				</p>

				<Stack horizontal>
					<p
						style={{
							color: "black",
						}}
					>
						{asset.assetDefinition.assetDefinitionName.toUpperCase()}
					</p>
					<pre style={{ margin: "0px" }}> </pre>
					<p
						style={{
							color: "black",
						}}
					>
						{asset.serialNumber.toUpperCase() !=
						asset.assetAlias.toUpperCase()
							? asset.serialNumber.toUpperCase()
							: ""}
					</p>
				</Stack>
				<p
					style={{
						fontSize: ".75em",
						color: "#222",
					}}
				>
					({asset?.currentCoordinates?.latitude?.toFixed(5)},
					{asset?.currentCoordinates?.longitude?.toFixed(5)})
				</p>
			</Stack>
			<Stack horizontal horizontalAlign="space-between">
				<ActionButton
					style={{
						color: "black",
					}}
					onClick={() => {
						console.log("ASSET MAP EDIT");
						asset.dispatch(showAssetEditPanel(asset.assetId));
					}}
					iconProps={{ iconName: "Edit" }}
				>
					Edit
				</ActionButton>
			</Stack>
			<ActionButton
				style={{
					color: "black",
				}}
				onClick={() => {
					const { history } = asset;
					if (history) {
						history.push(`/assets/${asset.assetId}/dashboard`);
					}
				}}
				iconProps={{ iconName: "ViewDashboard" }}
			>
				Open Dashboard
			</ActionButton>
		</Stack>
	);

	ReactDOM.render(content, div);

	return div;
};
