import { ActionButton, Icon, Stack, TextField, Spinner } from "@fluentui/react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getParameterByName } from "../../../../util";
import { defaultConfig } from "../forms/defaultConfigs/basicTable";

class Table extends Component {
	state = {
		globalFilter: "",
		data: [],
		loading: true,
	};

	static defaultProps = {
		decimals: "2",
		fontSize: "14",
	};

	componentDidMount() {
		const { search, type } = this.props.properties;
		if (!type.default) {
			this.setState({ globalFilter: search });
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		const { properties } = this.props;
		if (prevProps.properties !== properties) {
			this.setState({ loading: true });
			this.fetchData();
		}
	}

	fetchData = async () => {
		const { option, search } = this.props.properties;
		const id = getParameterByName("assetId");
		switch (option) {
			case "dataItems":
				this.setState({ loading: false, globalFilter: search });
				break;
			case "alarms":
				fetch(`/api/Assets/${id}/Alarms`)
					.then((res) => res.json())
					.then((alarms) =>
						this.setState({
							data: alarms,
							loading: false,
							globalFilter: search,
						})
					);
				break;
			case "events":
				fetch(`/api/Assets/${id}/Events`)
					.then((res) => res.json())
					.then((events) =>
						this.setState({
							data: events,
							loading: false,
							globalFilter: search,
						})
					);
				break;
			case "files":
				fetch(`/api/ef/assets/${id}/files`)
					.then((res) => res.json())
					.then((files) =>
						this.setState({
							data: files,
							loading: false,
							globalFilter: search,
						})
					);
				break;
			default:
				this.setState({ loading: false });

				break;
		}
	};

	export = () => {
		this.dt.exportCSV();
	};
	formatSize(len) {
		var sizes = ["B", "KB", "MB", "GB", "TB"];
		var order = 0;
		while (len >= 1024 && order < sizes.length - 1) {
			order++;
			len = len / 1024;
		}

		// Adjust the format string to your preferences. For example "{0:0.#}{1}" would
		// show a single decimal place, and no space.
		return `${len.toFixed(1)} ${sizes[order]}`;
	}
	renderFileTable = () => {
		const { data } = this.state;
		const columns = [
			{
				header: "File Name",
				field: "filename",
				sortable: true,
				body: (rowData, column) => (
					<a
						onClick={() => {
							fetch(`/api/files/${rowData.fileId}/download`).then(
								function (t) {
									return t.blob().then((b) => {
										var a = document.createElement("a");
										a.href = URL.createObjectURL(b);
										a.setAttribute(
											"download",
											rowData.filename
										);
										a.click();
									});
								}
							);
						}}
						download
					>{`${rowData.filename}`}</a>
				),
			},
			{ header: "Host Name", field: "hostname" },
			{ header: "haspId", field: "haspId" },
			{
				header: "Size",
				sortable: true,
				field: "length",
				body: (file) => {
					return this.formatSize(file.length);
				},
			},
			{ header: "Timestamp", sortable: true, field: "timestamp" },
		];
		const dynamicCols = columns.map((col, i) => {
			return <Column key={i} {...col} />;
		});
		return (
			<DataTable
				ref={(el) => {
					this.dt = el;
				}}
				value={data}
				rows={10}
				paginator={true}
				globalFilter={this.state.globalFilter}
				header={this.header}
				style={{ fontSize: "inherit" }}
			>
				{dynamicCols}
			</DataTable>
		);
	};

	renderEventTable = () => {
		const { data } = this.state;
		const columns = [
			{ header: "Name", field: "name" },
			{ header: "Cause", field: "cause" },
			{
				header: "Received",
				field: "received",
				body: (row) => {
					return new Date(row.timestamp).toLocaleString();
				},
			},
			{
				header: "Timestamp",
				field: "timestamp",
				body: (row) => {
					return new Date(row.timestamp).toLocaleString();
				},
			},
		];
		const dynamicCols = columns.map((col, i) => {
			return <Column key={i} {...col} sortable={true} />;
		});
		return (
			<DataTable
				ref={(el) => {
					this.dt = el;
				}}
				value={data}
				rows={10}
				paginator={true}
				globalFilter={this.state.globalFilter}
				header={this.header}
				style={{ fontSize: "inherit" }}
			>
				{dynamicCols}
			</DataTable>
		);
	};

	renderAlarmTable = () => {
		const { data } = this.state;
		const columns = [
			{ header: "Name", field: "name", sortable: true },
			{ header: "Reason", field: "reason", sortable: true },
			{ header: "Cause", field: "cause", sortable: true },
			{ header: "Last Raised", field: "raised", sortable: true },
			{ header: "Severity", field: "severity", sortable: true },
			{ header: "Count", field: "count", sortable: true },
			{
				sortable: false,
				header: "",
				body: (data) => {
					return (
						<ActionButton
							onClick={() => {
								fetch(
									`/api/assets/${data.assetId}/alarms/${data.alarmDefinitionId}/close`
								).then(() => this.fetchData());
							}}
							text="Close alarm"
						></ActionButton>
					);
				},
			},
		];
		const dynamicCols = columns.map((col, i) => {
			return <Column key={i} {...col} />;
		});
		return (
			<DataTable
				ref={(el) => {
					this.dt = el;
				}}
				value={data}
				rows={10}
				paginator={true}
				globalFilter={this.state.globalFilter}
				header={this.header}
				style={{ fontSize: "inherit" }}
			>
				{dynamicCols}
			</DataTable>
		);
	};

	renderDataItemTable = () => {
		const { globalFilter } = this.state;
		const { currentData, decimals } = this.props;
		let assetId = getParameterByName("assetId");
		//const validRegEx = /^[^\\\/&]*$/;
		let data;
		if (currentData) {
			data = Object.values(currentData[assetId] ?? {})
				.filter((di) => {
					try {
						return di.label?.match(globalFilter);
					} catch (e) {
						return di.label?.includes(globalFilter);
					}
				})
				?.map((di) => {
					return {
						...di,
						value: di.value.toFixed(decimals),
					};
				});
		}
		const columns = [
			{ header: "Alias", field: "label" },
			{ header: "Value", field: "value" },
		];
		const dynamicCols = columns.map((col, i) => {
			return (
				<Column
					key={i}
					field={col.field}
					header={col.header}
					sortable={true}
				/>
			);
		});
		return (
			<DataTable
				ref={(el) => {
					this.dt = el;
				}}
				style={{ fontSize: "inherit" }}
				value={data}
				rows={10}
				paginator={true}
				header={this.header}
			>
				{dynamicCols}
			</DataTable>
		);
	};

	render() {
		const { loading, globalFilter } = this.state;
		const { option, type, showHeader } = this.props.properties;
		const { decimals, fontSize } = this.props;

		if (type.default) return defaultConfig();
		// normal execution
		let renderTable;
		this.header = showHeader ? (
			<div style={{ textAlign: "left" }}>
				<Stack horizontal>
					<TextField
						type="search"
						value={globalFilter}
						onChange={(e, val) => {
							this.setState({ globalFilter: val });
						}}
						placeholder="Search"
						size="50"
					/>
					<span
						style={{
							marginLeft: "1rem",
							marginTop: ".5rem",
							cursor: "pointer",
							display: "inline-block",
						}}
						title="Refresh Data"
						onClick={(e) => {
							this.setState({ loading: true });
							this.fetchData();
						}}
					>
						<Icon iconName="refresh"></Icon>
					</span>
					<span
						style={{ marginLeft: "1rem", cursor: "pointer" }}
						title="Export data as csv"
						onClick={(e) => {
							this.export();
						}}
					>
						<Icon iconName="download"></Icon>
					</span>
				</Stack>
			</div>
		) : (
			<></>
		);
		if (loading) {
			return (
				<div
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%)",
					}}
				>
					<Spinner sizeUnit={"px"} size={30} color={"#fff"} />
				</div>
			);
		} else {
			switch (option) {
				case "dataItems":
					renderTable = this.renderDataItemTable();
					break;
				case "alarms":
					renderTable = this.renderAlarmTable();
					break;
				case "events":
					renderTable = this.renderEventTable();
					break;
				case "files":
					renderTable = this.renderFileTable();
					break;
				default:
					renderTable = this.renderDataItemTable();
					break;
			}
			return (
				<>
					<div
						className="table-background"
						style={{
							height: "100%",
							width: "100%",
							fontSize: `${fontSize}px`,
						}}
					>
						<div onMouseDown={(e) => e.stopPropagation()}>
							{renderTable}
						</div>
					</div>
				</>
			);
		}
	}
}

function mapStateToProps({ currentData }) {
	return { currentData };
}

export default connect(mapStateToProps)(Table);
