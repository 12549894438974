import DataframUPlotLineChart from "./DataframeUPlotLineChart";
import { Stack, IconButton } from "@fluentui/react";
import DanfoPlot from "./DanfoPlot";
import { useState } from "react";
import QueryTableViewer from "./QueryTableViewer";
import DataframeFlot from "./DataframeFlot";
import { useEffect } from "react";
import { useSignaledState } from "../Hooks/LimeHooks";

export default function DataframeVizualizer({ df, options, dataTab }) {
	return (
		<Stack>
			{
				{
					bar: <DanfoPlot type={"bar"} df={df}></DanfoPlot>,
					pie: <DanfoPlot type={"pie"} df={df}></DanfoPlot>,
					table: (
						<QueryTableViewer
							selectionMode={0}
							compact
							autoHidePageControls={true}
							df={df}
						></QueryTableViewer>
					),
					line: (
						<DataframUPlotLineChart
							df={df}
							options={options}
						></DataframUPlotLineChart>
					),
					//line:<DataframeFlot df={df} options={options} flot={flot}></DataframeFlot>
				}[dataTab]
			}
		</Stack>
	);
}

const flot = {
	dataItems: [1, 15],
	xaxes: [
		{
			label: "New Axis",
			min: null,
			max: null,
			position: "bottom",
			autoScale: "exact",
			axisLabel: "x",
			mode: "time",
			timezone: "browser",
			timeBase: "milliseconds",
		},
	],
	yaxes: [
		{
			label: "New Axis",
			min: null,
			max: null,
			position: "left",
			autoScale: "exact",
			axisLabel: "y",
		},
	],
	series: [
		{
			label: "newSeries",
			xaxis: 1,
			yaxis: 1,
			color: "rgb(103,183,220)",
			fillColor: "rgba(103,183,220,0.2)",
			lines: {
				show: true,
				fillColor: "rgba(103,183,220,0.2)",
				fill: 0.2,
				color: "rgb(103,183,220)",
			},
			key: 0,
			xvalue: 0,
			yvalue: 1,
		},
		{
			label: "newSeries2",
			xaxis: 1,
			yaxis: 1,
			color: "rgb(103,148,220)",
			fillColor: "rgba(103,148,220,0.2)",
			lines: {
				show: true,
				fillColor: "rgba(103,148,220,0.2)",
				fill: 0.2,
				color: "rgb(103,148,220)",
			},
			key: 1,
			xvalue: 0,
			yvalue: 2,
		},
	],
	hours: 5,
	zoom: {
		interactive: true,
	},
	pan: {
		interactive: true,
	},
	legend: {
		show: true,
	},
	crosshair: {
		mode: "xy",
	},
	grid: {
		hoverable: "true",
	},
	axisLabels: {
		show: true,
	},
};
