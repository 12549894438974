import React, { useEffect } from "react";
import * as actions from "../../actions";
import { Checkbox, getTheme, Separator } from "@fluentui/react";
import { connect } from "react-redux";
import { Switch, withRouter } from "react-router-dom";
import { compose } from "redux";
import defaultAssetImage from "./defaultAssetImage.png";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import {
	IconButton,
	SpinButton,
	Spinner,
	SpinnerSize,
	Icon,
	DefaultEffects,
	SearchBox,
	DefaultButton,
	ActionButton,
	PrimaryButton,
	Stack,
	DialogType,
	mergeStyles,
	mergeStyleSets,
	Slider,
	OverflowSet,
	Dropdown,
	ComboBox,
} from "@fluentui/react";
import AssetMap from "./assetMap";
import { Route, NavLink } from "react-router-dom";
import Dashboard from "../dashboard/components/Dashboard";
import PaginatedDetailsList from "../../components/PaginatedTable/PaginatedDetailsList";
import { Resizable, ResizableBox } from "react-resizable";
import PaginatedTable from "../../components/PaginatedTable/PaginatedTable";
import { useState } from "react";
import AssetMapbox from "./assetMapbox";
import { UnControlled } from "react-codemirror2";
import { LimePanel } from "../../components/LimePanel";

var pinnedAssets;

initializeIcons();

class AssetsPage extends React.Component {
	renderDate = new Date();
	state = {
		search: "",
		pageNum: 0,
		pageCount: 1,
		rowCount: 8,
		filteredAssets: [],
		attributesDialog: false,
		showMap: true,
		width: 400,
		compact: localStorage.compactAssetRow == "true",
	};

	componentWillUnmount() {
		window.removeEventListener("resize", () =>
			this.updateWindowDimensions()
		);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}
	componentDidUpdate(lastProps) {
		if (lastProps.assets.length == 0 && this.props.assets.length > 0)
			this.updateAssetList();
	}
	componentDidMount() {
		this.renderDate = new Date();
		if (!isNaN(parseInt(localStorage.assetRowCount))) {
			this.setState({
				rowCount: parseInt(localStorage.assetRowCount),
				showMap: "true" == localStorage.showMap,
			});
		}
		this.updateWindowDimensions();
		window.addEventListener("resize", () => this.updateWindowDimensions());
		this.updateAssetList();
		this.loadFilters();
		document.title = "Assets";
	}
	loadFilters() {
		fetch("/config/alarms.json")
			.then((data) => data.json())
			.then((alarms) => this.setState({ alarms }));
		fetch("/config/filters.json")
			.then((r) => r.json())
			.then((filters) => this.setState({ filters }));
	}
	loadAssets() {
		this.props.loadAssets();
		this.setState(
			{
				noAssets: this.props.assets.length == 0,
			},
			this.updateAssetList
		);
	}

	filterAssets(assets, search) {
		if (!assets) {
			assets = this.props.assets;
		}
		if (assets) {
			if (pinnedAssets == undefined) {
				try {
					pinnedAssets = JSON.parse(
						this.props.auth.auth.user.attributes.pinnedAssets ??
							"[]"
					);
				} catch {
					pinnedAssets = [];
				}
			}
			assets.forEach((el) => {
				el.pinned = pinnedAssets.indexOf(el.assetId) >= 0;
			});

			var filter1 = assets.filter((ass) => {
				return (
					ass.assetDefinitionId ==
						this.state.assetDefinitionIdFilter ||
					this.state.assetDefinitionIdFilter == null
				);
			});
			var filter2 = filter1.filter((ass) => {
				return (
					ass?.assetAlias
						?.toLowerCase()
						.includes(search.toLowerCase()) ||
					ass?.serialNumber
						?.toLowerCase()
						.includes(search.toLowerCase()) ||
					ass?.assetDefinition?.assetDefinitionName
						?.toLowerCase()
						.includes(search.toLowerCase())
				);
			});
			for (var x in this.state.attributeFilters) {
				filter2 = filter2.filter((ass) => {
					return ass.attributes.find(
						(att) =>
							att.attributeName ==
								this.state.attributeFilters[x].attributeName &&
							(this.state.attributeFilters[x].exact
								? att.attributeValue ==
								  this.state.attributeFilters[x].attributeValue
								: att.attributeValue.indexOf(
										this.state.attributeFilters[x]
											.attributeValue
								  ) >= 0)
					)
						? true
						: false;
				});
			}

			var filter = filter2.sort((a, b) => {
				//If neither is pinned, compare
				if (!a.pinned && !b.pinned) {
					//If both are connected or both are disconnected, sort by alias
					if (
						(!this.getConnectionStatus(a) &&
							!this.getConnectionStatus(b)) ||
						(this.getConnectionStatus(a) &&
							this.getConnectionStatus(b))
					) {
						if (a.assetAlias < b.assetAlias) return -1;
						if (a.assetAlias > b.assetAlias) return 1;
						return 0;
					} else {
						return (
							this.getConnectionStatus(b) -
							this.getConnectionStatus(a)
						);
					}
				}
				//If both are pinned, compare by order in pinned List
				else if (a.pinned && b.pinned) {
					return (
						pinnedAssets.indexOf(a.assetId) -
						pinnedAssets.indexOf(b.assetId)
					);
				}
				//Otherwise, pinned goes first
				else {
					return b.pinned - a.pinned;
				}
			});
			return filter;
		} else {
			return [];
		}
	}
	updateAssetList() {
		var assets = this.filterAssets(this.props.assets, this.state.search);
		var rowCount = this.state.rowCount;
		localStorage.assetRowCount = rowCount;
		localStorage.showMap = this.state.showMap;
		var pageCount =
			assets.length % rowCount == 0
				? assets.length / rowCount
				: parseInt(assets.length / rowCount) + 1;
		this.setState({ filteredAssets: assets, pageCount: pageCount });
	}
	addBlankLocation(assetId) {
		var assets = [...this.state.filteredAssets];
		assets.forEach((ass) => {
			if (ass.currentCoordinates) {
				ass.currentCoordinates.set = ass.assetId == assetId;
			} else {
				ass.currentCoordinates = { set: ass.assetId == assetId };
			}
		});

		this.setState({ filteredAssets: assets });
	}
	getLastContact(el) {
		var att = el.attributes.filter((att) => {
			return att.attributeName?.toLowerCase()?.includes("lastcontact");
		});
		if (att[0] != undefined) {
			return new Date(att[0].attributeValue);
		} else {
			return null;
		}
	}
	getAttributeValue(el, name) {
		return (
			el?.attributes?.filter((att) => {
				return att.attributeName
					?.toLowerCase()
					?.includes(name.toLowerCase());
			})[0]?.attributeValue ?? null
		);
	}
	getConnectionStatus(el) {
		var lc = new Date(
			el.attributes.filter((att) => {
				return att.attributeName
					?.toLowerCase()
					?.includes("lastcontact");
			})[0]?.attributeValue
		);
		var pingRate = parseInt(
			el.attributes.filter((att) => {
				return att.attributeName?.toLowerCase()?.includes("pingrate");
			})[0]?.attributeValue ?? 60000
		);
		return this.renderDate - lc < pingRate;
	}
	pinAsset(el) {
		pinnedAssets.push(el.assetId);
		this.setPinnedAssetAttribute();
		this.updateAssetList();
	}
	setPinnedAssetAttribute() {
		fetch(
			`/api/ef/users/${
				this.props.auth.auth.user.userId
			}/attributes/pinnedAssets/${JSON.stringify(pinnedAssets)}`
		);
	}
	setAttributeColumn(cols) {
		fetch(`/api/ef/userattributes`, {
			method: "POST",
			body: JSON.stringify([
				{
					userId: this.props.auth.auth.user.userId,
					attributeName: "attributeColumns",
					attributeValue: JSON.stringify(cols),
				},
			]),
		}).then(() => this.props.validateLogin());
	}
	addAttributeColumn(col) {
		let attributeColumns = this.attributeColumns();
		attributeColumns = attributeColumns.concat([col]);
		fetch(`/api/ef/userattributes`, {
			method: "POST",
			body: JSON.stringify([
				{
					userId: this.props.auth.auth.user.userId,
					attributeName: "attributeColumns",
					attributeValue: JSON.stringify(attributeColumns),
				},
			]),
		}).then(() => this.props.validateLogin());
	}
	removeAttributeColumns(col) {
		let attributeColumns = this.attributeColumns();
		attributeColumns = attributeColumns.filter(
			(ac) => ac.toLowerCase() !== col.toLowerCase()
		);
		fetch(`/api/ef/userattributes`, {
			method: "POST",
			body: JSON.stringify([
				{
					userId: this.props.auth.auth.user.userId,
					attributeName: "attributeColumns",
					attributeValue: JSON.stringify(attributeColumns),
				},
			]),
		}).then(() => this.props.validateLogin());
	}

	renderFilterButton(filt) {
		var values = [
			...new Set(
				this.props.assets.reduce((acc, ass) => {
					var matchAtt = ass.attributes.find(
						(att) =>
							att.attributeName.toLowerCase() ==
							filt.attributeName.toLowerCase()
					);
					if (matchAtt) {
						acc.push(matchAtt.attributeValue);
					}
					return acc;
				}, [])
			),
		];

		return (
			<Stack horizontal verticalAlign="end">
				<ComboBox
					// allowFreeform={true}
					autoComplete="on"
					options={values.map((el) => {
						return {
							key: el,
							text: el,
						};
					})}
					label={filt.label}
					key={filt.attributeName}
					//value={this.state.attributeFilters?.find(af=>af.attributeName == filt.attributeName)?.attributeValue}
					selectedKey={
						this.state.attributeFilters?.find(
							(af) => af.attributeName == filt.attributeName
						)?.attributeValue
					}
					onPendingValueChanged={(option, index, val) => {
						// console.log(option,index,val)
						if (option != undefined) {
							this.setAttributeFilter({
								attributeName: filt.attributeName,
								attributeValue: option.key,
								exact: true,
							});
						} else if (val != undefined) {
							this.setAttributeFilter({
								attributeName: filt.attributeName,
								attributeValue: val,
								exact: false,
							});
						}
					}}
				></ComboBox>
				<IconButton
					iconProps={{
						iconName: "Clear",
					}}
					onClick={() => {
						this.setAttributeFilter({
							attributeName: filt.attributeName,
							attributeValue: null,
							exact: false,
						});
					}}
				></IconButton>
			</Stack>
		);
	}
	unpinAsset(el) {
		const index = pinnedAssets.indexOf(el.assetId);
		if (index > -1) {
			pinnedAssets.splice(index, 1);
		}
		this.setPinnedAssetAttribute();
		this.updateAssetList();
	}

	loadAssetDetails(el) {
		fetch(`/api/ef/assets/${el.assetId}`)
			.then((data) => data.json())
			.then((json) => {
				this.setState({ selectedAsset: json });
			});
	}

	incPage() {
		var page = this.state.pageNum;

		if (page < this.state.pageCount - 1) {
			this.setState({ pageNum: page + 1 }, this.updateAssetList);
		}
	}
	decPage() {
		var page = this.state.pageNum;
		if (page > 0) {
			this.setState({ pageNum: page - 1 }, this.updateAssetList);
		}
	}
	onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
		this.setState((state) => {
			const rows = state.rows.slice();
			for (let i = fromRow; i <= toRow; i++) {
				rows[i] = { ...rows[i], ...updated };
			}
			return { rows };
		});
	};
	setAttributeFilter(att) {
		var filters = this.state.attributeFilters ?? [];
		if (att.attributeValue == null) {
			filters = filters.filter(
				(filt) => filt.attributeName != att.attributeName
			);
		} else {
			var matchfilter = filters.find(
				(filt) => filt.attributeName == att.attributeName
			);
			if (matchfilter) {
				matchfilter.attributeValue = att.attributeValue;
			} else {
				filters.push(att);
			}
		}

		this.setState({ attributeFilters: [...filters] }, this.updateAssetList);
	}
	toggleFilters() {
		this.setState({ showFilters: !(this.state.showFilters ?? false) });
	}
	attributeColumns() {
		return JSON.parse(
			this.props.auth.auth.user.attributes.attributeColumns ?? "[]"
		);
	}
	render() {
		const theme = getTheme();
		let attributeColumns = this.attributeColumns();
		//JSON.parse(this.props.auth.auth.user.attributes.attributeColumns);
		var assetDefFilters = [];
		var assetDefs = this.props.assets.map((ass) => ass.assetDefinition);

		for (var i = 0; i < assetDefs.length; i++) {
			var groupMatch = assetDefFilters.find(
				(el) => el.assetDefinitionId == assetDefs[i].assetDefinitionId
			);
			if (groupMatch) {
				groupMatch.count++;
			} else {
				assetDefFilters.push({ ...assetDefs[i], count: 1 });
			}
		}
		var assetDefFilters = assetDefFilters.sort((a, b) => {
			return b.count - a.count;
		});
		const onRenderItem = (adF) => {
			return adF.key != this.state.assetDefinitionIdFilter ? (
				<DefaultButton
					style={{ marginRight: "3px" }}
					text={adF.text}
					onClick={adF.onClick}
				></DefaultButton>
			) : (
				<PrimaryButton
					style={{ marginRight: "3px" }}
					text={adF.text}
					onClick={adF.onClick}
				></PrimaryButton>
			);
		};
		var filterLinks = (
			// <DefaultButton
			// 								text="Filters"
			// 								iconProps={{
			// 									iconName: "Filter",
			// 								}}
			// 								onClick={() => {
			// 									this.toggleFilters();
			// 								}}
			// 							></DefaultButton>
			<OverflowSet
				role="menubar"
				items={[
					{
						key: "Filterlink",
						text: "More Filters",
						onClick: () => {
							this.toggleFilters();
						},
					},
					{
						key: null,
						text: `All ${this.props.assets.length}`,
						onClick: () => {
							this.setState(
								{ assetDefinitionIdFilter: null },
								() => this.updateAssetList()
							);
						},
					},
					...assetDefFilters.slice(0, 3).map((ad) => {
						return {
							key: ad.assetDefinitionId,
							text: `${ad.alias ?? ad.assetDefinitionName} (${
								ad.count
							})`,
							onClick: () => {
								this.setState(
									{
										assetDefinitionIdFilter:
											ad.assetDefinitionId,
									},
									() => this.updateAssetList()
								);
							},
						};
					}),
				]}
				overflowItems={assetDefFilters.slice(4).map((ad) => {
					return {
						key: ad.assetDefinitionId,
						name: `${ad.alias ?? ad.assetDefinitionName} (${
							ad.count
						})`,
						onClick: () => {
							this.setState(
								{
									assetDefinitionIdFilter:
										ad.assetDefinitionId,
								},
								() => this.updateAssetList()
							);
						},
					};
				})}
				onRenderItem={onRenderItem.bind(this)}
				onRenderOverflowButton={(overflowItems) => {
					const buttonStyles = {
						root: {
							minWidth: 0,
							padding: "0 4px",
							alignSelf: "stretch",
							height: "auto",
						},
					};
					return (
						<IconButton
							role="menuitem"
							title="More options"
							styles={buttonStyles}
							menuIconProps={{ iconName: "More" }}
							menuProps={{ items: overflowItems }}
						/>
					);
				}}
			/>
		);

		if (this.props.assets?.length ?? 0 > 0) {
			const dialogContentProps = {
				type: DialogType.normal,
				title: "Login Error",
				closeButtonAriaLabel: "Close",
				subText: this.state.loginErrorMessage,
			};
			const assetEditorProps = {
				type: DialogType.normal,
				title: "Edit Asset",
				closeButtonAriaLabel: "Close",
				subText: this.state.selectedAsset?.assetAlias,
			};
			var pageLinks = [];
			for (var i = 0; i < this.state.pageCount; i++) {
				pageLinks.push(i);
			}

			var assetPage = this.state.filteredAssets.slice(
				this.state.pageNum * this.state.rowCount,
				(this.state.pageNum + 1) * this.state.rowCount
			);
			const fullPage = {
				root: {
					flexGrow: 1,
					minHeight: "90vh",
				},
			};
			return (
				<Switch>
					{this.props.auth.permissions?.find(
						(el) => el.permissionId == 54
					) && (
						<Route
							key={this.props.match.params.assetId}
							path="/assets/:assetId/dashboard/"
							render={(props) => {
								var system = {
									...this.props.assets?.find(
										(el) =>
											el.assetId ==
											props.match.params.assetId
									),
								};

								return (
									<Dashboard
										key={props.match.params.assetId}
										system={system}
									/>
								);
							}}
						></Route>
					)}
					{/* <Route exact={true} path="/assets"> */}
					<Route path="*">
						<Stack horizontal styles={fullPage}>
							<Stack.Item grow={1}>
								<Stack
									vertical
									verticalAlign="space-between"
									tokens={{ childrenGap: "s1" }}
								>
									<Stack.Item>
										<Stack
											horizontalAlign={"space-between"}
											horizontal
											tokens={{ childrenGap: "s1" }}
										>
											<Stack
												horizontal
												wrap
												tokens={{ childrenGap: "s1" }}
											>
												<h1>
													{
														process.env[
															"REACT_APP_ASSETS_LABEL"
														]
													}
												</h1>
												{/* <SearchBox
													placeholder="Search"
													iconProps={{
														iconName: "Search",
													}}
													value={this.state.search}
													onChange={(e) => {
														this.setState(
															{
																search:
																	e?.target
																		?.value ??
																	"",
															},
															this.updateAssetList
														);
													}}
												/> */}
											</Stack>

											<Stack.Item align="end">
												<Stack horizontal>
													<IconButton
														iconProps={{
															iconName: "MapPin",
														}}
														onClick={() => {
															localStorage.showMap =
																!this.state
																	.showMap;
															this.setState({
																showMap:
																	!this.state
																		.showMap,
															});
														}}
													></IconButton>
													<IconButton
														iconProps={{
															iconName: this.state
																.viewOptions
																? "hide"
																: "settings",
														}}
														onClick={() => {
															this.setState({
																viewOptions:
																	!this.state
																		.viewOptions,
															});
														}}
													></IconButton>
												</Stack>
											</Stack.Item>
										</Stack>
										{this.state.viewOptions && (
											<div
												style={{
													backgroundColor:
														theme.palette
															.neutralLight,
													padding: 15,
													position: "relative",
												}}
											>
												<div
													style={{
														position: "absolute",
														right: 5,
														top: 5,
													}}
												>
													<Icon
														iconName="Cancel"
														onClick={() => {
															this.setState({
																viewOptions: false,
															});
														}}
													></Icon>
												</div>
												<Stack
													tokens={{ childrenGap: 15 }}
													horizontal
													horizontalAlign="start"
													verticalAlign="center"
												>
													<Checkbox
														label="Compact"
														checked={
															this.state.compact
														}
														onChange={(e, val) => {
															localStorage.compactAssetRow =
																val;
															this.setState({
																compact: val,
															});
														}}
													></Checkbox>
													<Separator
														vertical
													></Separator>
													<div>
														<SpinButton
															value={
																this.state
																	.rowCount
															}
															label={"Rows:"}
															min={1}
															style={{
																width: "100px",
															}}
															max={100}
															step={1}
															onIncrement={(
																e
															) => {
																this.setState(
																	{
																		rowCount:
																			e +
																			1,
																	},
																	this
																		.updateAssetList
																);
															}}
															onDecrement={(
																e
															) => {
																this.setState(
																	{
																		rowCount:
																			e -
																			1,
																	},
																	this
																		.updateAssetList
																);
															}}
															onValidate={(e) => {
																this.setState(
																	{
																		rowCount:
																			parseInt(
																				e
																			),
																	},
																	this
																		.updateAssetList
																);
															}}
															incrementButtonAriaLabel={
																"Increase value by 1"
															}
															decrementButtonAriaLabel={
																"Decrease value by 1"
															}
														/>
													</div>
													<Separator
														vertical
													></Separator>
													<Checkbox
														label="Map"
														checked={
															this.state.showMap
														}
														onChange={(e, val) => {
															localStorage.showMap =
																val;
															this.setState({
																showMap: val,
															});
														}}
													></Checkbox>
													<Separator
														vertical
													></Separator>
													<AttributeSelector
														defaultAttributes={this.attributeColumns()}
														assets={
															this.props.assets
														}
														onAttributeAdd={(a) =>
															this.setAttributeColumn(
																a
															)
														}
													></AttributeSelector>
													{/* {this.attributeColumns().map(
													(att) => (
														<Stack horizontal>
															<Icon
																onClick={() => {
																	this.removeAttributeColumns(
																		att
																	);
																}}
																iconName="Cancel"
															></Icon>
															<p>{att}</p>
														</Stack>
													)
												)} */}
												</Stack>
											</div>
										)}
									</Stack.Item>
									{}
									{filterLinks}
									{this.state.showFilters && (
										<div
											style={{
												background: "#333",
												padding: 15,
												position: "relative",
											}}
										>
											<div
												style={{
													position: "absolute",
													right: 5,
													top: 5,
												}}
											>
												<Icon
													iconName="Cancel"
													onClick={() => {
														this.setState({
															showFilters: false,
														});
													}}
												></Icon>
											</div>
											<Stack
												horizontal
												wrap
												verticalAlign="center"
											>
												<Stack
													horizontal
													verticalAlign="end"
												>
													<Dropdown
														label="Asset definition"
														options={[
															{
																key: null,
																text: `All ${this.props.assets.length}`,
															},
															...assetDefFilters.map(
																(ad) => {
																	return {
																		key: ad.assetDefinitionId,
																		text: `${
																			ad.alias ??
																			ad.assetDefinitionName
																		} (${
																			ad.count
																		})`,
																	};
																}
															),
														]}
														selectedKey={
															this.state
																.assetDefinitionIdFilter
														}
														onChange={(e, val) => {
															this.setState(
																{
																	assetDefinitionIdFilter:
																		val.key,
																},
																() =>
																	this.updateAssetList()
															);
														}}
													></Dropdown>
													<IconButton
														iconProps={{
															iconName: "Clear",
														}}
														onClick={() => {
															this.setState(
																{
																	assetDefinitionIdFilter:
																		null,
																},
																() =>
																	this.updateAssetList()
															);
														}}
													></IconButton>
												</Stack>
												{this.state.filters?.map(
													(filt) =>
														this.renderFilterButton(
															filt
														)
												)}
											</Stack>
										</div>
									)}

									<div>
										{this.state.attributeFilters?.map(
											(af) => (
												<p
													onClick={() => {
														this.setAttributeFilter(
															{
																attributeName:
																	af.attributeName,
															}
														);
													}}
													style={{
														display: "inline-block",
														color: theme.palette
															.white,
														background:
															theme.palette
																.themePrimary,
														marginRight: "2px",
														paddingLeft: "5px",
														paddingRight: "5px",
														borderRadius: "12px",
													}}
												>{`${af.attributeName}=${af.attributeValue}`}</p>
											)
										)}
									</div>
									<PaginatedTable
										compact
										filter={(el, search) => {
											return (
												el.assetAlias
													.toLowerCase()
													.includes(
														search.toLowerCase()
													) ||
												el.serialNumber
													.toLowerCase()
													.includes(
														search.toLowerCase()
													) |
													el.assetDefinition.assetDefinitionName
														.toLowerCase()
														.includes(
															search.toLowerCase()
														)
											);
										}}
										rowBackgroundColor={(row) => {
											const bgTint =
												(this.state.alarms?.find(
													(al) =>
														al.severity ==
														row.attributes?.find(
															(att) =>
																att.attributeName.toLowerCase() ==
																"severity"
														)?.attributeValue
												)?.color ?? "#333333") + "20";
											return bgTint;
										}}
										constrainMode={0}
										layoutMode={0}
										selectionMode={0}
										columns={[
											...(this.state.compact
												? []
												: [
														{
															isCollapsible: true,
															isResizable: false,
															minWidth: 30,
															maxWidth: 100,
															key: "thumbnail",
															onRenderComponent: (
																el
															) => {
																if (
																	this.state
																		.compact
																) {
																	return (
																		<></>
																	);
																}
																return (
																	<img
																		style={{
																			objectFit:
																				"contain",
																			border: "none",
																			height: "75px",
																			width: "90px",
																		}}
																		src={
																			this.getAttributeValue(
																				el,
																				"thumbnail"
																			) ??
																			defaultAssetImage
																		}
																	></img>
																);
															},
														},
												  ]),

											{
												key: "fave",
												minWidth: 30,
												maxWidth: 30,
												sort: (a, b) => {
													return a.pinned - b.pinned;
												},
												fieldName: "pinned",
												onRenderComponent: (el) => {
													return (
														<IconButton
															iconProps={{
																iconName:
																	el.pinned
																		? "FavoriteStarFill"
																		: "FavoriteStar",
															}}
															title={
																el.pinned
																	? "Unpin from top of list"
																	: "Pin to top of list"
															}
															onClick={
																el.pinned
																	? () => {
																			this.unpinAsset(
																				el
																			);
																	  }
																	: () => {
																			this.pinAsset(
																				el
																			);
																	  }
															}
															ariaLabel="Pin"
														/>
													);
												},
											},
											{
												key: "alias",
												minWidth: 100,
												name: "Asset Name",
												fieldName: "assetAlias",
												export: (row, column) => {
													return row.assetAlias;
												},
												sortable: true,
												isResizable: true,
												sort: (a, b) => {
													return a.assetAlias.localeCompare(
														b.assetAlias
													);
												},
												onRenderComponent: (el) => {
													return (
														<Stack
															horizontal
															verticalAlign="center"
														>
															<p
																title={
																	el.assetAlias ??
																	el.serialNumber
																}
																style={{
																	fontFamily:
																		"Proxima Nova Semibold",
																}}
															>
																{(
																	el.assetAlias ??
																	el.serialNumber
																).toUpperCase()}
															</p>
															<pre
																style={{
																	margin: "0px",
																}}
															>
																{" "}
																|{" "}
															</pre>
															<p
																style={{
																	fontFamily:
																		"Proxima Nova Thin",
																}}
															>
																{el
																	.assetDefinition
																	.alias ??
																	el
																		.assetDefinition
																		.assetDefinitionName}
															</p>
														</Stack>
													);
												},
											},
											{
												key: "dashlink",
												isResizable: true,
												onRenderComponent: (el) => {
													const iconClass =
														mergeStyles({
															// height: 50,
															// width: 50,
															// margin: '0 25px',
														});
													const classNames =
														mergeStyleSets({
															white: [iconClass],
															green: [
																{
																	color: "green",
																},
																iconClass,
															],
															red: [
																{
																	color: "red",
																},
																iconClass,
															],
														});
													return (
														<NavLink
															to={(() => {
																//768 and 1200
																if (
																	this.getAttributeValue(
																		el,
																		"interfaceUrl"
																	)
																) {
																	return this.getAttributeValue(
																		el,
																		"interfaceUrl"
																	);
																} else if (
																	this.state
																		.width >
																		1200 &&
																	this.getAttributeValue(
																		el,
																		"pref-dash-lg"
																	)
																) {
																	return `/assets/${
																		el.assetId
																	}/dashboard/${this.getAttributeValue(
																		el,
																		"pref-dash-lg"
																	)}`;
																} else if (
																	this.state
																		.width >
																		768 &&
																	this.getAttributeValue(
																		el,
																		"pref-dash-sm"
																	)
																) {
																	return `/assets/${
																		el.assetId
																	}/dashboard/${this.getAttributeValue(
																		el,
																		"pref-dash-sm"
																	)}`;
																} else if (
																	this.state
																		.width >
																		1 &&
																	this.getAttributeValue(
																		el,
																		"pref-dash-xxs"
																	)
																) {
																	return `/assets/${
																		el.assetId
																	}/dashboard/${this.getAttributeValue(
																		el,
																		"pref-dash-xxs"
																	)}`;
																} else {
																	return `/assets/${el.assetId}/dashboard`;
																}
															})()}
														>
															<ActionButton
																className={
																	iconClass
																}
																iconProps={{
																	iconName:
																		"ViewDashboard",
																}}
																text="Dashboard"
															></ActionButton>
														</NavLink>
													);
												},
											},
											{
												isResizable: true,
												key: "connected",
												name: "Last connected",
												sort: (a, b) => {
													if (
														this.getConnectionStatus(
															a
														) &&
														!this.getConnectionStatus(
															b
														)
													) {
														return 1;
													} else if (
														!this.getConnectionStatus(
															a
														) &&
														this.getConnectionStatus(
															b
														)
													) {
														return -1;
													} else {
														return (
															this.getLastContact(
																a
															) -
															this.getLastContact(
																b
															)
														);
													}
												},
												export: (row) =>
													this.getLastContact(
														row
													)?.toJSON(),
												onRenderComponent: (el) => {
													const iconClass =
														mergeStyles({
															// height: 50,
															// width: 50,
															// margin: '0 25px',
														});
													const classNames =
														mergeStyleSets({
															white: [iconClass],
															green: [
																{
																	color: "green",
																},
																iconClass,
															],
															red: [
																{
																	color: "red",
																},
																iconClass,
															],
														});
													return (
														<Stack
															verticalAlign="center"
															horizontal
														>
															{this.getConnectionStatus(
																el
															) ? (
																<Icon
																	title={this.getLastContact(
																		el
																	)?.toLocaleString()}
																	className={
																		classNames.green
																	}
																	iconName="PlugConnected"
																/>
															) : (
																<Icon
																	title={this.getLastContact(
																		el
																	)?.toLocaleString()}
																	className={
																		classNames.red
																	}
																	iconName="PlugDisconnected"
																/>
															)}
															{this.props.ui
																.layout ==
																"lg" && (
																<p>
																	{this.getLastContact(
																		el
																	)?.toLocaleString() ??
																		"--"}
																</p>
															)}
														</Stack>
													);
												},
											},
											...attributeColumns.map(
												(attname) => {
													return {
														key: "att_" + attname,
														name: attname,
														onRenderComponent: (
															el
														) => {
															return el.attributes.find(
																(attr) =>
																	attr.attributeName.toLowerCase() ==
																	attname.toLowerCase()
															)?.attributeValue;
														},
														export: (el) => {
															return el.attributes.find(
																(attr) =>
																	attr.attributeName.toLowerCase() ==
																	attname.toLowerCase()
															)?.attributeValue;
														},
														sort: (a1, a2) => {
															return a1.attributes
																.find(
																	(attr) =>
																		attr.attributeName.toLowerCase() ==
																		attname.toLowerCase()
																)
																?.attributeValue.localeCompare(
																	a2.attributes.find(
																		(
																			attr
																		) =>
																			attr.attributeName.toLowerCase() ==
																			attname.toLowerCase()
																	)
																		?.attributeValue
																);
														},
													};
												}
											),
											{
												key: "more",
												minWidth: 30,
												maxWidth: 30,
												isIconOnly: true,
												onRenderComponent: (el) => {
													return el.currentCoordinates
														?.set ? (
														<Stack horizontal>
															<IconButton
																onClick={() => {
																	fetch(
																		`/api/assets/${el.assetId}/coordinates`,
																		{
																			method: "post",
																			headers:
																				{
																					"Content-Type":
																						"application/json",
																				},
																			body: JSON.stringify(
																				{
																					assetId:
																						el.assetId,
																					latitude:
																						this
																							.state
																							.mapLocation
																							.lat,
																					longitude:
																						this
																							.state
																							.mapLocation
																							.lng,
																				}
																			),
																		}
																	).then(
																		() => {
																			this.loadAssets();
																		}
																	);
																}}
																iconProps={{
																	iconName:
																		"Save",
																}}
															></IconButton>
															<IconButton
																onClick={() => {
																	var x = [
																		...this
																			.state
																			.filteredAssets,
																	];
																	x.forEach(
																		(ass) =>
																			delete ass
																				.currentCoordinates
																				.set
																	);
																	this.setState(
																		{
																			filteredAssets:
																				x,
																		}
																	);
																}}
																style={{
																	color: "red",
																}}
																iconProps={{
																	iconName:
																		"Cancel",
																}}
															></IconButton>
														</Stack>
													) : (
														<IconButton
															iconProps={{
																iconName:
																	"MoreVertical",
															}}
															menuProps={{
																shouldFocusOnMount: true,
																items: [
																	{
																		key: "editAsset",
																		text: "Edit",
																		onClick:
																			() => {
																				this.props.showAssetEditPanel(
																					el.assetId
																				);
																			},
																	},
																	{
																		key: "deleteAsset",
																		text: "Delete",
																		onClick:
																			(
																				e
																			) => {
																				if (
																					window.confirm(
																						`This will permanently delete asset ${el.serialNumber}. You may need additional permissions to take this action. Are you sure?`
																					)
																				) {
																					fetch(
																						`/api/assets/${el.assetId}`,
																						{
																							method: "delete",
																						}
																					).then(
																						(
																							res
																						) =>
																							this.loadAssets()
																					);
																				}
																			},
																	},
																	{
																		key: "Fave",
																		text: el.pinned
																			? "Un-favorite"
																			: "Favorite",
																		onClick:
																			el.pinned
																				? () => {
																						this.unpinAsset(
																							el
																						);
																				  }
																				: () => {
																						this.pinAsset(
																							el
																						);
																				  },
																	},
																	{
																		key: "map",
																		text: "Set location",
																		onClick:
																			() => {
																				this.addBlankLocation(
																					el.assetId
																				);
																			},
																	},
																	{
																		key: "closeAlarms",
																		text: "Close all alarms",
																		onClick:
																			() => {
																				fetch(
																					`/api/assets/${el.assetId}/alarms/close`
																				).then(
																					() =>
																						this.loadAssets()
																				);
																			},
																	},
																],
															}}
														></IconButton>
													);
												},
											},
										]}
										items={this.state.filteredAssets}
										showPageSize={false}
										pageSize={this.state.rowCount}
									></PaginatedTable>
								</Stack>
							</Stack.Item>
							{this.state.showMap &&
								this.state.filteredAssets.length > 0 && (
									<Stack.Item grow={2}>
										<AssetMapbox
											onMapMove={(loc) => {
												this.setState({
													mapLocation: loc,
												});
											}}
											alarms={this.state.alarms}
											assets={this.state.filteredAssets}
											onEditAsset={(asset) => {
												this.props.showAssetEditPanel(
													asset.assetId
												);
											}}
										></AssetMapbox>
									</Stack.Item>
								)}
						</Stack>
					</Route>
				</Switch>
			);
		} else if (this.props.assets?.length ?? 0 < 1) {
			return (
				<div
					style={{
						height: "94vh",
						position: "fixed",
						top: "50%",
						left: "50%",
						transform: "translateX(-50%)",
					}}
				>
					{this.props.auth.permissions?.find(
						(el) => el.permissionId == 6
					)
						? "You do not have access to any assets."
						: "You are missing permissions to view assets."}
				</div>
			);
		} else {
			return (
				<>
					<div
						style={{
							height: "94vh",
							position: "fixed",
							top: "50%",
							left: "50%",
							transform: "translateX(-50%)",
						}}
					>
						<Spinner size={SpinnerSize.large} />
					</div>
				</>
			);
		}
	}
}

function AttributeSelector(props) {
	let [selectedAttribute, setSelectedAttribute] = useState(
		props.defaultAttributes
	);
	useEffect(() => {
		setSelectedAttribute(props.defaultAttributes);
	}, [props.defaultAttributes]);
	var values = [
		...new Set(
			props.assets.reduce((acc, ass) => {
				ass.attributes.forEach((att) =>
					acc.push(
						att.attributeName
							.toLowerCase()
							.split(" ")
							.map((s) => s[0]?.toUpperCase() + s.substr(1))
							.join(" ")
					)
				);
				return acc;
			}, [])
		),
	];
	return (
		<Stack horizontal verticalAlign="center">
			<p style={{ marginRight: 8 }}>Extra Columns: </p>
			<Dropdown
				styles={{ dropdown: { width: 200 } }}
				multiSelect
				width="200px"
				selectedKeys={selectedAttribute}
				onChange={(e, item) => {
					if (item) {
						setSelectedAttribute(
							item.selected
								? [...selectedAttribute, item.key]
								: selectedAttribute.filter(
										(key) => key !== item.key
								  )
						);
					}
				}}
				options={values.map((el) => {
					return { key: el, text: el };
				})}
			></Dropdown>
			<IconButton
				iconProps={{ iconName: "Save" }}
				onClick={() => {
					props.onAttributeAdd(selectedAttribute);
				}}
			></IconButton>
		</Stack>
	);
}
function mapStateToProps({ auth, assets, ui }) {
	return {
		auth,
		ui,
		assets,
	};
}

export default compose(
	withRouter,
	connect(mapStateToProps, actions)
)(AssetsPage);
