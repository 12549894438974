import React, { Component } from "react";
import { connect } from "react-redux";
import { format, getAssetIdFromSystem, substitute } from "../../../../util";

class TextBox extends Component {
	static defaultProps = {
		data: undefined, //{}
		decimals: "2",
		fontSize: "14",
	};

	render() {
		const { properties, fontSize } = this.props;
		var { text, substitutions, decimals } = { ...properties };
		var currentDataPaths = {};
		var subObject = { ...this.props };
		for (var sub in substitutions) {
			var assetId = getAssetIdFromSystem(
				null,
				substitutions[sub].slotPath
			);
			if (substitutions[sub]["dataItem"]) {
				currentDataPaths[sub] = [
					"currentData",
					assetId,
					substitutions[sub].dataItem.dataItemId,
					"value",
				];

				// subObject["currentData"][assetId][
				// 	substitutions[sub].dataItem.dataItemId
				// ]["value"] = subObject["currentData"][assetId][
				// 	substitutions[sub].dataItem.dataItemId
				// ]["value"]?.toFixed(decimals);
			}
		}
		text = substitute(text, currentDataPaths);
		subObject.attributes = Object.fromEntries(
			this.props.system.attributes.map((att) => {
				return [att.attributeName, att.attributeValue];
			})
		);
		text = format(text, subObject, decimals);
		return (
			<section
				style={{
					width: "100%",
					height: "100%",
					fontSize: `${fontSize}px`,
					position: "relative",
				}}
			>
				<div
					className="ql-editor"
					dangerouslySetInnerHTML={{ __html: text }}
				></div>
			</section>
		);
	}
}

function mapStateToProps({ system, currentData }) {
	return { system, currentData };
}

export default connect(mapStateToProps)(TextBox);
