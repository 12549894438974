import {
	Checkbox,
	Dropdown,
	Separator,
	Stack,
	TextField,
} from "@fluentui/react";
import * as React from "react";
import DateTimePicker from "../../pages/dashboard/components/forms/DateTimePicker";
import { parseBool } from "../../util";

class JsonForm extends React.Component {
	state = {};
	componentDidMount() {
		this.loadInitialValues(this.props);
	}
	componentWillReceiveProps(nextProps) {
		this.loadInitialValues(nextProps);
	}
	loadInitialValues(nextProps) {
		var firstState = {};
		for (var key in nextProps.values) {
			firstState[key] = nextProps.values[key];
		}
		this.setState(firstState);
	}
	render() {
		return (
			<Stack>
				{this.props.schema.map((el) => {
					return (
						<FormComponent
							{...el}
							key={el.key}
							value={this.props?.values?.[el.key]}
							onChange={(e, val) => {
								var st = { [el.key]: val };

								this.setState(st, () => {
									if (this.props.onFormUpdated) {
										this.props.onFormUpdated(this.state);
									}
								});
							}}
						></FormComponent>
					);
				})}
			</Stack>
		);
	}
}

export default JsonForm;
function FormComponent(props) {
	//Need value, onChange, label
	switch (props.type) {
		case "text":
			return <TextField {...props}></TextField>;
		case "enum":
			return (
				<Dropdown
					{...props}
					selectedKey={props.value}
					onChange={(e, val) => props.onChange(e, val.key)}
				></Dropdown>
			);
		case "boolean":
			return (
				<Stack style={{ marginTop: 8 }} tokens={{ childrenGap: 4 }}>
					<p>{props.label}</p>
					<Checkbox
						{...props}
						label={undefined}
						checked={parseBool(props.value)}
						onChange={(e, val) => {
							props.onChange(e, val);
						}}
					></Checkbox>
				</Stack>
			);
		case "number":
			return <TextField type="number" {...props}></TextField>;
		case "separator":
			return <Separator {...props}></Separator>;
		case "datetime":
			return (
				<DateTimePicker
					{...props}
					datetime={new Date(props.value)}
					onDateChanged={(date) => {
						console.log(date);
						props.onChange(null, date.toJSON());
					}}
					defaultDateTtime={new Date(props.value)}
				></DateTimePicker>
			);
		default:
			return <TextField {...props}></TextField>;
	}
}
