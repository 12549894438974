import {
	ActionButton,
	createTheme,
	getTheme,
	IconButton,
	loadTheme,
	Spinner,
	Panel,
	registerOnThemeChangeCallback,
} from "@fluentui/react";
import { ThemeProvider } from "@fluentui/react-theme-provider";
import { Stack } from "@fluentui/react/lib/Stack";
import { createBrowserHistory } from "history";
import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, NavLink, Route, Switch } from "react-router-dom";
import * as actions from "./actions";
//import MaintenancePage from "./pages/maintenance/maintenance";
import AssetGroupOrganizer from "./components/AssetGroupOrganizer/AssetGroupOrganizer";
import RealtimeMessageClient from "./components/RealtimeMessageClient";
import SQLiteReport from "./components/SQLiteReport/SQLiteReport";
import UserConfiguration from "./components/UserConfiguration";
import AssetEditorPanel from "./pages/assets/assetsEditorPanel";
import AssetsPage from "./pages/assets/assetsPage";
import ConfigurationPage from "./pages/configuration/configuration";
import Dashboard from "./pages/dashboard/components/Dashboard";
import Main from "./pages/dashboard/containers/Main";
import Tile from "./pages/dashboard/containers/Tile";
import JobProvisioner from "./pages/DeepWellJobProvisioner/JobProvisioner";
import ExternalPage from "./pages/iframe/externalPage";
import LoginPage from "./pages/login/LoginPage";
import Ownership from "./pages/ownership/ownership";
import RegisterPage from "./pages/register/register";
import Seismic from "./pages/seismic/Seismic";
import SystemsPage from "./pages/systems/Systems";
import TDMSViewer from "./pages/tdmsviewer/TDMSViewer";
import "./styles/style.scss";
import { getParameterByName } from "./util";

export const history = createBrowserHistory();
class PortalRouter extends React.Component {
	state = {
		links: [
			{
				permissionId: 6,
				link: "/assets",
				iconName: "POI",
				text: "Assets",
				external: "false",
			},
			{
				permissionId: 72,
				link: "/configuration/assetdefinitions",
				iconName: "Settings",
				text: "Configuration",
				external: "false",
			},
			{
				permissionId: 1,
				link: "/admin",
				iconName: "SecurityGroup",
				text: "Admin",
				external: "false",
			},
		],
	};
	toggleTheme() {}
	componentDidMount() {
		this.props.validateLogin();
		this.props.loadAssets();
		this.updateWindowDimensions();
		window.addEventListener("resize", () => this.updateWindowDimensions());

		this.loadNavLinks();

		this.loadTheme();
		registerOnThemeChangeCallback((theme) => {
			this.forceUpdate();
		});
	}
	loadNavLinks() {
		fetch("/config/links.json")
			.catch((e) => {})
			.then((r) => r.json())
			.then((links) => this.setState({ links }));
	}
	componentDidUpdate(lastProps) {
		if (lastProps?.auth?.auth?.authId != this.props?.auth?.auth?.authId) {
			this.props.loadAssets();
		}
	}
	loadTheme() {
		fetch("/config/" + (localStorage.theme ?? "palette-dark") + ".json")
			.then((res) => res.json())
			.then((palette) => {
				var theme = createTheme({ palette });
				loadTheme(theme);
				this.forceUpdate();
			});
	}
	componentWillUnmount() {
		window.removeEventListener("resize", () =>
			this.updateWindowDimensions()
		);
	}

	updateWindowDimensions() {
		if (window.innerWidth > 0 && window.innerWidth < 768) {
			this.props.setLayout("xxs");
		} else if (window.innerWidth >= 768 && window.innerWidth < 1200) {
			this.props.setLayout("sm");
		} else if (window.innerWidth > 1200) {
			this.props.setLayout("lg");
		}
	}

	addToUserLinks() {
		var links = JSON.parse(
			this.props.auth.auth.user.attributes.extraLinks ?? "[]"
		);
		links.push(history.location.pathname + history.location.search);
		fetch(`/api/ef/userattributes`, {
			method: "POST",
			body: JSON.stringify([
				{
					attributeName: "extraLinks",
					userId: this.props.auth.auth.user.userId,
					attributeValue: JSON.stringify(links),
				},
			]),
			headers: { "Content-Type": "application/json" },
		});
		this.props.validateLogin();
	}

	removeFromUserLinks(i) {
		var links = JSON.parse(
			this.props.auth.auth.user.attributes.extraLinks ?? "[]"
		);
		links.splice(i);
		fetch(`/api/ef/userattributes`, {
			method: "POST",
			body: JSON.stringify([
				{
					attributeName: "extraLinks",
					userId: this.props.auth.auth.user.userId,
					attributeValue: JSON.stringify(links),
				},
			]),
			headers: { "Content-Type": "application/json" },
		});
		this.props.validateLogin();
	}
	protectedNavLink(obj, i) {
		return (
			<div key={"link" + i}>
				{obj.external ? (
					<>
						{this.props.auth.permissions?.find(
							(el) =>
								el.permissionId == obj.permissionId ||
								!obj.permissionId
						) && (
							<ActionButton
								key={i}
								onClick={() => {
									this.closePanelNav();
									document.title = obj.text;
									window.location.href = obj.link;
								}}
								iconProps={{ iconName: obj.iconName }}
							>
								{obj.text}
							</ActionButton>
						)}
					</>
				) : (
					<>
						{this.props.auth.permissions?.find(
							(el) =>
								el.permissionId == obj.permissionId ||
								!obj.permissionId
						) && (
							<NavLink key={i} to={obj.link}>
								<ActionButton
									onClick={() => {
										document.title = obj.text;
										this.closePanelNav();
									}}
									iconProps={{ iconName: obj.iconName }}
								>
									{obj.text}
								</ActionButton>
							</NavLink>
						)}
					</>
				)}
			</div>
		);
	}
	desktopNav(horizontal = true) {
		return (
			<Stack horizontal={horizontal} horizontalAlign="space-between">
				{/* Protected Nav Links */}
				<Stack horizontal tokens={{ childrenGap: "s1" }}>
					{this.state.links.map((el, i) =>
						this.protectedNavLink(el, i)
					)}
				</Stack>

				{/* Login Links */}
				{this.props.auth.valid ? (
					<Stack.Item>
						{this.props.auth.permissions?.find(
							(el) => el.permissionId == 12
						) && (
							<NavLink to={"/user-settings"}>
								<ActionButton
									iconProps={{
										iconName: "Personalize",
									}}
								>
									Settings
								</ActionButton>
							</NavLink>
						)}
						<ActionButton
							iconProps={{ iconName: "Signout" }}
							onClick={() => {
								this.props.logout();
							}}
						>
							Log Out
						</ActionButton>
					</Stack.Item>
				) : (
					<Stack.Item>
						<NavLink to={"/register"}>
							<ActionButton
								iconProps={{
									iconName: "Personalize",
								}}
							>
								Register
							</ActionButton>
						</NavLink>
						<NavLink to={"/login"}>
							<ActionButton iconProps={{ iconName: "SignIn" }}>
								Log in
							</ActionButton>
						</NavLink>
					</Stack.Item>
				)}
			</Stack>
		);
	}
	closePanelNav() {
		this.setState({ panelNavOpen: false });
	}
	mobileNav() {
		const menuProps = {
			shouldFocusOnMount: true,
			items: [
				...this.state.links.map((el, i) => {
					return {
						onRender: () => this.protectedNavLink(el, i),
					};
				}),
			],
		};
		return (
			<div
				style={{
					position: "relative",
					float: "right",
					marginBottom: "4px",
				}}
			>
				{/* <IconButton
					iconProps={{ iconName: "GlobalNavButton" }}
					menuProps={menuProps}
				></IconButton> */}
				<IconButton
					iconProps={{ iconName: "GlobalNavButton" }}
					onClick={() => this.setState({ panelNavOpen: true })}
				></IconButton>
				<Panel
					isLightDismiss={true}
					isOpen={this.state.panelNavOpen}
					onDismiss={() => {
						this.closePanelNav();
					}}
				>
					<Stack>
						{this.state.links.map((el, i) =>
							this.protectedNavLink(el, i)
						)}
						{this.props.auth.valid ? (
							<>
								{this.props.auth.permissions?.find(
									(el) => el.permissionId == 12
								) && (
									<NavLink to={"/user-settings"}>
										<ActionButton
											iconProps={{
												iconName: "Personalize",
											}}
											onClick={() => {
												this.closePanelNav();
											}}
										>
											Settings
										</ActionButton>
									</NavLink>
								)}
								<ActionButton
									iconProps={{ iconName: "Signout" }}
									onClick={() => {
										this.closePanelNav();
										this.props.logout();
									}}
								>
									Log Out
								</ActionButton>
							</>
						) : (
							<>
								<NavLink to={"/register"}>
									<ActionButton
										iconProps={{
											iconName: "Personalize",
										}}
										onClick={() => {
											this.closePanelNav();
										}}
									>
										Register
									</ActionButton>
								</NavLink>
								<NavLink to={"/login"}>
									<ActionButton
										onClick={() => {
											this.closePanelNav();
										}}
										iconProps={{ iconName: "SignIn" }}
									>
										Log in
									</ActionButton>
								</NavLink>
							</>
						)}
					</Stack>
				</Panel>
			</div>
		);
	}
	render() {
		var currentTheme = getTheme();
		if (this.props.auth.valid == null) {
			return <Spinner></Spinner>;
		}

		return (
			<ThemeProvider applyTo="body">
				<BrowserRouter>
					<RealtimeMessageClient></RealtimeMessageClient>
					<AssetEditorPanel></AssetEditorPanel>
					<div
						key={this.props.auth.auth?.authId}
						style={{ backgroundColor: currentTheme.palette.white }}
					>
						{!getParameterByName("hideNav") && (
							<div
								style={{
									boxShadow:
										"rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px",
									backgroundColor:
										currentTheme.palette.neutralLight,
								}}
							>
								{this.props.ui.navBar &&
								this.props.ui.layout != "xxs"
									? this.desktopNav()
									: this.mobileNav()}
								{/* Modals and Panels */}
							</div>
						)}
						<Switch>
							{/* Protected Routes */}
							{this.props.auth.permissions?.find(
								(el) => el.permissionId == 6
							) && (
								<Route
									path="/systems/:systemId?/:page?"
									render={(props) => {
										return (
											<SystemsPage
												{...props}
											></SystemsPage>
										);
									}}
								></Route>
							)}
							{this.props.auth.permissions?.find(
								(el) => el.permissionId == 72
							) && (
								<Route
									path="/configuration/:subpage?/:id?/:panelName?"
									render={(props) => {
										return (
											<ConfigurationPage
												{...props}
											></ConfigurationPage>
										);
									}}
								></Route>
							)}
							{this.props.auth.permissions?.find(
								(el) => el.permissionId == 1
							) && (
								<Route path="/admin">
									<Ownership />
								</Route>
							)}
							{this.props.auth.permissions?.find(
								(el) => el.permissionId == 1
							) && (
								<Route path="/tdms-viewer">
									<TDMSViewer />
								</Route>
							)}
							{this.props.auth.permissions?.find(
								(el) => el.permissionId == 1
							) && (
								<Route path="/assetgroups">
									<AssetGroupOrganizer />
								</Route>
							)}

							{/* Unprotected Routes */}
							{this.props.auth.valid && (
								<Route
									path="/ifr/*"
									render={(props) => {
										return (
											<ExternalPage
												style={{
													width: "100%",
													height: "90vh",
												}}
												{...props}
											/>
										);
									}}
								></Route>
							)}
							<Route
								path="/dwsjob"
								render={(props) => {
									return <JobProvisioner></JobProvisioner>;
								}}
							></Route>
							<Route
								path="/sqlite"
								render={(props) => {
									return <SQLiteReport></SQLiteReport>;
								}}
							></Route>
							{/* <Route path="/maintenance">
								<MaintenancePage></MaintenancePage>
							</Route> */}
							<Route path="/login">
								<LoginPage />
							</Route>
							<Route path="/seismic">
								<Seismic />
							</Route>
							<Route
								path="/user-settings"
								render={(props) => {
									return (
										<UserConfiguration
											key={this.props.auth.auth?.authId}
											showExtraLinks={true}
											user={this.props.auth?.auth?.user}
										/>
									);
								}}
							></Route>
							<Route path="/register">
								<RegisterPage />
							</Route>
							<Route path="*">
								{this.props.auth.valid == true ? (
									<AssetsPage />
								) : (
									<LoginPage />
								)}
							</Route>
						</Switch>
					</div>
				</BrowserRouter>
			</ThemeProvider>
		);
	}
}

function mapStateToProps({ assets, auth, ui, tiles }) {
	return {
		assets,
		ui,
		tiles,
		auth,
	};
}

export default connect(mapStateToProps, actions)(PortalRouter);

function Home() {
	return (
		<div>
			<h2>Home</h2>
		</div>
	);
}

function About() {
	return (
		<div>
			<h2>About</h2>
		</div>
	);
}
