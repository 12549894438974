import React, { createRef } from "react";
import RadialGauge from "./lib/RadialGauge.js";
class CanvasGauge extends React.Component {
	resizeObserver = null;
	container = createRef();
	el = createRef();

	componentDidMount() {
		const options = Object.assign({}, this.props, {
			renderTo: this.el.current,
		});
		this.gauge = new RadialGauge(options);
		try {
			this.gauge.draw();
		} catch (e) {}
		this.observer = new ResizeObserver(() => {
			this.resizeGauge();
		}).observe(this.container.current);
	}

	componentWillReceiveProps(nextProps) {
		for (var x in nextProps) {
			this.gauge[x] = nextProps[x];
		}
		if (nextProps.settingsGuid != this.props.settingsGuid) {
			try {
				this.gauge.update({ ...nextProps });
			} catch (e) {}
		}
	}
	componentDidUpdate() {
		if (this.props.value)
			try {
				this.gauge.update({ value: this.props.value });
			} catch (e) {}
	}
	componentWillUnmount() {
		if (this.observer) {
			this.observer.disconnect();
		}
	}
	resizeGauge() {
		window.devicePixelRatio = 1.4;
		//CSS pixels for coordinate systems
		// var ctx = this.el.getContext('2d')
		// ctx.scale(scale, scale);
		if (this.el.current != null) {
			this.el.current.style.width = "100%";
			this.el.current.style.height = "100%";
			if (
				this.el.current.offsetHeight > 50 &&
				this.el.current.offsetWidth > 50
			) {
				try {
					this.gauge.update({
						height: this.el.current.offsetHeight,
						width: this.el.current.offsetWidth,
					});
				} catch (ex) {}
			}
		}
	}
	render() {
		return (
			<div style={{ width: "100%", height: "100%" }} ref={this.container}>
				<canvas
					style={{ width: "100%", height: "100%" }}
					ref={this.el}
				/>
			</div>
		);
	}
}

export default CanvasGauge;
