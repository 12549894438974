import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Stack } from "@fluentui/react";

export default class StateChart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			controller: new AbortController(),
		};
		this.graphRef = React.createRef();
	}
	buildDepthProjectionSeries() {
		var startTime = new Date(new Date());
	}
	componentDidMount() {
		this.chart = am4core.create(this.graphRef?.current, am4charts.XYChart);
		var chart = this.chart;
		let title = chart.titles.create();
		title.text = this.props.title;
		chart.legend = new am4charts.Legend();
		chart.legend.fontSize = 12;
		chart.backgroundColor = "#00000000";
		chart.legend.position = "bottom";
		chart.legend.stroke = "white";
		if (this.props.colorMap) {
			chart.colors.list = Object.values(this.props.colorMap);
		}

		// Create axes
		let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
		dateAxis.dateFormat = "yyyy/MM/dd";
		dateAxis.tooltipDateFormat = "MM/dd/YYYY HH:mm:ss";
		dateAxis.baseInterval = { timeUnit: "second", count: 1 };
		dateAxis.renderer.minGridDistance = 100;
		dateAxis.renderer.stroke = "white";
		dateAxis.renderer.grid.template.location = 0.5;
		dateAxis.startLocation = 0.5;
		dateAxis.min = this.props.startTime;
		dateAxis.max = this.props.endTime;
		dateAxis.fontSize = 12;
		dateAxis.strictMinMax = true;
		this.dateAxis = dateAxis;
		var activityValueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		activityValueAxis.min = 0;
		activityValueAxis.max = 2;
		activityValueAxis.renderer.visible = false;
		activityValueAxis.renderer.opposite = true;
		activityValueAxis.renderer.grid.template.disabled = true;
		activityValueAxis.strictMinMax = true;
		activityValueAxis.cursorTooltipEnabled = false;

		// Create series
		var colorSeries = chart.series.push(new am4charts.LineSeries());
		colorSeries.xAxis = dateAxis;
		colorSeries.name = "Activity";
		colorSeries.yAxis = activityValueAxis;
		colorSeries.dataFields.valueY = "timeline";
		colorSeries.dataFields.dateX = "timestamp";
		colorSeries.fill = am4core.color("#96BBBB");
		colorSeries.stroke = am4core.color("#00000000");

		colorSeries.fillOpacity = 0.5;
		colorSeries.propertyFields.fill = "color";
		colorSeries.propertyFields.stroke = "color";
		colorSeries.tooltipText = "{dateX.formatDate('HH:mm')} - {activity}";
		colorSeries.tooltip.pointerOrientation = "vertical";
		chart.cursor = new am4charts.XYCursor();
		//chart.cursor.behavior = "zoomX";
		//chart.cursor.xAxis = dateAxis;
		chart.cursor.clickable = true;

		for (var i = 0; i < this.props.dataItems.length; i++) {
			var el = this.props.dataItems[i];
			console.log(el);
			var field = "value";
			var name = el.label;
			var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
			valueAxis.min = el.min;
			valueAxis.max = el.max;
			valueAxis.cursorTooltipEnabled = false;
			valueAxis.renderer.grid.template.disabled = true;
			valueAxis.renderer.opposite = i % 2 !== 0;
			valueAxis.renderer.stroke = "white";
			valueAxis.title.text = `${el.label} (${el.unit})`;
			valueAxis.title.stroke = "white";
			valueAxis.fontSize = 12;
			valueAxis.renderer.inversed = el.inversed ?? false;
			// Create series
			var series = chart.series.push(new am4charts.LineSeries());
			series.xAxis = dateAxis;
			series.yAxis = valueAxis;
			series.name = el.label;
			series.stroke = am4core.color("white");
			series.strokeWidth = 2;
			series.fillOpacity = 0.0;
			series.dataFields.valueY = field;
			series.tooltipText = "{name}: [bold]{valueY}[/]";
			series.dataFields.dateX = "timestamp";

			if (el.projection) {
				var seriesP = chart.series.push(new am4charts.LineSeries());
				seriesP.xAxis = dateAxis;
				seriesP.yAxis = valueAxis;
				seriesP.name = `Projected ${el.label}`;
				seriesP.stroke = am4core.color("white");
				seriesP.strokeWidth = 2;
				seriesP.fillOpacity = 0.0;
				seriesP.strokeDasharray = "3,3";
				seriesP.tooltipText = "{name}: [bold]{valueY}[/]";
				seriesP.dataFields.valueY = `Projected ${el.label}`;
				seriesP.dataFields.dateX = "timestamp";
			}
		}
		this.fetchData();
	}

	fillColorFromTimestamp(timestamp) {
		var item = this.props.data.find(
			(el) =>
				new Date(el.startTime) <= timestamp &&
				new Date(el.endTime) > timestamp
		);
		if (item) {
			return this.props.colorMap[item.activity];
		} else {
			return null;
		}
	}
	activityFromTimestamp(timestamp) {
		var item = this.props.data.find(
			(el) =>
				new Date(el.startTime) <= timestamp &&
				new Date(el.endTime) > timestamp
		);
		if (item) {
			return item.activity;
		} else {
			return null;
		}
	}

	fetchData() {
		var dataArr = this.props.data.sort((a, b) => {
			return new Date(a.startTime) - new Date(b.startTime);
		});
		var start = new Date(dataArr[0].startTime);
		var end = new Date(dataArr[dataArr.length - 1].endTime);
		var rawData = [
			this.props.data.map(
				(el) => new Date(el.startTime).getTime() / 1000
			),
			this.props.data.map((el) => el.depth),
		];
		//this.setState({ rawData }, this.cleanupData);

		fetch(
			`/api/historicaldata?columnar=true&threshold=400&dataItems=${this.props.dataItems
				.map((di) => `${di.assetId}-${di.dataItemId}`)
				.join(",")}&startTime=${start.toJSON()}&endTime=${end.toJSON()}`
		)
			.then((re) => re.json())
			.then((rawData) => {
				this.setState({ rawData }, this.cleanupData);
			});
	}

	cleanupData() {
		var data = this.state.rawData;
		var data3 = data[0].map((el, i, arr) => {
			return {
				timestamp: new Date(1000 * el),
				value: data[1][i],
				timeline: 2,
				color: this.fillColorFromTimestamp(new Date(1000 * el)),
				activity: this.activityFromTimestamp(new Date(1000 * el)),
			};
		});

		var startFillData = [...this.props.data].map((el) => {
			const t = new Date(new Date(el.startTime).getTime() + 1);
			return {
				timestamp: t,
				timeline: 2,
				color: this.fillColorFromTimestamp(t),
				activity: this.activityFromTimestamp(t),
			};
		});
		var endFillData = this.props.data.map((el) => {
			const t = new Date(new Date(el.endTime).getTime() - 1);
			return {
				timestamp: t,
				timeline: 2,
				color: this.fillColorFromTimestamp(t),
				activity: this.activityFromTimestamp(t),
			};
		});
		var timeLineData = startFillData
			.concat(endFillData)
			.concat(data3)
			.concat(this.props.projection ?? []);
		for (var i = 0; i < this.props.dataItems.length; i++) {
			if (this.props.dataItems[i].projection) {
				var projectionOffset = 0;
				if (this.state.projectionStart) {
					projectionOffset =
						this.state.projectionStart.getTime() -
						this.props.dataItems[
							i
						].projection[0].timestamp.getTime();
				}

				timeLineData = timeLineData.concat(
					this.props.dataItems[i].projection.map((el) => {
						el["timestamp"] = new Date(
							el.timestamp.getTime() + projectionOffset
						);
						el[`Projected ${this.props.dataItems[i].label}`] =
							el.value;
						delete el.value;
						return el;
					})
				);
			}
		}
		timeLineData.sort((a, b) => a.timestamp - b.timestamp);
		//var allData = [...data3,...timeLineData].sort((a,b)=>a.timestamp = b.timestamp)
		this.chart.data = timeLineData;
		this.chart.validateData();
		this.chart.invalidateLabels();
	}
	componentWillUnmount() {
		if (this.chart) {
			this.chart.dispose();
		}
	}
	render() {
		return (
			<Stack>
				{/* <Stack horizontal>
              {this.props.data.filter((el,i)=>i==0 || el.activity == "Rig Over").map(el=><button onClick={()=>{
                console.log(el.startTime)
                this.setState({projectionStart:new Date(el.startTime)},this.cleanupData)
                this.dateAxis.zoomToDates(new Date(el.startTime),new Date())
              }}>{el.activity} {el.startTime}</button>)}
            </Stack> */}
				<div
					ref={this.graphRef}
					style={{ width: "100%", height: "400px" }}
				></div>
			</Stack>
		);
	}
}
