import {
	DetailsList,
	Stack,
	TextField,
	Slider,
	getTheme,
} from "@fluentui/react";
import React from "react";
import { useEffect } from "react";

export default function PaginatedDetailsList(props) {
	let theme = getTheme();
	let [pageNum, setPageNum] = React.useState(0);
	let [pageSize, setPageSize] = React.useState(props.pageSize ?? 10);

	let [columns, setColumns] = React.useState(props.columns ?? []);
	let [items, setItems] = React.useState(props.items ?? []);
	useEffect(() => setItems(props.items), [props.items]);
	useEffect(() => setPageSize(props.pageSize), [props.pageSize]);
	useEffect(() => {
		if (items[0] && !props.columns) {
			setColumns(
				Object.keys(items[0]).map((c) => {
					return { key: c, name: c };
				})
			);
		}
	}, [props.columns, items[0]]);
	let maxPageNum = Math.floor(props.items.length / pageSize);

	let visibleItems = items.slice(
		pageNum * pageSize,
		(pageNum + 1) * pageSize
	);
	let pageNavStyle = { fontSize: "1.8rem", margin: 10 };
	let pageLinks = [];
	for (var i = 0; i < maxPageNum; i++) {
		pageLinks.push(i);
	}

	let _sortColumn = (ev, column) => {
		const newColumns = columns.slice();
		const currColumn = newColumns.filter(
			(currCol) => column.key === currCol.key
		)[0];
		let isSortedDescending = false;
		newColumns.forEach((newCol) => {
			if (newCol === currColumn) {
				isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});
		let newItems = items.sort(column.sort);
		if (isSortedDescending) {
			newItems.reverse();
		}
		setColumns(newColumns);
		setItems(newItems);
	};

	let modifiedColumns = columns.slice().map((c) => {
		if (c.sort) {
			c.onColumnClick = _sortColumn;
		}
		return c;
	});

	return (
		<Stack style={{ overflowX: "scroll" }} verticalAlign="space-between">
			<Stack horizontal horizontalAlign="space-between">
				{props.title && <h2>{props.title}</h2>}
				{(props.showPageSize ?? true) && (
					<TextField
						label="Page length: "
						underlined
						mask
						type="number"
						value={pageSize}
						onChange={(e, val) => setPageSize(parseInt(val))}
					></TextField>
				)}
			</Stack>
			<DetailsList
				{...props}
				items={visibleItems}
				columns={modifiedColumns}
			></DetailsList>
			<Slider
				max={maxPageNum}
				minimum={0}
				value={pageNum}
				valueFormat={(v) => {
					return v + 1;
				}}
				showValue
				// eslint-disable-next-line react/jsx-no-bind
				onChange={(v) => setPageNum(v)}
			/>
			<Stack
				horizontal
				verticalAlign={"center"}
				horizontalAlign={"space-between"}
			>
				<a
					style={{
						margin: 2,
						color: theme.palette.themePrimary,
					}}
					onClick={() => {
						setPageNum(pageNum - 1);
					}}
				>
					PREVIOUS
				</a>
				{pageLinks.map((el, i, arr) => {
					if (
						arr.length < 10 ||
						i == 0 ||
						i == arr.length - 1 ||
						(i > pageNum - 5 && i < pageNum + 5)
					) {
						return (
							<a
								key={el}
								style={{
									margin: 2,
									fontSize: el == pageNum ? "2rem" : "1rem",
									color:
										el == pageNum
											? theme.palette.black
											: theme.palette.themePrimary,
									fontWeight:
										el == pageNum ? "Bold" : "Regular",
								}}
								onClick={() => {
									setPageNum(el);
								}}
							>
								{el + 1}
							</a>
						);
					} else if (i == pageNum - 5 || i == pageNum + 5) {
						return (
							<a
								key={i}
								style={{
									margin: 5,
									color: theme.palette.themePrimary,
								}}
							>
								...
							</a>
						);
					}
				})}
				<a
					style={{
						margin: 5,
						color: theme.palette.themePrimary,
					}}
					onClick={() => {
						setPageNum(pageNum + 1);
					}}
				>
					NEXT
				</a>
			</Stack>
		</Stack>
	);
}
