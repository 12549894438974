import React, { useRef,useEffect } from "react";
import { useState } from "react";
import { createRef } from "react";
import {UnControlled as CodeMirror} from 'react-codemirror2'
import { guid } from "../../util";


export default function DanfoPlot(props){
    const ref = useRef(null);
    let [err,setErr] = useState(null)
    useEffect(()=>{
        try{
            let dfd=window.dfd
            if(props.type == "bar"){
            props.df.plot(ref.current.id).hist()
            }
            else if(props.type == "pie"){
                // var data =this.props.df.col_data.map((c,i)=>[this.props.df.columns[i],new dfd.Series(c).max()])
                // var x = new window.dfd.DataFrame(data,{columns:["label","data"]})
                props.df.head(30).plot(ref.current.id).pie({labels:props.df.columns[0],values:props.df.columns[1]})
                //this.props.df.plot(this.ref.current.id).pie({values:"data",labels:"label"})
            }
        }
        catch(e){
            setErr(e)
        }
    })
    return <div id={guid()} ref={ref}>{err?.toString()}</div>
}