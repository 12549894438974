import React, { Component } from "react";
import DateTimePicker from "./DateTimePicker";
import { _set, _put, getParameterByName } from "../../../../util";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

import { InputSwitch } from "primereact/inputswitch";

String.prototype.escapeJSONKey = function () {
	return this.replace(/\\n/g, "\\n")
		.replace(/\\"/g, '\\"')
		.replace(/\\r/g, "\\r")
		.replace(/\\t/g, "\\t")
		.replace(/\\b/g, "\\b")
		.replace(/\\f/g, "\\f");
};

class EventForm extends Component {
	constructor(props) {
		super(props);
		this.getEventDefinitions();
	}
	state = {
		edited: false,
		initCause: this.props.event?.cause,
		useFieldEditor: this.props.event?.metaExpanded != null ?? false,
		event: this.props.event,
	};
	componentDidMount() {
		this.setState({ event: this.props.event });
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		const path = target.path;
		console.log(name);
		console.log(value);
		console.log(path);
		// this.setState({
		//   [name]: value
		// });
	}
	async applyUpdate() {
		await fetch("/api/events", {
			method: "PUT",
			body: JSON.stringify([this.state.event]),
			headers: { "content-type": "application/json" },
		});
		this.props.onEventUpdated(this.state.event);
	}
	async getEventDefinitions() {
		fetch(
			"/api/events/definitions?assetDefinitionId=" +
				getParameterByName("assetDefinitionId")
		)
			.then((response) => response.json())
			.then((data) => {
				this.setState({
					eventDefinitions: data.filter((el) => el.category == 1),
				});
			});
	}
	setStatePath(path, value) {
		var newState = { ...this.state };
		newState.edited = true;
		_put(newState, path, value);
		if (path[1] == "meta") {
			try {
				newState.event.metaExpanded = JSON.parse(value);
			} catch {}
		} else {
			newState.event.meta = JSON.stringify(newState.event.metaExpanded);
		}

		newState.event.eventDefinitionId =
			newState.event.eventDefinition.eventDefinitionId;
		this.setState(newState);
	}
	getItemsFromData(data, path) {
		let items = [];
		if (!path) {
			path = [];
		}
		for (var x in data) {
			if (data.hasOwnProperty(x)) {
				let extPath = [...path];
				extPath.push(x.escapeJSONKey());

				if (Array.isArray(data)) {
					//Parent was array - lets see if there is a string label we can use
					let label = x;
					if (!Array.isArray(data[x])) {
						//If this element is also an array, then we have a nested array and should probably use indeces
						for (var y in data[x]) {
							//Check keys one at  atime
							if (
								typeof data[x][y] == "string" &&
								y != "timestamp"
							) {
								//Looks like this object has a string. Lets use that and move on
								label = data[x][y];
								break;
							} else if (typeof data[x][y] == "number") {
								//We found an integer. Hopefully its a unique ID. We can use that if we find no string.
								label = data[x][y];
							}
						}
					}
					var newItem = (
						<tr>
							<td>{extPath.join(".")}</td>
							<td>
								<input
									onChange={(event) => {
										this.setStatePath(
											extPath,
											event.target.value
										);
									}}
									path={extPath}
									defaultValue={data[x]}
								></input>
							</td>
						</tr>
					);
					if (typeof data[x] == "object") {
						items.push(this.getItemsFromData(data[x], extPath));
					}
					items.push(newItem);
				} else {
					if (typeof data[x] == "object") {
						items.push(this.getItemsFromData(data[x], extPath));
					} else {
						items.push(
							<tr key={extPath.join(",")}>
								<td>{extPath.join(".")}</td>
								<td>
									<input
										onChange={(event) => {
											this.setStatePath(
												[
													"event",
													"metaExpanded",
												].concat(extPath),
												event.target.value
											);
										}}
										path={extPath}
										defaultValue={data[x]}
									></input>
								</td>
							</tr>
						);
					}
				}
			}
		}
		//console.log(items)
		return items;
	}
	renderMeta() {
		var editorSwitch = (
			<div>
				<label style={{ verticalAlign: "middle" }}>Text</label>
				<InputSwitch
					label="Use Field Editor"
					checked={this.state.useFieldEditor ?? false}
					onChange={(e) => this.setState({ useFieldEditor: e.value })}
				></InputSwitch>
				<label style={{ verticalAlign: "middle" }}>JSON</label>
			</div>
		);
		var fieldEditor = (
			<table>
				<thead>
					<tr>
						<th>Path</th>
						<th>Value</th>
					</tr>
				</thead>
				<tbody>
					{this.getItemsFromData(this.state.event?.metaExpanded)}
				</tbody>
			</table>
		);
		var textEditor = (
			<div>
				<textarea
					style={{ width: "100%" }}
					onChange={(event) => {
						this.setStatePath(
							["event", "meta"],
							event.target.value
						);
					}}
					defaultValue={this.state.event.meta}
				></textarea>
			</div>
		);
		if (this.state.event.metaExpanded) {
			return (
				<div>
					{editorSwitch}
					{this.state.useFieldEditor ? fieldEditor : textEditor}
				</div>
			);
		} else {
			return <div>{textEditor}</div>;
		}
	}
	render() {
		if (this.state.event) {
			return (
				<div>
					<p>{"Event ID: " + this.props.event?.eventId}</p>
					<h3>Choose Maintenance Type</h3>
					<Dropdown
						style={{ width: "100%" }}
						optionLabel={"alias"}
						value={this.state.event.eventDefinition}
						onChange={(e) =>
							this.setStatePath(
								["event", "eventDefinition"],
								e.value
							)
						}
						options={this.state.eventDefinitions}
						placeholder="Select an Event"
						filter={true}
						filterBy="alias"
						required={true}
					/>
					<h3>Choose a Status</h3>
					<Dropdown
						style={{ width: "100%" }}
						optionLabel={"label"}
						optionValue={"value"}
						value={this.state.event.status}
						onChange={(e) =>
							this.setStatePath(["event", "status"], e.value)
						}
						options={[
							{ label: "Requested", value: 1 },
							{ label: "In Progress", value: 2 },
							{ label: "Completed", value: 3 },
							{ label: "Cancelled", value: 0 },
						]}
						placeholder="Status"
						required={true}
					/>
					<h3>Time Maintenance was done</h3>
					<DateTimePicker
						onDateChanged={(e) => {
							this.setStatePath(
								["event", "timestamp"],
								e.toJSON()
							);
						}}
						datetime={new Date(this.state.event?.timestamp)}
					/>
					<h3>Comments</h3>
					<ul style={{ maxHeight: 130, overflow: "auto" }}>
						{this.state.initCause.split("\n").map((el, i) => {
							return <li key={i}>{el}</li>;
						})}
					</ul>
					<textarea
						style={{ width: "100%" }}
						onChange={(event) => {
							this.setStatePath(
								["event", "cause"],
								event.target.value
							);
						}}
						defaultValue={""}
					></textarea>
					<h3>Edit Metadata</h3>
					{this.renderMeta()}
					{this.state.edited ? (
						<Button
							onClick={() => {
								this.applyUpdate();
							}}
							label="Apply Changes"
						></Button>
					) : null}
				</div>
			);
		} else {
			return <p>None Selected</p>;
		}
	}
}

export default EventForm;
