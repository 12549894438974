import React, { createRef } from "react";
import { Panel, Stack } from "@fluentui/react";
import * as flot from "flot";

import "flot/lib/jquery.event.drag";
import "flot/lib/jquery.mousewheel";
import "flot/source/jquery.flot.resize";
import "flot/source/jquery.flot.navigate";
import "flot/source/jquery.flot.time";
import "flot/source/jquery.flot.axislabels";
import "flot/source/jquery.flot.crosshair";
import XYChartForm from "../../pages/dashboard/components/forms/XYChartForm";
import { guid } from "../../util";

const $ = window.$;
class DataframeFlot extends React.Component {
	id = guid();
	state = { data: [] };
	ref = createRef();

	componentDidMount() {
		this.setState({ flot: this.props.flot }, () => this.drawFlot());
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
	}
	transformData(data) {
		var newData = this.state.flot.series.map((el) => {
			var x = { ...el };
			x["data"] = data
				.filter((dt) => {
					return [dt[el.xvalue] && dt[el.yvalue]];
				})
				.map((dt) => {
					return [dt[el.xvalue], dt[el.yvalue]];
				});
			return x;
		});
		var oldData = this.plot.getData();
		if (oldData.length > 0) {
			oldData.forEach((el, i) => {
				el.data = el.data.concat(newData[i].data);
			});
		} else {
			oldData = newData;
		}
		this.plot.setData(oldData);
		this.plot.draw();
		if (!this.first) {
			this.first = true;
			this.plot.zoom();
		}
		$(`#${this.id}-resizable text`).attr(
			"stroke",
			this.props?.properties?.flot?.fontColor ?? "#fff"
		);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return JSON.stringify(this.props) !== JSON.stringify(nextProps);
	}
	drawFlot() {
		if (this.state.flot) {
			var placeholder = $(`#${this.id}-placeholder`);
			var settings = { ...this.state.flot };
			var blankData = settings.series?.map((el) => {
				var x = { ...el };
				x["data"] = [];
				return x;
			});
			settings.yaxes.forEach((element) => {
				if (element.invert) {
					element.transform = function (v) {
						return -v;
					};
					element.inverseTransform = function (v) {
						return -v;
					};
				}
				if (element.mode == "time") {
				}
			});
			settings.xaxes.forEach((element) => {
				if (element.invert) {
					element.transform = function (v) {
						return -v;
					};
					element.inverseTransform = function (v) {
						return -v;
					};
				}
				if (element.mode == "time") {
				}
			});
			delete settings.series;
			settings.hooks = {
				draw: [
					() => {
						$(`#${this.id}-resizable text`).attr(
							"stroke",
							settings.fontColor ?? "#fff"
						);
					},
				],
			};
			this.plot = $.plot(placeholder, blankData, settings);
			this.plot.draw();
			placeholder.bind("plotpan", () => {
				$(`#${this.id}-resizable text`).attr(
					"stroke",
					settings.fontColor ?? "#fff"
				);
			});

			placeholder.bind("plotzoom", () => {
				$(`#${this.id}-resizable text`).attr(
					"stroke",
					settings.fontColor ?? "#fff"
				);
			});
			$(`#${this.id}-resizable text`).attr(
				"stroke",
				settings.fontColor ?? "#fff"
			);

			placeholder.bind("plothover", (event, pos, item) => {
				if (!this.updateCrosshairIndicator && item) {
					this.updateCrosshairIndicator = setTimeout(() => {
						var crosshair = {
							label: item.series.label,
							x:
								item.series.xaxis.options.mode == "time"
									? new Date(
											item.datapoint[0]
									  ).toLocaleString()
									: item.datapoint[0].toFixed(2),
							y:
								item.series.yaxis.options.mode == "time"
									? new Date(
											item.datapoint[1]
									  ).toLocaleString()
									: item.datapoint[1].toFixed(2),
						};
						//console.log(this.setState)
						this.setState({ crosshair }, () => {
							this.forceUpdate();
							this.updateCrosshairIndicator = null;
						});
					}, 50);
				}
			});
			this.transformData(this.props.df.data);
		}
	}
	componentWillUnmount() {
		console.log("Flot unmounted");
		if (this.observer) {
			this.observer.disconnect();
		}
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		if (this.plot) {
			this.plot.shutdown();
		}
	}
	render() {
		var flot = { ...this.state.flot };
		return (
			<>
				{flot && (
					<div
						id={`${this.id}-resizable`}
						style={{
							display: "flex",
							flexDirection: "column",
							height: "400px",
							width: "100%",
						}}
					>
						{flot.title && (
							<div
								style={{
									textAlign: "center",
									color: flot.fontColor,
								}}
							>
								{flot.title}
							</div>
						)}
						<div
							style={{ flexGrow: 1 }}
							ref={this.ref}
							id={`${this.id}-placeholder`}
						></div>
						{flot.crosshair && (
							<div
								style={{ textAlign: "center" }}
							>{`${this.state.crosshair?.label} = ${this.state.crosshair?.x}, ${this.state.crosshair?.y}`}</div>
						)}
					</div>
				)}
				<Panel isOpen={true}>
					<XYChartForm
						addData={(flot) => {
							this.setState({ flot });
						}}
					></XYChartForm>
				</Panel>
			</>
		);
		// if(this.props.properties){
		//     return (
		//     <ReactFlot id="product-chart" options={this.props.properties} data={[0,1,2,3]} width="50%" height="100px" />
		//     );
		// }

		// else{
		//     return <div>No config</div>
		// }
	}
}
export default DataframeFlot;
