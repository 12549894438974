import {
	Checkbox,
	IconButton,
	Selection,
	Stack,
	Text,
	TextField,
} from "@fluentui/react";
import React from "react";
// Warn if overriding existing method
export default class MultiSelect extends React.Component {
	state = {
		selectedOptions: [],
	};
	selection = new Selection({
		onSelectionChanged: () => console.log(this.selection.getSelection()),
	});
	formatString(item) {
		var definition = this.props.definition;
		var str = "";
		for (var x in item.slotPath) {
			definition = definition.slots?.find(
				(el) => el.slotId == item.slotPath[x]
			);
			if (!definition) break;
			str += definition.name;
			str += " > ";
		}
		str += item?.dataItem?.label;
		return str;
	}
	closeDialogListener = (event) => {
		var specifiedElement = document.getElementById("kennandropdown");
		if (specifiedElement == null) {
			return;
		}
		var isClickInside = specifiedElement.contains(event.target);

		if (!isClickInside) {
			this.setState({ showDropdown: false });
			document.removeEventListener("click", this.closeDialogListener);
		}
	};

	handleChange(selectedOptions) {
		this.setState({ selectedOptions }, () => {
			if (this.props.onChange) {
				this.props.onChange(selectedOptions);
			}
		});
	}
	render() {
		return (
			<Stack>
				{this.props.label && (
					<label className="ms-Label">{this.props.label}</label>
				)}
				<Stack
					horizontal
					horizontalAlign="space-between"
					verticalAlign="center"
					style={{ border: "1px solid rgb(208, 208, 208)" }}
				>
					<Text style={{ marginLeft: "5px" }}>
						{" "}
						{this.props.selectedOptions.length} Selected
					</Text>
					<IconButton
						iconProps={{ iconName: "ChevronDown" }}
						onClick={() => {
							document.addEventListener(
								"click",
								this.closeDialogListener
							);
							this.setState({ showDropdown: true });
						}}
						subMenuHoverDelay={0}
						subMenuProps={{ subMenuHoverDelay: 0 }}
						subMenuHoverDelay={0}
						subMenuProps={{ subMenuHoverDelay: 0 }}
					/>
				</Stack>
				<div
					id="kennandropdown"
					style={{
						display: this.state.showDropdown ? "block" : "none",
					}}
				>
					<Stack
						tokens={{ childrenGap: 3 }}
						style={{
							zIndex: 5,
							backgroundColor: "#252525",
							position: "absolute",
							padding: "14px",
						}}
					>
						<TextField
							placeholder="Search..."
							iconProps={{ iconName: "search" }}
							value={this.state.filter}
							onChange={(e, val) => {
								this.setState({ filter: val });
							}}
						></TextField>
						<Stack horizontal horizontalAlign="space-between">
							<Text
								onClick={() =>
									this.handleChange([...this.props.options])
								}
							>
								Check all
							</Text>
							<Text onClick={() => this.handleChange([])}>
								Clear all
							</Text>
						</Stack>
						{this.props.options?.map((el) => {
							return (
								<Stack
									style={{
										display: el.text
											?.toLowerCase()
											.includes(
												this.state.filter?.toLowerCase() ??
													""
											)
											? "initial"
											: "none",
										margin: "2px",
									}}
									horizontal
								>
									<Checkbox
										checked={
											this.props.selectedOptions.find(
												(sel) => sel.key == el.key
											) ?? false
										}
										onChange={(e, val) => {
											var selected = [
												...this.props.selectedOptions.filter(
													(sel) =>
														!(sel.key == el.key)
												),
											];
											if (val) {
												selected.push(el);
											}
											this.handleChange(selected);
										}}
										label={el.text}
									></Checkbox>
								</Stack>
							);
						})}
					</Stack>
				</div>
			</Stack>
		);
	}
}
