import { Dropdown, Separator, TextField } from "office-ui-fabric-react";

export default function ActionDispatcherForm(props) {
	let tileProps = props.properties;
	return (
		<div>
			<Dropdown
				label="Action"
				options={DispatchOptions}
				onChange={(e, val) => props.addData({ action: val.key })}
				selectedKey={tileProps?.action}
			></Dropdown>
			<TextField
				label="Action Text"
				value={tileProps?.actionText}
				onChange={(e, val) => props.addData({ actionText: val })}
			></TextField>
			<Separator horizontal></Separator>
			<Dropdown
				label="Reverse Action"
				options={DispatchOptions}
				onChange={(e, val) => props.addData({ reverseAction: val.key })}
				selectedKey={tileProps?.reverseAction}
			></Dropdown>
			<TextField
				label="Reverse Text"
				value={tileProps?.reverseText}
				onChange={(e, val) => props.addData({ reverseText: val })}
			></TextField>
		</div>
	);
}
const DispatchOptions = [
	{ key: "NONE", text: "No Action" },
	{ key: "TOGGLE_EXPORT", text: "Show Export Dialog" },
	{ key: "SET_VIEWONLY", text: "View Only Mode" },
	{ key: "UNSET_VIEWONLY", text: "Edit Dashboard Mode" },
	{ key: "SHOW_ASSET_PANEL", text: "Asset Editor Panel" },
	{ key: "TOGGLE_PLAYBACK_BAR", text: "Show Playback Bar" },
	{ key: "SHOW_NAV", text: "Show Navigation Bar" },
	{ key: "HIDE_NAV", text: "Hide Navigation Bar" },
	{ key: "SHOW_FILE_PANEL", text: "Show File Panel" },
	{ key: "HIDE_FILE_PANEL", text: "Hide File Panel" },
];
