import React, { useState } from "react";
import {
	IconButton,
	Callout,
	Link,
	getTheme,
	FontWeights,
	mergeStyleSets,
	Text,
	ColorPicker,
	Stack,
} from "@fluentui/react";
import { useBoolean, useId } from "@uifabric/react-hooks";

const theme = getTheme();
const styles = mergeStyleSets({
	buttonArea: {},
	callout: {
		maxWidth: 300,
		border: "1px solid white",
	},
	header: {
		padding: "18px 24px 12px",
	},
	title: [
		theme.fonts.xLarge,
		{
			margin: 0,
			fontWeight: FontWeights.semilight,
		},
	],
	inner: {
		height: "100%",
		padding: "0 24px 20px",
	},
	actions: {
		position: "relative",
		marginTop: 20,
		width: "100%",
		whiteSpace: "nowrap",
	},
	subtext: [
		theme.fonts.small,
		{
			margin: 0,
			fontWeight: FontWeights.semilight,
		},
	],
	link: [
		theme.fonts.medium,
		{
			color: theme.palette.neutralPrimary,
		},
	],
});
export const PopoutColorPicker = (props) => {
	const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
		useBoolean(false);

	const labelId = useId("callout-label");
	const descriptionId = useId("callout-description");
	return (
		<>
			<div className={styles.buttonArea}>
				<Stack>
					{props.label && (
						<label
							style={{
								fontFamily: `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`,
								height: 29,
								fontWeight: 600,
								fontSize: 14,
								padding: "5px 0px 5px",
							}}
						>
							{props.label}
						</label>
					)}
					<IconButton
						style={{ backgroundColor: props.color, color: "white" }}
						iconProps={{ iconName: "Color" }}
						onClick={toggleIsCalloutVisible}
					/>
				</Stack>
			</div>
			{isCalloutVisible && (
				<Callout
					className={styles.callout}
					ariaLabelledBy={labelId}
					ariaDescribedBy={descriptionId}
					role="alertdialog"
					gapSpace={0}
					target={`.${styles.buttonArea}`}
					onDismiss={toggleIsCalloutVisible}
					setInitialFocus
				>
					<ColorPicker
						color={props.color}
						onChange={(ev, color) => {
							if (props.onChange) props.onChange(ev, color);
						}}
					></ColorPicker>
				</Callout>
			)}
		</>
	);
};
export default PopoutColorPicker;
