import { Separator, Stack } from "office-ui-fabric-react";
import { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { loadAssets, setAssets } from "../../actions";
import PaginatedTable from "../../components/PaginatedTable/PaginatedTable";
import { dateFormat } from "../../util";
import AssetMapbox from "../assets/assetMapbox";

export default function Seismic(props) {
	const state = useSelector((state) => state);
	const assets = useSelector((state) => state.assets);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(loadAssets());
	}, []);
	const [smsEvents, setSmsEvents] = useState([]);
	const [quakeEvents, setQuakeEvents] = useState([]);
	useEffect(() => {
		if (!assets) return [];
		fetch("/api/ef/alarms?alarmDefinitionId=2")
			.then((r) => r.text())
			.then((j) => {
				var events = JSON.parse(j).map((al) => {
					var element = {
						ID: assets.find((ass) => ass.assetId == al.assetId)
							?.assetAlias,
						Timestamp: new Date(al.raised + "Z"),
						latitude: assets.find(
							(ass) => ass.assetId == al.assetId
						)?.currentCoordinates.latitude,
						longitude: assets.find(
							(ass) => ass.assetId == al.assetId
						)?.currentCoordinates.longitude,
						Magnitude: "TBD",
						Depth: "TBD",
					};
					return element;
				});

				setSmsEvents(events);
			});
	}, [assets]);

	useEffect(async () => {
		var txEvents = await fetch(
			"https://quakelink.raspberryshake.org/events/query?limit=200&minmag=2&sort=-time&region=Western+Texas",
			{}
		)
			.then((res) => res.text())
			.then((text) => {
				var events = text
					.split("\r\n")
					.map((line) => line.split(";"))
					.filter((el) => el.length > 5)
					.map((el) => {
						var element = {
							ID: el[0],
							Timestamp: new Date(el[2] + "Z"),
							latitude: el[5],
							longitude: el[6],
							Location: "(" + el[5] + "," + el[6] + ")",
							Magnitude: parseFloat(el[3]),
							Depth: parseFloat(el[7]),
							Region: el[12],
						};
						return element;
					});
				return events;
			});
		var okevents = await fetch(
			"https://quakelink.raspberryshake.org/events/query?limit=200&minmag=2&sort=-time&region=Oklahoma",
			{}
		)
			.then((res) => res.text())
			.then((text) => {
				var events = text
					.split("\r\n")
					.map((line) => line.split(";"))
					.filter((el) => el.length > 5)
					.map((el) => {
						var element = {
							ID: el[0],
							Timestamp: new Date(el[2] + "Z"),
							latitude: el[5],
							longitude: el[6],
							Location: "(" + el[5] + "," + el[6] + ")",
							Magnitude: parseFloat(el[3]),
							Depth: parseFloat(el[7]),
							Region: el[12],
						};
						return element;
					});
				return events;
			});
		var nmEvents = await fetch(
			"https://quakelink.raspberryshake.org/events/query?limit=200&minmag=2&sort=-time&region=New Mexico",
			{}
		)
			.then((res) => res.text())
			.then((text) => {
				var events = text
					.split("\r\n")
					.map((line) => line.split(";"))
					.filter((el) => el.length > 5)
					.map((el) => {
						var element = {
							ID: el[0],
							Timestamp: new Date(el[2] + "Z"),
							latitude: el[5],
							longitude: el[6],
							Location: "(" + el[5] + "," + el[6] + ")",
							Magnitude: parseFloat(el[3]),
							Depth: parseFloat(el[7]),
							Region: el[12],
						};
						return element;
					});
				return events;
			});
		var events = txEvents.concat(okevents).concat(nmEvents);
		events.sort((e1, e2) => e2.Timestamp - e1.Timestamp);
		setQuakeEvents(events);
	}, []);
	function queryForMultipleAssets() {
		document.getElementById("stationCode").innerHTML =
			event.ID +
			"- Magnitude " +
			event.Magnitude +
			" at (" +
			parseFloat(event.latitude).toFixed(5) +
			"," +
			parseFloat(event.longitude).toFixed(5) +
			") at " +
			event.Depth +
			"km";
		plots.forEach((p) => p.plot.destroy());
		removeElementsByClass("seis-uplot-container");
		plots = [];
		links = [];
		assets = assets.filter((ass) => ass.currentCoordinates != null);
		assets = assets.map((ass) => {
			ass.datatested = false;
			ass.distance = calcCrow(
				ass.currentCoordinates.latitude,
				ass.currentCoordinates.longitude,
				event.latitude,
				event.longitude
			);
			ass.bearing = calcBearing(
				ass.currentCoordinates.latitude,
				ass.currentCoordinates.longitude,
				event.latitude,
				event.longitude
			);
			return ass;
		});

		assets.sort((ass1, ass2) => {
			return ass1.distance - ass2.distance;
		});
		console.log(assets);
		for (
			var i = 0;
			i < document.getElementById("stationCount").value;
			i++
		) {
			queryData(assets[i]);
		}
	}
	let loadEvent = (eventId) => {
		el.style.order = parseInt(asset.distance * 10);
		var stationCode = asset.attributes.find(
			(att) => att.attributeName == "Station"
		).attributeValue;

		let desc = document.createElement("div");
		let station = document.createElement("div");
		station.innerHTML = asset.assetAlias;
		let distance = document.createElement("div");
		distance.innerHTML =
			asset.distance.toFixed(1) +
			" km away @ " +
			asset.bearing.toFixed(1) +
			"\u00B0";
		desc.className = "seis-desc";
		desc.append(station);
		desc.append(distance);
		el.append(desc);

		var controls = document.createElement("div");
		controls.className = "plot-controls";

		var expand = document.createElement("span");
		expand.className = "material-icons plot-button expand";
		expand.innerHTML = "expand";
		expand.onclick = () => {
			el.style["flex-grow"] = el.style["flex-grow"] == "1" ? "5" : "1";
			expand.innerHTML =
				el.style["flex-grow"] == "1" ? "expand" : "minimize";
			resizeGraphs();
		};
		controls.append(expand);

		var close = document.createElement("span");
		close.className = "material-icons plot-button close";
		close.innerHTML = "cancel";
		close.onclick = () => {
			el.style["display"] = "none";
			el.style["flex-grow"] = 0;
			resizeGraphs();
		};
		controls.append(close);

		el.append(controls);

		let uplotEl = document.createElement("div");
		uplotEl.className = "seis-uplot";
		el.append(uplotEl);
		uplotEl.style = "width:100%;height:100%;";

		let mooSync = uPlot.sync("moo");
		let synced = true;
		let color = ["#f00", "#0f0", "#00f"];
		let allData = [];
		var scalingFactor = document.getElementById("scalingFactor").value;
		let avgWindow = tryParseParameter("avgWindow", 1);
		const opts = {
			legend: { show: false },

			//title:asset.assetAlias + " - " + asset.distance.toFixed(1) +"mi" ,
			width: el.offsetWidth,
			height: el.offsetHeight,
			scales: {
				x: {
					time: true,
				},
				y: { auto: true },
				stalta: { auto: true },
			},
			axes: [
				{
					stroke: "white",
					grid: { show: true, stroke: "#aaaaaa22", width: 1 },
				},
				{
					scale: "y",
					position: 3,
					stroke: "white",
					label: "E,N,Z Counts",
				},
				{
					scale: "stalta",
					label: "STA/LTA",
					side: 1,
					stroke: "white",
					grid: { show: true, stroke: "#aaaaaa22", width: 1 },
				},
			],

			series: [
				{},
				{
					value: (self, rawValue) => rawValue.toFixed(5) + " counts",
					stroke: "#f00a",
					label: "EHE",
					scale: "y",
					show: showHide[1],
				},
				{
					value: (self, rawValue) => rawValue.toFixed(5) + " counts",
					stroke: "#0f0a",
					label: "EHN",
					scale: "y",
					show: showHide[2],
				},
				{
					value: (self, rawValue) => rawValue.toFixed(5) + " counts",
					stroke: "#00fa",
					label: "EHZ",
					scale: "y",
					show: showHide[3],
				},
				{
					scale: "stalta",
					stroke: "#fffa",
					label: "STA/LTA",
					show: showHide[4],
				},
				{
					scale: "stalta",
					stroke: "#fffa",
					dash: [5, 5],
					label: "STA/LTA Threshold",
					show: showHide[4],
				},
			],
			plugins: [touchZoomPlugin()],
			cursor: {
				sync: {
					key: mooSync.key,
				},
				drag: { x: true, y: false },
			},
		};

		var xData = [[], [], []];
		var yData = [[], [], []];
		for (var n = 0; n < seisArray.length; n++) {
			for (var i = 0; i < seisArray[n]._segmentArray.length; i++) {
				var x =
					seisArray[n]._segmentArray[i].startTime._d.getTime() / 1000;
				const yvalues = seisArray[n]._segmentArray[i].y;
				//let mean = arrMean(yvalues);
				let mean = 0;
				for (var j = 0; j < yvalues.length; j++) {
					xData[n].push(x);

					yData[n].push(yvalues[j]);
					x = x + 1 / seisArray[n]._segmentArray[i].sampleRate;
				}
			}
		}

		//Find the first values
		var firstVals = [
			{ i: 0, t: xData[0][0] },
			{ i: 1, t: xData[1][0] },
			{ i: 2, t: xData[2][0] },
		];
		//Find which array has the greatest first value
		var indexOfGreatestFirstVal = firstVals.sort((a, b) => b.t - a.t)[0].i;
		//Find the greatest first value, which is the latest starting timestamp
		var greatestFirstValue = xData[indexOfGreatestFirstVal][0];
		// console.log(`the first x values are ${xData[0][0]},${xData[1][0]}, and ${xData[2][0]}. The greatest one of these is ${greatestFirstValue}`)
		//Cut each y data array before that time stamp
		for (var i = 0; i < 3; i++) {
			var firstIndex = xData[i].findIndex((t) => t >= greatestFirstValue);
			// console.log(`cutting x(${i}) values at index ${firstIndex}. The value at this index is ${xData[i][firstIndex]} `)
			xData[i] = xData[i].slice(firstIndex);
			yData[i] = yData[i].slice(firstIndex);
		}

		ModifiedData = [xData, yData];
		data = [xData[indexOfGreatestFirstVal], yData[0], yData[1], yData[2]];

		var pga = parseFloat(document.getElementById("pgaThreshold").value);
		var pgaarr = data[0].map((d, i, arr) => {
			var rms = Math.pow(
				Math.pow(data[1][i], 2) +
					Math.pow(data[2][i], 2) +
					Math.pow(data[3][i], 2),
				0.5
			);
			return isNaN(rms) ? null : rms;
		});
		var sta = smooth(pgaarr, 100);
		var lta = smooth(pgaarr, 3000);
		data[4] = data[0].map((el, i, arr) => sta[i] / lta[i]);
		data[5] = data[0].map((el, i, arr) => pga);
		// data[4] = []
		// data[5] = []
		clearTimeout(window["resizeTimeout"]);
		window["resizeTimeout"] = setTimeout(resizeGraphs, 400);
		return new uPlot(opts, data, uplotEl);
	};
	const columns = [
		{
			fieldName: "ID",
			cellStyle: { whiteSpace: "nowrap" },
			name: "ID",
			cellStyle: { whiteSpace: "nowrap", fontSize: 10 },
			sort: true,
			fieldName: "ID",
		},
		{
			field: "Region",
			name: "Region",
			cellStyle: { whiteSpace: "nowrap", fontSize: 10 },
			sort: true,
			fieldName: "Region",
		},
		{
			fieldName: "Timestamp",
			name: "Time",
			cellStyle: { whiteSpace: "nowrap", fontSize: 10 },
			onRenderContent: (item) => {
				return dateFormat(item.Timestamp, "%Y-%m-%d %H:%M:%S", false);
			},
			sort: (i1, i2) => i1.Timestamp - i2.Timestamp,
		},
		{
			fieldName: "Magnitude",
			name: "Mag",
			cellStyle: { whiteSpace: "nowrap", fontSize: 10 },
			sort: (i1, i2) => i1.Magnitude - i2.Magnitude,
		},
		{
			fieldName: "Depth",
			name: "Depth",
			cellStyle: { whiteSpace: "nowrap", fontSize: 10 },
			sort: (i1, i2) => i1.Depth - i2.Depth,
		},
	];
	return (
		<Stack horizontal>
			<Stack>
				<Separator></Separator>
				<PaginatedTable
					title="Locally Detected Events"
					showSlider={false}
					showPageSize={false}
					columns={columns}
					items={smsEvents}
					onRowClick={(e, item, i) => console.log(item)}
				></PaginatedTable>
				<Separator></Separator>
				<PaginatedTable
					title="Seismic Events"
					showSlider={false}
					showPageSize={false}
					columns={columns}
					items={quakeEvents}
					onRowClick={(e, item, i) => console.log(item)}
				></PaginatedTable>
			</Stack>
			{assets && <AssetMapbox alarms={[]} assets={assets}></AssetMapbox>}
		</Stack>
	);
}
