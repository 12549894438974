import {
	ContextualMenu,
	Icon,
	Panel,
	PrimaryButton,
	Stack,
	TextField,
} from "@fluentui/react";
import React, { useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions/index";
import AssetAlarmOverrides from "../../configuration/assets/AssetAlarmOverrides";
const NavMenu = (props) => {
	const [editAsset, setEditAsset] = useState(false);
	const [editAlarms, setEditAlarms] = useState(false);
	const [customTileForm, setCustomTileForm] = useState(false);
	const [customTileName, setCustomTileName] = useState("");
	function handleSave() {
		const { tiles, layouts, tabs } = props;
		props
			.saveDashboard(tiles, layouts, tabs, props.ui.dashboard)
			.then(() => {
				props.setViewOnly();
			});
	}
	function handleSaveAs() {
		const { tiles, layouts, tabs, ui } = props;
		props
			.saveDashboardAs(tiles, layouts, tabs, ui.dashboard.dashboardName)
			.then(() => {
				props.setViewOnly();
			});
	}
	const linkRef = React.useRef(null);
	const [showContextualMenu, setShowContextualMenu] = React.useState(false);
	const onShowContextualMenu = React.useCallback((ev) => {
		ev.preventDefault(); // don't navigate
		setShowContextualMenu(true);
	}, []);
	const onHideContextualMenu = React.useCallback(
		() => setShowContextualMenu(false),
		[]
	);
	var menuItems = [
		{
			key: "file",
			text: "File",
			subMenuHoverDelay: 1000,
			subMenuProps: {
				items: [
					{
						key: "savedash",
						title: "Save Changes",
						text: "Save Changes",
						onClick: handleSave,
					},
					{
						key: "savedashas",
						title: "Save As",
						text: "Save As",
						onClick: handleSaveAs,
					},
					{
						key: "Discard Changes",
						text: props.ui.viewOnly
							? "Reload dashboard"
							: "Discard Changes",
						title: "Discard Changes",
						onClick: () => {
							props.applyDashboard(props.ui.dashboard);
							props.setViewOnly();
						},
					},
					{
						key: "loadDash",
						title: "Load Dashboard",
						text: "Load Dashboard",
						subMenuProps: {
							items: props.ui.dashboards.map((el) => {
								return {
									key: `dashboard${el.dashboardId}`,
									text: `${el.dashboardName} (${el.dashboardId})`,
									onClick: () => {
										props.applyDashboard(el);
									},
								};
							}),
						},
					},
					{
						key: "deleteDash",
						title: "Delete Dashboard",
						text: "Delete Dashboard",
						subMenuProps: {
							items: props.ui.dashboards.map((el) => {
								return {
									key: `dashboard${el.dashboardId}`,
									text: `${el.dashboardName} (${el.dashboardId})`,
									onClick: () => {
										if (
											window.confirm(
												"Are you sure you want to delete dashboard " +
													el.dashboardName +
													"?"
											)
										) {
											props.deleteDashboard(
												el.dashboardId
											);
										}
									},
								};
							}),
						},
					},
					{
						key: "defaultDash",
						title: "Set Default Dashboard",
						text: "Set Default Dashboard",
						subMenuProps: {
							items: [
								{
									key: "xxsDefault",
									title: "Extra Small/Mobile",
									text: "Extra Small/Mobile",
									subMenuProps: {
										items: props.ui.dashboards.map((el) => {
											return {
												key: `dashboard${el.dashboardId}`,
												text: `${el.dashboardName} (${el.dashboardId})`,
												onClick: () => {
													props.createAttribute({
														attributeName:
															"pref-dash-xxs",
														attributeValue:
															el.dashboardId,
													});
												},
											};
										}),
									},
								},
								{
									key: "smDefault",
									title: "Small/Tablet",
									text: "Small/Tablet",
									subMenuProps: {
										items: props.ui.dashboards.map((el) => {
											return {
												key: `dashboard${el.dashboardId}`,
												text: `${el.dashboardName} (${el.dashboardId})`,
												onClick: () => {
													props.createAttribute({
														attributeName:
															"pref-dash-sm",
														attributeValue:
															el.dashboardId,
													});
												},
											};
										}),
									},
								},
								{
									key: "lgDefault",
									title: "Large/PC",
									text: "Large/PC",
									subMenuProps: {
										items: props.ui.dashboards.map((el) => {
											return {
												key: `dashboard${el.dashboardId}`,
												text: `${el.dashboardName} (${el.dashboardId})`,
												onClick: () => {
													props.createAttribute({
														attributeName:
															"pref-dash-lg",
														attributeValue:
															el.dashboardId,
													});
												},
											};
										}),
									},
								},
							],
						},
					},
				],
			},
		},
		{
			key: "edit",
			text: "Edit",
			subMenuHoverDelay: 0,
			subMenuProps: {
				subMenuHoverDelay: 0,
				items: [
					{
						key: "addTile",
						text: "Add tile",
						title: "Add a new tile",
						subMenuHoverDelay: 0,
						subMenuProps: {
							subMenuHoverDelay: 0,
							items: [
								{
									key: "addGraphs",
									text: "Graphs",
									title: "Graphs",
									subMenuHoverDelay: 0,
									subMenuProps: {
										items: [
											//{key:"Basic",title: 'Basic Graph',text: 'Basic Graph', onClick: () => props.addTile(TileOptions.basicgraph,props.activeTab)},
											{
												key: "Advanced",
												title: "Advanced Graph",
												text: "Advanced Graph",
												onClick: () =>
													props.addTile(
														TileOptions.advancedgraph,
														props.activeTab
													),
											},
											// {key:"Fast",title: 'Fast Graph',text: 'Fast Graph', onClick: () => props.addTile(TileOptions.fastgraph,props.activeTab)},
											//{key:"Bar",title:'Bar Graph',text: 'Bar Graph', onClick: () => props.addTile(TileOptions.bargraph,props.activeTab)},
											// {key:"Box",title:'Box Chart',text: 'Box Chart', onClick: () => props.addTile(TileOptions.meanboxchart,props.activeTab)},
											// {key:"Pie",title:'Pie Chart',text: 'Pie Chart', onClick: () => props.addTile(TileOptions.pieChart,props.activeTab)},
											// {key:"Heat",title:'Heat Map',text: 'Heat Map', onClick: () => props.addTile(TileOptions.hourlyheatmap,props.activeTab)},
											//{key:"Editor",title:'Editor Chart',text: 'Editor Chart', onClick: () => props.addTile(TileOptions.editChart,props.activeTab)}
											{
												key: "XYChart",
												title: "XY Chart",
												text: "XY Chart",
												onClick: () =>
													props.addTile(
														TileOptions.XYChart,
														props.activeTab
													),
											},
											//{title: 'Dynamic Graph', onClick: () => addTileFromMenu(TileOptions.dynamicgraph)}
										],
									},
								},
								{
									key: "addIndicators",
									title: "Indicators",
									text: "Indicators",
									subMenuHoverDelay: 0,
									subMenuProps: {
										subMenuHoverDelay: 0,
										items: [
											{
												key: "dial",
												title: "Dial Gauge",
												text: "Dial Gauge",
												onClick: () =>
													props.addTile(
														TileOptions.dialgauge,
														props.activeTab
													),
											},
											{
												key: "cyl",
												title: "Cylinder Gauge",
												text: "Cylinder Gauge",
												onClick: () =>
													props.addTile(
														TileOptions.cylindergauge,
														props.activeTab
													),
											},
											{
												key: "textind",
												title: "Text Indicator",
												text: "Text Indicator",
												onClick: () =>
													props.addTile(
														TileOptions.indicator,
														props.activeTab
													),
											},
											// {
											// 	key: "alarm",
											// 	text: "Alarm Indictor",
											// 	onClick: () =>
											// 		props.addTile(
											// 			TileOptions.alarmtextbox,
											// 			props.activeTab
											// 		),
											// },
										],
									},
								},
								{
									title: "Misc.",
									text: "Misc",
									key: "misc",
									subMenuHoverDelay: 0,
									subMenuProps: {
										subMenuHoverDelay: 0,
										items: [
											{
												key: "iframe",
												text: "Iframe",
												onClick: () =>
													props.addTile(
														TileOptions.iframe,
														props.activeTab
													),
											},
											{
												key: "map",
												text: "Google Map",
												onClick: () =>
													props.addTile(
														TileOptions.googlemap,
														props.activeTab
													),
											},
											{
												key: "text",
												text: "Text/Image",
												onClick: () =>
													props.addTile(
														TileOptions.textbox,
														props.activeTab
													),
											},
											{
												key: "table",
												text: "Basic Table",
												onClick: () =>
													props.addTile(
														TileOptions.basictable,
														props.activeTab
													),
											},
											{
												key: "ctable",
												text: "Custom Table",
												onClick: () =>
													props.addTile(
														TileOptions.customtable,
														props.activeTab
													),
											},
											// {
											// 	key: "ctable",
											// 	text: "Pump Maintenance",
											// 	onClick: () =>
											// 		props.addTile(
											// 			TileOptions.rendering,
											// 			props.activeTab
											// 		),
											// },
											//{text:'Patient Clipboard', onClick: () => props.addTile(TileOptions.patientChart,props.activeTab)},
											//{text:'Alarm Expressions', onClick: () => props.addTile(TileOptions.fleePanel,props.activeTab)}
											//{title:'wsGauge', onClick: () => addTileFromMenu(TileOptions.wsGauge)}
										],
									},
								},
								{
									text: "Add by name",
									key: "addByName",
									onClick: () => {
										setCustomTileForm(true);
									},
								},
							],
						},
					},
					props.ui.viewOnly
						? {
								key: "editLayouts",
								text: "Edit Mode",
								title: "Edit layouts",
								onClick: props.unsetViewOnly,
						  }
						: {
								key: "viewOnly",
								text: "View Only Mode",
								title: "Edit layouts",
								onClick: props.setViewOnly,
						  },
				],
			},
		},

		{
			text: "Tools",
			title: "Tools",
			key: "tools",
			subMenuHoverDelay: 0,
			subMenuProps: {
				items: [
					{
						text: "Set Up Alarms",
						key: "Set Up Alarms",
						title: "Set Up Alarms",
						onClick: () => {
							setEditAlarms(true);
						},
					},
					{
						text: "Attributes",
						key: "Attributes",
						title: "Attributes",
						onClick: () => {
							props.showAssetEditPanel(props.system.assetId);
						},
					},
					{
						text: "Export Data",
						key: "export",
						title: "Export Data",
						onClick: () => {
							props.toggleExport();
						},
					},
					{
						text: "Toggle full screen button",
						key: "fsbutton",
						title: "Toggle FS Button",
						onClick: () => {
							window.localStorage.showFullscreenButton = !(
								window.localStorage.showFullscreenButton ==
								"true"
							);
						},
					},
					// {
					// 	text: "Maintenance",
					// 	key: "Maintenance",
					// 	title: "Maintenance",
					// 	onClick: () => {
					// 		props.toggleMaintenance();
					// 	},
					// },
					{
						text: "Playback",
						key: "Playback",
						title: "Playback",

						onClick: () => {
							props.togglePlaybackBar();
						},
					},
					{
						text: "Files",
						key: "Files",
						title: "Files",

						onClick: () => {
							props.showFilePanel();
						},
					},
				],
			},
		},
	];
	return (
		<span
			key={"settings"}
			ref={linkRef}
			style={{ cursor: "pointer" }}
			onClick={onShowContextualMenu}
			style={{
				textAlign: "center",
				verticalAlign: "middle",
				cursor: "pointer",
				minWidth: "5rem",
			}}
		>
			<Icon iconName="More" href="#"></Icon>
			<ContextualMenu
				items={menuItems}
				hidden={!showContextualMenu}
				target={linkRef}
				subMenuHoverDelay={300}
				subMenuProps={{ subMenuHoverDelay: 0 }}
				onItemClick={onHideContextualMenu}
				onDismiss={onHideContextualMenu}
			/>
			<Panel
				isOpen={customTileForm}
				onDismiss={() => {
					setCustomTileForm(false);
				}}
			>
				<Stack tokens={{ childrenGap: 15 }}>
					<TextField
						defaultValue=""
						placeholder="Tile name"
						value={customTileName}
						onChange={(e, val) => {
							setCustomTileName(val);
						}}
					></TextField>
					<PrimaryButton
						text="Add tile"
						onClick={() => {
							props.addTile(
								{
									type: {
										name: "Manually added tile",
										value: customTileName,
										default: false,
									},
								},
								props.activeTab
							);
							setCustomTileForm(false);
						}}
					></PrimaryButton>
				</Stack>
			</Panel>
			<AssetAlarmOverrides
				isOpen={editAlarms}
				onDismiss={() => {
					setEditAlarms(false);
				}}
				asset={props.system}
			></AssetAlarmOverrides>
		</span>
	);
};

function mapStateToProps({ ui, activeTab, tiles, layouts, tabs, system }) {
	return { ui, activeTab, tiles, layouts, tabs, system };
}

export default connect(mapStateToProps, actions)(NavMenu);

const TileOptions = {
	basicgraph: {
		type: { name: "Basic Graph", value: "BasicGraph", default: true },
	},
	advancedgraph: {
		type: { name: "Advanced Graph", value: "AdvancedGraph", default: true },
	},
	fastgraph: {
		type: { name: "Fast Graph", value: "FastGraph", default: true },
	},
	bargraph: { type: { name: "Bar Graph", value: "BarGraph", default: true } },
	meanboxchart: {
		type: { name: "Mean Box Chart", value: "MeanBoxChart", default: false },
	},
	patientChart: {
		type: { name: "Patient Chart", value: "PatientChart", default: false },
	},
	editChart: {
		type: { name: "Editor Chart", value: "EditChart", default: false },
	},
	XYChart: {
		type: { name: "XY Chart", value: "XYChart", default: false },
	},
	fleePanel: {
		type: { name: "Alarm Expressions", value: "FleePanel", default: false },
	},
	pieChart: {
		type: { name: "Pie Chart", value: "PieChart", default: false },
	},
	hourlyheatmap: {
		type: {
			name: "Hourly Heat Map",
			value: "HourlyHeatMap",
			default: false,
		},
	},
	indicator: {
		type: { name: "Indicator", value: "Indicator", default: true },
	},
	dialgauge: { type: { name: "Dial Gauge", value: "Gauge", default: true } },
	cylindergauge: {
		type: { name: "Cylinder Gauge", value: "CylinderGauge", default: true },
	},
	textbox: { type: { name: "Text Box", value: "TextBox", default: true } },
	alarmtextbox: {
		type: { name: "Alarm Indicator", value: "AlarmTextBox", default: true },
	},
	basictable: {
		type: { name: "Basic Table", value: "Table", default: true },
	},
	customtable: {
		type: { name: "Custom Table", value: "CustomTable", default: true },
	},
	googlemap: { type: { name: "Google Map", value: "Map", default: true } },
	iframe: { type: { name: "iFrame", value: "IframeTile", default: true } },
	dynamicgraph: {
		type: { name: "Dynamic Graph", value: "DynamicGraph", default: true },
	},
	rendering: {
		type: { name: "Rendering", value: "Rendering", default: true },
	},
	//wsGauge: {type: {name: 'wsGauge', value: 'WSGauge', default: true}}
};

// var editMenu = {
//     label:"Edit",
//     items:[
//       {
//         label:'Add Tile',
//         items:[
//           {
//              label:'Graphs',
//              items: [
//                {label: 'Basic Graph', command: () => actions.addTileFromMenu(TileOptions.basicgraph)},
//                {label: 'Advanced Graph', command: () => actions.addTileFromMenu(TileOptions.advancedgraph)},
//                {label:'Bar Graph', command: () => actions.addTileFromMenu(TileOptions.bargraph)},
//                {label:'Box Chart', command: () => actions.addTileFromMenu(TileOptions.meanboxchart)},
//                {label:'Pie Chart', command: () => actions.addTileFromMenu(TileOptions.pieChart)},
//                {label:'Heat Map', command: () => actions.addTileFromMenu(TileOptions.hourlyheatmap)},
//                {label:'Editor Chart', command: () => actions.addTileFromMenu(TileOptions.editChart)}
//                //{label: 'Dynamic Graph', command: () => addTileFromMenu(TileOptions.dynamicgraph)}
//              ],
//           },
//           {
//              label:'Gauges',
//              items: [
//               {label:'Dial Gauge', command: () => actions.addTileFromMenu(TileOptions.dialgauge)},
//               {label: 'Cylinder Gauge', command: () => actions.addTileFromMenu(TileOptions.cylindergauge)},
//               {label:'Indicator', command: () => actions.addTileFromMenu(TileOptions.indicator)}]
//           },
//           {
//              label:'Misc.',
//              items: [
//                {label:'Iframe', command: () => actions.addTileFromMenu(TileOptions.iframe)},
//                {label:'Google Map', command: () => actions.addTileFromMenu(TileOptions.googlemap)},
//                {label: 'Text/Image', command: () => actions.addTileFromMenu(TileOptions.textbox)},
//                {label: 'Basic Table', command: () => actions.addTileFromMenu(TileOptions.basictable)},
//                {label:'Custom Table', command: () => actions.addTileFromMenu(TileOptions.customtable)},
//                {label:'Patient Clipboard', command: () => actions.addTileFromMenu(TileOptions.patientChart)},
//                {label:'Alarm Expressions', command: () => actions.addTileFromMenu(TileOptions.fleePanel)}
//                //{label:'wsGauge', command: () => addTileFromMenu(TileOptions.wsGauge)}
//              ]
//           },
//         ]
//       }]
// }
