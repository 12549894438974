import {
	DefaultButton,
	DefaultEffects,
	PrimaryButton,
	TextField,
} from "@fluentui/react";
import { Dialog, DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import { Stack } from "@fluentui/react/lib/Stack";
import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { compose } from "redux";
import * as actions from "../../actions";
import { myTheme } from "../../styles/FluentTheme";
import { getParameterByName } from "../../util";

class LoginPage extends React.Component {
	state = {
		fastLogin: false,
		username: getParameterByName("username"),
		password: "",
		changeId: this.props.changeId,
	};
	componentDidMount() {
		if (this.props.auth !== false) {
			// this.props.history.push('/assets');
		}
	}
	componentWillReceiveProps(nextProps) {
		this.setState({ shake: !nextProps.auth.valid });
	}
	redirectToHome = () => {
		const { history } = this.props;
		if (history) history.push("/assets");
	};
	async login() {
		this.props.login(this.state.username, this.state.password);
	}
	resetPassword() {
		let request;
		const url = `/api/auth/submit/${getParameterByName("changeId")}`;
		const auth = btoa(`${this.state.username}:${this.state.password}`);
		fetch(url, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Basic ${auth}`,
			},
		}).then((req) => {
			switch (req.status) {
				case 200:
					this.props.validateLogin();
				case 403:
					this.setState({ expiredLink: true });
				default:
			}
		});
	}
	renderLoginForm() {
		return this.state.fastLogin ? (
			<Stack
				style={{
					backgroundColor: myTheme().palette.neutralLight,
					boxShadow: DefaultEffects.elevation8,
					padding: "30px",
				}}
				vertical
				tokens={{ childrenGap: 15 }}
			>
				<h1>Welcome to the Portal!</h1>
				<p>
					Click login below for a sandbox demo, or log in with your
					own account.
				</p>
				<Stack tokens={{ childrenGap: 15 }} horizontal>
					<PrimaryButton
						label="Enter the portal"
						onClick={() => {
							this.setState(
								{
									username: "view-only",
									password: "i-LOVE-software!!!",
								},
								() => {
									this.login();
									this.props.history.push("/");
								}
							);
						}}
					>
						Log in for demo
					</PrimaryButton>
					<DefaultButton
						text="Use a different login"
						onClick={(e) => {
							this.setState({ fastLogin: false });
						}}
					/>
				</Stack>
			</Stack>
		) : (
			<Stack
				style={{
					backgroundColor: myTheme().palette.neutralLight,
					boxShadow: DefaultEffects.elevation8,
					padding: "30px",
				}}
				vertical
				tokens={{ childrenGap: 15 }}
			>
				<TextField
					label="Username"
					value={this.state.username}
					onChange={(e, val) => {
						this.setState({ username: val });
					}}
				></TextField>
				<TextField
					label="Password"
					type="password"
					value={this.state.password}
					onKeyDown={(e) => {
						e = e || window.event;
						if (e.keyCode == 13) {
							this.login();
						}
					}}
					onChange={(e, val) => {
						this.setState({ password: val });
					}}
				></TextField>
				<Stack tokens={{ childrenGap: 15 }} horizontal>
					<PrimaryButton
						text="Login"
						onClick={() => {
							this.login();
						}}
					/>
					<DefaultButton
						text="Reset Password"
						onClick={(e) => {
							fetch(
								`/api/auth/request/${this.state.username}`
							).then(() => {
								this.setState({ resetDialog: true });
							});
						}}
					/>
				</Stack>
				{!this.props.auth.valid && (
					<p
						style={{ color: myTheme().black }}
						onClick={() => {
							this.setState({ shake: true });
						}}
						className={`animate ${
							this.state.shake == 1 ? "shake" : ""
						}`}
						onAnimationEnd={() => this.setState({ shake: false })}
					>
						{this.props.auth.message}
					</p>
				)}
				<a
					onClick={() => {
						this.props.history.push("/register");
					}}
				>
					Register an account
				</a>
			</Stack>
		);
	}
	render() {
		const dialogContentProps = {
			type: DialogType.normal,
			title: "Login Error",
			closeButtonAriaLabel: "Close",
			subText: this.state.loginErrorMessage,
		};
		var changeId = getParameterByName("changeId");
		return (
			<div style={{ padding: "50px" }}>
				<Stack
					style={{ height: "90vh" }}
					tokens={{ childrenGap: 30 }}
					horizontal
					horizontalAlign="space-around"
					verticalAlign="center"
				>
					<Stack.Item grow disableShrink>
						{changeId ? (
							<Stack
								style={{
									backgroundColor: myTheme().palette
										.neutralLight,
									boxShadow: DefaultEffects.elevation8,
									padding: "30px",
								}}
								vertical
								tokens={{ childrenGap: 15 }}
							>
								<TextField
									label="Username"
									defaultValue="view-only"
									value={this.state.username}
									onChange={(e, val) => {
										this.setState({ username: val });
									}}
								></TextField>
								<TextField
									valid
									label="Password"
									defaultValue="i-LOVE-software!!!"
									type="password"
									value={this.state.password}
									onChange={(e, val) => {
										var match = val == this.state.confirm;
										this.setState({
											password: val,
											match: match,
										});
									}}
								></TextField>
								<TextField
									color={"white"}
									label="Confirm Password"
									type="password"
									errorMessage={
										!this.state.match
											? "Passwords do not match"
											: null
									}
									value={this.state.confirm}
									onChange={(e, val) => {
										var match = val == this.state.password;
										this.setState({
											confirm: val,
											match: match,
										});
									}}
								></TextField>
								<Stack tokens={{ childrenGap: 15 }} horizontal>
									<PrimaryButton
										text="Set password"
										onClick={() => {
											if (this.state.match) {
												this.resetPassword();
											}
										}}
									/>
									<DefaultButton
										text="Send a new link"
										onClick={(e) => {
											fetch(
												`/api/auth/request/${this.state.username}`
											);
										}}
									/>
								</Stack>
							</Stack>
						) : !this.props.auth.valid ?? false ? (
							this.renderLoginForm()
						) : (
							<Stack tokens={{ childrenGap: 15 }}>
								<h1>You are logged in.</h1>
								<Stack horizontal tokens={{ childrenGap: 15 }}>
									<PrimaryButton
										text="Go to assets"
										onClick={() => {
											this.props.history.push("/assets/");
										}}
									></PrimaryButton>
									<DefaultButton
										text="Log out"
										onClick={() => {
											this.props.logout();
										}}
									></DefaultButton>
								</Stack>
							</Stack>
						)}
					</Stack.Item>
					<Stack.Item grow>
						<img
							style={{
								objectFit: "contain",
								width: "100%",
								maxHeight: "500px",
							}}
							src={"/LIMECLOUD-LOGO.png"}
						></img>
					</Stack.Item>
				</Stack>
				<Dialog
					hidden={!this.state.loginError}
					onDismiss={(a, b) => {
						this.setState({ loginError: false });
					}}
					dialogContentProps={dialogContentProps}
				>
					<DialogFooter>
						<DefaultButton
							onClick={(a, b) => {
								this.setState({ loginError: false });
							}}
							text="Close"
						/>
					</DialogFooter>
				</Dialog>
				{this.props.auth.valid && (
					<Dialog
						hidden={!(this.state.alreadyLoggedIn ?? true)}
						onDismiss={(a, b) => {
							this.setState({ loginError: false });
						}}
					>
						It looks like you are already logged in. Would you like
						to go to the assets page?
						<DialogFooter>
							<NavLink to="/assets">
								<PrimaryButton text="Go to assets" />
							</NavLink>
							<DefaultButton
								onClick={(a, b) => {
									this.setState({ alreadyLoggedIn: false });
								}}
								text="Close"
							/>
						</DialogFooter>
					</Dialog>
				)}
				{this.state.resetDialog && (
					<Dialog
						hidden={!this.state.resetDialog}
						onDismiss={() => {
							this.setState({ resetDialog: false });
						}}
					>
						<p>
							A password reset link has been sent to you email or
							phone. Please check your quarantines and spam
							filters as well. You can request a new link from the
							login page with your email address.
						</p>
						<DialogFooter>
							<NavLink to={"/login"}>
								<PrimaryButton
									onClick={() => {
										this.setState({ resetDialog: false });
									}}
									text="Close"
								/>
							</NavLink>
						</DialogFooter>
					</Dialog>
				)}
				{/* {(this.props.auth?.login == "valid" || this.props.auth.valid) && <Redirect to="/assets/"></Redirect>} */}
			</div>
		);
	}
}
function mapStateToProps({ auth }) {
	return {
		auth: auth,
	};
}

export default compose(
	withRouter,
	connect(mapStateToProps, actions)
)(LoginPage);
