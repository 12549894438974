import { Controlled as CodeMirror } from "react-codemirror2";
import {
	Dropdown,
	ActionButton,
	TextField,
	Stack,
	Icon,
	PrimaryButton,
	DefaultButton,
	Text,
} from "office-ui-fabric-react";
import { useState } from "react";
import { format } from "../../../util";

import playback from "../../../reducers/playback";
import system from "../../../reducers/system";
import currentData from "../../../reducers/currentData";
import { enhancedStore } from "../../..";

export function executeFetch(props) {
	var sub = enhancedStore.getState();
	if (sub.currentData[sub.system.assetId]) {
		sub.data = Object.fromEntries(
			Object.values(sub.currentData[sub.system.assetId]).map((val) => [
				val.label,
				val,
			])
		);
	}
	if (sub.system.attributes?.length > 0) {
		sub.attributes = Object.fromEntries(
			sub.system.attributes?.map((att) => {
				return [att.attributeName, att.attributeValue];
			})
		);
	}

	var reqProps = { ...props };
	if (props?.url) {
		const url = format(props.url, sub);
		var subbedBody = format(reqProps.body, sub);
		var evalstatement = "x=" + subbedBody;

		try {
			var x = subbedBody;
			eval(evalstatement);
			reqProps.body = JSON.stringify(x);
		} catch (e) {
			console.log(e, evalstatement);
		}
		return fetch(url, reqProps)
			.then((res) => {
				if (
					props.contentType == "text" ||
					res.headers.get("content-type")?.includes("text/plain")
				) {
					return res.text();
				} else if (
					props.contentType == "json" ||
					res.headers
						.get("content-type")
						?.includes("application/json")
				) {
					return res.json();
				} else {
					try {
						return res.json();
					} catch (e) {
						return res.text();
					}
				}
			})
			.catch()
			.then((data) => {
				try {
					var parsed = (d) => d;
					eval("parsed=" + format(props.parsing, sub));
					data = parsed(data);
				} catch (e) {}
				return data;
			});
	} else {
		try {
			var parsed = () => d;
			eval("parsed=" + format(props.parsing, sub));
			return Promise.resolve(parsed());
		} catch (e) {
			return Promise.reject("No Fetch configured");
		}
	}
}
export function FetchForm(props) {
	let onChange = (e, val) => {
		if (props.onChange) {
			props.onChange(e, val);
		}
	};
	let [showHeaders, setShowHeaders] = useState(false);
	let [newHeader, setNewHeader] = useState();

	let [newHeaderValue, setNewHeaderValue] = useState();
	return (
		<Stack>
			<Stack horizontal verticalAlign="end">
				<Stack.Item grow>
					<TextField
						value={props?.url}
						label="URL"
						onChange={(e, val) => {
							var og = { ...props };
							og.url = val;
							onChange(e, og);
						}}
					></TextField>
				</Stack.Item>

				<DefaultButton
					onClick={() => {
						setShowHeaders(!showHeaders);
					}}
					iconProps={{
						iconName: showHeaders ? "ChromeMinimize" : "Settings",
					}}
				></DefaultButton>
			</Stack>
			{showHeaders && (
				<>
					<Dropdown
						selectedKey={props?.method ?? "GET"}
						label="Method"
						onChange={(e, val) => {
							var og = { ...props };
							og.method = val.key;
							onChange(e, og);
						}}
						options={[
							{ text: "GET", key: "GET" },
							{ text: "POST", key: "POST" },
						]}
					></Dropdown>
					{(props?.method == "POST" || props?.method == "PUT") && (
						// <TextField
						// 	value={props?.body}
						// 	label="Request Body"
						// 	onChange={(e, val) => {
						// 		var og = { ...props };
						// 		og.body = val;
						// 		onChange(e, og);
						// 	}}
						// ></TextField>
						<Stack>
							<Text>Request Body</Text>
							<CodeMirror
								value={props?.body}
								options={{
									mode: "text/javascript",
									theme: "material",
									lineNumbers: true,
								}}
								onChange={(editor, data, value) => {}}
								onBeforeChange={(editor, data, value) => {
									var og = { ...props };
									og.body = value;
									onChange(null, og);
								}}
							/>
						</Stack>
					)}
					{Object.keys(props?.headers ?? {}).map((headerName, i) => {
						return (
							<Stack horizontal verticalAlign="center">
								<TextField
									disabled
									value={`${headerName}: ${props.headers[headerName]}`}
								></TextField>
								<Icon
									onClick={(e) => {
										let og = { ...props };
										delete og.headers[headerName];
										onChange(e, og);
									}}
									iconName="Cancel"
								></Icon>
							</Stack>
						);
					})}
					<Stack
						horizontal
						verticalAlign="end"
						token={{ childrenGap: 15 }}
					>
						<TextField
							label="Header"
							suffix=":"
							value={newHeader}
							onChange={(e, val) => setNewHeader(val)}
						></TextField>

						<TextField
							label="Value"
							value={newHeaderValue}
							onChange={(e, val) => setNewHeaderValue(val)}
						></TextField>
						<ActionButton
							text="Add header"
							iconProps={{ iconName: "Add" }}
							onClick={(e) => {
								let og = { ...props };
								if (!og.headers) {
									og.headers = {};
								}
								og.headers[newHeader] = newHeaderValue;
								onChange(e, og);
							}}
						></ActionButton>
					</Stack>
					<Dropdown
						label="Response parsing"
						selectedKey={props.contentType}
						onChange={(e, val) => {
							let og = { ...props };
							og.contentType = val.key;
							onChange(e, og);
						}}
						options={[
							{ key: "json", text: "JSON" },
							{ key: "text", text: "Plain text" },
						]}
					></Dropdown>
					<Text>Request Parsing</Text>
					<CodeMirror
						value={
							props.parsing ??
							`//Extra parsing can be done here with JS.
						(json)=>{
							var newJson = json
							return newJson;
						}`
						}
						options={{
							mode: "text/javascript",
							theme: "material",
							lineNumbers: true,
						}}
						onChange={(editor, data, value) => {}}
						onBeforeChange={(editor, data, value) => {
							var og = { ...props };
							og.parsing = value;
							onChange(null, og);
						}}
					/>
					<Stack horizontal horizontalAlign="end">
						<PrimaryButton
							onClick={() => {
								console.log(props);
								executeFetch(props, props.sub)
									.then((data) => {
										var datastr = JSON.stringify(
											data,
											null,
											2
										);
										window.alert(datastr);
									})
									.catch((e) => window.alert(e));
							}}
						>
							Test Request
						</PrimaryButton>
					</Stack>
				</>
			)}
		</Stack>
	);
}
