const currentData = (state = {}, action) => {
	switch (action?.type) {
		// case "CURRENT_DATA": {
		// 	if (action.payload.status === 200) {
		// 		for (var i = 0; i < action.payload.data.length; i++) {
		// 			var cdi = action.payload.data[i];
		// 			if (!state[cdi.assetId]) state[cdi.assetId] = {};
		// 			if (!state[cdi.assetId][cdi.dataItemId]) {
		// 				state[cdi.assetId][cdi.dataItemId] = cdi;
		// 			}
		// 			Object.assign(state[cdi.assetId][cdi.dataItemId], cdi);
		// 		}
		// 	}
		// 	return { ...state };
		// }
		case "DEFINITION": {
			if (!state.definitions) state.definitions = {};
			for (var x in action.payload.dataItemDefinitions) {
				state.definitions[
					action.payload.dataItemDefinitions[x].dataItemId
				] = action.payload.dataItemDefinitions[x];
			}
			return { ...state };
		}
		case "UPDATE_SINGLE": {
			var cdi = action.payload;
			if (!state[cdi.assetId]) state[cdi.assetId] = {};
			if (!state[cdi.assetId][cdi.dataItemId])
				state[cdi.assetId][cdi.dataItemId] = cdi;
			Object.assign(state[cdi.assetId][cdi.dataItemId], cdi);
			return { ...state };
		}
		case "UPDATE_MULTIPLE": {
			for (var i = 0; i < action.payload.length; i++) {
				var cdi = action.payload[i];

				if (!state[cdi.assetId]) state[cdi.assetId] = {};
				if (!state[cdi.assetId][cdi.dataItemId]) {
					state[cdi.assetId][cdi.dataItemId] =
						state.definitions?.[cdi.dataItemId] ?? {};
				}
				if (state[cdi.assetId][cdi.dataItemId].label) {
					delete cdi.label;
				}
				Object.assign(state[cdi.assetId][cdi.dataItemId], cdi);
			}
			return { ...state };
		}
		case "SYSTEM_UPDATED": {
			let assetId = action.payload.assetId;
			if (!state[assetId]) state[assetId] = {};
			let aliasMap = action.payload.attributes.find(
				(att) => att.attributeName.toLowerCase() == "aliasmap"
			)?.attributeValue;
			if (aliasMap && state[assetId]) {
				aliasMap = JSON.parse(aliasMap);
				for (var dataItemId in aliasMap) {
					if (state[assetId][dataItemId]) {
						state[assetId][dataItemId].label = aliasMap[dataItemId];
					} else {
						state[assetId][dataItemId] = {
							label: aliasMap[dataItemId],
						};
					}
				}
			}
			return { ...state };
		}
		default:
			return { ...state };
	}
};

export default currentData;
