import {
	ActionButton,
	DefaultButton,
	DetailsList,
	Dropdown,
	Panel,
	PanelType,
	PrimaryButton,
	Separator,
	Stack,
	TextField,
} from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
	assetDefinitionId,
	assetId,
	getAssetIdFromSystem,
	guid,
	_put,
} from "../../../../../util";
import DateTimePicker from "../../forms/DateTimePicker";
import cap from "./cap.svg";
import crush from "./crush.svg";
import dring from "./dring.svg";
import flange from "./flange.svg";
import hardpacking from "./hardpacking.svg";
import keeper from "./keeper.svg";
import "./maintenance.scss";
import plunger from "./plunger.svg";
import seat from "./seat.svg";
import softpacking from "./softpacking.svg";
import spring from "./spring.svg";
import valve from "./valve.svg";
class PumpMaintenanceChart extends Component {
	state = { schedule: {} };
	componentDidMount() {
		this.loadData();
	}
	loadData() {
		this.loadEvents();
		this.loadEventDefinitions();
		this.loadSchedules();
		this.loadAssetState();
	}
	loadAssetState() {
		fetch("/api/ef/dataitemdefinitions", {
			method: "post",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify([
				{ label: "Pump Hours" },
				{ label: "Engine Hours" },
				{ label: "Stroke Counts" },
			]),
		})
			.then((res) => res.json())
			.then((dids) => {
				this.setState({ dids });
			});
	}
	loadEvents() {
		fetch(`/api/events?assetId=${assetId()}`)
			.then((res) => res.json())
			.then((events) =>
				this.setState({ events }, () => {
					this.groupEventsByType();
				})
			)
			.catch((err) => {});
	}
	loadSchedules() {
		fetch(`/api/assets/${assetId()}/attributes`)
			.then((res) => res.json())
			.then((attributes) => {
				var schedule = attributes.find(
					(att) => att.attributeName == "maintenance-schedule"
				);
				if (schedule) {
					this.setState({
						schedule: JSON.parse(schedule.attributeValue),
					});
				}
			});
	}
	saveTheEditedEvent() {
		var ev = { ...this.state.editedEvent };
		ev.meta = JSON.stringify(ev.metaExpanded);
		fetch(`/api/events`, {
			method: "put",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify([ev]),
		}).then(() => {
			this.loadData();
		});
	}

	submitNewMaintenance() {
		var meta = {
			details: {},
			state: this.state.assetState,
		};
		var events = {
			eventId: guid(),
			eventDefinitionId:
				this.state.selectedEvent.eventDefinition.eventDefinitionId,
			cause: this.state.newComment,
		};
	} // loadEvents(){
	//   fetch(`/api/datasets/PumpMaintenance2`,{method:"get",headers:{"Content-Type":"application/json"}})
	//   .then(res=>res.json())
	//   .then(events=>this.setState({events},()=>{this.groupEventsByType()}))
	// }
	loadEventDefinitions() {
		fetch(
			`/api/events/definitions?assetDefinitionId=${assetDefinitionId()}`,
			{ method: "get", headers: { "Content-Type": "application/json" } }
		)
			.then((res) => res.json())
			.then((ed) => {
				this.setState({ eventDefinitions: ed }, () => {
					this.groupEventsByType();
				});
			});
	}
	groupEventsByType() {
		if (this.state.events && this.state.eventDefinitions) {
			console.log(this.state);
			var events = [...this.state.events];
			var eventDefinitions = [...this.state.eventDefinitions];
			var categories = [
				"Cap Top",
				"D Ring Top",
				"Crush Cap Top",
				"Spring Top",
				"Valve Top",
				"Seat Top",
				"Soft Packing",
				"Hard Packing",
				"Stuffing Box",
				"Plunger",
				"Spring Keeper",
				"Spring Bottom",
				"Valve Bottom",
				"Seat Bottom",
				"Crush Cap Bottom",
				"D Ring Bottom",
				"Cap Bottom",
			];
			var groups = categories.map((category) => {
				return {
					category: category,
					1: undefined,
					2: undefined,
					3: undefined,
					4: undefined,
					5: undefined,
				};
			});
			for (var x in eventDefinitions) {
				var pod = eventDefinitions[x].name.slice(4, 5);
				var action = eventDefinitions[x].name.slice(8);
				var counter =
					this.state.schedule[
						eventDefinitions[x].name
					]?.counterPath?.slice(2) ?? "Pump Hours";

				var catInd = categories.indexOf(action);
				if (catInd >= 0) {
					var matchedEvents = events.filter(
						(ev) =>
							ev.eventDefinitionId ==
							eventDefinitions[x].eventDefinitionId
					);
					var mostRecent = matchedEvents.sort((a, b) => {
						return (
							(b?.metaExpanded?.state?.[counter] ?? 0) -
							(a.metaExpanded?.state?.[counter] ?? 0)
						);
					})[0];
					groups[catInd][pod] = {
						eventDefinition: eventDefinitions[x],
						event: mostRecent,
					};
				} else {
					//console.log("cannot find action",action)
				}
			}
			this.setState({ groups });
		}
	}
	chooseImage(group) {
		var action = group.category;
		switch (action) {
			case "D Ring Top":
				return dring;
			case "D Ring Bottom":
				return dring;
			case "Cap Top":
				return cap;
			case "Cap Bottom":
				return cap;
			case "Crush Cap Top":
				return crush;
			case "Crush Cap Bottom":
				return crush;
			case "Seat Top":
				return seat;
			case "Seat Bottom":
				return seat;
			case "Spring Top":
				return spring;
			case "Spring Bottom":
				return spring;
			case "Hard Packing":
				return hardpacking;
			case "Spring Keeper":
				return keeper;
			case "Soft Packing":
				return softpacking;
			case "Plunger":
				return plunger;
			case "Flange Bottom":
				return flange;
			default:
				return valve;
		}
	}
	actionArea(category) {
		if (this.state.schedule && category && this.state.assetState) {
			var counter =
				this.state.schedule[
					category.eventDefinition.name
				]?.counterPath?.slice(2) ?? "Pump Hours";
			var interval =
				this.state.schedule[category.eventDefinition.name]?.interval ??
				300;
			var lastValue = category.event?.metaExpanded.state[counter] ?? 0;
			var currentValue = this.state.assetState[counter];
			var val = 1 - (lastValue + interval - currentValue) / interval;
			var color = this.pickHex([0, 255, 0], [255, 0, 0], 1 - val);
			return (
				<div>
					<div>
						<div style={{ fontSize: 12 }}>{`${(
							currentValue - lastValue
						).toFixed(0)}/${interval} ${counter}`}</div>
						<div
							style={{ width: "100%", border: "1px solid #ddd" }}
						>
							<div
								style={{
									backgroundColor: color,
									width: `${(val ?? 1) * 100}%`,
									height: "10px",
								}}
							></div>
						</div>
					</div>
				</div>
			);
		}
	}
	bigbar(category) {
		if (this.state.schedule) {
			var counter =
				this.state.schedule[
					category.eventDefinition.name
				]?.counterPath?.slice(2) ?? "Pump Hours";
			var interval =
				this.state.schedule[category.eventDefinition.name]?.interval ??
				300;
			var lastValue = category.event?.metaExpanded.state[counter] ?? 0;
			var currentValue = this.state.assetState[counter];
			var val = 1 - (lastValue + interval - currentValue) / interval;
			var color = this.pickHex([0, 255, 0], [255, 0, 0], 1 - val);
			return (
				<div>
					<div>
						<div style={{ fontSize: 12 }}>{`${(
							currentValue - lastValue
						).toFixed(0)}/${interval} ${counter}`}</div>
						<div
							id="barouter"
							style={{ width: "100%", border: "1px solid #ddd" }}
						>
							<div
								id="barprogress"
								style={{
									backgroundColor: color,
									width: `${(val < 1 || 1) * 100}%`,
									height: "20px",
								}}
							></div>
						</div>
					</div>
				</div>
			);
		}
	}
	pickHex(color1, color2, weight) {
		var w1 = weight;
		var w2 = 1 - w1;
		var rgb = [
			Math.round(color1[0] * w1 + color2[0] * w2),
			Math.round(color1[1] * w1 + color2[1] * w2),
			Math.round(color1[2] * w1 + color2[2] * w2),
		];
		return "rgb(" + rgb.join() + ")";
	}
	groupRow(group) {
		return (
			<tr key={group.category} style={{}}>
				<td
					style={{
						width: "80px",
						height: "30px",
						overflow: "hidden",
						verticalAlign: "middle",
					}}
				>
					<Stack horizontal verticalAlign="center">
						<p>{group.category}</p>
						<img
							style={{ width: "80px", height: "30px" }}
							src={this.chooseImage(group)}
						></img>
					</Stack>
				</td>
				<td
					className="action"
					onClick={() => {
						this.setState({
							selectedEvent: { ...group["1"] },
							panelOpen: true,
							editedEvent: undefined,
						});
					}}
				>
					{this.actionArea(group["1"])}
				</td>
				<td
					className="action"
					onClick={() => {
						this.setState({
							selectedEvent: { ...group["2"] },
							panelOpen: true,
							editedEvent: undefined,
						});
					}}
				>
					{this.actionArea(group["2"])}
				</td>
				<td
					className="action"
					onClick={() => {
						this.setState({
							selectedEvent: { ...group["3"] },
							panelOpen: true,
							editedEvent: undefined,
						});
					}}
				>
					{this.actionArea(group["3"])}
				</td>
				<td
					className="action"
					onClick={() => {
						this.setState({
							selectedEvent: { ...group["4"] },
							panelOpen: true,
							editedEvent: undefined,
						});
					}}
				>
					{this.actionArea(group["4"])}
				</td>
				<td
					className="action"
					onClick={() => {
						this.setState({
							selectedEvent: { ...group["5"] },
							panelOpen: true,
							editedEvent: undefined,
						});
					}}
				>
					{this.actionArea(group["5"])}
				</td>
			</tr>
		);
	}
	eventPopout() {
		return <Stack>{this.state?.selectedEvent?.eventId}</Stack>;
	}
	editEvent(path, value) {
		var ev = { ...this.state.editedEvent };
		_put(ev, path, value);
		this.setState({ editedEvent: ev });
	}
	componentWillReceiveProps(nextProps) {
		var assetId = getAssetIdFromSystem(null, []);
		var assetState = {
			"Pump Hours":
				this.props.currentData?.[assetId]?.[
					this.state.dids?.[0].dataItemId
				]?.value,
			"Engine Hours":
				this.props.currentData?.[assetId]?.[
					this.state.dids?.[1].dataItemId
				]?.value,
			"Stroke Count":
				this.props.currentData?.[assetId]?.[
					this.state.dids?.[2].dataItemId
				]?.value,
		};
		this.setState({ assetState });
	}
	maintenanceDetailsPanel() {
		var counter =
			this.state.schedule[
				this.state.selectedEvent.eventDefinition.name
			]?.counterPath?.slice(2) ?? "Pump Hours";
		var interval =
			this.state.schedule[this.state.selectedEvent.eventDefinition.name]
				?.interval ?? 300;
		var lastValue =
			this.state.selectedEvent.event?.metaExpanded.state[counter] ?? 0;
		var currentValue = this.state.assetState[counter];

		lastValue = parseFloat(lastValue);
		var val = 1 - (lastValue + interval - currentValue) / interval;
		var dueAt = lastValue + interval;
		var remaining = dueAt - currentValue;
		return (
			<Panel
				isOpen={this.state.panelOpen}
				isLightDismiss
				isBlocking={false}
				type={PanelType.medium}
				onDismiss={() => {
					this.setState({ panelOpen: false });
				}}
				headerText={this.state.selectedEvent?.eventDefinition?.name}
			>
				<Stack>
					{this.bigbar(this.state.selectedEvent)}
					<Stack>
						<h3>{`Last performed at: ${lastValue.toFixed(
							0
						)} ${counter}`}</h3>
						<h3>{`Maintenance Interval: ${interval.toFixed(
							0
						)} ${counter}`}</h3>
						<h3>{`Due at: ${dueAt.toFixed(0)} ${counter}`}</h3>
						<h3>{`${counter} remaining: ${remaining.toFixed(
							0
						)}`}</h3>
						{/* {this.state.newMaintenance?
              <Stack tokens={{childrenGap:15}}>
                <Separator>New maintenance record</Separator>
                <DateTimePicker onDateChanged={(datetime)=>{this.setState({newDatetime:datetime})}} datetime = {this.state.newDatetime}></DateTimePicker>
                <Dropdown 
                  label="Part Number"
                  options ={this.state.selectedEvent.eventDefinition.reasonExpanded.options.map((opt,i)=>{return{ key:opt,text:opt}})}
                  selectedKey={this.state.newSelection}
                  onChange = {(ev,opt,i)=>{
                    this.setState({newSelection:opt.key})
                  }}
                ></Dropdown>
                <TextField 
                  label="Commments" 
                  multiline 
                  value={this.state.newComment}
                  onChange={(e,val)=>{
                    this.setState({newComment:val})
                  }}
                ></TextField>
                <Stack horizontal tokens={{childrenGap:15}}>
                  <PrimaryButton onClick={()=>{this.submitNewMaintenance()}}>Save Record</PrimaryButton>
                  <DefaultButton onClick={()=>{this.setState({newMaintenance:false})}}>Cancel</DefaultButton>
                </Stack>
              </Stack>
              :
              <Stack horizontal tokens={{childrenGap:15}}>
                <PrimaryButton onClick={()=>{this.setState({newMaintenance:true})}}>Submit record</PrimaryButton>
              </Stack>
              } */}
					</Stack>
					<Separator>Update maintenance records</Separator>
					<Stack>
						{this.state.editedEvent && (
							<Stack tokens={{ childrenGap: 15 }}>
								<DateTimePicker
									datetime={
										new Date(
											this.state.editedEvent.timestamp +
												"Z"
										)
									}
									onDateChanged={(date) => {
										this.editEvent(
											["timestamp"],
											date.toJSON()
										);
									}}
								></DateTimePicker>
								<Dropdown
									label="Part Number"
									options={this.state.selectedEvent.eventDefinition.reasonExpanded.options.map(
										(opt, i) => {
											return { key: opt, text: opt };
										}
									)}
									selectedKey={
										this.state.editedEvent.metaExpanded
											.details.selection
									}
									onChange={(ev, opt, i) => {
										console.log(opt);
										this.editEvent(
											[
												"metaExpanded",
												"details",
												"selection",
											],
											opt.key
										);
									}}
								></Dropdown>

								<TextField
									label={counter}
									type="number"
									defaultValue={
										this.state.editedEvent.metaExpanded
											.state[counter]
									}
									value={
										this.state.editedEvent.metaExpanded
											.state[counter]
									}
									onChange={(e, val) => {
										this.editEvent(
											["metaExpanded", "state", counter],
											parseFloat(val)
										);
									}}
								></TextField>
								<TextField
									label="Previous Commments"
									multiline
									disabled
									readOnly
									value={
										this.state.events.find(
											(ev) =>
												ev.eventId ==
												this.state.editedEvent.eventId
										).cause
									}
								></TextField>
								<TextField
									label="Additional Commments"
									multiline
									value={this.state.editedEvent.cause}
									onChange={(e, val) => {
										this.editEvent(["cause"], val);
									}}
								></TextField>
								<Stack
									tokens={{ childrenGap: 15 }}
									horizontal
									horizontalAlign="end"
								>
									<PrimaryButton
										onClick={() => {
											this.saveTheEditedEvent();
										}}
									>
										{"Save record"}
									</PrimaryButton>
									<DefaultButton
										onClick={() => {
											this.setState({
												editedEvent: undefined,
											});
										}}
									>
										Cancel
									</DefaultButton>
								</Stack>
							</Stack>
						)}
						{this.state.events.filter((ev) => {
							return (
								ev.eventDefinitionId ==
								this.state.selectedEvent.eventDefinition
									.eventDefinitionId
							);
						}).length > 0 ? (
							<DetailsList
								items={this.state.events.filter((ev) => {
									return (
										ev.eventDefinitionId ==
										this.state.selectedEvent.eventDefinition
											.eventDefinitionId
									);
								})}
								columns={[
									{
										key: "time",
										fieldName: "timestamp",
										name: "Time",
									},
									{
										key: "part",
										name: "Part #",
										onRender: (item) => {
											return item.metaExpanded.details
												.selection;
										},
									},
									{
										key: "counter",
										name: this.state.schedule[
											this.state.selectedEvent
												.eventDefinition.name
										]?.counterPath?.slice(2),
										onRender: (item) => {
											var path =
												this.state.schedule[
													this.state.selectedEvent
														.eventDefinition.name
												]?.counterPath?.slice(2);
											return (
												item.metaExpanded.state[path] ??
												0
											);
										},
									},
								]}
								onActiveItemChanged={(editedEvent, i) => {
									var ev = { ...editedEvent };
									ev.cause = "";
									this.setState({
										editedEvent: ev,
										editedCounterValue:
											ev.metaExpanded.state[counter],
									});
								}}
							></DetailsList>
						) : (
							<div>{"No records to show."}</div>
						)}
					</Stack>
				</Stack>
			</Panel>
		);
	}
	render() {
		return (
			<Stack>
				<ActionButton
					iconProps={{ iconName: "Refresh" }}
					onClick={() => {
						this.loadData();
					}}
				>
					Refresh
				</ActionButton>
				<table style={{ tableLayout: "fixed", width: "100%" }}>
					<thead>
						<tr>
							<th align="left">Action</th>
							<th align="left">Pod 1</th>
							<th align="left">Pod 2</th>
							<th align="left">Pod 3</th>
							<th align="left">Pod 4</th>
							<th align="left">Pod 5</th>
						</tr>
					</thead>
					<tbody style={{ tableLayout: "fixed", width: "100%" }}>
						{this.state?.groups?.map((group) => {
							return this.groupRow(group);
						})}
					</tbody>
				</table>
				{this.state.selectedEvent && this.maintenanceDetailsPanel()}
			</Stack>
		);
	}
}

function mapStateToProps({ currentData }) {
	return { currentData };
}

export default connect(mapStateToProps)(PumpMaintenanceChart);
