import { getTheme, IconButton, Icon, Stack } from "@fluentui/react";
import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { iOS } from "../../../util";
import AISettings from "../components/AIMConfigs/AISettings/AISettings";
import AdvancedGraph from "../components/visuals/AdvancedGraph";
import AlarmTextBox from "../components/visuals/AlarmTextBox";
import BarChartFlot from "../components/visuals/BarChartFlot/BarChartFlot";
// import BasicGraph from "../components/visuals/BasicGraph";
import CustomHTML from "../components/visuals/CustomHTML";
import CustomTable from "../components/visuals/CustomTableFluentUI";
import DataItemTable from "../components/visuals/DataItemTable";
import CylinderGauge from "../components/visuals/CylinderGauge";
// import DynamicGraph from "../components/visuals/DynamicGraph";
//import variables from '../styles/variables.scss';
import Gauge from "../components/visuals/Gauge/Gauge";
// import HourlyHeatMap from "../components/visuals/HourlyHeatMap";
import IframeTile from "../components/visuals/IframeTile";
import Indicator from "../components/visuals/Indicator";
import Map from "../components/visuals/Map";
// import MeanBoxChart from "../components/visuals/MeanBoxChart";
import MessageViewer from "../components/visuals/messageViewer/MessageViewer";
import PatientChart from "../components/visuals/PatientChart";
//import EditChart from "../components/visuals/EditChart";
import PumpMaintenanceChart from "../components/visuals/pumpMaintenance/PumpMaintenanceChart";
//import SolidGauge from "../components/visuals/SolidGauge";
import StateReport from "../components/visuals/StateReport/StateReport";
//import GltfLoaderComponent from "../components/visuals/GLTFLoader";
import Table from "../components/visuals/Table";
import TextBox from "../components/visuals/TextBox";
import TileType from "../components/visuals/TileType";
import FastGraph from "../components/visuals/uPlot/FastGraph";
import XYChart from "../components/visuals/xychart/xychart";
import DownloadButton from "../components/visuals/DownloadButton/DownloadButton";
import ActionDispatcher from "../components/visuals/ActionDispatcher/ActionDispatcher";
//import WSGauge from '../components/visuals/wsGauge';
import { FullScreener } from "../../../components/FullScreener";
const components = {
	TileType: TileType,
	Gauge: Gauge,
	// BasicGraph: BasicGraph,
	AdvancedGraph: AdvancedGraph,
	//GltfLoaderComponent: GltfLoaderComponent,
	FastGraph: FastGraph,
	TextBox: TextBox,
	AlarmTextBox: AlarmTextBox,
	Map: Map,
	Table: Table,
	CustomTable: CustomTable,
	CylinderGauge: CylinderGauge,
	DataItemTable: DataItemTable,
	//SolidGauge: SolidGauge,
	IframeTile: IframeTile,
	Indicator: Indicator,
	BarGraph: BarChartFlot,
	DownloadButton: DownloadButton,
	ActionDispatcher: ActionDispatcher,
	//BarGraph:BarGraph,
	// MeanBoxChart: MeanBoxChart,
	// PatientChart: PatientChart,
	//EditChart: EditChart,
	//PieChart: PieChart,
	// HourlyHeatMap: HourlyHeatMap,
	// DynamicGraph: DynamicGraph,
	Rendering: PumpMaintenanceChart,
	XYChart: XYChart,
	MessageViewer: MessageViewer,
	AISettings: AISettings,
	StateReport: StateReport,
	CustomHTML: CustomHTML,
	//WSGauge: WSGauge
};

class Tile extends Component {
	fsRef = createRef();
	handleEditTile(tile) {
		const { toggleModal } = this.props;
		toggleModal(tile);
	}
	handleCloneTile() {
		this.props.addTileWithSize(this.props.tile, this.props.activeTab);
	}
	enlargeTile(tile) {
		const { enlargeTile } = this.props;
		enlargeTile(tile.id);
	}
	state = { big: false };
	render() {
		let palette = getTheme().palette;
		const { tile, deleteTile, activeTab, ui, layout } = this.props;
		const TileType =
			tile.properties.type && components[tile.properties.type.value]
				? components[tile.properties.type.value]
				: components["TileType"] ?? <div></div>;
		const viewOnly = ui.viewOnly ? "none" : "";
		const visibility = ui.viewOnly ? "hidden" : "";
		const edit = ui.viewOnly ? "tile-view" : "tile-edit";
		const regularDisplay = {
			height: "100%",
			width: "100%",
			backgroundColor: palette.neutralLighter,
			overflow: tile.properties.overflow ?? "hidden",
		};

		var tileComponent = (
			<>
				<div
					ref={this.fsRef}
					className={`tile ${edit}`}
					style={regularDisplay}
				>
					{ui.viewOnly ? (
						<div
							className="fsButton"
							style={{
								position: "absolute",
								zIndex: 99999,
							}}
						>
							<IconButton
								styles={{
									root: { height: 18 },
									menuIcon: { fontSize: 10 },
									icon: { fontSize: 10 },
								}}
								iconProps={{
									iconName: this.state.big
										? "ChromeMinimize"
										: "ChromeFullScreen",
								}}
								onClick={() => {
									if (this.state.big) {
										this.setState({ big: false });
									} else {
										this.setState({ big: true });
									}
								}}
							></IconButton>
						</div>
					) : (
						<div
							className="tile-toolbar"
							style={{
								position: "absolute",
								width: "100%",
								backgroundColor: "#00000055",
								cursor: "move",
								zIndex: 99999,
							}}
						>
							<Stack horizontalAlign="space-between" horizontal>
								{/* <IconButton
									style={{ zIndex: 99999 }}
									iconProps={{ iconName: "Edit" }}
									onDragStart={(ev) => {
										ev.stopPropagation();
									}}
									onClick={(ev) => {
										ev.stopPropagation();
										this.handleEditTile(tile);
									}}
								></IconButton> */}
								<IconButton
									style={{ zIndex: 99999 }}
									styles={{
										root: { height: 18 },
										menuIcon: { fontSize: 10 },
										icon: { fontSize: 10 },
									}}
									iconProps={{ iconName: "Edit" }}
									onDragStart={(ev) => {
										ev.stopPropagation();
									}}
									onClick={(ev) => {
										ev.stopPropagation();
										this.handleEditTile(tile);
									}}
								></IconButton>
								<IconButton
									style={{
										color: palette.themePrimary,
									}}
									styles={{
										root: { height: 18 },
										menuIcon: { fontSize: 10 },
										icon: { fontSize: 10 },
									}}
									iconName="MoreVertical"
									iconProps={{ iconName: "MoreVertical" }}
									menuProps={{
										shouldFocusOnMount: true,
										items: [
											{
												key: "deleteTile",
												text: "Delete",
												onClick: () =>
													deleteTile(
														tile.id,
														activeTab
													),
											},
											{
												key: "clonetile",
												text: "Clone",
												onClick: (e) =>
													this.props.cloneTile(
														tile,
														activeTab
													),
											},
											{
												key: "edittile",
												text: "Edit",
												onClick: (e) =>
													this.handleEditTile(tile),
											},
											// {key:"dataItem",onRender:()=>{
											//   return <SystemDataItemSelector
											//     onDataItemSelected={(val)=>{
											//       const {slotPath,dataItem } = val
											//       const {editTile, activeTab } = this.props;
											//       var newTile = {...this.props.tile}
											//       if(newTile && newTile.properties && val ){
											//         newTile.properties.settingsGuid=guid()
											//         newTile.properties.dataItem = dataItem
											//         newTile.properties.slotPath = slotPath
											//         editTile(newTile, activeTab.index);
											//         console.log(newTile,activeTab.index)
											//       }
											//     }}
											//   ></SystemDataItemSelector>
											// }
											// }
										],
									}}
								></IconButton>
							</Stack>
						</div>
					)}
					<div
						onMouseDown={(e) => e.stopPropagation()}
						//onTouchStart={e => e.stopPropagation()}
						style={{ height: "100%", width: "100%" }}
					>
						<TileType
							key={tile.properties?.settingsGuid ?? ""}
							{...tile}
						/>
					</div>
					<p
						style={{
							fontSize: "12px",
							visibility: visibility,
							position: "absolute",
							bottom: "6px",
							right: "6px",
						}}
					>
						{this.props.grid?.w}x{this.props.grid?.h}
					</p>
				</div>
			</>
		);
		return (
			<FullScreener
				fullscreen={this.state.big ?? false}
				onExitFullScreen={() => this.setState({ big: false })}
			>
				{tileComponent}
			</FullScreener>
		);
	}
}

function mapStateToProps({ activeTab, ui, system, currentData, tabs }) {
	return { activeTab, ui, system, currentData: { ...currentData }, tabs };
}

export default connect(mapStateToProps, actions)(Tile);
