import React, { Component } from "react";
import { connect } from "react-redux";
import { getAssetIdFromSystem } from "../../../../util/index";
import variables from "../../styles/variables.scss";
class Indicator extends Component {
	static defaultProps = {
		defaultValue: Math.floor(Math.random() * 100),
		defaultBackground: variables.tileBackground,
		decimals: "2",
		fontSize: "14",
	};

	componentDidMount() {}

	defaultConfig = () => {
		const { fontSize, defaultBackground } = this.props;
		return (
			<div
				className="indicator"
				style={{
					width: "100%",
					height: "100%",
					textAlign: "center",
					backgroundColor: defaultBackground,
					fontSize: `${fontSize}px`,
				}}
			>
				<span>Indicator</span>
				<br />
				<span>{`Value: XX`}</span>
			</div>
		);
	};

	config = () => {
		try {
			const { labelColor, valueColor, backgroundColor, timestamp } =
				this.props.properties;
			const { decimals, fontSize } = this.props;
			var dataItem = { ...this.props.properties.dataItem };
			var assetId = getAssetIdFromSystem(
				null,
				this.props.properties.slotPath ?? []
			);
			Object.assign(
				dataItem,
				this.props.currentData[assetId][dataItem.dataItemId]
			);
			const showValue = dataItem?.value?.toFixed(decimals) ?? "- -";
			return (
				<div
					className="indicator"
					style={{
						width: "100%",
						height: "100%",
						textAlign: "center",
						backgroundColor: `${backgroundColor}`,
						fontSize: `${fontSize}px`,
					}}
				>
					<span style={{ color: `${labelColor}` }}>
						{dataItem.label}
					</span>
					<br />
					<span style={{ color: `${valueColor}` }}>
						{dataItem.isString
							? dataItem.stringValue
							: `${showValue} ${dataItem.unit ?? ""}`}
					</span>
					<br />
					<span style={{ color: `${valueColor}` }}>
						{timestamp
							? new Date(dataItem.timestamp).toLocaleString()
							: ""}
					</span>
				</div>
			);
		} catch (e) {
			return <div>{e.toString()}</div>;
			return this.defaultConfig();
		}
	};

	render() {
		const { type } = this.props.properties;
		return type.default ? this.defaultConfig() : this.config();
	}
}

function mapStateToProps({ currentData }) {
	return { currentData };
}

export default connect(mapStateToProps)(Indicator);
