import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import React, { Component } from "react";
import "./StateReport.css"; // This only needs to be imported once in your app

export default class SchedulePieChart extends Component {
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		this.chart = am4core.create(this.props.id, am4charts.PieChart);
		let legendContainer = am4core.create(
			this.props.id + "legend",
			am4core.Container
		);
		legendContainer.width = am4core.percent(100);
		legendContainer.height = am4core.percent(100);
		// Set up Pie Chart
		// let title = this.chart.titles.create();
		// title.text = "Activity Breakdown";

		this.chart.legend = new am4charts.Legend();
		this.chart.legend.parent = legendContainer;
		this.chart.legend.layout = "grid";
		this.chart.legend.maxColumns = 3;
		this.chart.legend.labels.template.fill = am4core.color("#fff");
		this.chart.legend.valueLabels.template.fill = am4core.color("#fff");
		this.chart.legend.fontSize = 12;
		let resizeLegend = (ev) => {
			document.querySelector(
				".statePie > div > div.legend"
			).style.height = this.chart.legend.contentHeight + "px";
		};
		this.chart.events.on("datavalidated", resizeLegend);
		this.chart.events.on("maxsizechanged", resizeLegend);
		this.chart.legend.events.on("datavalidated", resizeLegend);
		this.chart.legend.events.on("maxsizechanged", resizeLegend);

		this.chart.radius = am4core.percent(50);
		//this.chart.legend.scrollable = true;
		var pieSeries = this.chart.series.push(new am4charts.PieSeries());
		pieSeries.dataFields.value = "hours";
		pieSeries.dataFields.category = "activity";
		pieSeries.slices.template.stroke = am4core.color("#fff");
		pieSeries.slices.template.strokeWidth = 2;
		pieSeries.slices.template.strokeOpacity = 1;
		//this.chart.innerRadius = 50;
		this.chart.legend.valueLabels.template.text =
			"{value.formatNumber('#.00')} hr";
		//pieSeries.labels.template.wrap = true;
		pieSeries.labels.template.fontSize = 12;
		pieSeries.ticks.template.adapter.add("hidden", hideSmall);
		pieSeries.labels.template.adapter.add("hidden", hideSmall);
		pieSeries.labels.template.stroke = "#ffffff";
		function hideSmall(hidden, target) {
			return target.dataItem.values.value.percent == 0 ? true : false;
		}
		//pieSeries.labels.template.bent = true;
		//pieSeries.labels.template.padding(0,0,0,0);
		pieSeries.labels.template.text =
			"{category}:\n{value.formatNumber('#.0')} hours";
		pieSeries.labels.template.color = "#fff";
		//pieSeries.labels.template.relativeRotation = 90;
		pieSeries.alignLabels = true;
		//pieSeries.alignLabels = false;

		//pieSeries.ticks.template.disabled = true;

		// This creates initial animationasdsads
		pieSeries.hiddenState.properties.opacity = 1;
		pieSeries.hiddenState.properties.endAngle = -90;
		pieSeries.hiddenState.properties.startAngle = -90;

		//Set Data and validate

		this.chart.data = this.summarizeSchedule();
	}
	summarizeSchedule() {
		let summary = [];
		var summObj = {};
		let sorted = this.props.data.sort((a, b) => {
			return new Date(a.startTime) - new Date(b.startTime);
		});

		for (var i = 0; i < sorted.length; i++) {
			if (!summObj[sorted[i].activity]) {
				summObj[sorted[i].activity] = 0;
			}
			summObj[sorted[i].activity] += sorted[i].hours;
		}
		for (var act in summObj) {
			summary.push({
				activity: act,
				hours: summObj[act],
				startTime: "",
				endTime: "",
			});
		}
		summary = summary.sort(function (a, b) {
			if (a.activity < b.activity) {
				return -1;
			}
			if (a.activity > b.activity) {
				return 1;
			}
			return 0;
		});
		if (this.props.colorMap) {
			this.chart.series.values[0].colors.list = summary.map((el) => {
				return this.props.colorMap[el.activity];
			});

			var presentAct = summary.map((el) => {
				return el.activity;
			});
			var allAct = Object.keys(this.props.colorMap);
			for (var i in allAct) {
				if (presentAct.indexOf(allAct[i]) < 0) {
					summary.push({
						activity: allAct[i],
						hours: 0,
						startTime: "",
						endTime: "",
					});
				}
			}
		}

		return summary;
	}
	componentWillUnmount() {
		if (this.chart) {
			this.chart.dispose();
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.data.length > 0 && this.props.data.length > 0)
			return this.props.data[0].startTime != nextProps.data[0].startTime;
		else return true;
	}
	render() {
		return (
			<div className="statePie" style={{ textAlign: "center" }}>
				<h1 style={{ color: "white" }}>Activity Breakdown</h1>
				<div>
					<div className="legend" id={this.props.id + "legend"}></div>
					<div className="chart" id={this.props.id}></div>
				</div>
			</div>
		);
	}
}
