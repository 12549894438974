import { color } from "@amcharts/amcharts4/core";
import { DetailsList, Icon, Stack, Spinner } from "@fluentui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import PaginatedTable from "../../../../components/PaginatedTable/PaginatedTable";
import { format, getAssetFromSystemByAssetId } from "../../../../util";
import variables from "../../styles/variables.scss";
import { executeFetch } from "../FetchForm";

class CustomTable extends Component {
	state = {
		globalFilter: "",
		data: [],
		loading: true,
	};

	static defaultProps = {
		decimals: "2",
		fontSize: "14",
	};

	fetchData = () => {
		let properties = { ...this.props.properties };

		executeFetch(properties.fetch, this.props)
			.catch((err) => {
				this.setState({ loading: false });
			})
			.then((data) => {
				this.setState({ data: data, loading: false });
			});
	};

	export = () => {
		this.dt.exportCSV();
	};

	componentDidMount() {
		this.fetchData();
	}
	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { error };
	}
	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
	}
	componentDidUpdate(prevProps) {
		const { properties } = this.props;
		if (prevProps.properties !== properties) {
			this.fetchData();
		}
	}

	linkTemplate = (rowData, column, body) => {
		const cell = body(rowData);
		return <div dangerouslySetInnerHTML={{ __html: cell }}></div>;
	};

	renderTable = () => {
		const { data, loading, error } = this.state;
		//const { rows } = this.props.properties;
		if (loading) {
			return (
				<div
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%)",
					}}
				>
					<Spinner></Spinner>
				</div>
			);
		} else if (!data) {
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%)",
				}}
			>
				No Data yet...
				<Spinner></Spinner>
			</div>;
		} else if (error) {
			return (
				<div
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%)",
					}}
				>
					{error.toString()}
				</div>
			);
		} else {
			try {
				var colstring = this.props.properties.columns;
				colstring.replace(/\^/g, " ");
				var subObject = { ...this.props };
				subObject.data = {};
				if (this.props.currentData[this.props.system.assetId]) {
					subObject.data = Object.fromEntries(
						Object.values(
							this.props.currentData[this.props.system.assetId]
						).map((val) => [val.label, val])
					);
				}

				subObject.attributes = Object.fromEntries(
					this.props.system.attributes.map((att) => {
						return [att.attributeName, att.attributeValue];
					})
				);
				colstring = format(colstring, subObject);
				var columns = [];

				columns = eval("columns=" + colstring);

				columns.forEach((col, i) => {
					if (col.body && !col.onRenderContent) {
						col.onRenderContent = col.body;
					}
					if (col.field && !col.fieldName) {
						col.fieldName = col.field;
					}
					if (col.header && !col.name) {
						col.name = col.header;
					}

					if (!col.key) {
						col.key = col.field + i;
					}
				});
				//const columns = eval(this.props.properties.columns);

				return (
					<PaginatedTable
						style={{ height: "100%" }}
						title={this.props.properties.title}
						pageSize={this.props.properties.pageSize ?? 10}
						columns={columns}
						items={data ?? []}
					></PaginatedTable>
				);
			} catch (err) {
				const { type } = this.props.properties;
				if (type.default) {
					return (
						<div
							style={{
								position: "absolute",
								top: "50%",
								left: "50%",
								transform: "translate(-50%)",
							}}
						>
							Custom Table. Columns and/or data source needs to be
							filled out.
						</div>
					);
				}
				return (
					<div
						style={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%)",
						}}
					>
						Invalid Input, please try again {err.toString()}
					</div>
				);
			}
		}
	};

	render() {
		if (this.state.error != null) {
			// You can render any custom fallback UI
			return <p>{this.state.error.toString()}</p>;
		}
		const { fontSize } = this.props;
		const table = this.renderTable();
		return (
			<>
				<div
					className="table-background"
					style={{ height: "100%", width: "100%" }}
				>
					{table}
				</div>
			</>
		);
	}
}

function mapStateToProps({ currentData, system }) {
	return { currentData, system };
}

export default connect(mapStateToProps)(CustomTable);
