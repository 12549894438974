import { getTheme, IconButton, Spinner } from "@fluentui/react";
import * as Papa from "papaparse";
import React from "react";
import { connect } from "react-redux";
import {
	getAssetById,
	getAssetFromSystem,
	getAssetFromSystemByAssetId,
	getAssetIdFromSystem,
	getFormattedLabel,
	getParameterByName,
} from "../../../../../util";
import { axisHide } from "./AxisHide";
import SteppedLine from "./StepLinePlugin";
import { tooltipsPlugin } from "./TooltipPlugin";
import UPlotComponent from "./uPlot";

class FastGraph extends React.Component {
	state = {
		userZoom: false,
	};
	controller = new AbortController();
	fetchTimeout;
	chartData;
	cleanColor(color) {
		if (color?.length == 6 || color?.length == 3) {
			return "#" + color;
		} else {
			return color;
		}
	}

	componentWillReceiveProps(nextProps) {
		if (
			this.props.playback.startDate != nextProps.playback.startDate &&
			nextProps.playback.startDate != null
		) {
			var thresh =
				(this.props.properties.decimation ?? 20) < 100
					? (this.props.properties.decimation ?? 20) * 30
					: -1;
			var url = `/api/historicaldata?columnar=true&threshold=${thresh}&dataItems=${this.props.properties?.series?.map(
				(di) =>
					getAssetIdFromSystem(null, di.slotPath) +
					"-" +
					di.dataItem.dataItemId
			)}`;
			//const lastLoad = (new Date(new Date() - (this.props.properties.fixedStart??.5)*3600*1000)).toJSON()
			const lastLoad =
				nextProps.playback.startDate ??
				new Date(
					new Date() -
						(this.props.properties.fixedStart ?? 0.5) * 3600 * 1000
				);
			clearTimeout(this.fetchTimeout);
			this.setState({ url, lastLoad, userZoom: false }, () => {
				this.fetchData(true);
			});
		}
	}
	componentDidMount() {
		try {
			let fontColor = getTheme().palette.black;
			if (this.props.properties) {
				var scales = {
					x: {
						dir: this.props.properties.invert ? -1 : 1,
						ori: this.props.properties.invert ? 1 : 0,
					},
				};
				for (var i in this.props.properties.axes) {
					var axis = this.props.properties.axes[i];
					var min = parseFloat(axis.min);
					var max = parseFloat(axis.max);
					scales[axis.label] = {
						dir: this.props.properties.invert ? 1 : 1,
						ori: this.props.properties.invert ? 0 : 1,
						auto: isNaN(min) || isNaN(max),
						range:
							isNaN(min) || isNaN(max) ? undefined : [min, max],
					};
				}

				const opts = {
					invert: this.props.properties.invert,
					padding: [0, 10, 0, 10],
					axes: [
						{
							font: "12px Proxima Nova",
							labelFont: "12px Proxima Nova",
							stroke: fontColor,
							side: this.props.properties.invert ? 3 : 2,
						},
					].concat(
						this.props.properties?.axes?.map((ser, i) => {
							return {
								stroke: fontColor,
								scale: ser.label,
								font: "12px Proxima Nova",
								labelFont: "12px Proxima Nova",
								label: ser.label,
								side: this.props.properties.invert
									? i % 2 == 0
										? 2
										: 0
									: i % 2 == 0
									? 3
									: 1,
								grid: { show: ser.grid ?? false },
							};
						})
					),
					legend: {
						show: this.props.properties.legend,
						font: "12px Proxima Nova",
					},
					// 	: undefined,
					cursor: {
						fill: "#ffffff",
						drag: { x: true, y: true, uni: 50 },
						// sync: {
						//     key: "moo",setSeries: true,
						//     scales: ["x"]
						// }
					},

					plugins: [tooltipsPlugin()],
					time: false,
					invert: this.props.properties.invert,
					rotated: true,
					title: this.props.properties.title,
					scales: scales,

					hooks: {
						setSelect: [
							(u, key) => {
								this.setState({ userZoom: true });
							},
						],
					},
					series: [{}].concat(
						this.props.properties?.series?.map((ser) => {
							var assetId = getAssetIdFromSystem(
								null,
								ser.slotPath
							);
							var asset = getAssetById(
								this.props.assets,
								assetId
							);
							//var assetId = asset.assetId;

							ser.spanGaps = true;
							Object.assign(
								ser.dataItem,
								this.props?.currentData?.[assetId]?.[
									ser.dataItem.dataItemId
								]
							);
							var labelPrefix =
								ser.slotPath?.length > 0
									? (asset?.assetAlias ??
											asset?.serialNumber) + " "
									: "";
							return {
								stroke: this.cleanColor(ser.color),
								fill: this.cleanColor(ser.fill),
								scale: ser.axis.label,
								dash: ser.line == "dash" ? [5, 5] : undefined,
								spanGaps: true,
								smooth: ser.smooth,

								label: getFormattedLabel(
									this.props.definition,
									this.props.system,
									this.props.assets,
									ser.dataItem.dataItemId,
									ser.slotPath
								),
								fillTo: -999999,
								// fill:(uplot,seriesIdx)=>{
								//     //return "blue"
								// },
								paths:
									ser.line == "step"
										? SteppedLine
										: undefined,
							};
						})
					),
				};

				var thresh =
					(this.props.properties.decimation ?? 20) < 100
						? (this.props.properties.decimation ?? 20) * 30
						: -1;
				var url = `/api/historicaldata?columnar=true&threshold=${thresh}&dataItems=${this.props.properties?.series?.map(
					(di) =>
						getAssetIdFromSystem(null, di.slotPath) +
						"-" +
						di.dataItem.dataItemId
				)}`;
				//const lastLoad = (new Date(new Date() - (this.props.properties.fixedStart??.5)*3600*1000)).toJSON()
				const lastLoad =
					this.props.playback.startDate ??
					new Date(
						new Date() -
							(this.props.properties.fixedStart ?? 0.5) *
								3600 *
								1000
					);
				this.setState({ url, lastLoad, opts }, () => {
					this.fetchData();
				});
			}
		} catch (error) {
			console.log(error);
		}
	}
	deepCopyObject = (obj) => {
		let tempObj = {};
		for (let [key, value] of Object.entries(obj)) {
			if (Array.isArray(value)) {
				tempObj[key] = this.deepCopy(value);
			} else {
				if (typeof value === "object") {
					tempObj[key] = this.deepCopyObject(value);
				} else {
					tempObj[key] = value;
				}
			}
		}
		return tempObj;
	};
	deepCopy = (arr) => {
		let copy = [];
		arr.forEach((elem) => {
			if (Array.isArray(elem)) {
				copy.push(this.deepCopy(elem));
			} else {
				if (typeof elem === "object") {
					copy.push(this.deepCopyObject(elem));
				} else {
					copy.push(elem);
				}
			}
		});
		return copy;
	};

	componentWillUnmount() {
		clearTimeout(this.fetchTimeout);
	}
	fetchData(reseed) {
		const { properties } = this.props;
		const end = this.props.ui.showPlayback
			? this.props.playback.endDate
			: new Date();

		var startTime = reseed
			? this.props.playback.startDate ??
			  new Date(
					new Date() -
						(this.props.properties.fixedStart ?? 0.5) * 3600 * 1000
			  )
			: this.state.lastLoad;
		if (reseed) {
			this.setState({ loading: true });
		}
		if (!this.controller?.signal?.aborted) {
			this.controller.abort();
		}
		this.controller = new AbortController();
		fetch(
			this.state.url +
				`&startTime=${startTime.toJSON()}&endTime=${end.toJSON()}`,
			{ signal: this.controller.signal }
		)
			.then((res) => res.json())
			.then((data) => {
				try {
					var newData;
					if (
						this.state.data == undefined ||
						reseed ||
						this.state.data[0].length == 0
					) {
						newData = data;
					} else {
						var newData = [...this.state.data];
						for (var i = 0; i < data.length; i++) {
							newData[i] = newData[i].concat(data[i]);
						}
					}

					//this.fetchTimeout=setTimeout(()=>{this.fetchData()},properties.refreshInterval * 1000)
					this.setState(
						{
							data: newData,
							loading: false,
							lastLoad:
								data[0].length > 0 ? end : this.state.lastLoad,
						},
						() => {
							if (
								properties.refreshData &&
								!this.props.ui.showPlayback
							) {
								this.fetchTimeout = setTimeout(() => {
									this.fetchData();
								}, properties.refreshInterval * 1000);
							}
						}
					);
				} catch (e) {
					this.fetchTimeout = setTimeout(() => {
						this.fetchData();
					}, properties.refreshInterval * 1000 * 5);
				}
			})
			.catch(() => {});
	}
	sort2DarrayByFirstArray(data) {
		var originalOrder = data.map((el, i) => {
			return { el, i };
		});
		originalOrder.sort((e1, e2) => e2.el - e1.el);
		var pointers = originalOrder.map((og) => og.i);
		var newData = [];
		for (var i = 0; i < data.length; i++) {
			newData[i] = [];
			for (var j = 0; j < pointers.length; j++) {
				newData[i][j] = data[i][pointers[j]];
			}
		}
		return newData;
	}
	shouldComponentUpdate(nextProps) {
		return !this.state.userZoom;
	}
	emptyArr(length) {
		var arr = [];
		for (var i = 0; i < length; i++) {
			arr.push([]);
		}
		return arr;
	}
	render() {
		try {
			if (this.state.opts && this.state.data) {
				return (
					<div
						style={{
							display: "flex",
							height: "100%",
							flexDirection: "column",
						}}
					>
						{this.state.loading && <Spinner></Spinner>}
						{this.props.ui.viewOnly && (
							<div
								style={{
									position: "absolute",
									top: "0px",
									right: "0px",
								}}
							>
								<IconButton
									title="Refresh Graph"
									styles={{
										root: { height: 18 },
										menuIcon: { fontSize: 10 },
										icon: { fontSize: 10 },
									}}
									style={{ zIndex: 10 }}
									iconProps={{ iconName: "refresh" }}
									onClick={() => {
										clearTimeout(this.fetchTimeout);
										this.fetchData(true);
									}}
								/>
								<IconButton
									title="Export raw data"
									styles={{
										root: { height: 18 },
										menuIcon: { fontSize: 10 },
										icon: { fontSize: 10 },
									}}
									style={{ zIndex: 10 }}
									iconProps={{ iconName: "download" }}
									onClick={() => {
										let csvContent =
											"data:text/csv;charset=utf-8,";
										var headers = [
											"timestamp",
											...this.props.properties.series.map(
												(el) => el.dataItem.label
											),
										];
										var csvData = [];
										for (var i in this.state.data) {
											for (var j in this.state.data[i]) {
												if (!csvData[j])
													csvData[j] = [];
												csvData[j][i] =
													this.state.data[i][j];
											}
										}
										csvContent += headers.join(",");
										csvContent += "\n";
										csvContent += Papa.unparse(csvData);
										var encodedUri = encodeURI(csvContent);
										var link = document.createElement("a");
										link.setAttribute("href", encodedUri);
										link.setAttribute(
											"download",
											"export.csv"
										);
										document.body.appendChild(link); // Required for FF
										link.click();
									}}
								/>
								{!this.state.userZoom ? (
									<IconButton
										style={{ zIndex: 10 }}
										styles={{
											root: { height: 18 },
											menuIcon: { fontSize: 10 },
											icon: { fontSize: 10 },
										}}
										title="Stop re-drawing graph"
										iconProps={{ iconName: "pause" }}
										onClick={() => {
											this.setState({ userZoom: true });
										}}
									/>
								) : (
									<IconButton
										style={{ zIndex: 10 }}
										styles={{
											root: { height: 18 },
											menuIcon: { fontSize: 10 },
											icon: { fontSize: 10 },
										}}
										title="Continue drawing graph"
										iconProps={{ iconName: "play" }}
										onClick={() => {
											this.setState({ userZoom: false });
										}}
									/>
								)}
							</div>
						)}
						<div
							style={{
								height: "100%",
								width: "100%",
								visibility: this.state.loading
									? "hidden"
									: "inherit",
							}}
						>
							<UPlotComponent
								opts={this.state.opts}
								data={
									this.state.data.length ==
									this.props.properties.series.length + 1
										? this.state.data
										: this.emptyArr(
												this.props.properties.series
													.length + 1
										  )
								}
							/>
						</div>
					</div>
				);
			} else {
				return <></>;
			}
		} catch (error) {
			console.log("ERROR RENDERING CHART", error);
			return <>{error.toString()}</>;
		}
	}
}
function mapStateToProps({ currentData, playback, ui, assets, definition }) {
	return {
		currentData,
		definition,
		playback,
		ui,
		assets,
	};
}

export default connect(mapStateToProps)(FastGraph);
