import { connect } from "react-redux";
import {
	Icon,
	IconButton,
	Text,
	Stack,
	SearchBox,
	TextField,
	Checkbox,
} from "@fluentui/react";
import React from "react";
import { guid } from "../util";
class SystemDataItemSelector extends React.Component {
	state = {
		hideMenu: true,
	};
	ref = React.createRef();

	// componentWillReceiveProps(){
	//     console.log(this.props)
	//     this.setState({slotPath:this.props.slotPath,dataItem:this.props.dataItem})
	// }
	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.slotPath == undefined &&
			this.props.slotPath != undefined
		) {
			this.setState({ slotPath: this.props.slotPath });
		}
		if (
			prevProps.dataItem == undefined &&
			this.props.dataItem != undefined
		) {
			this.setState({ dataItem: this.props.dataItem });
		}
	}
	componentDidMount() {
		this.setState({ slotPath: this.props.slotPath });
		this.setState({ dataItem: this.props.dataItem });
	}
	formatString() {
		if (!this.state.dataItem || !this.state.slotPath) {
			return "Choose data item";
		}
		var definition = this.props.definition;
		var str = "";
		for (var x in this.state.slotPath) {
			definition = definition.slots?.find(
				(el) => el.slotId == this.state.slotPath[x]
			);
			if (!definition) break;
			str += definition.name;
			str += " > ";
		}
		str += this.state?.dataItem?.label;
		return str;
	}
	getMenuItem(definition, slotPath) {
		if (!slotPath) slotPath = [];
		var item = {
			key: guid(),
			shouldFocusOnMount: true,
			title: definition.dataItemDefinitions ? "Data items" : "Slots",
			subMenuHoverDelay: 0,
		};
		if (definition.slots) {
			item.items = [];
			for (var x in definition.slots) {
				if (definition.slots[x].assetDefinition) {
					var newItem = this.getMenuItem(
						definition.slots[x].assetDefinition,
						[...slotPath, definition.slots[x].slotId]
					);
					newItem.text = definition.slots[x].name;
					item.items.push(newItem);
				} else if (definition.slots[x].systemDefinition) {
					var newItem = this.getMenuItem(
						definition.slots[x].systemDefinition,
						[...slotPath, definition.slots[x].slotId]
					);
					newItem.text = definition.slots[x].name;
					item.items.push(newItem);
				}
			}
		} else if (definition.dataItemDefinitions) {
			item.items = [];
			var dids = definition.dataItemDefinitions;
			for (var x in dids) {
				let did = { ...dids[x] };
				item.items.push({
					key: guid(),
					text: this.props.multiSelect ? undefined : did.label,
					onClick: this.props.multiSelect
						? undefined
						: () => {
								this.setState({ slotPath, dataItem: did });
								if (this.props.onDataItemSelected) {
									this.props.onDataItemSelected({
										slotPath,
										dataItem: did,
									});
								}
						  },
					onRender: this.props.multiSelect
						? () => {
								return (
									<Stack horizontal>
										<Checkbox label={did.label}></Checkbox>
									</Stack>
								);
						  }
						: undefined,
				});
			}
		}
		return item;
	}
	render() {
		return (
			<Stack tokens={{ childrenGap: 3 }}>
				{this.props.label && <Text>{this.props.label}</Text>}
				<Stack
					horizontal
					horizontalAlign="space-between"
					verticalAlign="center"
					style={{ border: "1px solid rgb(208, 208, 208)" }}
				>
					<Text style={{ marginLeft: "5px" }}>
						{" "}
						{this.formatString()}
					</Text>
					<IconButton
						//iconName="ChevronDown"
						menuProps={this.getMenuItem(this.props.definition, [])}
						subMenuHoverDelay={0}
						subMenuProps={{ subMenuHoverDelay: 0 }}
						subMenuHoverDelay={0}
						subMenuProps={{ subMenuHoverDelay: 0 }}
					/>
				</Stack>
			</Stack>
		);
	}
}
function mapStateToProps({ definition }) {
	return { definition };
}
export default connect(mapStateToProps)(SystemDataItemSelector);
