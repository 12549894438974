import { Checkbox, TextField } from "office-ui-fabric-react";
import { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import PopoutColorPicker from "../../../../components/PopoutColorPicker";
import SystemDataItemSelector from "../../../../components/SystemDataItemSelector";

export default function CyclinderGaugeForm(props) {
	let tileProps = props.properties;
	return (
		<div>
			<SystemDataItemSelector
				onDataItemSelected={(val) => {
					props.addData({
						dataItem: val.dataItem,
						slotPath: val.slotPath,
					});
				}}
				dataItem={tileProps.dataItem}
				slotPath={tileProps.slotPath}
			></SystemDataItemSelector>

			<TextField
				value={tileProps.name}
				type="text"
				onChange={(e, val) => {
					props.addData({ name: val });
				}}
				label="Name"
			/>
			<TextField
				value={tileProps.min}
				type="number"
				onChange={(e, val) => {
					props.addData({ min: val });
				}}
				label="Min"
			/>
			<TextField
				value={tileProps.max}
				type="number"
				onChange={(e, val) => {
					props.addData({ max: val });
				}}
				label="Max"
			/>
			<PopoutColorPicker
				label={"Fill color"}
				color={tileProps.color}
				onChange={(e, val) => props.addData({ color: val.str })}
			></PopoutColorPicker>
			<PopoutColorPicker
				label={"Border color"}
				color={tileProps.strokeColor ?? "#aaa"}
				onChange={(e, val) => props.addData({ strokeColor: val.str })}
			></PopoutColorPicker>
		</div>
	);
}
