import React, { Component } from "react";
import ReactQuill from "react-quill"; // ES6
import { connect } from "react-redux";
import SystemDataItemSelector from "../../../../components/SystemDataItemSelector";
import { slotIdPathToSlotNamePath } from "../../../../util";

const Quill = ReactQuill.Quill;
var Font = Quill.import("formats/font");
Font.whitelist = ["Proxima Nova"];
Quill.register(Font, true);

class TextBoxForm extends Component {
	state = { ...this.props.ui.modal.tile.properties };

	modules = {
		toolbar: [
			[{ size: ["small", false, "large", "huge"] }], // custom dropdown
			["bold", "italic", "underline"],
			["link", "image"],
			//[{ 'list': 'ordered'}, { 'list': 'bullet' }],
			//[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
			[{ color: [] }, { background: [] }], // dropdown with defaults from theme
			[{ align: [] }],
			["clean"],
		],
	};
	componentDidMount() {
		const { currentData } = this.props;
	}

	handleChange = (e) => {
		const { addData } = this.props;
		this.setState({ text: e.htmlValue });
	};

	addText = (string) => {
		const { text } = this.state;
		this.setState({ text: text + string });
	};

	render() {
		const { text } = this.state;
		const { addData } = this.props;
		return (
			<section className="component-form">
				<ReactQuill
					style={{ height: "300px", marginBottom: "50px" }}
					ref={(el) => {
						this.reactQuillRef = el;
					}}
					modules={this.modules}
					value={text}
					onChange={(value, a, b) => {
						this.setState({ text: value }, () => {
							addData({ ...this.state });
						});
					}}
					onChangeSelection={(range, source, editor) => {
						// try{
						//   this.setState({range})
						// }catch{}
					}}
				/>

				<SystemDataItemSelector
					onDataItemSelected={(val) => {
						var slotNamePath = slotIdPathToSlotNamePath(
							val.slotPath
						);
						slotNamePath.push(val.dataItem.label);
						var pathString = `{{${slotNamePath.join(".")}}}`;
						this.reactQuillRef
							.getEditor()
							.insertText(
								this.state.range?.index ?? 0,
								pathString
							);
						var substitutions =
							{ ...this.state.substitutions } ?? {};
						substitutions[slotNamePath.join(".")] = val;
						for (var x in substitutions) {
							delete substitutions[x].definition;
						}
						console.log(substitutions);
						this.setState(
							{ substitutions: { ...substitutions } },
							() => {
								console.log(this.state.substitutions);
								addData({ ...this.state });
							}
						);
					}}
				></SystemDataItemSelector>
			</section>
		);
	}
}

function mapStateToProps({ ui }) {
	return { ui };
}
export default connect(mapStateToProps)(TextBoxForm);
