import React, { Component } from 'react';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import MyColorPicker from './MyColorPicker';
import * as am4core from "@amcharts/amcharts4/core";

const colorSet = new am4core.ColorSet();


class MeanBoxChartForm extends Component {
  state = {
    dataItem: null,
    title:"",
    color: colorSet.getIndex(0).hex.substring(1),
  }

  dataItems = this.props.data.dataItemDefinitions.array;

  componentDidMount() {
      console.log("Mean Box form")
    // populates fields when editing
    const { tile } = this.props.ui.modal;
    if(tile && !tile.properties.type.default) {
      const { dataItem, title, color } = tile.properties;
      this.setState({
        dataItem,
        title,
        color
      });
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    const {addData} = this.props;
    this.setState({[e.target.name]: e.target.value}, () =>{
      addData(this.state);
    });
  }

  render() {
    const { title, dataItem, color } = this.state;

    return (
      <div className='component-form'>
        <Dropdown
          style={{width:'100%'}}
          optionLabel={'label'}
          name='dataItem'
          value={dataItem}
          onChange={this.handleChange}
          options={this.dataItems}
          placeholder='Select a data item'
          filter={true}
          filterBy='label'
          required={true}
        />
        <span className='p-float-label'>
          <InputText
            value={title}
            type='text'
            onChange={this.handleChange}
            name='title'
          />
          <label htmlFor='title'>Title</label>
        </span>
        <MyColorPicker color={color} handleChange={this.handleChange} label='Color:' name='color'/>
      </div>
    );
  }
}

function mapStateToProps({data, ui}) {
  return {data, ui}
}

export default connect(mapStateToProps)(MeanBoxChartForm);

// <span className='color-picker-color'>{band3}</span>
