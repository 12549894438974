import {
	ActionButton,
	Checkbox,
	CommandBar,
	DefaultButton,
	Dropdown,
	Icon,
	IconButton,
	Panel,
	PrimaryButton,
	SearchBox,
	Separator,
	Spinner,
	SpinnerSize,
	Stack,
	Text,
	TextField,
	TooltipHost,
	getTheme,
} from "@fluentui/react";
import React from "react";
import { connect } from "react-redux";
import UserConfiguration from "../../components/UserConfiguration";
import { httpErrorHandler } from "../../util/index";

const defaultPermissions = [
	{ type: "Admin", view: false, create: false, delete: false, modify: false },
	{
		type: "Permissions",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{
		type: "Assets",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{ type: "Users", view: false, create: false, delete: false, modify: false },
	{
		type: "UserGroups",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{
		type: "AssetGroups",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{ type: "Jobs", view: false, create: false, delete: false, modify: false },
	{
		type: "AdminUnits",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{
		type: "CurrentDataItems",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{
		type: "HistoricalDataItems",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{
		type: "Alarms",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{
		type: "Events",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{
		type: "Coordinates",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{
		type: "Attributes",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{
		type: "Dashboard",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{
		type: "Commands",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{
		type: "Alerts",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{
		type: "Formulas",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{
		type: "AssetDefinitions",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{
		type: "SystemDefinitions",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{
		type: "Slots",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{
		type: "Systems",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{
		type: "SlotAssignments",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{
		type: "DataItems",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{ type: "Files", view: false, create: false, delete: false, modify: false },
	{
		type: "CustomPermission1",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{
		type: "CustomPermission2",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
	{
		type: "CustomPermission3",
		view: false,
		create: false,
		delete: false,
		modify: false,
	},
];

class Ownership extends React.Component {
	state = {
		adminUnits: [],
		alarms: [],
		newUsers: [],
		loading: true,
		usersFilter: "",
		assetsFilter: "",
		userGroupsFilter: "",
		assetGroupsFilter: "",
		tab: "assets",
		selectedAdminUnit: this.props.auth.auth.user.adminId,
		editablePermissions: defaultPermissions,
		originalPermissions: defaultPermissions,
	};
	userGroupRefs = new Map();
	userRefs = new Map();
	userUserGroupPaths = React.createRef();
	componentDidMount() {
		this.makeTrees();
		this.getAdminUnits();
		//setInterval(()=>{this.drawPaths()},1000)
		fetch("/config/alarms.json")
			.then((data) => data.json())
			.then((alarms) => this.setState({ alarms }));
	}
	getAdminUnits() {
		fetch(`/api/ef/admin/adminunits`)
			.then((r) => r.json())
			.then((au) =>
				this.setState({
					selectedAdminUnit: au[0].adminId,
					adminUnits: au,
				})
			);
	}
	makeTrees() {
		fetch(
			`/api/ef/admin/users/ownership?adminId=${
				this.state.selectedAdminUnit ?? 1
			}`
		)
			.then((response) => {
				if (response.status == 401) {
					const { history } = this.props;
					if (history) history.push("/login");
					return null;
				} else if (response.status == 200) return response.json();
			})
			.then((data) => {
				if (data)
					this.setState(
						{
							loading: false,
							original: data,
							filtered: data,
							permissionsChanged: false,
						},
						() => {
							this.filter();
						}
					);
			})
			.catch(httpErrorHandler);
	}
	filter() {
		var og = { ...this.state.original };
		var users = og.users;
		var userGroups = og.userGroups;
		var assetGroups = og.assetGroups;
		var assets = og.assets;
		let { usersFilter, userGroupsFilter, assetGroupsFilter, assetsFilter } =
			{ ...this.state };
		og.users = users.filter((user) => {
			return user?.username
				?.toLowerCase()
				.includes(usersFilter.toLowerCase());
		});
		og.userGroups = userGroups.filter((usergroup) => {
			return usergroup?.userGroupName
				?.toLowerCase()
				.includes(userGroupsFilter.toLowerCase());
		});
		og.assetGroups = assetGroups.filter((assetgroup) => {
			return assetgroup?.assetGroupName
				?.toLowerCase()
				.includes(assetGroupsFilter.toLowerCase());
		});
		og.assets = assets.filter((ass) => {
			return (
				ass?.assetAlias
					?.toLowerCase()
					.includes(assetsFilter.toLowerCase()) ||
				ass?.serialNumber
					?.toLowerCase()
					.includes(assetsFilter.toLowerCase()) ||
				ass?.assetDefinition?.assetDefinitionName
					?.toLowerCase()
					.includes(assetsFilter.toLowerCase())
			);
		});
		this.setState({ filtered: og }, this.RelationsByLastSelection);
	}
	RelationsByLastSelection() {
		switch (this.state.relationOrder) {
			case "user":
				this.RelationsByUser(this.state.relationId);
				return;
			case "userGroup":
				this.RelationsByUserGroup(this.state.relationId);
				return;
			case "asset":
				this.RelationsByAsset(this.state.relationId);
				return;
			case "assetGroup":
				this.RelationsByAssetGroup(this.state.relationId);
				return;
		}
	}
	RelationsByAssetGroup(id) {
		var cp = { ...this.state.original };

		//Mark Selected asset groups
		var assetGroups = [id];
		cp.assetGroups.forEach((element) => {
			element.selected = assetGroups.indexOf(element.assetGroupId) >= 0;
			element.removable = false;
		});

		//Mark Selected Assets
		var assets = [];
		cp.assetGroupAssets.forEach((element) => {
			element.selected = assetGroups.indexOf(element.assetGroupId) >= 0;
			if (element.selected) assets.push(element.assetId);
		});
		cp.assets.forEach((element) => {
			element.selected = assets.indexOf(element.assetId) >= 0;
			//Assets can be removed from asset groups
			element.removable = assets.indexOf(element.assetId) >= 0;
			element.onRemove = () => {
				fetch(`/api/assetgroups/${id}/assets`, {
					method: "delete",
					body: JSON.stringify([element.assetId]),
				}).then(() => {
					this.makeTrees();
				});
				console.log(`Remove asset ${element.assetId} from group ${id}`);
			};
			element.addable = assets.indexOf(element.assetId) == -1;
			element.onAdd = () => {
				fetch(`/api/assetgroups/${id}/assets`, {
					method: "put",
					body: JSON.stringify([element.assetId]),
				}).then(() => {
					this.makeTrees();
				});
				console.log(`add asset ${element.assetId} to group ${id}`);
			};
		});

		//Mark Selected User Groups
		var userGroups = [];
		cp.userGroupAssetGroups.forEach((element) => {
			element.selected = assetGroups.indexOf(element.assetGroupId) >= 0;
			if (element.selected) userGroups.push(element.userGroupId);
		});
		cp.userGroups.forEach((element) => {
			element.selected = userGroups.indexOf(element.userGroupId) >= 0;
			element.removable = userGroups.indexOf(element.userGroupId) >= 0;
			element.onRemove = () => {
				fetch(`/api/usergroups/${element.userGroupId}/assetgroups`, {
					method: "delete",
					body: JSON.stringify([id]),
				}).then(() => {
					this.makeTrees();
				});
			};
			element.addable = userGroups.indexOf(element.userGroupId) == -1;
			element.onAdd = () => {
				fetch(`/api/usergroups/${element.userGroupId}/assetgroups`, {
					method: "put",
					body: JSON.stringify([id]),
				}).then(() => {
					this.makeTrees();
				});
			};
		});

		//Mark Selects Users
		var users = [];
		cp.userGroupUsers.forEach((element) => {
			element.selected = userGroups.indexOf(element.userGroupId) >= 0;
			if (element.selected) users.push(element.userId);
		});
		cp.users.forEach((element) => {
			element.selected = users.indexOf(element.userId) >= 0;
			element.removable = false;
			element.addable = false;
		});
		this.setState({
			original: cp,
			relationOrder: "assetGroup",
			relationId: id,
			editablePermissions: defaultPermissions,
		});
	}
	RelationsByUser(id) {
		var cp = { ...this.state.original };
		var permissionHeader;
		//Mark Selected Users
		var users = [id];
		cp.users.forEach((element) => {
			element.selected = users.indexOf(element.userId) >= 0;
			element.removable = false;
			element.addable = false;
			if (element.selected) {
				permissionHeader = element.username;
			}
		});

		//Mark Selected User Groups
		var userGroups = [];
		var editablePermissions = [];
		cp.userGroupUsers.forEach((element) => {
			element.selected = element.userId == id;
			if (element.selected) userGroups.push(element.userGroupId);
		});
		cp.userGroups.forEach((element) => {
			element.selected = userGroups.indexOf(element.userGroupId) >= 0;
			element.removable = userGroups.indexOf(element.userGroupId) >= 0;
			element.onRemove = () => {
				fetch(`/api/usergroups/${element.userGroupId}/users`, {
					method: "delete",
					body: JSON.stringify([id]),
				}).then(() => {
					this.makeTrees();
				});
			};
			element.addable = userGroups.indexOf(element.userGroupId) == -1;
			element.onAdd = () => {
				fetch(`/api/usergroups/${element.userGroupId}/users`, {
					method: "put",
					body: JSON.stringify([id]),
				}).then(() => {
					this.makeTrees();
				});
			};
			if (element.selected) {
				var extraPerms = element.permissionGroups;
				for (var i = 0; i < extraPerms.length; i++) {
					var existingType = editablePermissions.find((el) => {
						return el.type == extraPerms[i].type;
					});
					if (existingType != null) {
						existingType.view =
							existingType.view || extraPerms[i].view;
						existingType.create =
							existingType.create || extraPerms[i].create;
						existingType.delete =
							existingType.delete || extraPerms[i].delete;
						existingType.modify =
							existingType.modify || extraPerms[i].modify;
					} else {
						editablePermissions.push({ ...extraPerms[i] });
					}
				}
			}
		});

		//Mark Selected AssetGroups
		var assetGroups = [];
		cp.userGroupAssetGroups.forEach((element) => {
			element.selected = userGroups.indexOf(element.userGroupId) >= 0;
			if (element.selected) assetGroups.push(element.assetGroupId);
		});
		cp.assetGroups.forEach((element) => {
			element.selected = assetGroups.indexOf(element.assetGroupId) >= 0;
			var alerts = cp.alerts.filter(
				(al) =>
					userGroups.indexOf(al.userGroupId) >= 0 &&
					al.assetGroupId == element.assetGroupId
			);
			element.alert = alerts?.sort(
				(a, b) => a.severityMin - b.severityMin
			)[0];
			element.removable = false;
			element.addable = false;
		});

		//MarkSelected Assets
		var assets = [];
		cp.assetGroupAssets.forEach((element) => {
			element.selected = assetGroups.indexOf(element.assetGroupId) >= 0;
			if (element.selected) assets.push(element.assetId);
		});
		cp.assets.forEach((element) => {
			element.selected = assets.indexOf(element.assetId) >= 0;
			element.removable = false;
			element.addable = false;
		});
		this.setState({
			viewIndeterminate: true,
			createIndeterminate: true,
			deleteIndeterminate: true,
			modifyIndeterminate: true,
			original: cp,
			relationOrder: "user",
			relationId: id,
			permissionHeader,
			originalPermissions: [...editablePermissions],
			editablePermissions: [...editablePermissions],
		});
	}

	RelationsByUserGroup(id) {
		var cp = { ...this.state.original };

		//Mark Selected User Groups
		var userGroups = [id];
		var editablePermissions = [];
		var permissionHeader;
		cp.userGroups.forEach((element) => {
			element.selected = userGroups.indexOf(element.userGroupId) >= 0;
			element.removable = false;
			if (element.selected) {
				editablePermissions = element.permissionGroups.map((el) => {
					return { ...el };
				});
				permissionHeader = element.userGroupName;
			}
		});
		//Mark Selected AssetGroups
		var assetGroups = [];
		cp.userGroupAssetGroups.forEach((element) => {
			element.selected = userGroups.indexOf(element.userGroupId) >= 0;
			if (element.selected) assetGroups.push(element.assetGroupId);
		});
		cp.assetGroups.forEach((element) => {
			element.selected = assetGroups.indexOf(element.assetGroupId) >= 0;
			element.removable = assetGroups.indexOf(element.assetGroupId) >= 0;
			const assetGroupId = element.assetGroupId;
			element.onRemove = () => {
				console.log(element.assetGroupId);
				fetch(`/api/usergroups/${id}/assetgroups`, {
					method: "delete",
					body: JSON.stringify([element.assetGroupId]),
				}).then(() => {
					this.makeTrees();
				});
				console.log(
					`Remove assetGroup ${element.assetGroupId} from group ${id}`
				);
			};
			element.alert = cp.alerts.find(
				(alert) =>
					alert.userGroupId == id &&
					alert.assetGroupId == assetGroupId
			);
			element.addable = assetGroups.indexOf(element.assetGroupId) == -1;
			element.onAdd = () => {
				fetch(`/api/usergroups/${id}/assetgroups`, {
					method: "put",
					body: JSON.stringify([element.assetGroupId]),
				}).then(() => {
					this.makeTrees();
				});
				console.log(
					`Add assetGroup ${element.assetGroupName} to ${id}`
				);
			};
			element.setAlertLevel = (severity) => {
				console.log(element.assetGroupId);
				const genericAlert = {
					assetGroupId: element.assetGroupId.toString(),
					userGroupId: id,
					severityMin: severity,
					severityMax: 10,
					status: "open",
					online: false,
					offline: false,
				};
				fetch("/api/ef/genericalerts", {
					method: "put",
					body: JSON.stringify([genericAlert]),
					headers: { "Content-Type": "application/json" },
				})
					.then((data) => data.json())
					.then((json) => this.makeTrees());
				console.log(
					`User Group ${id} gets alerts for asset group ${element.assetGroupId} on severity ${severity}`
				);
			};
		});

		//MarkSelected Assets
		var assets = [];
		cp.assetGroupAssets.forEach((element) => {
			element.selected = assetGroups.indexOf(element.assetGroupId) >= 0;
			if (element.selected) assets.push(element.assetId);
		});
		cp.assets.forEach((element) => {
			element.selected = assets.indexOf(element.assetId) >= 0;
			element.removable = false;
			element.addable = false;
		});
		//Mark Selects Users
		var users = [];
		cp.userGroupUsers.forEach((element) => {
			element.selected = userGroups.indexOf(element.userGroupId) >= 0;
			if (element.selected) users.push(element.userId);
		});
		cp.users.forEach((element) => {
			element.selected = users.indexOf(element.userId) >= 0;
			element.removable = users.indexOf(element.userId) >= 0;
			element.onRemove = () => {
				fetch(`/api/usergroups/${id}/users`, {
					method: "delete",
					body: JSON.stringify([element.userId]),
				}).then(() => {
					this.makeTrees();
				});
			};
			element.addable = users.indexOf(element.userId) == -1;
			element.onAdd = () => {
				fetch(`/api/usergroups/${id}/users`, {
					method: "put",
					body: JSON.stringify([element.userId]),
				}).then(() => {
					this.makeTrees();
				});
			};
		});
		this.setState({
			viewIndeterminate: true,
			createIndeterminate: true,
			deleteIndeterminate: true,
			modifyIndeterminate: true,
			original: cp,
			relationOrder: "userGroup",
			relationId: id,
			permissionHeader,
			originalPermissions: editablePermissions.map((a) => ({ ...a })),
			editablePermissions: editablePermissions,
		});
	}
	RelationsByAsset(id) {
		var cp = { ...this.state.original };

		var assets = [id];
		cp.assets.forEach((element) => {
			element.selected = assets.indexOf(element.assetId) >= 0;
			element.removable = false;
		});
		//Mark Selected AssetGroups
		var assetGroups = [];
		cp.assetGroupAssets.forEach((element) => {
			element.selected = assets.indexOf(element.assetId) >= 0;
			if (element.selected) assetGroups.push(element.assetGroupId);
		});
		cp.assetGroups.forEach((element) => {
			element.selected = assetGroups.indexOf(element.assetGroupId) >= 0;
			element.removable = assetGroups.indexOf(element.assetGroupId) >= 0;
			element.onRemove = () => {
				fetch(`/api/assetgroups/${element.assetGroupId}/assets`, {
					method: "delete",
					body: JSON.stringify([id]),
				}).then(() => {
					this.makeTrees();
				});
				console.log(
					`Remove asset ${id} from group ${element.assetGroupId}`
				);
			};
			element.addable = assetGroups.indexOf(element.assetGroupId) == -1;
			element.onAdd = () => {
				fetch(`/api/assetgroups/${element.assetGroupId}/assets`, {
					method: "put",
					body: JSON.stringify([id]),
				}).then(() => {
					this.makeTrees();
				});
				console.log(`Add asset ${id} to group ${element.assetGroupId}`);
			};
			element.setAlertLevel = undefined;
		});
		//Mark Select User Groups
		var userGroups = [];
		cp.userGroupAssetGroups.forEach((element) => {
			element.selected = assetGroups.indexOf(element.assetGroupId) >= 0;
			if (element.selected) userGroups.push(element.userGroupId);
		});
		cp.userGroups.forEach((element) => {
			element.selected = userGroups.indexOf(element.userGroupId) >= 0;
			element.removable = false;
			element.addable = false;
		});

		//Mark Selects Users
		var users = [];
		cp.userGroupUsers.forEach((element) => {
			element.selected = userGroups.indexOf(element.userGroupId) >= 0;
			if (element.selected) users.push(element.userId);
		});
		cp.users.forEach((element) => {
			element.selected = users.indexOf(element.userId) >= 0;
			element.removable = false;
			element.addable = false;
		});
		this.setState({
			original: cp,
			relationOrder: "asset",
			relationId: id,
			editablePermissions: defaultPermissions,
		});
	}

	deleteUser(userId) {
		fetch(`/api/users/${userId}`, { method: "delete" }).then(() => {
			this.makeTrees();
		});
	}
	addUser(username) {
		var user = {
			userId: 0,
			username: username,
			password: "",
			adminId: this.state.selectedAdminUnit ?? 1,
			email: true,
		};
		var newUsers = [...this.state.newUsers];
		newUsers.push(username);
		this.setState({ newUsers: newUsers });
		fetch(`/api/users?sendEmail=false`, {
			method: "post",
			body: JSON.stringify(user),
			headers: {
				"Content-Type": "application/json",
			},
		}).then(() => {
			this.makeTrees();
		});
	}
	deleteUserGroup(id) {
		fetch(`/api/usergroups/${id}`, { method: "delete" }).then(() => {
			this.makeTrees();
		});
	}

	addUserGroup(name) {
		var userGroup = {
			userGroupId: 0,
			userGroupName: name,
			adminId: this.state.selectedAdminUnit ?? 1,
			description: "",
			parentGroupId: 1,
		};
		fetch(`/api/usergroups`, {
			method: "post",
			body: JSON.stringify(userGroup),
			headers: {
				"Content-Type": "application/json",
			},
		}).then(() => {
			this.makeTrees();
		});
	}

	deleteAssetGroup(id) {
		fetch(`/api/assetgroups/${id}`, { method: "delete" }).then(() => {
			this.makeTrees();
		});
	}

	addAssetGroup(name) {
		var assetGroup = {
			assetGroupId: 0,
			assetGroupName: name,
			description: "",
			parentGroupId: 1,
			adminId: this.state.selectedAdminUnit ?? 1,
		};
		fetch(`/api/assetgroups`, {
			method: "post",
			body: JSON.stringify(assetGroup),
			headers: {
				"Content-Type": "application/json",
			},
		}).then(() => {
			this.makeTrees();
		});
	}
	updateEditablePermission(type, method, val) {
		var perms = [...this.state.editablePermissions];
		perms.find((el) => el.type == type)[method] = val;
		this.setState({ editablePermissions: perms, permissionsChanged: true });
	}
	applyPermissions() {
		fetch(
			`/api/usergroups/${this.state.relationId}/permissions/groupedlist`,
			{
				method: "post",
				body: JSON.stringify(this.state.editablePermissions),
				headers: { "Content-Type": "application/json" },
			}
		).then(() => {
			this.makeTrees();
		});
	}
	drawPaths() {
		var x = this.state.original?.userGroupUsers
			?.filter((el) => el.selected)
			.map((el) => {
				var start =
					this.userRefs[el.userId]?.current?.getBoundingClientRect();
				var end =
					this.userGroupRefs[
						el.userGroupId
					]?.current?.getBoundingClientRect();
				var d = `M${start?.x + start?.width},${start?.y}L${end?.x},${
					end?.y
				}`;
				return <path stroke="red" d={d} />;
			});
		this.setState({ userUserGroupPaths: x });
	}
	getAlarmColor(severity) {
		return this.state.alarms.find((el) => el.severity == severity)?.color;
	}
	render() {
		var theme = getTheme();
		var standardStyle = {
			backgroundColor: theme.palette.neutralLighter,
			border: "1px solid transparent",
		};
		var relatedStyle = {
			backgroundColor: theme.palette.neutralLighter,
			border: `1px solid ${theme.palette.themePrimary}`,
		};
		var selectedStyle = {
			backgroundColor: theme.palette.themePrimary,
			color: theme.palette.white,
			border: "1px solid transparent",
		};
		let { users, userGroups, assetGroups, assets } = {
			...this.state.filtered,
		};
		// return <div>{Object.keys(this.state.data ?? {})?.map(
		//     (key,index)=>{
		//     return <div style={{display:"inline-block",verticalAlign:"top"}}><h3>{key}</h3><table >{this.state.data[key]?.map(
		//         el=>{return <tr onClick style={{backgroundColor:el.selected?"green":"transparent"}}>{Object.keys(el)?.map((key, index) => {return <td>{el[key]}</td>; })}</tr>})}
		//     </table></div>})}
		// </div>
		let order = this.state.relationOrder;
		if (this.state.loading === false) {
			return (
				<Stack>
					<CommandBar
						items={[
							{
								key: "assets",
								text: "Asset Ownership",
								onClick: () => {
									this.setState({ tab: "assets" });
								},
							},
							{
								key: "permissions",
								text: "Permissions",
								onClick: () => {
									this.setState({ tab: "permissions" });
								},
							},
							...[
								{
									key: "admin",
									text: "Admin Units",
									onClick: () => {
										this.setState({
											tab: "admin",
										});
									},
								},
							],
						]}
						ariaLabel="Use left and right arrow keys to navigate between commands"
					/>
					<Panel
						isOpen={this.state.userConfig}
						onDismiss={() => {
							this.setState({ userConfig: false });
						}}
						isLightDismiss={true}
					>
						<UserConfiguration
							user={this.state.selectedUser}
							onSave={() => {
								this.makeTrees();
							}}
						></UserConfiguration>
					</Panel>
					{this.state.tab == "admin" &&
						this.props.auth.permissions.find(
							(p) => p.permissionId == 1
						) &&
						this.props.auth.auth.user.adminId == 1 && (
							<>
								<Stack
									horizontal
									verticalAlign="center"
									tokens={{ childrenGap: 5 }}
								>
									<p>Change admin unit</p>
									<Dropdown
										selectedKey={
											this.state.selectedAdminUnit ?? 1
										}
										onChange={(e, val) =>
											this.setState(
												{
													selectedAdminUnit: val.key,
												},
												() => {
													this.makeTrees();
												}
											)
										}
										options={
											this.state.adminUnits?.map((au) => {
												return {
													text: au.adminName,
													key: au.adminId,
												};
											}) ?? []
										}
									></Dropdown>
									<ActionButton
										text={
											this.state.showNewAdmin
												? "Cancel"
												: "New"
										}
										iconProps={{
											iconName: this.state.showNewAdmin
												? "CalculatorSubtract"
												: "WebAppBuilderFragmentCreate",
										}}
										onClick={() => {
											this.setState({
												showNewAdmin: !(
													this.state.showNewAdmin ??
													false
												),
											});
										}}
									></ActionButton>
								</Stack>
								{this.state.showNewAdmin && (
									<Stack
										horizontal
										tokens={{ childrenGap: 3 }}
									>
										<TextField
											placeholder="New admin unit name"
											onChange={(e, val) =>
												this.setState({
													newAdminUnit: val,
												})
											}
											value={this.state.newAdminUnit}
										></TextField>
										<PrimaryButton
											text="Create"
											onClick={() => {
												fetch(
													"/api/ef/admin/adminunits",
													{
														method: "post",
														headers: {
															"Content-Type":
																"application/json",
														},
														body: JSON.stringify([
															{
																adminName:
																	this.state
																		.newAdminUnit,
																parentAdminId: 1,
															},
														]),
													}
												).then((r) => {
													setState({
														showNewAdmin: false,
													});
													this.getAdminUnits();
												});
											}}
										></PrimaryButton>
									</Stack>
								)}
							</>
						)}
					{this.state.tab != "admin" && (
						<Stack horizontal style={{ height: "95vh" }}>
							<Stack>
								<SearchBox
									placeholder="Users"
									onChange={(e) =>
										this.setState(
											{
												usersFilter:
													e?.target?.value ?? "",
											},
											this.filter
										)
									}
								/>
								<Separator></Separator>
								<Stack
									tokens={{ childrenGap: 3 }}
									style={{ overflow: "auto" }}
								>
									{users?.map((el) => {
										this.userRefs[el.userId] =
											React.createRef();
										return (
											<div
												key={el.userId}
												ref={this.userRefs[el.userId]}
											>
												<Stack
													onDragOver={(ev) => {
														ev.preventDefault();
													}}
													draggable={true}
													onDragEnter={(ev) => {
														var data = JSON.parse(
															ev.dataTransfer.getData(
																"dropData"
															)
														);
														if (data.userGroup) {
															ev.target.backgroundColor =
																"green";
														} else {
															ev.target.backgroundColor =
																"red";
														}
													}}
													onDragExit={(ev) => {
														ev.target.backgroundColor =
															"#222";
													}}
													onDrop={(ev) => {
														ev.preventDefault();
														var data = JSON.parse(
															ev.dataTransfer.getData(
																"dropData"
															)
														);
														if (data.userGroup) {
															fetch(
																`/api/usergroups/${data.userGroup.userGroupId}/users`,
																{
																	method: "put",
																	body: JSON.stringify(
																		[
																			el.userId,
																		]
																	),
																}
															).then(() => {
																this.makeTrees();
															});

															console.log(
																`Add user  ${el.username} to ${data.userGroup.userGroupName}`
															);
														} else {
															console.log(
																"Invalid drop combo"
															);
														}
													}}
													onDragStart={(ev) => {
														ev.dataTransfer.setData(
															"dropData",
															JSON.stringify({
																user: el,
															})
														);
													}}
													horizontal
													verticalAlign="center"
													horizontalAlign="space-between"
													onClick={() => {
														this.RelationsByUser(
															el.userId
														);
													}}
													style={
														this.state
															.relationOrder ==
															"user" &&
														this.state.relationId ==
															el.userId
															? selectedStyle
															: el.selected
															? relatedStyle
															: standardStyle
													}
												>
													{el.removable && (
														<IconButton
															style={{
																color: "red",
															}}
															iconProps={{
																iconName:
																	"Cancel",
															}}
															onClick={(e) => {
																e.stopPropagation();
																el.onRemove();
															}}
														></IconButton>
													)}
													{el.addable && (
														<IconButton
															style={{
																color: "green",
															}}
															iconProps={{
																iconName: "Add",
															}}
															onClick={(e) => {
																e.stopPropagation();
																el.onAdd();
															}}
														></IconButton>
													)}

													<Text>{el.username}</Text>
													<Stack horizontal>
														{this.state.newUsers?.indexOf(
															el.username
														) >= 0 && (
															<TooltipHost content="This user has not has a password reset email sent yet">
																<Icon iconName="Alert"></Icon>
															</TooltipHost>
														)}
														<IconButton
															iconProps={{
																iconName:
																	"MoreVertical",
															}}
															menuProps={{
																shouldFocusOnMount: true,
																items: [
																	{
																		key: "deleteUser",
																		text: "Delete user",
																		onClick:
																			(
																				e
																			) => {
																				if (
																					window.confirm(
																						"Do you want to delete " +
																							el.username +
																							"?"
																					)
																				)
																					this.deleteUser(
																						el.userId
																					);
																			},
																	},
																	{
																		key: "resetPassword",
																		text: "Send password reset",
																		onClick:
																			(
																				e
																			) => {
																				fetch(
																					`/api/auth/request/${el.username}`
																				);
																			},
																	},
																	{
																		key: "getLink",
																		text: "Get password reset link",
																		onClick:
																			(
																				e
																			) => {
																				fetch(
																					`/api/auth/link/${el.username}`
																				)
																					.then(
																						(
																							req
																						) =>
																							req.json()
																					)
																					.then(
																						(
																							json
																						) =>
																							window.alert(
																								json.link
																							)
																					);
																			},
																	},
																	{
																		key: "settings",
																		text: "Edit user",
																		onClick:
																			(
																				e
																			) => {
																				this.setState(
																					{
																						selectedUser:
																							el,
																						userConfig: true,
																					}
																				);
																			},
																	},
																],
															}}
														></IconButton>
													</Stack>
												</Stack>
											</div>
										);
									})}

									<Stack
										horizontal
										verticalAlign="center"
										alignContent="center"
									>
										<TextField
											value={this.state.newUsername}
											underlined
											placeholder="New User"
											onChange={(e, val) => {
												this.setState({
													newUsername: val,
												});
											}}
										></TextField>
										<IconButton
											iconProps={{ iconName: "Add" }}
											onClick={() => {
												this.addUser(
													this.state.newUsername
												);
											}}
										></IconButton>
									</Stack>
								</Stack>
							</Stack>
							<Separator vertical></Separator>
							<Stack>
								<SearchBox
									placeholder="User Groups"
									onChange={(e) =>
										this.setState(
											{
												userGroupsFilter:
													e?.target?.value ?? "",
											},
											this.filter
										)
									}
								/>
								<Separator></Separator>
								<Stack
									tokens={{ childrenGap: 3 }}
									style={{ overflow: "auto" }}
								>
									{userGroups?.map((el) => {
										this.userGroupRefs[el.userGroupId] =
											React.createRef();
										return (
											<div
												key={el.userGroupId}
												ref={
													this.userGroupRefs[
														el.userGroupId
													]
												}
											>
												<Stack
													onDragOver={(ev) => {
														ev.preventDefault();
													}}
													draggable={true}
													onDrop={(ev) => {
														ev.preventDefault();
														var data = JSON.parse(
															ev.dataTransfer.getData(
																"dropData"
															)
														);
														if (data.user) {
															fetch(
																`/api/usergroups/${el.userGroupId}/users`,
																{
																	method: "put",
																	body: JSON.stringify(
																		[
																			data
																				.user
																				.userId,
																		]
																	),
																}
															).then(() => {
																this.makeTrees();
															});

															console.log(
																`Add user ${data.user.username} to ${el.userGroupName}`
															);
														} else if (
															data.assetGroup
														) {
															fetch(
																`/api/usergroups/${el.userGroupId}/assetgroups`,
																{
																	method: "put",
																	body: JSON.stringify(
																		[
																			data
																				.assetGroup
																				.assetGroupId,
																		]
																	),
																}
															).then(() => {
																this.makeTrees();
															});

															console.log(
																`Add assetGroup ${data.assetGroup.assetGroupName} to ${el.userGroupName}`
															);
														} else {
															console.log(
																"Invalid drop combo"
															);
														}
													}}
													onDragStart={(ev) => {
														ev.dataTransfer.setData(
															"dropData",
															JSON.stringify({
																userGroup: el,
															})
														);
													}}
													horizontal
													verticalAlign="center"
													horizontalAlign="space-between"
													onClick={() => {
														this.RelationsByUserGroup(
															el.userGroupId
														);
													}}
													style={
														this.state
															.relationOrder ==
															"userGroup" &&
														this.state.relationId ==
															el.userGroupId
															? selectedStyle
															: el.selected
															? relatedStyle
															: standardStyle
													}
												>
													{el.removable && (
														<IconButton
															style={{
																color: "red",
															}}
															iconProps={{
																iconName:
																	"Cancel",
															}}
															onClick={(e) => {
																e.stopPropagation();
																el.onRemove();
															}}
														></IconButton>
													)}
													{el.addable && (
														<IconButton
															style={{
																color: "green",
															}}
															iconProps={{
																iconName: "Add",
															}}
															onClick={(e) => {
																e.stopPropagation();
																el.onAdd();
															}}
														></IconButton>
													)}
													<Text>
														{el.userGroupName}
													</Text>
													<Stack horizontal>
														<IconButton
															iconProps={{
																iconName:
																	"MoreVertical",
															}}
															menuProps={{
																shouldFocusOnMount: true,
																items: [
																	{
																		key: "deleteUserGroup",
																		text: "Delete",
																		onClick:
																			(
																				e
																			) => {
																				this.deleteUserGroup(
																					el.userGroupId
																				);
																			},
																	},
																],
															}}
														></IconButton>
													</Stack>
												</Stack>
											</div>
										);
									})}

									<Stack
										horizontal
										verticalAlign="center"
										alignContent="center"
									>
										<TextField
											value={this.state.newUserGroupName}
											underlined
											placeholder="New User Group"
											onChange={(e, val) => {
												this.setState({
													newUserGroupName: val,
												});
											}}
										></TextField>
										<IconButton
											iconProps={{ iconName: "Add" }}
											onClick={() => {
												this.addUserGroup(
													this.state.newUserGroupName
												);
											}}
										></IconButton>
									</Stack>
								</Stack>
							</Stack>
							<Separator vertical></Separator>
							{this.state.tab == "assets" && (
								<Stack horizontal>
									<Stack>
										<SearchBox
											placeholder="Asset Groups"
											onChange={(e) =>
												this.setState(
													{
														assetGroupsFilter:
															e?.target?.value ??
															"",
													},
													this.filter
												)
											}
										/>
										<Separator></Separator>
										<Stack
											tokens={{ childrenGap: 3 }}
											style={{ overflow: "auto" }}
										>
											{assetGroups?.map((el) => {
												return (
													<Stack
														key={el.assetGroupId}
														onDragOver={(ev) => {
															ev.preventDefault();
														}}
														draggable={true}
														onDrop={(ev) => {
															ev.preventDefault();
															var data =
																JSON.parse(
																	ev.dataTransfer.getData(
																		"dropData"
																	)
																);
															if (
																data.userGroup
															) {
																fetch(
																	`/api/usergroups/${data.userGroup.userGroupId}/assetgroups`,
																	{
																		method: "put",
																		body: JSON.stringify(
																			[
																				el.assetGroupId,
																			]
																		),
																	}
																).then(() => {
																	this.makeTrees();
																});
																console.log(
																	`Add assetGroup ${el.assetGroupName} to ${data.userGroup.userGroupName}`
																);
															} else if (
																data.asset
															) {
																fetch(
																	`/api/assetgroups/${el.assetGroupId}/assets`,
																	{
																		method: "put",
																		body: JSON.stringify(
																			[
																				data
																					.asset
																					.assetId,
																			]
																		),
																	}
																).then(() => {
																	this.makeTrees();
																});

																console.log(
																	`Add assetGroup ${el.assetGroupName} to ${data.asset.assetAlias}`
																);
															} else {
																console.log(
																	"Invalid drop combo"
																);
															}
														}}
														onDragStart={(ev) => {
															ev.dataTransfer.setData(
																"dropData",
																JSON.stringify({
																	assetGroup:
																		el,
																})
															);
														}}
														horizontal
														verticalAlign="center"
														horizontalAlign="space-between"
														onClick={() => {
															this.RelationsByAssetGroup(
																el.assetGroupId
															);
														}}
														style={
															this.state
																.relationOrder ==
																"assetGroup" &&
															this.state
																.relationId ==
																el.assetGroupId
																? selectedStyle
																: el.selected
																? relatedStyle
																: standardStyle
														}
													>
														<Stack
															horizontal
															horizontalAlign="begin"
														>
															{el.removable && (
																<IconButton
																	style={{
																		color: "red",
																	}}
																	iconProps={{
																		iconName:
																			"Cancel",
																	}}
																	onClick={(
																		e
																	) => {
																		e.stopPropagation();
																		el.onRemove();
																	}}
																></IconButton>
															)}
															{el.addable && (
																<IconButton
																	style={{
																		color: "green",
																	}}
																	iconProps={{
																		iconName:
																			"Add",
																	}}
																	onClick={(
																		e
																	) => {
																		e.stopPropagation();
																		el.onAdd();
																	}}
																></IconButton>
															)}

															{(this.state
																.relationOrder ==
																"userGroup" ||
																this.state
																	.relationOrder ==
																	"user") &&
																el.alert && (
																	<IconButton
																		title="Alert on"
																		iconName="AlertSettings"
																		iconProps={{
																			iconName:
																				"AlertSettings",
																		}}
																		style={{
																			color:
																				this.getAlarmColor(
																					el
																						.alert
																						?.severityMin
																				) ??
																				"#999",
																		}}
																	></IconButton>
																)}
														</Stack>
														<Text>
															{el.assetGroupName}
														</Text>
														<Stack horizontal>
															<IconButton
																iconProps={{
																	iconName:
																		"MoreVertical",
																}}
																menuProps={{
																	shouldFocusOnMount: true,
																	items: [
																		this
																			.state
																			.relationOrder ==
																			"asset" ||
																		this
																			.state
																			.relationOrder ==
																			"userGroup"
																			? el.selected
																				? {
																						key: "removeAssetGroup",
																						text:
																							this
																								.state
																								.relationOrder ==
																							"userGroup"
																								? "Remove from user group"
																								: "Remove asset from this group",
																						onClick:
																							(
																								e
																							) => {
																								el.onRemove();
																							},
																				  }
																				: {
																						key: "addAssetGroup",
																						text:
																							this
																								.state
																								.relationOrder ==
																							"userGroup"
																								? "Add to user group"
																								: "Add asset to this group",
																						onClick:
																							(
																								e
																							) => {
																								el.onAdd();
																							},
																				  }
																			: {},
																		{
																			key: "deleteUserGroup",
																			text: "Delete",
																			onClick:
																				(
																					e
																				) => {
																					this.deleteAssetGroup(
																						el.assetGroupId
																					);
																				},
																		},
																		{
																			key: "alerts",
																			text: "Alerts",
																			disabled:
																				this
																					.state
																					.relationOrder !=
																				"userGroup",
																			items: [
																				{
																					key: "unsub",
																					text: "Remove all alerts",
																					onClick:
																						() => {
																							el.setAlertLevel(
																								0
																							);
																						},
																				},
																				{
																					key: "alarms",
																					text: "Alert on alarm",
																					onClick:
																						() => {
																							el.setAlertLevel(
																								10
																							);
																						},
																				},
																				{
																					key: "warnings",
																					text: "Alert on warning or alarm",
																					onClick:
																						() => {
																							el.setAlertLevel(
																								5
																							);
																						},
																				},
																				{
																					key: "messages",
																					text: "Alert for all important updates",
																					onClick:
																						() => {
																							el.setAlertLevel(
																								2
																							);
																						},
																				},
																			],
																		},
																	],
																}}
															></IconButton>
														</Stack>
													</Stack>
												);
											})}

											<Stack
												horizontal
												verticalAlign="center"
												alignContent="center"
											>
												<TextField
													value={
														this.state
															.newAssetGroupName
													}
													underlined
													placeholder="New Asset Group"
													onChange={(e, val) => {
														this.setState({
															newAssetGroupName:
																val,
														});
													}}
												></TextField>
												<IconButton
													iconProps={{
														iconName: "Add",
													}}
													onClick={(e) => {
														this.addAssetGroup(
															this.state
																.newAssetGroupName
														);
													}}
												></IconButton>
											</Stack>
										</Stack>
									</Stack>
									<Separator vertical></Separator>
									<Stack>
										<SearchBox
											placeholder="Assets"
											onChange={(e) =>
												this.setState(
													{
														assetsFilter:
															e?.target?.value ??
															"",
													},
													this.filter
												)
											}
										/>
										<Separator></Separator>
										<Stack
											tokens={{ childrenGap: 3 }}
											style={{ overflow: "auto" }}
										>
											{assets
												.sort((a, b) => {
													return (
														b.selected - a.selected
													);
												})
												?.map((el) => {
													return (
														<Stack
															key={el.assetId}
															onDragOver={(
																ev
															) => {
																ev.preventDefault();
															}}
															draggable={true}
															onDrop={(ev) => {
																ev.preventDefault();
																var data =
																	JSON.parse(
																		ev.dataTransfer.getData(
																			"dropData"
																		)
																	);
																if (
																	data.assetGroup
																) {
																	fetch(
																		`/api/assetgroups/${data.assetGroup.assetGroupId}/assets`,
																		{
																			method: "put",
																			body: JSON.stringify(
																				[
																					el.assetId,
																				]
																			),
																		}
																	).then(
																		() => {
																			this.makeTrees();
																		}
																	);
																	console.log(
																		`Add assetGroup ${data.assetGroup.assetGroupName} to ${el.assetAlias}`
																	);
																} else {
																	console.log(
																		"Invalid drop combo"
																	);
																}
															}}
															onDragStart={(
																ev
															) => {
																ev.dataTransfer.setData(
																	"dropData",
																	JSON.stringify(
																		{
																			asset: el,
																		}
																	)
																);
															}}
															horizontal
															verticalAlign="center"
															horizontalAlign="space-between"
															onClick={() => {
																this.RelationsByAsset(
																	el.assetId
																);
															}}
															style={
																this.state
																	.relationOrder ==
																	"asset" &&
																this.state
																	.relationId ==
																	el.assetId
																	? selectedStyle
																	: el.selected
																	? relatedStyle
																	: standardStyle
															}
														>
															<Stack
																horizontal
																horizontalAlign="begin"
															>
																{el.removable ? (
																	<IconButton
																		style={{
																			color: "red",
																		}}
																		iconProps={{
																			iconName:
																				"Cancel",
																		}}
																		onClick={(
																			e
																		) => {
																			e.stopPropagation();
																			el.onRemove();
																		}}
																	></IconButton>
																) : (
																	<></>
																)}
																{el.addable ? (
																	<IconButton
																		style={{
																			color: "green",
																		}}
																		iconProps={{
																			iconName:
																				"Add",
																		}}
																		onClick={(
																			e
																		) => {
																			e.stopPropagation();
																			el.onAdd();
																		}}
																	></IconButton>
																) : (
																	<></>
																)}
															</Stack>
															<Stack.Item
																grow={1}
															>
																<Text>
																	{
																		el.assetAlias
																	}
																</Text>
															</Stack.Item>

															<Stack horizontal>
																<IconButton
																	iconProps={{
																		iconName:
																			"MoreVertical",
																	}}
																	menuProps={{
																		shouldFocusOnMount: true,
																		items:
																			this
																				.state
																				.relationOrder ==
																			"assetGroup"
																				? [
																						el.selected
																							? {
																									key: "changeGroup",
																									text: "Remove from asset group",
																									onClick:
																										(
																											e
																										) => {
																											el.onRemove();
																										},
																							  }
																							: {
																									key: "changeGroup",
																									text: "Add to asset group",
																									onClick:
																										(
																											e
																										) => {
																											el.onAdd();
																										},
																							  },
																				  ]
																				: [],
																	}}
																></IconButton>
															</Stack>
														</Stack>
													);
												})}
										</Stack>
									</Stack>
								</Stack>
							)}

							{this.state.tab == "permissions" && (
								<Stack>
									<Text>
										{this.state.permissionHeader +
											" Permissions"}
									</Text>
									<Separator></Separator>
									<table>
										<tr>
											<th>Type</th>
											<th>
												<Checkbox
													label="View"
													disabled={
														this.state
															.relationOrder !=
														"userGroup"
													}
													defaultIndeterminate
													defaultChecked={true}
													indeterminate={
														this.state
															.viewIndeterminate
													}
													onChange={(e, val) => {
														var x = [
															...this.state
																.editablePermissions,
														];
														x.forEach((el) => {
															el.view = val;
														});
														this.setState({
															permissionsChanged: true,
															viewIndeterminate: false,
															editablePermissions:
																x,
														});
													}}
												/>
											</th>
											<th>
												{" "}
												<Checkbox
													label="Create"
													disabled={
														this.state
															.relationOrder !=
														"userGroup"
													}
													defaultIndeterminate
													defaultChecked={true}
													indeterminate={
														this.state
															.createIndeterminate
													}
													onChange={(e, val) => {
														var x = [
															...this.state
																.editablePermissions,
														];
														x.forEach((el) => {
															el.create = val;
														});
														this.setState({
															permissionsChanged: true,
															createIndeterminate: false,
															editablePermissions:
																x,
														});
													}}
												/>
											</th>
											<th>
												{" "}
												<Checkbox
													label="Delete"
													disabled={
														this.state
															.relationOrder !=
														"userGroup"
													}
													defaultIndeterminate
													defaultChecked={true}
													indeterminate={
														this.state
															.deleteIndeterminate
													}
													onChange={(e, val) => {
														var x = [
															...this.state
																.editablePermissions,
														];
														x.forEach((el) => {
															el.delete = val;
														});
														this.setState({
															permissionsChanged: true,
															deleteIndeterminate: false,
															editablePermissions:
																x,
														});
													}}
												/>
											</th>
											<th>
												{" "}
												<Checkbox
													label="Modify"
													disabled={
														this.state
															.relationOrder !=
														"userGroup"
													}
													defaultIndeterminate
													defaultChecked={true}
													indeterminate={
														this.state
															.modifyIndeterminate
													}
													onChange={(e, val) => {
														var x = [
															...this.state
																.editablePermissions,
														];
														x.forEach((el) => {
															el.modify = val;
														});
														this.setState({
															permissionsChanged: true,
															modifyIndeterminate: false,
															editablePermissions:
																x,
														});
													}}
												/>
											</th>
										</tr>
										{this.state.editablePermissions?.map(
											(grp, i) => {
												return (
													<tr>
														<td>{grp.type}</td>
														<td>
															<Checkbox
																disabled={
																	this.state
																		.relationOrder !=
																	"userGroup"
																}
																checked={
																	grp.view
																}
																onChange={(
																	e,
																	val
																) => {
																	this.updateEditablePermission(
																		grp.type,
																		"view",
																		val
																	);
																}}
															/>
														</td>
														<td>
															<Checkbox
																disabled={
																	this.state
																		.relationOrder !=
																	"userGroup"
																}
																checked={
																	grp.create
																}
																onChange={(
																	e,
																	val
																) => {
																	this.updateEditablePermission(
																		grp.type,
																		"create",
																		val
																	);
																}}
															/>
														</td>
														<td>
															<Checkbox
																disabled={
																	this.state
																		.relationOrder !=
																	"userGroup"
																}
																checked={
																	grp.delete
																}
																onChange={(
																	e,
																	val
																) => {
																	this.updateEditablePermission(
																		grp.type,
																		"delete",
																		val
																	);
																}}
															/>
														</td>
														<td>
															<Checkbox
																disabled={
																	this.state
																		.relationOrder !=
																	"userGroup"
																}
																checked={
																	grp.modify
																}
																onChange={(
																	e,
																	val
																) => {
																	this.updateEditablePermission(
																		grp.type,
																		"modify",
																		val
																	);
																}}
															/>
														</td>
													</tr>
												);
											}
										)}
									</table>

									<Stack
										horizontal
										tokens={{ childrenGap: 20 }}
									>
										<DefaultButton
											text="Clear All"
											onClick={() => {
												this.setState({
													viewIndeterminate: true,
													createIndeterminate: true,
													deleteIndeterminate: true,
													modifyIndeterminate: true,
													editablePermissions: [
														...defaultPermissions,
													],
													permissionsChanged: true,
												});
											}}
										/>
										{this.state.permissionsChanged && (
											<DefaultButton
												text="Revert"
												onClick={() => {
													this.setState({
														viewIndeterminate: true,
														createIndeterminate: true,
														deleteIndeterminate: true,
														modifyIndeterminate: true,
														editablePermissions:
															this.state
																.originalPermissions,
														permissionsChanged: false,
													});
												}}
											/>
										)}

										{this.state.permissionsChanged && (
											<PrimaryButton
												text="Save Changes"
												onClick={() => {
													this.applyPermissions();
												}}
											/>
										)}
									</Stack>
								</Stack>
							)}
						</Stack>
					)}
				</Stack>
			);
		} else {
			return (
				<>
					<div
						style={{
							height: "94vh",
							position: "fixed",
							top: "50%",
							left: "50%",
							transform: "translateX(-50%)",
						}}
					>
						<Spinner size={SpinnerSize.large} />
					</div>
				</>
			);
		}
	}
}
function mapStateToProps({ auth }) {
	return {
		auth,
	};
}

export default connect(mapStateToProps, null)(Ownership);
